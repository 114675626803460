@import "theme";


table {
  width: 100%;
  margin-bottom: 0;

  thead {
    tr {
      .heading {
        font-weight: 600 !important;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba(112, 51, 255, 0.1);
      }

      &.active {
        background-color: rgba(112, 51, 255, 0.1);
      }

      td {
        padding: 15px 20px 8px 24px;

        .question_text {
          font-size: 18px;
          font-weight: 600;
        }

        .question_result {
          font-size: 16px;
          margin-left: -22px;
          font-weight: 500;
        }
      }
    }
  }
}