@import "theme";

.mHeader {
  display: block;
  padding: 0;
  :global(.modal-title) {
    font-weight: 600;
    font-size: 20px;
    color: #37324b;
    letter-spacing: 0.26px;
    :global(span) {
      font-weight: 500;
      display: block;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0.36px;
    }
  }
}
.mBody {
  position: relative;
  :global(.box-light-gradient) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  :global(.custom-table) {
    position: relative;
    max-height: 340px;
    overflow-y: auto;
    padding: 30px 25px 0;
    th {
      border-bottom-width: 1px;
      border-top: none;
      font-weight: 600;
      font-size: 14px;
      padding: 0 5px 10px;
    }
    td {
      font-weight: 600;
      cursor: pointer;
      font-size: 16px;
      color: #37324b;
      letter-spacing: 0.36px;
      padding: 20px 5px;
      &.preview_heading {
        text-align: end;
        padding-right: 20px;
      }
    }
  }
}
:global(.que_template_modal) {
  :global(input[name="searchBox"]) {
    + :global(.btn-primary) {
      min-width: auto;
    }
  }
  :global(.btn-primary) {
    font-size: 14px;
    min-width: 170px;
  }
  :global(.btn) {
    font-weight: 600;
  }
}
.no_templates {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  height: 100px;
  span {
    font-weight: 500;
    font-size: 14px;
    color: #bdbcc3;
    letter-spacing: 0.32px;
    text-align: center;
  }
}
.templatePreview {
  font-weight: 500;
  font-size: 14px;
  color: $primaryColor;
}
