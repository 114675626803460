@import "theme";

.tooltip_wrapper {
  position: relative;

  .tooltip_info {
    z-index: 1999;
    position: absolute;
    left: -20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 0.53px;
    font-size: 14px;
    top: 40px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
    @include transition(0.5s all);
    @include border-radius(4px);
    &::before {
      left: 24px;
      height: 7px;
      width: 7px;
      border-top: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;
      transform: rotate(-45deg);
      top: -4px;
      background-color: #fff;
      margin: auto;
      content: "";
      position: absolute;
      box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
    }
  }

  > span {
    cursor: pointer;

    &:hover {
      + .tooltip_info {
        opacity: 1;
        visibility: visible;
        top: 25px;
      }
    }
  }
}
