@import "theme";

.my_certificate_table {
  // overflow-x: auto;
  padding-top: 15px;

  :global(.table) {
    margin-bottom: 0;

    thead {
      th {
        border-top: 0;
        border-bottom-width: 1px;
        width: 45%;

        &:nth-child(2) {
          width: 31%;
        }

        &:nth-child(3) {
          width: 14%;
        }

        &:last-child {
          width: 10%;
        }
      }
    }

    tbody {
      td {
        font-weight: 500;
        font-size: 15px;
        vertical-align: top;

        > :global(.fill-svg-icon-h) {
          display: inline-block;
          margin-right: 20px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }

        .certificate_info_tooltip {
          display: inline-block;
          margin-left: 10px;
          position: relative;
          top: -1px;

          :global(.box-info) {
            + :global(.info-attach-file) {
              top: auto;
              bottom: 50px;
              min-width: 135px;
            }

            &:hover {
              + :global(.info-attach-file) {
                top: auto;
                bottom: 30px;
              }
            }
          }

          :global(.info-attach-file) {
            min-width: 115px;
            padding: 10px;
            text-align: center;
            top: -83px;

            :global(h5) {
              font-size: 14px;
              color: #74798c;
            }

            :global(.expired-days) {
              font-size: 14px;
              color: #050505;
              font-weight: 500;
            }
          }
        }

        .valid {
          background: #f9f7ff;
          border: 1px solid rgba(149, 106, 251, 0.23);
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          color: #814cff;
          letter-spacing: 0.33px;
          text-align: center;
          padding: 5px 10px;
          display: inline-block;
          width: 75px;
        }

        .expired {
          background: #fcf6f4;
          border: 1px solid #fce6dd;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 600;
          color: #fd8c61;
          letter-spacing: 0.33px;
          text-align: center;
          padding: 5px 10px;
          display: inline-block;
          width: 75px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .my_certificate_table {
    overflow-x: auto;
    :global(.table) {
      width: 880px;
      tbody {
        td {
          font-size: 14px;

          > :global(.fill-svg-icon-h) {
            margin-right: 10px;
          }

          .certificate_info_tooltip {
            :global(.info-attach-file) {
              min-width: 90px;
            }

            :global(.info-attach-file) {
              &::before {
                left: 10px;
              }

              :global(h5) {
                font-size: 13px;
              }

              :global(.expired-days) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .my_certificate_table {
    :global(.table) {
      width: 767px;
    }
  }
}
