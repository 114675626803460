@import "theme";
.notification_filter_wrapper {
  padding: 25px 25px 0px;
  position: relative;
  display: block;
  .title_text_wrapper,
  .subject_text_wrapper {
    margin-bottom: 20px;
    padding: 0 0;
    :global(.form-group) {
      margin-bottom: 20;
      :global(label) {
        font-weight: 600;
        font-size: 16px;
        color: #322e46;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        display: block;
      }
    }
  }
}
.action_wrapper {
  width: 25%;
  position: relative;
  text-align: center;
  .eye_icon {
    &:hover {
      svg {
        g {
          g {
            fill: #7033ff;
          }
        }
      }
      cursor: pointer;
    }
  }
}
.campaignName {
  font-size: 16px;
  font-weight: 600;
  color: $themeColor11;
  letter-spacing: 0.36px;
  :hover {
    color: #7033ff;
    cursor: pointer;
  }
}

.campaignNamePastQuiz {
  font-size: 16px;
  font-weight: 600;
  color: $themeColor11;
  letter-spacing: 0.36px;
}

.custom_table {
  position: relative;
  thead {
    th {
      padding: 10px;
      border: none;
      &:nth-child(2) {
        width: 15%;
      }
    }
  }
}
.quizButton {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 240px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33px;
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  height: 66px;
  border-radius: 33px;
  left: 40%;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  outline: none;
  &:focus {
    outline: none;
  }
}
@media only screen and (max-width: 767px) {
  .quizButton {
    margin-left: 100px;
    // align-items: center;
    width: 240px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33px;
    background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
    height: 66px;
    border-radius: 33px;
    left: 40%;
  }

  .notification_filter_wrapper {
    .title_text_wrapper {
      .custom_table {
        position: relative;
        overflow-x: auto !important;
        width: 590;
      }
    }
  }
}
