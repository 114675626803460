@import "../../theme.scss";
:global(.toast){
    border: 0px solid #000;
    position: fixed;
    right: 10px;
    top: 80px;
    width: 320px;
    z-index: 1;
    box-shadow: 0 0 6px 3px rgba(233,233,233,0.50);
    border-radius: 12px;
    // &:global(.show){
    //   -webkit-animation: slide-in 0.5s, slide-out 0.5s 9.5s;
    //   animation: slide-in 0.5s, slide-out 0.5s 9.5s;
     
    // }

}
:global(.toast-body){
    background: #FFFFFF;

}
.toastwrapper{
    position: relative;
    .toastclose{
      position: absolute;
      right: 10px;
      top: 0px;
      line-height:0px;
    }
    .tostimage{
      display: inline-flex;
      vertical-align: top;
      margin-right: 10px;
      margin-top: 0px;
    }
    .toastcontent{
      display: inline-block;
      margin-top: 0px;
      width: calc(100% - 55px);
      .toastheader{
          padding: 0;
          margin: 0;
          color: $primaryText;
          font-size: 16px;
          font-weight: 600;
      }
      .toasttext{
          padding: 0;
          margin: 0;
          font-size: 14px;
          color: $textShade1;
          font-weight: 500;
      }
    }
}



.autoslide{
  -webkit-animation: slide-in 0.5s, slide-out 0.5s 2.5s;
  animation: slide-in 0.5s, slide-out 0.5s 2.5s;
}
.enter{
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;

}
.dismiss {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

@keyframes slide-in {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes slide-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  @keyframes slide-out {
    0% {
      transform: translateX(0%);
      // opacity: 0;
      // display: none;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
      display: none;
    }
  }
  
  // @-webkit-keyframes slide-out {
  //   0% {
  //     -webkit-transform: translateX(0%);
  //   }
  //   100% {
  //     -webkit-transform: translateX(100%);
  //   }
  // }