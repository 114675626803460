@import "theme";

.cycleListWrapper {
  display: block;
  .create_new_btn {
    padding: 10px 20px;
  }
  .box_2 {
    @include border-radius(8px 8px 0 0);
    margin-bottom: 0;
    padding-bottom: 0;
  }
  :global(.outer-table-wrapper) {
    min-height: 300px;
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 0;
    .tab_table_responsive {
      margin-bottom: 0;
      thead {
        th {
          padding: 10px;
          border: none;
          width: 15%;
          &:nth-child(2) {
            width: 70%;
          }
        }
      }
      tbody {
        td {
          padding: 20px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          a {
            font-weight: 600;
            font-size: 16px;
            color: $themeColor11;
            letter-spacing: 0.36px;
            &:hover {
              color: $primaryColor;
            }
          }
          span {
            font-size: 16px;
            font-weight: 500;
            color: $themeColor11;
            display: flex;
            .show_grade_tooltip {
              position: relative;
              .info_icon {
                margin-left: 10px;
                cursor: pointer;
                &:hover {
                  + .show_grade_tooltip_info {
                    opacity: 1;
                    visibility: visible;
                    top: 35px;
                  }
                }
              }
              .show_grade_tooltip_info {
                padding: 10px 15px;
                border: 1px solid #eaeaea;
                min-width: 210px;
                box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
                background-color: #fff;
                position: absolute;
                z-index: 2;
                font-weight: 600;
                top: 45px;
                left: -10px;
                opacity: 0;
                visibility: hidden;
                border-radius: 4px;
                transition: 0.5s all;
                pointer-events: none;
                span {
                  color: #928fa0;
                  font-weight: normal;
                }
                &:before {
                  left: 24px;
                  height: 7px;
                  width: 7px;
                  border-top: 1px solid #eaeaea;
                  border-right: 1px solid #eaeaea;
                  transform: rotate(-45deg);
                  top: -4px;
                  background-color: #fff;
                  margin: auto;
                  content: "";
                  position: absolute;
                  box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
          .ended,
          .inprogress,
          .view {
            font-size: 12px;
            font-weight: 600;
            padding: 4px 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: inline-block;
            min-width: 86px;
            text-align: center;
          }
          .inprogress {
            color: #33c5ff;
            background: #f0fafd;
            border-color: #cff1ff;
          }
          .ended {
            color: #fd8c61;
            background: #fcf6f4;
            border-color: #fce6dd;
          }
          .view {
            color: #33c5ff;
            background: #f0fafd;
            border-color: #cff1ff;
          }
          .edit_cycle {
            cursor: pointer;
            display: inline-block;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .cycleListWrapper {
    .custom_table {
      .tab_table_responsive {
        .show_grade_tooltip {
          .show_grade_tooltip_info {
            left: -86px !important;
            &:before {
              left: 100px !important;
            }
          }
        }
      }
    }
  }
}
