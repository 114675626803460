@import "theme";
.switch_wrapper {
  display: flex;
  cursor: pointer;
  background: white;
  width: 140px;
  margin-bottom: 20px;
  border-radius: 8px;
  margin: 20px auto;
  justify-content: center;
  .left_side_switch,
  .right_side_switch {
    padding: 10px 20px;
    svg {
      g {
        fill: #b5afca;
      }
    }
    &.active {
      border-bottom: 3px solid $primaryColor;
      svg {
        g {
          fill: $primaryColor;
        }
      }
    }
  }
}
.round_switch {
  .switch_label {
    position: relative;
    top: -5px;
    margin-right: 10px;
    font-size: 14px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 10px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 0;
      bottom: -6px;
      box-shadow: 2px 2px 12px grey;
      background-image: radial-gradient(#2196f3 10%, white, white);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
    .disabled::before {
      background-image: radial-gradient(#ccc 10%, white, white) !important;
    }
    :global(.assessment::before) {
      background-image: radial-gradient(#71dcc9 10%, white, white) !important;
    }
    :global(.projects::before) {
      background-image: radial-gradient(#ff895b 10%, white, white) !important;
    }
    :global(.promotion::before) {
      background-image: radial-gradient(#25a400 10%, white, white) !important;
    }
    :global(.rewards::before) {
      background-image: radial-gradient(#ffcb00 10%, white, white) !important;
    }

    input:checked + .slider {
      background-color: #ccc;
      &:global(.assessment) {
        background-color: #71dcc9;
      }
      &:global(.projects) {
        background-color: #ff895b;
      }
      &:global(.promotion) {
        background-color: #25a400;
      }
      &:global(.rewards) {
        background-color: #ffcb00;
      }
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(19px);
      -ms-transform: translateX(19px);
      transform: translateX(19px);
    }
  }
}
