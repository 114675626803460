@import "../../../theme.scss";

.hamburger_menu {
  display: none;
  height: 12px;
  width: 18px;
  position: relative;
  cursor: pointer;
  .line {
    height: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    background-color: $whiteColor;
    border-radius: 3px;
    &:before {
      height: 2px;
      position: absolute;
      top: -5px;
      width: 100%;
      background-color: $whiteColor;
      border-radius: 3px;
      content: "";
    }
    &:after {
      height: 2px;
      position: absolute;
      bottom: -5px;
      width: 100%;
      background-color: $whiteColor;
      border-radius: 3px;
      content: "";
    }
  }
}

:global(.mobile_nav_wrapper) {
  position: fixed;
  left: -400px;
  top: 0;
  width: 400px;
  height: 100%;
  transition: 0.5s all;
  box-sizing: border-box;
  z-index: 11; // 11 instead of 3
  display: block;
  &:global(.show_mobile_nav) {
    left: 0;
    :global(.mobile_nav_overlay) {
      z-index: auto;
      opacity: 1;
      visibility: visible;
    }
    :global(.header-top) {
      transition-delay: 1s;
    }
  }
  :global(.mobile_nav_overlay) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: auto;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    right: 0;
    transition: 0.5s all;
    opacity: 0;
    visibility: hidden;
    z-index: -999;
  }
  :global(.mobile_nav_slider) {
    background: $primaryText;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    transition: right 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94), box-shadow 500ms ease;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 3;
    :global(.header) {
      height: auto;
      padding: 20px;
      background: linear-gradient(180deg, #463e6e 0%, #322e46 100%);
      border-bottom: 1px solid rgba(216, 216, 216, 0.1);
      :global(.header-top) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition-duration: 0.5s;
      }
      :global(.mobile_nav_close) {
        justify-content: flex-end;
        display: flex;
        > :global(div) {
          cursor: pointer;
        }
      }
      :global(.profile-detail-wrapper) {
        background-image: linear-gradient(180deg, #534f69 8%, #57526b 98%);
        border-radius: 38.5px;
        &:global(.active) {
          border-radius: 15px;
        }
        :global(.profile-detail) {
          padding: 15px;
          margin: 25px 0 0;
        }
        :global(.user_icon) {
          display: inline-block;
          background: rgb(84, 161, 255);
          font-weight: 600;
          font-size: 16px;
          color: white;
          text-align: center;
          line-height: 29px;
          height: 45px;
          width: 45px;
          border: 2px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          vertical-align: middle;
          :global(img) {
            max-width: 100%;
          }
        }
        :global(.emp-info) {
          display: inline-block;
          width: calc(100% - 80px);
          padding: 0 15px;
          vertical-align: middle;
          color: #fff;
          :global(h5) {
            font-weight: 600;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          :global(span) {
            font-weight: 400;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        :global(.logoff) {
          background: url(../../../assets/images/ic_log_off.svg) no-repeat center;
          display: inline-block;
          height: 30px;
          width: 30px;
          vertical-align: middle;
        }
        :global(.mobile-admin-nav-menu) {
          display: block;
          padding-bottom: 15px;
          a {
            display: block;
            padding: 13px 0 0 20px;
            color: white;
            text-decoration: none;
            &:global(.last-menu) {
              padding-bottom: 13px;
            }
          }
          :global(.divide-line) {
            border: 1px solid #ffffff;
            opacity: 0.1;
          }
        }
      }
    }
  }
  :global(.mobile_nav_list_wrapper) {
    height: calc(100% - 199px);
    overflow-y: auto;
    padding: 10px 0;
    :global(ul) {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    :global(.nav-link) {
      li {
        position: relative;
        display: inline-block;
        width: 100%;
        &:global(.isSubmenu) {
          &:after {
            content: "";
            position: absolute;
            height: 10px;
            width: 15px;
            right: 20px;
            top: 17px;
            background: url(../../../assets/images/ic_arrow_down_white.svg) no-repeat center;
            background-size: 13px;
            transform: rotate(-90deg);
            transition: 0.5s all ease-in-out;
          }
        }
        &:global(.open) {
          &:after {
            transform: rotate(0deg);
          }
        }
        a {
          font-size: 16px;
          font-weight: 500;
          width: 100%;
          padding: 10px 20px;
          display: inline-block;
          width: 100%;
          color: #fff;
          &:global(.selected) {
            color: #fff !important;
            background-image: linear-gradient(270deg, #322e46 14%, #57526d 100%);
          }
        }
        :global(.sub-nav-links) {
          li {
            a {
              font-size: 14px;
              color: #928fa0;
              padding-left: 40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .hamburger_menu {
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  :global(.mobile_nav_wrapper) {
    left: -280px;
    width: 280px;

    :global(.mobile_nav_slider) {
      :global(.header) {
        :global(.header-top) {
          :global(.logo) {
            width: 120px;
            :global(img) {
              max-width: 100%;
            }
          }
        }
        :global(.profile-detail-wrapper) {
          :global(.user_icon) {
            height: 32px;
            width: 32px;
          }
          :global(.emp-info) {
            width: calc(100% - 55px);
            padding: 0 0 0 5px;
            :global(h5) {
              font-size: 14px;
            }
            :global(span) {
              font-size: 13px;
            }
          }
          :global(.logout) {
            display: inline-block;
            height: 20px;
            width: 20px;
            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
    :global(.mobile_nav_list_wrapper) {
      :global(.nav-link) {
        li {
          a {
            font-size: 14px;
          }
          :global(.sub-nav-links) {
            li {
              a {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
