@import "../../theme.scss";

.switches {
  display: inline;
  :global(.custom-switch){
    padding-left: 0;
    :global(.custom-control-input){
      &:focus{
        ~ :global(.custom-control-label){
          &:before{
            border-color: #E9E9EB;
            box-shadow: none;
            outline: none;
          }
        }
      }
      &:checked{
        ~ :global(.custom-control-label){
          &:before{
            background-color: #00C4BC;
            border-color: #00C4BC;
          }
          &:after{
            right: 14px;
            box-shadow: 0 2px 2px 0 rgba(45,45,45,0.20);
            border: none;
          }
        }
      }
    }
    :global(.custom-control-label){
      font-weight: 600;
      color: #322E46;
      letter-spacing: 0.47px;
      line-height: 24px;
      padding-right: 55px;
      &:before{
        left: auto;
        right: 0;
        width:40px;
        height: 24px;
        border-radius: 25px;
        background: #FFFFFF;
        border: 1px solid #E9E9EB;
        top: 0;
      }
      &:after{
        right: 17px;
        left: auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        top: 2px;
        background: #FFFFFF;
        border: 1px solid rgba(45,45,45,0.20);
      }
    }
  }
}