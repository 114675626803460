@import "../../../../theme.scss";

.viewFeedbackContainer {
  width: 100%;
  padding-bottom: 25px;
  position: relative;
}
.view_feedback_link {
  color: #7033ff;
  position: absolute;
  left: 48%;
  transition: all 0.4s ease-out;
  text-decoration: none;
  cursor: pointer;
}
.teams_feedback_wrapper {
  padding-bottom: 10px;
  margin-bottom: 0;
  padding-top: 0;
  &:global(.active) {
    padding-top: 0;
    :global(.box-heading-wrapper) {
      display: none !important;
    }
    .filter_info_wrapper {
      display: block;
      z-index: 10;
    }
  }
  .panel_title {
    position: relative;
    @include flexbox;
    padding: 15px 0 10px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    span {
      font-weight: 600;
      font-size: 14px;
      color: #322e46;
      letter-spacing: 0.47px;
    }
    .nameTitle {
      padding-left: 30px;
      width: 35%;
    }
    .statusTitle {
      width: 13%;
    }
    .dateTitle {
      width: 12%;
    }
  }
  .teams_feedback_list {
    @include flexbox;
    @include justify-content(space-between);
    padding: 5px;
  }
  .teams_feedback_list:first-child {
    border-top: 0;
  }
  > :global(.box-inner-pad) {
    padding: 25px 25px 0 !important;
  }
  .my_team_feedback_box {
    &:global(.box) {
      margin-bottom: 0 !important;
      padding: 25px 0 0 !important;
    }
  }
}
.listing_wrapper {
  position: relative;
  .item {
    display: inline-block;
    width: 100%;
    &:nth-child(n + 2) {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
    .item_info_top_wrapper {
      padding: 15px 0;
      &:global(.active) {
        background-color: rgba(246, 245, 250, 0.5);
      }
    }
    .item_feedback_top_wrapper {
      padding: 0px 38px 0px 38px;
      background-color: rgb(255, 255, 255);
    }
  }
}

.item_feedback_top_bar {
  @include flexbox;
  @include align-items(center);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  .member_info {
    width: 35%;
    @include flexbox;
    @include align-items(center);
    padding-left: 0;
    position: relative;
    .expand_collaspe_Wrapper_Container {
      width: 27px;
    }
    .expand_collapse_wrapper {
      cursor: pointer;
      position: static;
      margin-right: 10px;
    }
    .user_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user_info {
      @include flexbox;
      flex-direction: column;
      @include align-items(flex-start);
      .username {
        font-size: 16px;
        color: #322e46;
        font-weight: 600;
      }
      .department {
        font-size: 14px;
        color: #322e46;
        width: 85%;
        letter-spacing: 0.39px;
        font-weight: 400;
        margin-top: 2px;
      }
    }
  }
  .member_feedback_info {
    width: 80%;
    @include flexbox;
    @include align-items(center);
    padding-left: 0;
    align-self: start;
    position: relative;
    .expand_collaspe_Wrapper_Container {
      width: 27px;
    }
    .expand_collapse_wrapper {
      cursor: pointer;
      position: static;
    }
    .user_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user_info {
      @include flexbox;
      flex-direction: column;
      @include align-items(flex-start);
      .username {
        font-size: 16px;
        color: #322e46;
        font-weight: 600;
      }
      .department {
        font-size: 14px;
        color: #322e46;
        width: 90%;
        letter-spacing: 0.39px;
        font-weight: 400;
        margin-top: 2px;
      }
    }
  }
  .skillgaps_text {
    width: 14%;
    font-weight: 600;
    font-size: 16px;
    color: #37324b;
    letter-spacing: 0.36px;
  }

  .questions_feedback_answer_row {
    display: flex;
    width: 75%;
  }

  :global(.action-dropdown-wrapper) {
    width: 10%;
    :global(svg) {
      * {
        fill: #212529;
      }
    }
    :global(.dropdown-item) {
      &:hover {
        color: $primaryColor;
        :global(svg) {
          * {
            fill: $primaryColor;
          }
        }
      }
    }
  }
}

.item_topbar {
  @include flexbox;
  @include align-items(center);
  .member_info {
    width: 35%;
    @include flexbox;
    @include align-items(center);
    padding-left: 0;
    position: relative;
    .expand_collaspe_Wrapper_Container {
      width: 27px;
    }
    .expand_collapse_wrapper {
      cursor: pointer;
      position: static;
      margin-right: 10px;
    }
    .user_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user_info {
      @include flexbox;
      flex-direction: column;
      @include align-items(flex-start);
      .username {
        font-size: 16px;
        color: #322e46;
        font-weight: 600;
      }
      .department {
        font-size: 14px;
        color: #322e46;
        width: 85%;
        letter-spacing: 0.39px;
        font-weight: 400;
        margin-top: 2px;
      }
    }
  }
  .skillgaps_text {
    width: 14%;
    font-weight: 600;
    font-size: 16px;
    color: #37324b;
    letter-spacing: 0.36px;
  }

  .questions_feedback_answer_row {
    display: flex;
    width: 75%;
  }

  :global(.action-dropdown-wrapper) {
    width: 10%;
    :global(svg) {
      * {
        fill: #212529;
      }
    }
    :global(.dropdown-item) {
      &:hover {
        color: $primaryColor;
        :global(svg) {
          * {
            fill: $primaryColor;
          }
        }
      }
    }
  }
}

.feedbackListContainer {
  width: 100%;
  margin-bottom: 0;
}

.searchIconStyle {
  padding: 10px;
}

.searchBoxContainer {
  padding: 10px;
}

.closeIconStyle {
  padding: 20px;
}
.button_group {
  margin-top: 20px;
  :global(.btn-secondary) {
    min-width: 120px;
    margin-right: 15px;
    padding: 5px;
    height: 40px;
  }
}
.date_range_wrapper {
  :global(.cl_t_wrapper) {
    padding: 25px 0 0 25px;
  }
}
