@import "theme";

.made_my_day_box {
  position: relative;
  h6 {
    color: $textShade1;
    margin-top: 3px;
  }
}

:global(.box-heading-light) {
  &:global(.fs-15) {
    margin-top: 5px;
    font-size: 15px !important;
  }
}
.made_my_day_items {
  .made_my_day_item {
    border: 1px solid $borderColor;
    padding: 12px 15px 14px;
    font-size: 16px;
    margin-top: 12px;
    line-height: 19px;
    color: $primaryText;
    position: relative;
    @include flexbox;
    // @include align-content(center);
    // @include justify-content(center);
    @include border-radius(4px);
    word-break: break-word;
    flex-direction: column;
    span {
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      font-weight: 500;
    }
    :global(.message-time) {
      font-size: 12px;
      color: #928fa0;
      line-height: normal;
      margin-top: 5px;
      letter-spacing: 0.27px;
      font-weight: 400;
    }
  }
}

.made_my_day_type {
  @include position(absolute, 0, 0, auto, auto);
  width: 50px;
  height: 100%;
  background: $textShade4;
  text-align: center;
  border-left: 1px solid $borderColor;
  a {
    @include flexbox;
    @include align-content(center);
    @include justify-content(center);
    height: 100%;
    svg,
    img {
      margin: 0 2px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
.add_made_my_day_item {
  margin-top: 12px;
  position: relative;
  z-index: 1;
  .made_my_day_type {
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    z-index: 10;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  > :global(.btn) {
    min-width: 117px;
    font-weight: 600 !important;
  }
}
.made_my_day_history {
  text-align: right;
  margin-top: 18px;
  a {
    font-weight: 500;
  }
}

.overview_of_achievement {
  @include flexbox;
  @include justify-content(center);
  .achievement_received,
  .achievement_sent {
    text-align: center;
  }
  .user_pics {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: $bodyColor;
    overflow: hidden;
    display: block;
    text-align: center;
    line-height: 78px;
    font-weight: bold;
    font-size: 20px;
    margin: 0 -10px;
    z-index: 1;
    img {
      width: 78px;
      height: 78px;
      object-fit: contain;
    }
  }
  .achievement_sent_no,
  .achievement_received_no {
    width: 75px;
    height: 75px;
    text-align: center;
    line-height: 75px;
    border-radius: 50%;
    background: $bodyColor;
    display: block;
    font-weight: bold;
    font-size: 20px;
  }
  .achievement_text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.36px;
    color: $textShade1;
  }
}

.achievement_type {
  text-align: right;
  padding-right: 10px;
  :global(.select_dropdown_text) {
    display: inline-block;
    width: 200px;
    :global(.select__single-value) {
      right: 0;
      font-size: 16px;
    }
  }
  :global(.select__value-container) {
    text-align: right;
  }
}
