@import "../../../../theme.scss";

.assigneepopup_wrapper {
  .modal_header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: $themeColor11;
    }

    h2 {
      font-size: 16px;
      color: $headingColor3;
    }
  }

  .modal_alert {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    font-size: 15px;
    font-weight: 400;
    color: $primaryText;
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 5px;
  }

  .modal_header_filter {
    padding: 20px;
    min-height: 150px;
    width: 400px;
    max-width: 100%;

    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: $primaryText;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }

    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 46px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value),
      :global(.select__placeholder) {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  :global(.modal-body) {
    padding: 0;
  }

  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: $primaryColor;
    letter-spacing: 0;
    text-align: center;
    min-width: 120px;
  }

  .btn_save {
    margin-right: 20px;
    min-width: 120px;
  }

  .assigned_modal_footer {
    text-align: left;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  .assigneepopup_wrapper {
    .modal_header {
      h2 {
        margin: 5px 0 15px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .assigneepopup_wrapper {
    .assigned_modal_footer {
      :global(.btn) {
        min-width: 100px;

        strong {
          font-weight: 600;
        }
      }
    }
  }
}
