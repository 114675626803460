@import "theme";

.dynamic_table_wrapper {
  z-index: 0 !important;
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
  background: #fff;
  border-radius: 0 0 8px 8px;
  margin-bottom: 30px;
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    overflow: auto;
    max-height: 500px;
    .table_export_btn {
      margin: 20px 0;
      min-width: 146px;
    }
  }
  .export_button {
    min-width: 146px;
    margin: 20px 20px 20px 0;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0 2px 4px 0 #e6e6e6;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $themeColor16;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $themeColor16;
  }
}
