@import "../../../../../theme.scss";

.filter_selection_wrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  @include mobile {
    align-items: flex-start;
    flex-direction: column;
  }
  .filter_selection {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .view_more_option {
    align-items: center;
    display: flex;
    justify-content: center;
    color: #7033ff;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    margin-left: 20px;
  }
  :global(.filter-wrapper) {
    display: flex;
    align-items: flex-start;
    width: 48%;
    padding: 20px 10px 10px 15px;
    margin: 0 10px 10px 0;
    @include mobile {
      width: 100%;
    }

    :global(.filter-wrapper-render) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      :global(.w-45) {
        @include tablet {
          width: 100% !important;
        }
      }
      :global(.w-38) {
        @include tablet {
          width: 100% !important;
        }
      }
      .count {
        height: 40px;
        width: 40px;
        cursor: pointer;
        background-color: #f6f2ff;
        border-radius: 50%;
        margin-right: 12px;
        text-align: center;
        font-weight: 600;
        color: $primaryColor;
        line-height: 40px;
      }
      .view_less {
        color: #7033ff;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        align-self: flex-end;
        line-height: 40px;
      }
    }
    span {
      font-weight: 500;
      font-size: 16px;
      padding-right: 10px;
      line-height: 40px;
    }
  }
  .badge_selection_wrapper {
    padding: 7px 7px;
    margin: 0 20px 10px 0;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    @include mobile {
      width: 100%;
    }
    .selection_text {
      color: #3e3266;
      font-size: 16px;
      letter-spacing: 0.36px;
      margin-right: 10px;
      line-height: 19px;
    }
    .svg {
      cursor: pointer;
    }
  }
}
