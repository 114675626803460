@import "theme";

.preview_template_wrapper {
  display: block;
  .preview_tabs {
    :global(.nav-tabs) {
      justify-content: flex-start;
    }
    :global(.nav-tabs .nav-link) {
      width: 180px !important;
      flex: none !important;
    }
  }
}
.quesion_inner_wrapper {
  display: block;
  .add_new_question {
    font-weight: 500;
    font-size: 16px;
    color: #7033ff;
    margin: 25px 25px 0;
    display: inline-block;
  }
}

.question_list {
  @include flexbox;
  @include justify-content(flex-start);
  .question_sr_no {
    font-weight: 600;
    font-size: 22px;
    color: #322e46;
    letter-spacing: 0.26px;
  }
  .que_ans_wrapper {
    margin: 5px 0 0 15px;
    width: 100%;
    .question_name {
      @include flexbox;
      @include justify-content(space-between);
      h4 {
        font-weight: 600;
      }
      .add_action {
        @include flexbox;
        > * {
          cursor: pointer;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .answer_description {
    font-weight: 500;
    font-size: 16px;
    color: #74798c;
    letter-spacing: 0.36px;
    line-height: 20px;
  }
  .txt_box_wrapper {
    width: 71%;
    textarea {
      margin-top: 10px;
    }
  }
  .ans_wrapper {
    margin-top: 15px;
    width: 71%;

    label {
      + textarea {
        margin-top: 10px;
      }
    }

    :global(.ratingBarBox) {
      width: 100%;
      margin-top: 45px;
      .workload_rating_guide {
        font-size: 15px;
        color: #928fa0;
        font-weight: 500;
        padding-bottom: 7px;
        margin-top: 20px;
      }
      :global(.custom-range-slider) {
        :global(.input-range__track--active) {
          background-size: 740px 100% !important;
        }
      }
      :global(.textarea-wrapper) {
        :global(.attached-pin-icon) {
          right: 15px;
        }
      }
    }
    .txt_box_wrapper {
      width: 71%;
      :global(.textarea-wrapper) {
        :global(.attached-pin-icon) {
          right: 15px;
        }
      }
      :global(.custom-default-dd-box) {
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-radius: 4px;
        :global(.select__control) {
          min-height: 50px;
          min-width: 108px;
          border: none;
          border-radius: 4px;
        }
        :global(.select__indicator-separator) {
          display: none;
        }
        :global(.select__single-value) {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
}
.disabledClick {
  pointer-events: none;
}
.add_new_question {
  font-weight: 500;
  font-size: 16px;
  color: #7033ff;
  margin: 25px 25px 0;
  display: inline-block;
  cursor: pointer;
}

.save_btn {
  display: block;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-left: 25px;
  :global(.btn) {
    min-width: 120px;
    font-weight: 600;
    font-size: 14px;
    margin-right: 15px;
  }
}
.rank_value_wrapper {
  @include flexbox;
  margin-bottom: 8px;
  @include align-items(flex-start);
  &:last-child {
    margin-bottom: 0;
  }
}
.rank_value {
  margin-left: 10px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.36px;
  line-height: 22px;
  color: $primaryText;
}
.categoryTitle {
  font-weight: 600;
  color: #322e46;
  letter-spacing: 0.19px;
  padding-top: 30px;
  padding-left: 25px;
  padding-bottom: 10px;
  position: relative;
}
