@import '../../../../theme';

:global(.custom_modal_popup) {
  .scale_modal {
    padding: 0;

    :global(.custom_modal_button) {
      border-top: 0;
      padding: 0 25px 25px;

      :global(.btn) {
        font-weight: 600;
      }
    }
    .mHeader{
      border-bottom: 0;
      padding-bottom: 20px;
    }
    .mBody {
      position: relative;
      padding: 0 25px 0;

      p {
        font-weight: 500;
        font-size: 14px;
        color: #A3A0B2;
        letter-spacing: 0.39px;
        padding-top: 10px !important;
      }
    }

    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322E46;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }
    .scale_list{
      @include flexbox;
      @include justify-content(space-between);
      padding: 0;
      margin: 10px 0 0;
      list-style: none;
      background-color: #F1F1F1;
      border-radius: 50px;
      width: 100%;
      @include align-items(center);
      li{
        padding: 15px;
        font-size: 14px;
        color: $primaryText;
        font-weight: 500;
        border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
        width: 20%;
        text-align: center;
        &:last-child{
          border-right: 0;
        }
      }
    }
  }
}

.mHeader {
  display: block;
  padding: 25px;

  :global(.modal-title) {
    font-weight: 500;
    font-size: 18px;
    color: #37324B;
    letter-spacing: 0.26px;

    span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: #A3A0B2;
      letter-spacing: 0.39px;
    }
  }
}