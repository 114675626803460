@import '../../../theme';

.addskill_wrapper{
    :global(.page-title){
      padding-bottom: 5px;
      + :global(span){
        font-weight: 500;
        font-size: 14px;
        color: #928FA0;
        letter-spacing: 0;
      }
    }
    .skill_dd_wrapper{
        display: block;
        .dd_title{
            font-weight:600;
            font-size: 16px;
            color: #322E46;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
            display: block;
        }
        :global(.card_action) {
            min-width: 100%;
            &:global(.dropdown_50){
                display: flex;
                justify-content: space-between;
                :global(.custom-default-dd-box) {
                    width: 48%;
                }
            }
            :global(.custom-default-dd-box) {
                :global(.select__control) {
                    min-height: 50px;
                    border-color: rgba(0, 0, 0, 0.1);
                }
                :global(.select__indicator-separator) {
                    display: none;
                }
                :global(.select__single-value) {
                    font-size: 16px;
                }
            }
        }
    }
    .saveBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .save_box_left {
        :global(.btn) {
          min-width: 200px;
          :global(strong){
              font-weight: 600;
          }
        }
    }
    .save_box_right {
        font-size: 14px;
        line-height: 16px;
        color: #B4B0C8;
        span {
          color: #928FA0;
          font-weight: 500;
        }
    }
    .save_box_center {
        margin: auto;
        font-size: 14px;
        line-height: 16px;
        color: #B4B0C8;
        span {
          color: #928FA0;
          font-weight: 500;
        }
    }
}

@media only screen and (max-width:1024px){
    .addskill_wrapper{
        margin-top: 30px;
        display: inline-block;
        width: 100%;
    }
}

@media only screen and (max-width:767px){
    .addskill_wrapper{
        .skill_dd_wrapper{
            :global(.box-inner-pad){
                :global(.row){
                    > div{
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .addskill_wrapper{
        .saveBox{
            &:global(.box-flex-center){
                display: block;
                text-align: center;
            }
        }
        .save_box_left {
            :global(.btn) {
            min-width: 140px;
            }
        }
        .save_box_right{
            margin-top: 15px;
        }
    }
}