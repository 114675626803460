@import "theme";
.keyresult_accordion_wrapper {
  :global(.card) {
    margin: 0px 0px 13px 0px;
    background: rgba(255, 255, 255, 0.61);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-bottom: solid !important;
    border-bottom-width: 1px !important;
    border-bottom-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    // height: 70px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  :global(.card-header) {
    background: rgba(255, 255, 255, 0.61);
    border: 0px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0px;
    // font-family: Gilroy-SemiBold;
    // font-size: 14px;
    // color: #322E46;
    letter-spacing: 0.47px;
    text-align: right;
    // font-family: Gilroy-Medium;
    // font-size: 16px;
    color: #37324b;
    letter-spacing: 0.36px;
    margin-bottom: 0px;
    padding: 14px 0px 10px 0px !important;
  }
  :global(.card-body) {
    padding: 0px;
  }
  .hours {
    color: $themeColor11;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px;
  }
  .duration {
    color: $textShade1;
    font-size: 13px;
    font-weight: 400;
    margin: 0px 0px;
  }
  ul {
    list-style: none;
    color: $textShade1;
    padding: 15px 0px 15px 18px;
    position: relative;
    margin-bottom: 0px;
    li {
      color: $themeColor11;
      font-size: 14px;
      font-weight: 500;
      background: url(../../../../assets/images/ic_keyresult_bullet.svg) no-repeat left 8px;
      padding: 0px 10px 4px 15px;
      line-height: 22px;
      margin: 0px 0px 0px 0px;
      span {
        display: block;
        word-break: break-all;
        color: $primaryColor;
        cursor: pointer;
      }
    }
  }
  button {
    width: 100%;
    &:global(.btn-link) {
      padding-right: 20px !important;
    }
    :global(.accordion-icon) {
      position: absolute;
      top: 50%;
      left: 16px;
      margin-top: -10px;
      display: block;
      width: 10px;
      height: 10px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
  .accordion_title {
    clear: both;
    padding-left: 26px;
    .title_left {
      float: left;
      color: $themeColor11;
      font-size: 16px;
      font-weight: 600;
      width: 80%;
      text-align: left;
    }
    .title_right {
      float: right;
      color: $themeColor11;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: 480px) {
  .keyresult_accordion_wrapper {
    :global(.box) {
      :global(.box-heading-wrapper) {
        flex-direction: column;
        @include justify-content(flex-start);
        @include align-items(flex-start);
        :global(.box-info) {
          position: static;
          margin-top: 15px;
        }
      }
    }
    :global(.card) {
      :global(.card-header) {
        padding-top: 10px !important;
      }
      button {
        padding-right: 10px !important;
      }
      .accordion_title {
        text-align: left;
        .title_left {
          width: 75%;
        }
      }
    }
  }
}
