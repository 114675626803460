@import "theme";

.mmg_tooltip {
  position: absolute;
  right: 15px;
  top: 10px;
  :global(.box-info) {
    margin: 0;
  }
  :global(.info-attach-file) {
    min-width: 260px;
    left: auto;
    right: -14px;
    width: 260px;
    padding: 5px;
    white-space: normal;
    &::before {
      right: 20px;
      left: auto;
    }
    p {
      color: #37324b;
      text-align: center;
    }
  }
  &:global(.mmg_table_tooltip) {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    right: 0;
    top: 0;
    :global(.info-attach-file) {
      right: -170px;
      &::before {
        right: 174px;
      }
    }
  }
}
.card_dd {
  display: block;
  margin-top: 10px;
  width: 100%;
  :global(.custom-default-dd-box) {
    :global(.select__control) {
      min-height: 40px;
      border-color: rgba(0, 0, 0, 0.1);
    }
    :global(.select__indicator-separator) {
      display: none;
    }
    :global(.select__single-value),
    :global(.select__placeholder),
    :global(.select__option) {
      font-size: 14px;
    }
  }
}
.endrose_req_wrapper {
  // @include flexbox;
  // white-space: nowrap;
  @include transition(all, 0.5s, ease-out);
  // overflow-x: auto;
  // flex-wrap: wrap;
  display: inline-block;
  width: 100%;
  max-height: 800px;
  // overflow-y: auto;
  position: relative;
  min-height: 378px;
  &:global(.active) {
    min-height: 514px;
  }
  &:global(.overflow_y_visible) {
    overflow-y: auto;
  }
  .card_item {
    border: 1px solid #ededed;
    @include box-shadow(0, 2px, 4px, 0 rgba(0, 0, 0, 0.09));
    font-size: 16px;
    color: $primaryText;
    font-weight: 500;
    letter-spacing: 0.36px;
    margin: 0 1.33% 0 0;
    width: 24%;
    padding: 25px 15px;
    border-radius: 8px;
    position: relative;
    @include transition(all, 0.5s, ease-out);
    display: inline-block;
    vertical-align: top;
    min-height: 376px;
    * {
      transition: 0.5s all;
    }
    .card_top,
    .card_text {
      @include flexbox;
      @include justify-content(center);
      flex-direction: column;
      @include align-items(center);
    }
    .user_img {
      height: 78px;
      width: 78px;
      overflow: hidden;
      display: inline-block;
      border-radius: 50%;
      line-height: 79px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      font-size: 24px;
      margin-bottom: 10px;
      img {
        max-width: 100%;
        position: relative;
        top: -3px;
      }
    }
    .user_info {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      flex-direction: column;
      margin-bottom: 12px;
      width: 100%;
      .username {
        font-size: 18px;
        color: #322e46;
        font-weight: 600;
        letter-spacing: 0.41px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
      .department {
        color: #322e46;
        letter-spacing: 0.39px;
        font-weight: 400;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        ~ .department {
          letter-spacing: 0;
          color: #928fa0;
        }
      }
    }
    .card_text {
      > h5 {
        font-size: 15px;
        color: #322e46;
        letter-spacing: 0.44px;
        font-weight: 600;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
      .end_type {
        font-weight: 400;
        font-size: 14px;
        color: #322e46;
        margin-top: 2px;
        letter-spacing: 0.39px;
      }
      .end_date {
        font-weight: 400;
        font-size: 12px;
        color: #928fa0;
        letter-spacing: 0.33px;
        margin-top: 2px;
      }
    }
    .card_bottom {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      margin-top: 15px;
      > span {
        cursor: pointer;
        margin-right: 7px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &:global(.fadeout_ci) {
      opacity: 0;
    }
    &:global(.slideup_ci) {
      width: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      margin-right: 0;
    }
    ~ .no_card_item {
      display: none;
    }
  }
  .no_card_item {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 270px;
    color: #bdbcc3;
    font-weight: 600;
    font-size: 18px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  :global(.removed-card-item) {
    animation: removed-item-animation-card 1s cubic-bezier(0.65, -0.02, 0.72, 0.29);
  }
}

.approve_decline_wrapper {
  display: block;
  width: 100%;
  background-color: #fff;
  transition: 0.5s all;
  height: 0;
  overflow: hidden;
  position: relative;
  .ad_wrapper {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    :global(.form-control) {
      font-size: 14px;
      resize: none;
    }
    :global(.btn) {
      min-width: 100px;
      margin-top: 10px;
      font-weight: 600;
    }
  }
}
.card_item {
  border: none !important;
  box-shadow: none !important;
  .card_item_inner {
    transition: 0.5s all;
    position: absolute;
    left: 0;
    right: 0;
    padding: 15px;
    transform: translateY(0);
    border: 1px solid #ededed;
    @include box-shadow(0, 2px, 4px, 0 rgba(0, 0, 0, 0.09));
    border-radius: 4px;
    background-color: #fff;
    top: 0;
    height: 100%;
  }
  &:global(.approve-item),
  &:global(.decline-item) {
    z-index: 1;
    .card_item_inner {
      // transform: translateY(-65px);
      height: 511px;
    }
  }
  &:global(.approve-item) {
    .approve_decline_wrapper {
      margin-top: 15px;
      height: 123px;
      :global(.approved-item) {
        opacity: 1;
      }
    }
  }
  &:global(.decline-item) {
    .approve_decline_wrapper {
      margin-top: 15px;
      height: 123px;
      :global(.decline-item) {
        opacity: 1;
      }
    }
  }
}
@keyframes removed-item-animation-card {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    transform: translateY(50px);
  }

  80% {
    opacity: 0;
    transform: translateY(-320px);
  }

  100% {
    opacity: 0;
    transform: translateY(-320px);
    // display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .endrose_req_wrapper {
    .card_item {
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n + 5) {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .endrose_req_wrapper {
    .card_item {
      width: 32%;
      margin-right: 2%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .endrose_req_wrapper {
    .card_item {
      width: 48.5%;
      margin-right: 3%;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .endrose_req_wrapper {
    .card_item {
      width: 100%;
      margin-right: 0;
      &:nth-child(n + 2) {
        margin-top: 20px;
      }
    }
  }
  .endorsement_skill_table {
    overflow-x: auto;
    :global(.table) {
      width: 600px;
    }
  }
}
