@import "theme";

.modal_header {
  padding: 50px 0 20px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .heading_wrapper {
    h2 {
      font-weight: 600;
    }

    h6 {
      padding-top: 5px;
      font-size: 15px;
    }
  }
}

:global(.modal-body) {
  padding: 0;
}

.content_wrapper {
  padding: 42px;
}

.modal_footer {
  text-align: left;
  justify-content: flex-start;
  border-top: none;
  padding: 0 34px 34px 34px;

  .btn_close {
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 21px;
    font-size: 14px;
    background-color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
}

.your_feedback,
.manager_feedback {
  border: 1px solid rgba(0, 0, 0, 0.1);

  table {
    margin-bottom: 0;

    th {
      border-top: none;
      border-bottom: none;
      padding: 24px;

      h3 {
        font-weight: 600;
      }
    }

    td {
      padding: 24px;

      p {
        font-size: 18px;
        font-weight: 600;
      }

      ul {
        margin-bottom: 0;

        li {
          font-size: 16px;
          margin-left: -22px;
          font-weight: 500;
          overflow-wrap: break-word;
          max-width: 640px;
        }

        li::marker {
          color: #B5AFCA;
        }
      }
    }
  }
}

.your_feedback {
  border-radius: 6px 6px 0 0;
}

.manager_feedback {
  border-top: none;
  border-radius: 0 0 6px 6px;

  table {
    th {
      padding-top: 50px;
    }
  }
}

.greyText {
  color: #928fa0;
}
