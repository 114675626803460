@import '../../theme';

.user_info {
  @include flexbox;
  padding: 0 25px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @include align-items(center);

  .user_img {
    width: 72px;
    height: 72px;
    margin: 0 15px 0 0;
    @include flex(0 0 72px);
    overflow: hidden;
    @include border-radius(50%);
    font-size: 26px;
    font-weight: 600;
    color: $whiteColor;
    line-height: 72px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    img {
      max-width: 100%;
      height: 100%;
    }
    a{
      color: $whiteColor;
      height: 100%;
      display: inline-flex;
      
      &:hover{
        color: $whiteColor;
      }
    }
  }

  .user_name {
    font-size: 18px;
    font-weight: 600;
    color: $themeColor11;
  }

  .user_designation {
    font-size: 16px;
    font-weight: 400;
    color: $primaryText;
  }
}

.progressBox {
  padding: 18px 20px 13px 20px;

  .progressText {
    margin: 0 0 0 5px;
    font-size: 15px;
    font-weight: 500;
  }

  .due {
    font-size: 15px;
    font-weight: 500;

    .label {
      color: $textShade1;
      padding: 0 5px 0 0;
    }

    .value {
      color: $themeColor12;
    }
  }

  :global(.progress) {
    margin: 10px 0 0 0;
    height: 8px;
    background: $whiteColor;
    border: 1px solid rgba(0, 0, 0, 0.1);

    :global(.progress-bar) {

      @include gradient($secondaryLightColor, $themeColor10, horizontal);
      @include transition(width, 1s, ease-in-out);

      &:global(.bg-one) {
        @include gradient($mode1Dark, $themeColor8, horizontal);
      }

      &:global(.bg-two) {
        @include gradient($mode2Light, $mode2Dark, horizontal);
      }

      &:global(.bg-three) {
        @include gradient($mode3Light, $mode3Dark, horizontal);
      }

      &:global(.bg-four) {
        @include gradient($mode4Light, $mode4Dark, horizontal);
      }

      &:global(.bg-five) {
        @include gradient($mode5Light, $mode5Dark, horizontal);
      }
    }
  }
}

.userInfoText{
  padding: 15px 25px 0px;
  .userExpDetailTitle{
    color: #928FA0;
    letter-spacing: 0.39px;
    font-weight: 500;
  }
  .userExpDetailYears{
    color: #37324B;
    letter-spacing: 0.36px;
  }
  
}
.user_details {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  width: 100%;
  padding-right: 30.33%;
}
.userBandWrapper{
  position: absolute;
  right: 27%;
  .userBandTitle{
    color: #928FA0;
    letter-spacing: 0.39px;
    font-weight: 500;
  }
  .userBand{
    color: #37324B;
    letter-spacing: 0.36px;
  }
}
.user_submission_ontime {
  font-size: 14px;
  font-weight: normal;
  color: $primaryText;
  margin-top: 5px;
}
.user_details_right {
  text-align: right;
  white-space: nowrap; //adding word nowrap as per discussion
}
.user_date_of_submission {
  font-size: 15px;
  font-weight: 500;
  color: $textShade1;
  margin-bottom: 5px;
  span {
    color: $primaryText;
  }
}

.user_checkin_status {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid $borderColor;
  @include border-radius(4px);
  display: inline-block;

  &.ready {
    color: #33C5FF;
    background: #F0FAFD;
    border-color: #CFF1FF;
  }

  &.pending {
    color: #FD8C61;
    background: #FCF6F4;
    border-color: #FCE6DD;
  }

  &.reviewed {
    color: #00C4BC;
    background: #F5FFFF;
    border-color: rgba(36, 218, 210, 0.23);
  }
}

.current_week_feels_wrapper {
  margin: 20px 0px 20px;
  border: 1px solid $borderColor;
  padding: 10px 15px;
  @include align-items(center);
  min-height: 72px;
  border-radius: 4px;
  .current_week_feels_info {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.41px;
    display: flex;
    align-items: center;
  }
  &.one {
    border-color: $themeColor8;
  }
  &.two {
    border-color: $mode2Dark;
  }
  &.three {
    border-color: $themeColor6;
  }
  &.four {
    border-color: $mode5Dark;
  }
  &.five {
    border-color: $themeColor10;
  }
}

.current_week_value {
  height: 50px;
  width: 50px;
  display: inline-block;
  @include border-radius(50%);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  color: #fff;
  cursor: pointer;
  @include transition(all,0.5s,ease-in-out);
  z-index: 1;
  margin-left: 5px;
  &.one {
    background: linear-gradient(217deg, $mode1Dark 0%, $themeColor8 90%);
  }
  &.two {
    background: linear-gradient(217deg, $mode2Light 0%, $mode2Dark 90%);
  }
  &.three {
    background: linear-gradient(217deg, $mode3Light 0%, $mode3Dark 90%);
  }
  &.four {
    background: linear-gradient(217deg, $mode4Light 0%, $mode4Dark 90%);
  }
  &.five {
    background: linear-gradient(217deg, $mode5Light 0%, $mode5Dark 90%);
  }
}

:global(.myPageView){
    .user_details{
      padding-right: 0;
    }
    .userBandWrapper,
    .userInfoText{
      display: none;
    }
  }

/*start responsive css*/
@media (max-width:1024px){
  .user_details{
    padding-right: 0;
    width: calc(100% - (232px + 15px));
  }
  .userBandWrapper{
    width: calc(50% - ( 78px + 15px));
    position: static;
    padding-left: 15px;
  }
  :global(.myPageView){
    .user_details{ 
        width: 100%;
    }
  }
}

@media (max-width:767px){
  .user_info {
    .user_img {
      width: 60px;
      height: 60px;
      @include flex(0 0 60px);
      line-height: 64px;
    }
    .user_name{
      font-size: 16px;
    }
    .user_designation{
      font-size: 14px;
    }
  }
  .user_details{
    width: 100%;
  }
  .userBandWrapper{
    padding-left: 15px;
  }
  .userInfoText{
    > :global(.row){
      > *{
        .userExpDetails{
          margin-bottom: 15px;
        }
        &:last-child{
          .userExpDetails{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  :global(.myPageView){
    .user_info{
      flex-direction: column;
      .user_img{
        margin: 0 0 15px;
      }
      .submission_ontime{
        margin-top: 10px;
      }
    }
    .user_details_right {
      text-align: center;
      margin-top: 10px;
    }
    .user_details{ 
        width: 100%;
        flex-direction: column;
        text-align: center;
    }
  }
}
