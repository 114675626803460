@import '../../theme.scss';

/*for sticky footer*/
:global(.footer-outer-wrapper) {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
/**********************/
:global(.footer-column){
  display: flex;
  justify-content: center;
}
.footer_wrapper {
  // margin-top: 64px;
  margin-top: 0;
  background: $whiteColor;
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
  color: $textShade1;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 0;
  .row {
    @include justify-content(space-between);
  }
  .nav_link {
    color: $textShade1;
    font-size: 16px;
    font-weight: 500;
    margin: 0 20px 0 0;
    padding: 0;
  }
}
/**********************/
// page all media query 
/**********************/
@media only screen and (max-width: 992px) {
  :global(.footer-column) {
    text-align: center;
    flex-direction: column;
    align-items: center;
    :global(.nav-left) {
      justify-content: center;
      margin-bottom: 10px;
      :global(.nav-item){
        &:last-child{
          .nav_link{
            margin-right: 0;
          }
        }
        .nav_link{
          margin-right: 10px;
          font-size: 14px;
        }
      }
    }
    :global(.nav-right) {
      justify-content: center;
      :global(.nav-item){
        .nav_link{
          margin-right: 0;
          font-size: 12px;
        }
      }
    }
  }
}