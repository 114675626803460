@import "theme";

.filter_box {
  width: 250px;
  margin-top: 40px;

  .dd_title {
    margin-left: 25px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.select__placeholder) {
    font-size: 15px;
    font-weight: 500;
  }

  :global(.custom-default-dd-box) {
    margin-left: 25px;
    :global(.select__control) {
      min-height: 46px;
      border-color: rgba(0, 0, 0, 0.1);
    }
    :global(.select__indicator-separator) {
      display: none;
    }
    :global(.select__single-value) {
      font-size: 16px;
    }
  }
}

.filter_btn_group {
  margin-left: 25px;
  margin-top: 20px;
  .search_btn {
    min-width: 140px;
  }
}

.desc_box {
  width: 700px;
  margin-left: 25px;
}

textarea:hover,
textarea:active,
textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.searchbox_div {
  min-width: 400px;
  margin-top: 20px;
}

.table_div {
  padding-left: 25px;
  padding-right: 25px;

  .feedback_table {
    .thead {
      padding: 0 25px 15px;
      list-style: none;
      width: calc(100% + 50px);
      margin-left: -25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0;

      span {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        border-top: 0;
        border-bottom-width: 1px;
        font-weight: 600;
      }
    }
    .heading_employeeName {
      width: 34%;
      display: inline-block;
      vertical-align: middle;
    }

    .heading_feedbackDate {
      width: 18%;
      display: inline-block;
      vertical-align: middle;
    }

    .heading_feedback {
      width: 48%;
      display: inline-block;
      vertical-align: middle;
    }

    .tbody {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        padding: 5px 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }

        .item_info_top_wrapper {
          padding: 15px 0 15px 5px;
        }
        .member_info {
          flex-wrap: wrap;
          width: 100%;
          @include flexbox;
          padding-left: 0;
          position: relative;
          .fixed_name {
            max-height: 25px;
            width: 32%;
            display: inline-block;
          }
          .feedback_name {
            color: #74798c;
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            vertical-align: middle;
          }
          .feedback_name:hover {
            cursor: pointer;
          }
          .expand_collapse_wrapper {
            margin-right: 5px;
            display: inline-block;
            width: 20px;
            margin-bottom: 5px;
          }
          .expand_collapse_wrapper:hover {
            cursor: pointer;
          }
          .feedback_date {
            padding-top: -10px;
            width: 26%;
            display: inline-block;
            max-height: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #928fa0;
            vertical-align: top;
          }
        }
      }

      .comment_txt {
        width: 70%;
        display: inline-block;
        font-weight: 500;
        font-size: 16px;
        color: #928fa0;
        margin-left: 12px;
        .dot {
          display: inline-block;
          width: 3%;
          margin-top: 1px;
          vertical-align: top;
        }
        .feedback_area {
          display: inline-block;
          width: 97%;
        }
      }

      .feedback_div {
        padding: 10px;
        padding-left: 20px;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
      .viewFeedbackContainer {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        color: #7033ff;
      }
      .viewFeedbackContainer:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .desc_box {
    width: 500px;
  }
  .table_div {
    .feedback_table {
      .heading_employeeName {
        width: 36%;
      }
      .heading_feedbackDate {
        width: 18%;
      }
      .heading_feedback {
        width: 44%;
      }
      .tbody {
        .comment_txt {
          width: 70%;
          .dot {
            width: 4%;
          }
          .feedback_area {
            width: 96%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .search_Filter_wrapper {
    :global(.check_in_search_bar) {
      padding: 0;
      padding-bottom: 20px;
    }
    :global(.tab_check_in_status) {
      float: right;
    }
  }
}
@media only screen and (max-width: 767px) {
  .desc_box {
    width: 100%;
  }
  .searchbox_div {
    min-width: 300px;
  }
  .table_responsive {
    overflow: auto;
    .table_width {
      width: 720px;
    }
  }
}
