@import "theme";
.compose_chart_wrapper {
  .switch_wrapper {
    padding: 25px 0 0 60px;
    display: flex;
    flex-wrap: wrap;
    .switch_item {
      margin-right: 50px;
    }
    .left_side_switch,
    .right_side_switch {
      padding: 10px 20px;
      svg {
        g {
          fill: #b5afca;
        }
      }
      &.active {
        border-bottom: 3px solid $primaryColor;
        svg {
          g {
            fill: $primaryColor;
          }
        }
      }
    }
  }
  :global(.recharts-tooltip-custom-wrapper) {
    text-align: left;
    :global(.recharts-tooltip-value) {
      color: #928fa0;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.39px;
      line-height: 17px;
    }
  }
  :global(.recharts-responsive-container) {
    width: 90% !important;
  }
}

@media only screen and(max-width: 767px) {
  .compose_chart_wrapper {
    .switch_wrapper {
      .switch_item {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
    :global(.recharts-responsive-container) {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .compose_chart_wrapper {
    .switch_wrapper {
      .switch_item {
        margin-right: 15px;
      }
    }
  }
}
