@import '../../theme.scss';

.sidebar_wc_wrapper{
    padding-left: 25px;
    padding-right: 25px;
    .wc_list_wrapper{
        list-style: none;
        padding:0;
        margin: 0;
        list-style-position: outside;
        li{
            padding:10px 15px 15px;
            border: 1px solid rgba($color: #000000, $alpha: 0.1);
            background-color: rgba($color: #ffffff, $alpha: 0.61);
            @include border-radius(4px);
            margin-bottom: 15px;
            p{
                font-size: 16px;
                color: $primaryText;
                letter-spacing: 0.36px;
            }
            h6{
                color: $textShade1;
                span{
                    color: $primaryText;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.view_more {
    text-align: right;
    margin-top: 18px;
    a {
      font-weight: 500;
    }
}