@import "theme";

.additional_skills_wrapper {
  padding-bottom: 0;
  padding-top: 15px;
  + :global(.pagination-col) {
    margin-top: 0;
  }

  > :global(.box-heading-wrapper) {
    padding: 0 15px 15px;

    :global(.box-heading) {
      width: 70%;
    }
  }

  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.additional_skill_table {
  padding: 15px 0 0;

  :global(.table) {
    margin-bottom: 0;
  }

  thead {
    padding: 0;
    list-style: none;

    th {
      font-size: 14px;
      font-weight: 600;
      color: #37324b;
      border-top: 0;
      border-bottom-width: 1px;
      width: 15%;

      &:first-child {
        padding-left: 25px;
        width: 30%;
      }

      &:last-child {
        width: 10%;
      }
    }
  }

  tbody {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    td {
      font-size: 15px;
      font-weight: 500;
      color: #37324b;
      position: static;

      // >div {
      //   position: static;
      // }

      &:first-child {
        padding-left: 25px;
        color: #74798c;
      }

      .add_action {
        font-weight: 500;
        font-size: 14px;
        color: #7033ff;

        > * {
          cursor: pointer;
          display: inline-block;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
}

.endorse_tooltip {
  position: relative;
  display: inline-block;

  ~ .endorse_tooltip {
    margin-left: -7px;
  }

  .tool_tip_info {
    position: absolute;
    left: -25px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #37324b;
    bottom: 50px;
    padding: 5px;
    border-radius: 4px;
    @include transition(0.2s all);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    min-width: 120px;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 70px;
      height: 7px;
      width: 7px;
      border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      background-color: #fff;
      bottom: -4px;
      transform: rotate(45deg);
    }

    h6 {
      margin-bottom: 3px;
      font-weight: 600;
    }

    > span {
      display: block;
      text-align: center;
      font-size: 13px;
      margin-top: 2px;
      font-weight: 400;
    }
  }

  > img {
    cursor: pointer;

    &:hover {
      + .tool_tip_info {
        opacity: 1;
        bottom: 40px;
        visibility: visible;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .additional_skill_table {
    overflow-x: auto;

    :global(.table) {
      width: 1000px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .additional_skills_wrapper {
    > :global(.box-heading-wrapper) {
      flex-wrap: wrap;

      :global(.box-heading) {
        width: 100%;
      }
    }

    .create_new_btn {
      margin-top: 10px;
    }
  }
}
