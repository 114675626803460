@import '../../../theme';

.mHeader{
  display: block;
  padding: 0;
  :global(.modal-title){
    font-weight: 600;
    font-size: 20px;
    color: #37324B;
    letter-spacing: 0.26px;
    :global(span){
      font-family: 500;
      display: block;
      font-size: 16px;
      color: #928FA0;
      letter-spacing: 0.36px;
    }
  }
}
.mBody{
  position: relative;
  padding-top: 25px !important;
  :global(.box-light-gradient){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.activites_item{
  position: relative;
  padding: 0px 25px;
  display: block;
  margin-bottom: 25px;
  &:last-child{
      margin-bottom: 0px;
  }
  .user_icon{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color:$primaryColor ;
      color:$whiteColor;
      overflow: hidden;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-block;
      margin-right: 10px;
      line-height: 38px;
      vertical-align: top;
      img{
          width: auto;
          height: 100%;
      }
  }
  .activites_data{
      color:$primaryText;
      font-size: 16px;
      font-weight: 400;
      width: 92%;
      display: inline-block;
      span{
          font-weight: 600;
      }
      .activites_hours{
          color:$textShade1;
          font-size:12px;
          font-weight: 500;
      }
  }
}
.reject_comment_wrapper{
  position: relative;
  margin-top: 20px;
  :global(.view_comment_box_row){
      ~ .reject_cb_box{
          border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
  }
  .reject_cb_box{
      padding-top: 20px;
      :global(.fg-comment-wrapper){
          :global(.textarea-wrapper){
              width: 100%;
              margin-right: 0;
          }
      }
  }
}