@import "../../theme";

// .priorities_box {

// }
.planWrapper{
  margin-bottom: 0px !important;
}
.priority_block {
  @include border-radius(4px);
  padding-left: 0; //0 oinstead of 10px
  padding-right: 0; // 0 instead of 20px
  @include transition(all, 0.4s, linear);
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  :global(.list-of-objective) {
    padding-left: 20px;
    :global(li) {
      padding-top: 5px;
      background-position: 15px 5px;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &.open {
    padding: 10px 20px 20px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
}

.priorities_list {
  .priority_title {
    // + :global(.list-of-objective){
    //   padding-left: 20px;
    //   // :global(li){
    //   //   padding-top: 2px;
    //   //   padding-bottom: 2px;
    //   //   background-position: 15px center;
    //   // }
    // }
    @include flexbox;
    @include justify-content(space-between);
    :global(.custom-checkbox) {
      input[type="checkbox"] {
        &:checked + label {
          color: $textShade1;
          text-decoration: line-through;
          // span{
          //   position: relative;
          //   &:after {
          //     content: ' ';
          //     position: absolute;
          //     top: 50%;
          //     left: 0;
          //     width: 100%;
          //     height: 1px;
          //     background: $textShade1;
          //     animation-name: strike;
          //     animation-duration: 1s;
          //     animation-timing-function: linear;
          //     animation-iteration-count: 1;
          //     animation-fill-mode: forwards;
          //   }
          //   @keyframes strike{
          //     0%   { width : 0; }
          //     100% { width: 100%; }
          //   }
          // }
        }
      }
    }
  }
  .priority_details {
    padding-left: 20px;
    margin-left: 38px;
    background: url(../../assets/images/ic-child-arrow.svg) no-repeat top left;
    margin-bottom: 24px;
    .priority_row {
      @include flexbox;
      @include align-content(flex-start);
      font-size: 14px;
      color: $primaryColor;
      img,
      svg {
        margin-right: 8px;
      }
      a {
        text-decoration: underline;
        margin-bottom: 5px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.add_priority_link {
  margin-top: 34px;
  margin-bottom: 10px;
  a {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.41px;
    font-weight: 500;
  }
}
.plan_box {
  :global(.common_list_items.pt10) {
    :global(li) {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .plan_next_checkin {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    :global(.list-of-objective) {
      :global(li) {
        padding-top: 5px;
        background-position: 15px 5px;
      }
    }
  }
  .plan_textarea_container {
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    > :global(.form-group) {
      + :global(.list-of-objective) {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.15);
      }
    }

    .plan_textarea {
      min-height: 50px !important;
      padding-right: 85px;
      border: 1px solid transparent;
      border-bottom-color: transparent;
      border-radius: 0;
      &:disabled {
        background-color: #fff;
      }
      &:hover,
      &:focus {
        // border:1px solid transparent;
        // border-bottom-color: rgba(0,0,0,0.15);
        // box-shadow: none;
        border-radius: 4px;
      }
    }
    :global(.form-group) {
      :global(.textarea-wrapper) {
        :global(.link_icon) {
          right: 20px;
          top: 14px;
        }
        :global(.dustbin_icon) {
          right: 20px;
          top: 14px;
          + :global(.link_icon) {
            right: 50px;
          }
        }
      }
    }
  }
  :global(.addnewplan_link) {
    font-weight: 500;
    color: $primaryColor;
    &:global(.disabled) {
      pointer-events: none;
      color: $textShade3;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .priority_block {
    padding-left: 0;
    padding-right: 0;
  }
}
