.form_option_list {
  list-style: none;
  padding-left: 0;
  margin: 15px 0 0;
  &:global(.limit-reached) {
    :global(.custom-checkbox) {
      :global(label) {
        color: #f07575;
      }
    }
  }
  li {
    display: inline-block;
    margin-right: 25px;
    &:last-child {
      margin-right: 0;
    }
    &:global(.w-100) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  :global(.custom-checkbox) {
    margin-bottom: 0;
    cursor: auto;
    input {
      &:disabled {
        + :global(label) {
          &:after {
            background-image: url(../../assets/images/ic_radio_disabled_2.svg);
            background-size: 100%;
            -ms-background-size: 100%;
            -moz-background-size: 100%;
          }
        }
      }
      &:checked {
        + :global(label) {
          &:after {
            background-image: url(../../assets/images/ic_radio_active_2.svg);
          }
        }
      }
    }
    :global(input[type="radio"] + label) {
      font-size: 14px;
      padding-left: 30px;
      line-height: 20px;
      cursor: pointer;
      &::before {
        height: 20px !important;
        width: 20px !important;
      }
      &:after {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
  li {
    &:last-child {
      :global(.custom-checkbox) {
        margin-bottom: 0;
      }
    }
  }
}
