@import "theme.scss";

.box_section_container {
    padding-bottom: 0;
    .greyText {
        color: #928fa0;
    }

    .statusList {
        list-style: none;
        display: flex;
        padding-left: 0;
        font-size: 16px;
        font-weight: 600;
        padding-top: 15px;

        li {
            padding-right: 35px;
            :global(.circle) {
                width: 8px;
                height: 8px;
                display: inline-block;
                border-radius: 50%;
                margin-right: 5px;
            }

            .green {
                background-color: $themeGreen;
            }

            .red {
                background-color: $themeRed;
            }

            .percentage {
                padding-left: 15px;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}