@import "theme";

.home_tabs {
  :global(.nav-tabs) {
    :global(.nav-link) {
      padding: 16px 12px;
      text-align: center;
    }
  }

  .pending_action_wrapper {
    padding: 30px;
    overflow-y: scroll;
    height: 340px;
  }
}

.campaign_quiz_table_box {
  padding: 25px 0 0 0;

  .collapseClose {
    background-color: $textShade3;
  }
  .passIcon {
    margin-right: 9px;
  }

  .endorse_tooltip {
    position: relative;
    display: inline-block;
    padding: 18px 0;
    ~ .endorse_tooltip {
      margin-left: -7px;
    }
    .tool_tip_info {
      position: absolute;
      left: -33px;
      top: -12px;
      margin: auto;
      background-color: #fff;
      border: 1px solid #eaeaea;
      color: #37324b;
      // bottom: 40px;
      padding: 5px;
      border-radius: 4px;
      @include transition(0.2s all);
      opacity: 0;
      visibility: hidden;
      z-index: 1;
      min-width: 120px;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        right: 70px;
        height: 7px;
        width: 7px;
        border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        background-color: #fff;
        bottom: -4px;
        transform: rotate(45deg);
      }
      h6 {
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 12px;
        color: #928fa0;
      }
    }
    > span {
      display: flex;
      cursor: pointer;
      color: black;

      &:hover {
        color: #7033ff;
        + .tool_tip_info {
          opacity: 1;
          // bottom: 30px;
          visibility: visible;
          color: #7033ff;
        }
      }
    }
  }

  :global(.outer-table-wrapper) {
    min-height: 300px;

    :global(.tab_filter) {
      :global(.export_button) {
        &:hover {
          svg {
            path {
              fill: #7033ff;
            }
            :global(rect) {
              stroke: #7033ff;
            }
          }
        }
      }
    }
  }
  .pending_submit_info {
    @include flexbox;
    .pending,
    .submitted {
      position: relative;
      &:before {
        position: absolute;
        left: 0;
        top: 10px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        content: "";
      }
    }
    .pending {
      &:before {
        background-color: #f07575;
      }
    }
    .submitted {
      &:before {
        background-color: #6dd400;
      }
    }
    > span {
      padding-left: 17px;
      @include flexbox;
      flex-direction: column;
      font-weight: 500;
      font-size: 20px;
      color: #3e3266;
      margin-right: 75px;
      > small {
        font-weight: 600;
        letter-spacing: 0.26px;
        font-size: 20px;
        margin-top: 2px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  :global(.box-light-gradient) {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

  :global(.table-theme-1) {
    table {
      tbody {
        .normal_row {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding-top: 50px;
        }
        .expanded_row {
          // height: 10%;
          margin-top: 100px;
          margin-left: 100px;
        }
        td {
          border-bottom: none;
          margin-top: 100px;
        }
      }
    }
  }
  :global(.table) {
    td {
      border-top: none;
    }
  }
  .tab_table_responsive {
    thead {
      th {
        border-top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        &:first-child {
          padding-left: 42px;
        }
      }
    }
    tbody {
      tr {
        td {
          :global(.employee_location) {
            font-weight: 500;
            font-size: 15px;
            color: #322e46;
            line-height: 17.66px;
          }
          :global(.employee_attempts) {
            font-weight: 500;
            font-size: 15px;
            color: #322e46;
            line-height: 17.66px;
          }
          :global(.action_wrapper) {
            cursor: pointer;
            width: 7%;
            position: relative;
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            color: #322e46;
            line-height: 17.66px;
          }
          &:nth-child(3) {
            padding-left: 40px;
          }
        }
      }
    }
    table {
      table-layout: fixed;
    }
  }
}
.tab_team_member_info {
  position: relative;
  width: 400px;
  .team_member_info {
    position: relative;
    color: $primaryText;
    padding-left: 32px;
    @include flexbox;
    @include align-items(center);
    &.sub_team_member {
      padding-left: 52px;
    }
    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .private_notification {
      background: #7033ff;
      border: 2.5px solid #ffffff;
      position: absolute;
      top: 0px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      left: 66px;
    }
    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      display: flex;
      align-items: center;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
      :global(.custom-tooltip) {
        :global(.box-info) {
          margin: 0px;
          &:hover {
            + :global(.info-attach-file) {
              top: -145px;
            }
          }
        }
        :global(.info-attach-file) {
          top: -155px;
          left: -12px;
          &:before {
            top: auto;
            bottom: -4px;
            transform: rotate(135deg);
          }
          ul {
            li {
              line-height: 20px;
            }
          }
        }
      }
    }
    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }
    .team_member_info_collapse {
      width: 17px;
      height: 17px;
      line-height: 12px;
      border: 1px solid $textShade3;
      display: block;
      @include border-radius(50%);
      text-align: center;
      cursor: pointer;
      @include position(absolute, 18px, auto, auto, 0);
    }
  }
}
@media only screen and (max-width: 991px) {
  :global(.custom-table) {
    overflow-x: auto;
    .tab_table_responsive {
      overflow-x: auto;
      overflow-y: hidden;
      width: 880px;
    }
  }
}
@media only screen and (max-width: 992px) {
  :global(.box-heading-wrapper) {
    flex-direction: column;
    align-items: flex-start;
  }
  .pending_submit_info {
    margin-top: 20px;
  }
}
