@import "theme";

.cycleTeamWrapper {
  display: block;
  :global(.box) {
    :global(.box-heading) {
      :global(h3) {
        margin-top: 5px;
      }
    }
  }
  .box_2 {
    @include border-radius(8px 8px 0 0);
    margin-bottom: 0;
    padding-bottom: 0;
    :global(.box-heading-wrapper) {
      @include flexbox;
      @include justify-content(space-between);
    }
    .pending_submit_info {
      @include flexbox;
      .pending,
      .submitted {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 10px;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          content: "";
        }
      }
      .pending {
        &:before {
          background-color: #f07575;
        }
      }
      .submitted {
        &:before {
          background-color: #6dd400;
        }
      }
      > span {
        padding-left: 17px;
        @include flexbox;
        flex-direction: column;
        font-weight: 500;
        font-size: 20px;
        color: #3e3266;
        margin-right: 75px;
        > small {
          font-weight: 600;
          letter-spacing: 0.26px;
          font-size: 20px;
          margin-top: 2px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  :global(.outer-table-wrapper) {
    min-height: auto;
    :global(.tab_filter) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    .tab_table_responsive {
      margin-bottom: 0;
      thead {
        th {
          padding: 10px;
          border: none;
          width: 10%;
          &:first-child {
            width: 35%;
          }
          &:nth-child(2) {
            width: 10%;
            text-align: center;
          }
          &:nth-child(3) {
            width: 12%;
          }
          &:nth-child(4) {
            width: 13%;
          }
          &:nth-child(5) {
            width: 12%;
          }
          &:nth-child(6) {
            width: 13%;
          }
        }
      }
      tbody {
        td {
          padding: 18px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          a {
            font-weight: 600;
            font-size: 16px;
            color: $themeColor11;
            letter-spacing: 0.36px;
            &:hover {
              color: $primaryColor;
            }
          }
          &:nth-child(2) {
            text-align: center;
          }
          &.manager_name {
            font-size: 16px;
            font-weight: 500;
            color: $themeColor11;
          }
        }
      }
    }
    .tab_team_member_info {
      position: relative;
      .team_member_info {
        position: relative;
        color: $primaryText;
        @include flexbox;
        @include align-items(center);
        .team_member_img {
          width: 52px;
          height: 52px;
          @include border-radius(50%);
          @include flex(0 0 52px);
          margin-right: 12px;
          overflow: hidden;
          flex: 0 0 52px;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          line-height: 52px;
          background-color: rgb(184, 180, 204);
          text-align: center;
          align-items: center;
          justify-content: center;
          display: inline-flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .team_member_name {
          font-size: 18px;
          font-weight: 600;
          color: $themeColor11;
          line-height: 22px;
          letter-spacing: 0.44px;
          display: flex;
          align-items: center;
          a {
            color: $primaryText;
            &:hover {
              color: #a962ff;
            }
          }
          .team_member_designation {
            font-size: 16px;
            font-weight: normal;
            line-height: 19px;
            color: $primaryText;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .cycleTeamWrapper {
    :global(.check_in_search_bar) {
      margin-bottom: 0;
    }
  }
}
@media only screen and (max-width: 992px) {
  .cycleTeamWrapper {
    .custom_table {
      :global(.table-responsive) {
        overflow-x: auto !important;
      }
      .tab_table_responsive {
        width: 890px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .cycleTeamWrapper {
    :global(.outer-table-wrapper) {
      min-height: auto;
      :global(.tab_filter) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    :global(.box) {
      :global(.box-heading) {
        :global(h3) {
          margin-top: 0;
        }
      }
    }
    .box_2 {
      :global(.box-heading-wrapper) {
        flex-direction: column;
      }
      .pending_submit_info {
        width: 100%;
        margin-top: 15px;
        > span {
          font-size: 18px;
          margin-right: 30px;
          > small {
            font-size: 18px;
          }
        }
      }
    }
  }
}
