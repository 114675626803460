@import "theme";
.obj_accordion {
  padding: 0;
  :global(.card) {
    overflow: visible;
  }
  :global(.accordion-heading) {
    &:hover {
      :global(.accordion-heading-left) {
        .idp_heading_wrapper {
          h3 {
            &:hover {
              color: $primaryLightColor !important;
            }
          }
        }
      }
    }
    :global(.accordion-heading-left) {
      :global(h3) {
        color: #322e46;
      }
    }
    :global(.accordion-heading-right) {
      position: relative;
      width: 15%;
      .flag {
        position: absolute;
        left: 15px;
        top: 1px;
      }
    }
  }
}
// Process indicate
.idp_process_indicate {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  margin-top: 3px;
  margin-right: 10px;
  .emp_objective_tooltip {
    top: -14px !important;
    left: 25px !important;
    min-width: 160px !important;
    &:before {
      border-right: 1px solid #eaeaea !important;
      border-top: 1px solid #eaeaea !important;
      left: -6px !important;
      top: -8px !important;
      transform: rotate(-135deg) !important;
    }
  }
  .idp_step {
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    border: 1px solid $textShade3;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: relative;
    + .idp_step {
      margin-left: 8px;
    }

    .step1,
    .step2,
    .step3 {
      width: 5px;
      height: 5px;
      @include border-radius(50%);
      background: $textShade1;
      @include transition(all, 0.2s, ease);
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }

    &:hover .step,
    .active {
      width: 16px;
      height: 16px;
    }

    .step1 {
      background: #6dd400;
    }

    .step2 {
      background: #ffcc40;
    }

    .step3 {
      background: #f07575;
    }
  }
}

// Heading right
.idp_process_complete {
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: $primaryText;
}
.idp_end_days {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.36px;
  color: $textShade1;
  position: absolute;
  right: 0;
  bottom: -19px;
  width: 100px;
}

// idp list
.idp_info_list {
  .idp_info_block,
  :global(.idp_info_block) {
    @include border-radius(4px);
    padding-left: 10px;
    padding-right: 0;
    @include transition(all, 0.4s, linear);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    &.open {
      padding: 10px 20px 20px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
      :global(.custom-checkbox) {
        margin-bottom: 5px;
        :global(.cmp-in-comp-info) {
          margin-top: 0;
          font-size: 13px;
          :global(.custom-checkbox) {
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
  .idp_info_title {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    position: relative;
    padding-right: 75px;
    :global(.custom-checkbox) {
      display: inline-block;
      margin: 0 0 0 5px;
    }
  }

  .idp_info_details {
    padding-left: 20px;
    margin-left: 38px;
    background: url(../../../../assets/images/ic-child-arrow.svg) no-repeat top left;
    margin-bottom: 24px;
    .idp_info_row {
      @include flexbox;
      @include align-content(flex-start);
      font-size: 14px;
      color: $primaryColor;
      img {
        margin-right: 8px;
      }
      a {
        text-decoration: underline;
        margin-bottom: 5px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.idp_box {
  padding-bottom: 0;
  :global(.custom-accordion) {
    :global(.accordion-icon) {
      right: 0;
    }
    :global(.idp_info_action) {
      width: 137px; //width:75px instead of min-width: 100px
      text-align: right;
      justify-content: flex-end;
      font-size: 16px;
      color: $themeColor11;
      font-weight: 600;
      position: absolute;
      right: 0;
      top: -7px;
      :global(.form-group) {
        margin-bottom: 0;
        width: 100%;
        position: relative;
        :global(.percentage) {
          position: absolute;
          right: 0px;
          top: 10px;
        }
        :global(.dollor-hash) {
          position: absolute;
          right: 2px;
          top: 10px;
        }
        :global(.info-attach-file) {
          min-width: 110px;
          @include box-shadow(0, 3px, 4px, rgba(0, 0, 0, 0.09), false);
          left: 47px;
          margin: auto;
          top: -55px;
          padding: 12px 5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          text-align: center;
          &:global(.dollarTooltip) {
            min-width: 250px;
            left: -110px;
            &::before {
              right: -140px;
            }
          }
          &::before {
            top: auto;
            bottom: -6px;
            transform: rotate(135deg);
            box-shadow: none;
            height: 11px;
            width: 11px;
            left: 62px;
            border-top-color: rgba(0, 0, 0, 0.2);
            border-right-color: rgba(0, 0, 0, 0.2);
          }
        }
        :global(.form-control) {
          padding: 10px 12px 10px 0;
          height: auto;
          background-color: transparent;
          text-align: right;
          @include transition(0.4s, all, ease-in-out);
          border-color: transparent;
          cursor: pointer;
          position: relative;
          z-index: 1;
          &:hover {
            + :global(.info-attach-file) {
              top: -45px;
              opacity: 1;
              visibility: visible;
            }
          }
          &:focus {
            padding: 10px 5px;
            cursor: auto;
            border-color: $primaryColor;
            background-color: #fff;
            + :global(.info-attach-file) {
              top: -55px;
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
    }
    :global(.card) {
      padding: 20px 25px 20px 35px; // padding: 20px 25px 20px 35px instead of padding:20px 0px 25px 10px;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      :global(.card-header) {
        padding-top: 0;
        border-top: none;
        // position: relative;
        // padding-bottom: 20px;
        :global(.btn) {
          padding: 0 45px 0 0;
          :global(.accordion-heading) {
            @include align-items(flex-start);
          }
        }
      }
      :global(.card-body) {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 25px;
      }
    }
  }
}
.key_initiative_wrapper {
  display: inline-block;
  padding-left: 33px;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  > span {
    display: inline-block;
    color: #928fa0;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #979797;
    line-height: 15px;
    font-size: 14px;
    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
    &.initiative_wrapper {
      cursor: pointer;
      @include transition(0.2s all);
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
.overall_obj {
  padding: 25px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  @include flexbox;
  @include justify-content(flex-start);
  &::before {
    height: 100%;
  }
}
.comment_exp_col {
  cursor: pointer;
  &:hover {
    svg {
      * {
        fill: $primaryColor;
      }
    }
  }
}
p {
  &.obj_desc {
    margin: 5px 0 0;
    padding-left: 34px;
    color: #928fa0;
    span {
      color: #322e46;
    }
  }
}
.view_all_objective_comment {
  display: inline-block;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  span {
    color: #7033ff;
    font-size: 14px;
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.5;
  cursor: default !important;
}
.member_manager_wrapper {
  display: inline-block;
  width: 100%;
  .manager_list {
    display: inline-block;
    width: 100%;
    .topbar {
      @include flexbox;
      @include align-items(center);
      cursor: pointer;
      padding: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .collapse_img {
        cursor: pointer;
        margin-right: 15px;
        position: relative;
        top: -1px;
        :global(.colaps) {
          display: none;
        }
      }
      .manager_info {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        &:hover {
          h4 {
            color: $primaryLightColor;
          }
        }
        h4 {
          color: #928fa0;
          font-weight: 600;

          strong {
            color: #3e3d42;
            font-weight: 600;
          }
          span {
            padding-left: 2px;
            color: #928fa0;
            font-weight: normal;
          }
          span.managerRoleTooltip {
            position: relative;
            .managerRoleTooltipInfo {
              z-index: 1;
              position: absolute;
              left: -20px;
              width: 180px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              background: #fff;
              padding: 5px;
              top: 40px;
              color: #322e46;
              font-size: 16px;
              opacity: 0;
              visibility: hidden;
              @include transition(0.5s all);
              @include border-radius(4px);
            }
            > span {
              cursor: pointer;

              &:hover {
                + .managerRoleTooltipInfo {
                  opacity: 1;
                  visibility: visible;
                  top: 25px;
                  color: #322e46;
                  font-size: 16px;
                }
              }
            }
          }
        }
        span {
          color: #322e46;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .obj_accordion {
    :global(.custom-default-dd-box) {
      margin: 0 10px;
    }
    :global(.accordion-heading) {
      :global(.accordion-heading-right) {
        width: 100px;
        .flag {
          left: 5px;
        }
        .idp_end_days {
          position: static;
          width: auto;
        }
      }
    }
  }
}
.timeframe_wrapper {
  margin-left: 31px;
  display: flex;
  .timeframe_info {
    display: flex;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    color: $textShade1;
    letter-spacing: 0.32px;
    margin: 10px 0 0;
    span {
      font-size: 16px;
      color: #393f51;
      letter-spacing: 0.36px;
    }
    // display: flex;
    // .timeframe {
    //   size: 16px;
    //   color: #393f51;
    //   line-height: 19px;
    // }
    // span {
    //   color: #928fa0;
    //   font-size: 14px;
    //   margin-left: 10px;
    //   line-height: 20px;
    // }
    .edit_button {
      cursor: pointer;
      line-height: 16px;
      width: 17px;
      height: 17px;
      margin: 0 10px;
      svg {
        cursor: pointer;
        * {
          fill: #928fa0;
        }
      }
    }
  }

  .timeframe_edit_box {
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    width: 85%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 15px;
    .timeframe {
      margin: 10px 15px 0 0;
    }
    .date_picker_wrapper {
      display: flex;
      flex-direction: column;
      :global(.calendar_wrapper) {
        display: inline-block;
        margin-right: 19px;
        :global(.react-datepicker__input-container) {
          width: 170px;
        }
      }
      :global(.form-group) {
        margin-bottom: 0 !important;
      }
      .btn_round {
        display: inline-block;
        cursor: pointer;
        margin: 12px 12px 0px 0px;
      }
    }
  }
}

.time_frame_info {
  font-weight: 500;
  font-size: 14px;
  color: $textShade1;
  letter-spacing: 0.32px;
  margin: 10px 0 0;
  padding-left: 33px;
  span {
    font-size: 16px;
    color: #393f51;
    letter-spacing: 0.36px;
  }
}

@media only screen and (max-width: 767px) {
  .timeframe_edit_box {
    display: block !important;
    .timeframe {
      margin: 0 0 10px 0;
      text-align: center;
    }
    .date_picker_wrapper {
      display: inline-block;
      margin: 18px 0;
      text-align: center;
      width: 100%;
      :global(.calendar_wrapper) {
        margin-right: 0px !important;
      }
      .btn_round {
        display: inline-block;
        cursor: pointer;
        margin: 12px;
      }
    }
  }
  .overall_obj {
    padding: 15px;
    :global(h3) {
      font-size: 18px;
    }
  }
  .member_manager_wrapper {
    .manager_list {
      .topbar {
        @include align-items(flex-start);
        padding: 15px;
        flex-wrap: wrap;
        position: relative;
        .collapse_img {
          margin-right: 0;
          position: absolute;
          top: 15px;
          left: 15px;
        }
        .manager_info {
          @include align-items(flex-start);
          flex-wrap: wrap;
          width: 100%;
          padding-left: 30px;
          h4 {
            font-size: 16px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
  .idp_end_days {
    position: static;
  }
  .key_initiative_wrapper {
    padding-left: 33px;
    margin-top: 0;
    > span {
      display: inline;
    }
  }
  .obj_accordion {
    :global(.custom-default-dd-box) {
      margin: 0;
    }
    :global(.card) {
      :global(.card-footer) {
        :global(.card-ft-wrapper) {
          flex-direction: column;
          flex-wrap: wrap;
          @include justify-content(flex-start);
          @include align-items(flex-start);
          position: relative;
          :global(.card_action) {
            min-width: 80%;
          }
          .comment_exp_col {
            position: absolute;
            right: 7px;
            bottom: 15px;
          }
        }
      }
    }
  }
  .idp_box {
    :global(.custom-accordion) {
      :global(.card) {
        padding: 15px;
      }
    }
  }
  .time_frame_info {
    // padding-left: 0;
  }
  .obj_accordion {
    &:global(.custom-accordion) {
      :global(.card) {
        padding: 10px 15px;
        :global(.card-header) {
          :global(.btn) {
            padding-right: 20px;
          }
        }
      }
      :global(.accordion-icon) {
        top: 0;
        margin-top: 0;
        width: 15px;
        // right: 20px;
        svg {
          width: 100%;
        }
      }
      :global(.accordion-heading) {
        align-items: normal;
        flex-direction: column;
        :global(.accordion-heading-left) {
          width: 100%;
          :global(h3) {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 2px;
          }
          :global(h4) {
            font-size: 13px;
            line-height: 16px;
          }
        }
        :global(.accordion-heading-right) {
          text-align: left;
          padding-left: 30px;
          margin-top: 5px;
          margin-bottom: 5px;
          width: 100%;
          .idp_process_complete {
            font-size: 14px;
            margin-right: 5px;
            line-height: normal;
            display: inline-block;
            vertical-align: middle;
          }
          .idp_end_days {
            font-size: 14px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .timeframe_edit_box {
    .date_picker_wrapper {
      :global(.calendar_wrapper) {
        margin-right: 0px !important;
      }
    }
  }
}
