@import "theme.scss";

.try {
  text-align: center;
}

.popupText {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding: 20px 0px 10px 0px;
  color: #37324b;
}

.custom_modal_popups {
  margin-top: 100px;

  .modal-lg {
    .custom_modal_container {
      &.custom_lg_modal_container {
        padding: 0;

        .modal-header,
        .modal-footer {
          padding: 20px 25px;
        }
      }
    }
  }

  .custom_modal_container {
    padding: 35px;

    .modal-body {
      padding: 0px;
    }
  }
}
