.profile_card {
  margin-bottom: 0;
  border: 0;
  border-radius: 0 0 8px 8px;

  .profile_card_info {
    padding: 25px 25px 10px 25px;
    border-top: 1px solid #ededed;

    .profile_card_title {
      width: 100%;
      margin-bottom: 0;

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.26px;
        color: #322e46;
      }

      .details_wrapper>div {
        margin-bottom: 12px;
      }
    }
  }
}

.details_wrapper {
  padding-top: 6px;
  padding-bottom: 8px;
}

.greyText {
  color: #928fa0;
}
.recent_checkin_card_wrapper {
  padding: 45px 90px 90px;

  .submission_card_wrapper:not(:first-child) {
    border-top: none;
  }

  .submission_card_wrapper:first-child {
    border-radius: 6px 6px 0 0;
  }

  .submission_card_wrapper:last-child {
    border-radius: 0 0 6px 6px;
  }
}

.submission_card_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 38px;
  align-items: center;
  border: 1px solid #E0DFE2; 
}

.employee_names_wrapper {
  display: flex;
  flex-wrap: wrap;

  div {
    border: 1px solid #E0DFE2;
    border-radius: 20px;
    margin: 9px 8px;
    padding: 6px 17px;
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(246, 245, 250, 0.61);
  }
}

.view_feedback {
  color: #7033ff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .recent_checkin_card_wrapper {
    padding: 24px;
  }

  .submission_card_wrapper {
    flex-direction: column;
    padding: 12px;
  }

  .view_feedback {
    padding-top: 24px;
    padding-bottom: 12px;
  }
}