.project_details_wrapper{
  :global(.box-heading-wrapper){
    :global(h3.box-heading-light){
      font-weight: 500;
      font-size: 16px;
      color: #928FA0;
      letter-spacing: 0.36px;
      margin-top: 5px;
    }
  }
  .viewMore{
    color: #7033ff !important;
    transition: all 0.4s ease-out;
    cursor: pointer;
  }
}