@import "../../../theme";

.one_on_one_wrapper {
  .create_new_btn {
    min-width: 115px;
  }
  .tab_table_responsive {
    thead {
      th {
        border-top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
        font-weight: 600;
        &:first-child {
          padding-left: 42px;
        }
      }
    }

    table {
      table-layout: fixed;
    }
  }
  :global(.custom-tooltip) {
    :global(.info-attach-file) {
      @include mobile {
        right: 0 !important;
        left: unset !important;
      }
      &::before {
        @include mobile {
          right: 5px !important;
          left: unset !important;
        }
      }
    }
  }

  :global(.check_in_search_bar) {
    @include flexbox;
    align-items: center;
    :global(form) {
      width: 100%;
    }
  }
}
.tab_team_member_info {
  position: relative;
  width: 400px;
  .team_member_info {
    position: relative;
    color: $primaryText;
    padding-left: 32px;
    @include flexbox;
    @include align-items(center);
    &.sub_team_member {
      padding-left: 52px;
    }
    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .private_notification {
      background: #7033ff;
      border: 2.5px solid #ffffff;
      position: absolute;
      top: 0px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      left: 66px;
    }
    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      display: flex;
      align-items: center;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
      :global(.custom-tooltip) {
        :global(.box-info) {
          margin: 0px;
          &:hover {
            + :global(.info-attach-file) {
              top: -145px;
            }
          }
        }
        :global(.info-attach-file) {
          top: -155px;
          left: -12px;
          &:before {
            top: auto;
            bottom: -4px;
            transform: rotate(135deg);
          }
          ul {
            li {
              line-height: 20px;
            }
          }
        }
      }
    }

    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }

    .team_member_info_collapse {
      width: 17px;
      height: 17px;
      line-height: 12px;
      border: 1px solid $textShade3;
      display: block;
      @include border-radius(50%);
      text-align: center;
      cursor: pointer;
      @include position(absolute, 18px, auto, auto, 0);
    }
  }
}

.collapseClose {
  background-color: $textShade3;
}

.tab_checkin_patterns {
  @include flexbox;
  @include align-items(center);
  padding: 0;
  margin-bottom: 0;
  .dot {
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    @include border-radius(50%);
    line-height: 18px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include transition(all, 0.4s, ease-out);
    cursor: pointer;

    span {
      width: 8px;
      height: 8px;
      @include flex(8px 0 0);
      line-height: 8px;
      background: $themeColor10;
      list-style: none;
      @include border-radius(50%);
      @include transition(all, 0.4s, ease-out);
      position: relative;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        line-height: 10px;
        display: block;
        @include position(absolute, -1px, auto, auto, -1px);
        @include border-radius(50%);
        @include transition(all, 0.1s, ease-out);
        transform: scale(0);
        background: $themeColor10;
      }
    }

    &.tab_checkin_delay {
      span,
      span:after {
        background: $themeColor1;
      }
    }

    &:hover,
    &.active {
      border: 1px solid #dfdfdf;
      background: $whiteColor;
      @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.2));

      span {
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}
