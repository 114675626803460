@import "theme";

.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.26px;
    color: #322e46;
  }
}

.greyText {
  color: #928fa0;
}

.search_bar {
  width: 100%;
  align-items: center;
  padding: 0 24px 36px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .dw_obj_btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .searchbar_wrapper {
    width: 400px;
    display: inline-flex;
    max-width: 100%;

    input[type="text"] {
      padding-right: 50px;
      border-radius: 18px;
      height: 40px;

      &::placeholder {
        color: #928fa0;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .form-search-item {
    height: 40px;
  }
}

.eye_icon {
  &:hover {
    svg {
      g {
        g {
          fill: #7033ff;
        }
      }
    }

    cursor: pointer;
  }
}

:global(.past-checkins-box) {
  margin-top: 25px;
}

.main_table_wrapper {
  margin: 0 24px;
}

.tab_table_responsive {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  background: transparent;
  border-radius: 0;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  thead, tbody {
    .name {
      padding: 24px 40px;
      width: 35%;
      vertical-align: top;
    }
    .submitted_date {
      width: 200px;
      width: 30%;
      padding: 24px 0;
      font-size: 16px;
    }
    .status, .action {
      padding: 24px 40px 24px 12px;
      vertical-align: top;
    }
  }
  thead {
    tr {
      border-bottom: 1px solid #dee2e6;

      .status {
        padding: 24px 40px 24px 12px;
        font-weight: 600;
        font-size: 16px;
      }

      
      th {
        border-bottom: none;
        border-top: none;
      }

      td {
        font-size: 14px;
        color: $primaryText;
        font-weight: 600;
        border-top: 0px solid #dee2e6;

        .status {
          text-align: center;
        }

        a {
          color: $themeColor11;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;

      .name {
        padding: 24px 40px;
        width: 35%;
        vertical-align: top;
      }

      .status {
        padding-top: 24px;
      }

      .table_cell {
        padding: 0;
      }

      & .active {
        background-color: rgba(246, 245, 250, 0.5);
      }

      td {
        font-size: 16px;
        font-weight: 500;
        color: $primaryText;
        border-bottom: 0px solid rgba(0, 0, 0, 0.1);

        &.close_expand {
          height: 0px;
          -moz-transition: height 1s ease;
          -webkit-transition: height 1s ease;
          -o-transition: height 1s ease;
          transition: height 1s ease;
          opacity: 0;
          visibility: hidden;
        }

        &.open_expand {
          height: 200px;
          opacity: 1;
          visibility: visible;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 10px 0px 0px 0px;
          display: inline-block;
          width: 100%;

          li {
            float: left;
            padding: 0px 0px 0px 15px;
            margin: 0px 0px 0px 0px;
            color: $themeColor11;
            font-size: 14px;
            font-weight: 500;

            &:last-child {
              margin-bottom: 0;
            }

            &:first-child {
              background: url("../../../../assets/images/ic_keyresult_bullet.svg") no-repeat 0px 6px;
              width: 730px;
            }

            &.key_value {
              width: 230px;
            }
          }
        }

        a {
          font-weight: 600;
          color: $primaryText;
        }

        &:global(.action-dropdown-wrapper) {
          border-bottom: 0px solid rgba(0, 0, 0, 0.1);
          border-top: 0px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.eye_icon {
  &:hover {
    svg {
      g {
        g {
          fill: #7033ff;
        }
      }
    }

    cursor: pointer;
  }
}

.inner_table {
  padding: 0;
  margin-bottom: 0;

  tbody {
    .status {
      padding: 24px 40px 24px 12px;
    }

    .name {
      padding: 24px 40px;
      width: 33%;
      vertical-align: top;
    }
    .submitted_date_value {
      width: 200px;
      width: 29%;
      padding: 24px 0;
      font-size: 16px;
    }
    .status_value {
      width: 23%;
    }

    .submitted_date_value, .status_value, .action_icon {
      padding: 40px 12px;
    }
  }
}

.TOTAL_PENDING {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}
.PARTIALLY_PENDING {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.COMPLETED {
  border-radius: 5px;
  font-size: 12px;
  color: #00c4bc;
  background: #f5ffff;
  border-color: rgba(36, 218, 210, 0.23);
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.tab_team_member_info {
  position: relative;
  display: flex;

  .expand_image_wrapper {
    margin-left: -42px;
    padding: 12px;
    cursor: pointer;
  }

  .team_member_info {
    position: relative;
    color: $primaryText;
    @include flexbox;
    @include align-items(center);

    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      display: flex;
      align-items: center;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
      :global(.custom-tooltip) {
        :global(.box-info) {
          margin: 0px;
          &:hover {
            + :global(.info-attach-file) {
              top: -145px;
            }
          }
        }
        :global(.info-attach-file) {
          top: -155px;
          left: -12px;
          &:before {
            top: auto;
            bottom: -4px;
            transform: rotate(135deg);
          }
          ul {
            li {
              line-height: 20px;
            }
          }
        }
      }
    }

    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }
  }
}

:global(.expandable-table) {
  margin-bottom: 0;
  tbody {
    td {
      &:nth-child(3) {
        padding-left: 7px;
      }
    }
  }
  .team_member_details {
    width: 235px;
  }
  .tab_team_member_info {
    padding-left: 50px;
  }
}

.completed {
  border-radius: 5px;
  font-size: 12px;
  color: #00c4bc;
  background: #f5ffff;
  border-color: rgba(36, 218, 210, 0.23);
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.pending {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

@media only screen and (max-width: 767px) {
  .heading_wrapper {
    padding: 0 0 22px 0;

    h2 {
      padding-left: 8px;
    }
  }

  .date_wrapper {
    flex-direction: column;
    width: 100%;
    padding-left: 18px;

    >div {
      margin: 8px;
      width: 250px;
    }
  }

  .main_table_wrapper {
    margin: 0;
    overflow-x: auto;
  }

  .tab_table_responsive {
    width: 100%;
    margin: 0;


    tbody {
      tr {

        td:first-child {
          div > span {
            left: 18px;
          }
        }
      }
    }
  }

  .inner_table_wrapper {
    overflow-x: auto;
  }

  .inner_table {
    thead, tbody {
      .submitted_date {
        padding: 24px 12px;
      }
    }
  }

  .box_wrapper {
    overflow-x: auto;
  }
}
