@import "theme";

.campaign_list_wrapper {
  display: block;
  .create_new_btn {
    padding: 10px 20px;
  }
  :global(.box) {
    @include border-radius(8px 8px 0 0);
  }
  :global(.outer-table-wrapper) {
    min-height: 300px;
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    &.hr_table {
      .tab_table_responsive {
        thead {
          th {
            width: 48%;
            &:nth-child(2) {
              width: 15%;
            }
            &:nth-child(3) {
              width: 15%;
            }
            &:nth-child(4) {
              width: 12%;
            }
            &:nth-child(5) {
              width: 12%;
            }
            :global(.custom-tooltip.custom-tooltip-top) {
              top: -1px;
              :global(.info-attach-file) {
                left: -270px;
                &::before {
                  right: -193px;
                }
              }
            }
          }
        }
        tbody {
          td {
            &.date {
              font-size: 16px;
              font-weight: 500;
              color: #37324b;
            }
          }
        }
      }
    }
    .tab_table_responsive {
      margin-bottom: 0;
      thead {
        th {
          padding: 10px;
          border: none;
          &.date {
            width: 15%;
          }
          &:last-child {
            width: 15%;
          }
        }
      }
      tbody {
        td {
          padding: 20px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          &.date {
            font-size: 16px;
            font-weight: 500;
            color: #37324b;
          }
          &.campaignName {
            font-size: 16px;
            font-weight: 500;
            color: $themeColor11;
            letter-spacing: 0.36px;
            a {
              color: $themeColor11;
              &:hover {
                color: $primaryColor;
              }
            }
          }
          .emp_cycle,
          .edit_cycle {
            cursor: pointer;
            display: inline-block;
            &:hover {
              svg {
                > g {
                  > g {
                    > g {
                      path {
                        &:first-child {
                          fill: $primaryColor;
                        }
                        &:nth-child(2) {
                          stroke: $primaryColor;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .custom_table {
    &.hr_table {
      :global(.custom-tooltip.custom-tooltip-top) {
        :global(.box-info:hover + .info-attach-file) {
          top: 30px;
        }
        :global(.info-attach-file) {
          &::before {
            top: -79px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .campaignName {
    :global(.info-attach-file) {
      min-width: 230px;
      left: -48px;
      &::before {
        left: -73px;
      }
    }
  }
  .campaign_list_wrapper {
    .custom_table {
      :global(.table-responsive) {
        overflow-x: auto !important;
      }
      .tab_table_responsive {
        width: 890px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .campaign_list_wrapper {
    :global(.m-text-left) {
      text-align: left !important;
      margin: 0 0 20px;
    }
  }
}
