@import "theme";

.mainWrapper {
  padding: 0 24px;
}

.heading {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #37324b;
  padding: 6px 0;
  .count {
    color: #928fa0;
  }
}

.textContent {
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
  padding: 6px 0;

  .gretTextColor {
    color: #928fa0;
  }
}

.description {
  padding: 12px 0;
}


.startButton {
  text-align: center;
  margin: 18px 0 0;
  button {
    min-width: 120px;
  }
}

.team_checkin_status {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid $borderColor;
  @include border-radius(4px);
  display: inline-block;
}

:global(.COMPLETED) {
  color: #00c4bc;
  background: #f5ffff;
  border-color: rgba(36, 218, 210, 0.23);
}

:global(.TOTAL_PENDING) {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
}

:global(.PARTIALLY_PENDING) {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
}