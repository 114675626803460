@import '../../../theme';

.my_resume_wrapper{
  padding-bottom: 0;
  padding-top: 15px;
  >:global(.box-heading-wrapper){
    padding: 0 15px 15px;
    :global(.box-heading){
      width: 70%;
    }
  }
  >:global(.box-inner-pad){
    padding:15px;
  }
}
.file_info_wrapper{
  @include flexbox;
  .file_info{
    @include flexbox;
    flex-direction: column;
    .file_txt{
      font-weight: 600;
      font-size: 14px;
      color: #37324B;
      letter-spacing: 0.32px;
      line-height: 22px;
    }
    .file_time{
      font-size: 12px;
      color: #74798C;
      letter-spacing: 0.27px;
    }
  }
  .file_download{
    padding: 5px 12px;
    border-radius: 4px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    cursor: pointer;
    line-height: 26px;
    margin-left: 15px;
  }
  .info_tooltip {
    display: flex;
    align-items: center;
    margin-left: 15px;
    position: relative;
    top: -1px;
  }
}
.file_btn_group{
  @include flexbox;
  margin-top: 15px;
  .create_new_btn{
    padding: 10px 20px;
  }
}

@media only screen and (max-width:479px){
  .file_btn_group{
    flex-wrap: wrap;
    > *{
      &:last-child{
        margin-top: 15px;
      }
    }
  }
}