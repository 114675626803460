@import "../../theme.scss";
.info_banner_wrapper {
  background-color: #7033ff;
  padding: 17px 0;
  animation-name: fadeInUp;
  animation-fill-mode: both;
  p {
    color: #fff;
    margin-bottom: 8px;
    display: inline;

    span {
      padding-left: 18px;
    }
  }
  span {
    font-weight: 500;
    color: #fff;
  }

  a, a:visited {
    padding-left: 6px;
    color: #fff;
    text-decoration: underline;
  }
}

.info_banner_wrapper_margin {
  background-color: #7033ff;
  padding: 17px 0;
  animation-name: fadeInUp;
  animation-fill-mode: both;
  margin-bottom: 25px;
  p {
    color: #fff;
    margin-bottom: 8px;
    display: inline;

    span {
      padding-left: 18px;
    }
  }
  span {
    font-weight: 500;
    color: #fff;
  }

  a, a:visited {
    padding-left: 6px;
    color: #fff;
    text-decoration: underline;
  }
}

.scroll_container {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.scroll_text {
  -moz-transform: translateX(30%);
  -webkit-transform: translateX(30%);
  transform: translateX(30%);

  -moz-animation: text-scroll-animation 20s linear infinite;
  -webkit-animation: text-scroll-animation 20s linear infinite;
  animation: text-scroll-animation 20s linear infinite;

  &:hover {
    animation-play-state: paused;
  }

  p {
    color: #fff;
    margin-bottom: 8px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;

    span {
      padding-left: 18px;
    }
  }

  span {
    font-weight: 500;
    color: #fff;
  }
}

@keyframes text-scroll-animation {
  from {
    -moz-transform: translateX(30%);
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
  }
  to {
    -moz-transform: translateX(-130%);
    -webkit-transform: translateX(-130%);
    transform: translateX(-130%);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .info_banner_wrapper {
    margin-bottom: 20px;
  }
}
