@import "theme";

.role_based_skills_wrapper {
  padding-bottom: 0;
  border-radius: 0 8px 8px;

  ~ .role_based_skills_wrapper {
    border-radius: 8px;
  }

  :global(.tab_filter) {
    padding: 25px;
  }

  .roll_based_skill_box_info {
    top: 15px;

    > span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.custom-default-dd-box) {
    :global(.select__control) {
      min-height: 46px;
      border-color: rgba(0, 0, 0, 0.1);
    }

    :global(.select__indicator-separator) {
      display: none;
    }

    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .skill_table {
    padding: 25px 25px 0;

    :global(.table) {
      margin-bottom: 0;
    }

    thead {
      padding: 0;
      list-style: none;

      th {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        border-top: 0;
        border-bottom-width: 1px;
        width: 12.5%;

        &.skill_name {
          width: 100%;
          padding-left: 30px;
        }
      }
    }

    tbody {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      td {
        font-size: 15px;
        font-weight: 500;
        color: #37324b;
        padding: 0;
        border-bottom: 0;

        .skill_name {
          width: 100%;
          font-weight: 600;
          padding: 15px 0;
          position: relative;
          cursor: pointer;
          @include flexbox;
          @include align-items(center);

          .user_img {
            height: 52px;
            width: 52px;
            overflow: hidden;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
            line-height: 52px;
            text-align: center;
            color: #fff;
            font-weight: 700;
            font-size: 18px;

            img {
              max-width: 100%;
              position: relative;
              top: -2px;
            }
          }

          .user_info {
            @include flexbox;
            flex-direction: column;
            @include align-items(flex-start);

            .username {
              font-size: 16px;
              color: #322e46;
              font-weight: 600;
            }

            .department {
              font-size: 14px;
              color: #322e46;
              letter-spacing: 0.39px;
              font-weight: 400;
              margin-top: 2px;

              .counter {
                font-style: normal;
                color: #928fa0;
                font-weight: 600;
                display: inline-block;
                margin-left: 5px;
              }
            }
          }
        }

        .expand_collapse_wrapper {
          cursor: pointer;
          position: static;
          margin-right: 10px;
        }

        .endrosement_info {
          border-radius: 4px;
          position: relative;

          :global(.box-light-gradient) {
            margin-left: -25px;
            width: calc(100% + 50px);
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            &::before {
              border-radius: 0;
            }
          }

          .skills_head {
            margin: 0;
            padding: 15px 15px 5px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            position: relative;

            .skillsname,
            .last_used_on {
              padding: 0.75rem;
              width: 16%;
            }

            .rel_exp {
              padding: 0.75rem;
              width: 12%;
            }

            .cmp_lvl {
              width: 21%;
              padding: 0.75rem;
            }

            .endorsement_type {
              width: 14%;
              padding: 0.75rem;
            }
          }

          .skills_body {
            padding: 0 15px;
            // max-height: 340px;
            // overflow-y: auto;
            // min-height: 90px;

            li {
              margin-bottom: 0;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

              &:last-child {
                border-bottom: 0;
              }

              .skillsname {
                color: #74798c;

                .mmg_tooltip {
                  position: absolute;
                  right: 15px;
                  top: 10px;

                  :global(.box-info) {
                    margin: 0;
                  }

                  :global(.info-attach-file) {
                    min-width: 260px;
                    left: auto;
                    right: -14px;
                    width: 260px;
                    padding: 5px;
                    white-space: normal;

                    &::before {
                      right: 20px;
                      left: auto;
                    }

                    p {
                      color: #37324b;
                      text-align: center;
                    }
                  }

                  &:global(.mmg_table_tooltip) {
                    display: inline-block;
                    margin-left: 5px;
                    position: relative;
                    right: 0;
                    top: 0;

                    :global(.info-attach-file) {
                      right: -170px;

                      &::before {
                        right: 174px;
                      }
                    }
                  }
                }
              }

              .endorsement_type {
                padding-left: 30px;
              }
            }
          }

          .skillsname,
          .last_used_on {
            padding: 0.75rem;
            width: 16%;
          }

          .rel_exp {
            padding: 0.75rem;
            width: 12%;
          }

          .cmp_lvl {
            width: 21%;
            padding: 0.75rem;
          }

          .endorsement_type {
            width: 14%;
            padding: 0.75rem;
          }
        }
      }

      tr {
        &:hover {
          background-color: transparent;
        }

        &:global(.active) {
          td {
            .expand_collapse_wrapper {
              top: 12px;

              .collapse {
                display: block;
              }

              .expand {
                display: none;
              }
            }

            .endrosement_info {
              display: block;
            }
          }
        }
      }
    }
  }

  .endrosment_tab {
    padding: 0 25px;

    :global(.card) {
      background-color: transparent;

      :global(.card-header) {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;

        :global(button) {
          padding-left: 30px;
          position: relative;
          z-index: 1;
          text-align: left;
        }

        &:before {
          top: 21px;
        }
      }

      :global(.card-body) {
        max-height: 300px;
        overflow-x: auto;
        padding-bottom: 15px;
      }

      :global(.show) {
        &:before {
          top: 21px;
        }
      }
    }
  }
}

.endorsement_skill_table {
  // overflow-x: auto;
  .skills_head {
    padding: 0;
    list-style: none;

    li {
      display: block;

      > * {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        vertical-align: middle;
      }
    }
  }

  .skills_body {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      font-weight: 500;
      font-size: 15px;
      color: #74798c;
      display: block;
      position: relative;
      margin-bottom: 15px;

      > * {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #37324b;

        &:first-child {
          padding-left: 15px;
        }
      }

      .required_skill {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
}

.th_info_wrapper {
  display: inline-block;
  position: relative;

  > * {
    display: inline-block;
  }
}

.th_info_tooltip {
  text-align: left;
  margin-left: 10px;

  :global(.box-info) {
    margin: 0;
  }

  :global(.info-attach-file) {
    min-width: 240px;
    left: auto;
    right: -50px;

    // display: none;
    &::before {
      right: 54px;
      left: auto;
    }

    :global(.attachment-title) {
      color: #b4b0c8;
    }

    :global(ul) {
      margin: 10px 0;

      :global(li) {
        line-height: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

.endorse_tooltip {
  position: relative;

  ~ .endorse_tooltip {
    margin-left: -7px;
  }

  .tool_tip_info {
    position: absolute;
    left: -25px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #37324b;
    bottom: -50px;
    padding: 5px 10px;
    border-radius: 4px;
    @include transition(0.2s all);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    min-width: 90px;
    text-align: center;
  }

  > img {
    cursor: pointer;

    &:hover {
      + .tool_tip_info {
        opacity: 1;
        bottom: -45px;
        visibility: visible;
      }
    }
  }
}

.endorsement_skill_table {
  .skills_body {
    li {
      ~ li {
        .endorse_tooltip {
          .tool_tip_info {
            top: auto;
            bottom: 35px;
          }

          > img {
            cursor: pointer;

            &:hover {
              + .tool_tip_info {
                top: auto;
                bottom: 26px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .role_based_skills_wrapper {
    :global(.check_in_search_bar) {
      margin-bottom: 0;
    }

    .skill_table {
      tbody {
        td {
          .endrosement_info {
            overflow-x: auto;
            overflow-y: visible;
            .skills_body {
              min-height: 90px;
            }
            :global(.box-light-gradient) {
              margin-left: 0;
              width: 100%;
            }
            > *,
            :global(.box-light-gradient) {
              width: 1100px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .role_based_skills_wrapper {
    :global(.table),
    :global(tbody),
    :global(thead),
    :global(tr),
    :global(td) {
      display: block;
    }

    .skill_table {
      thead {
        th {
          border-bottom: 0;
        }
      }
    }
  }

  .role_based_skills_wrapper {
    :global(.box-inner-pad) {
      :global(div[class*="col-md"]) {
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .role_based_skills_wrapper {
    :global(.box-heading-wrapper) {
      :global(.box-heading) {
        width: 88%;
      }
    }

    :global(.box-inner-pad) {
      :global(div[class*="col-md"]) {
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-top: 15px;
        }
      }
    }

    .skill_table {
      tbody {
        td {
          .skill_name {
            flex-wrap: wrap;

            .user_info {
              width: calc(100% - 93px);
            }
          }
        }
      }
    }
  }
}
