@import "../../../theme.scss";

.myobjectives_wrapper {
  .create_new_btn {
    padding: 10px 20px;
  }

  .tab_title {
    h2 {
      color: $primaryText;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 5px;
    }

    h3 {
      color: $textShade1;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .search_bar {
    width: 100%;
    display: inline-block;
    text-align: right;

    .allobjective_searchbar {
      width: 320px;
      max-width: 100%;
      display: inline-flex;
    }

    .form-search-item {
      height: 40px;
    }
  }

  .allobjectives_tabs {
    // :global(.nav-tabs) {
    //     width: 780px;
    //     text-align: center;

    // }

    // :global(.tab-content) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    background: transparent;
    border-radius: 0;
    z-index: 1;
    position: relative;

    .tab_container_wrapper {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
      background: #fff;
      border-radius: 0 8px 8px;
      z-index: 1;
      position: relative;

      .objective_heading {
        padding: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .allobjective_table {
        padding-bottom: 20px;

        .tab_table_responsive {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
          background: transparent;
          border-radius: 0;
          z-index: 1;
          position: relative;
          margin-bottom: 0;

          thead {
            tr {
              border-bottom: 1px solid #dee2e6;

              td {
                font-size: 14px;
                color: $primaryText;
                font-weight: 600;
                border-top: 0px solid #dee2e6;

                &.name {
                  width: 400px;
                }

                &.key_result {
                  width: 200px;
                }

                &.assigned_by {
                  width: 100px;
                }

                &.duration {
                  width: 200px;
                }

                &.action {
                  width: 100px;
                }

                &.objectivesstatus {
                  width: 150px;
                }

                a {
                  color: $themeColor11;
                }
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #dee2e6;

              & .active {
                background-color: rgba(246, 245, 250, 0.5);
              }

              td {
                font-size: 14px;
                font-weight: 400;
                color: $primaryText;
                border-bottom: 0px solid rgba(0, 0, 0, 0.1);

                &:first-child {
                  color: $primaryText;
                  font-size: 15px;
                  font-weight: 600;
                  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
                  padding-left: 40px;

                  span {
                    position: absolute;
                    left: 10px;
                    cursor: pointer;
                  }
                }

                &.progress_value {
                  color: $themeColor11;
                  font-size: 14px;
                  font-weight: 600;
                }
                &.assign_by_name {
                  color: $themeColor11;
                  font-size: 14px;
                  font-weight: 500;
                }

                .in_progress {
                  background: #f0fafd;
                  border: 1px solid #cff1ff;
                  border-radius: 4px;
                  font-weight: 600;
                  font-size: 12px;
                  color: $themeColor13;
                  letter-spacing: 0.33px;
                  text-align: center;
                  max-width: 80px;
                  padding: 2px 5px;
                }

                .in_completed {
                  background: #fcf6f4;
                  border: 1px solid #fce6dd;
                  border-radius: 5px;
                  font-weight: 600;
                  font-size: 12px;
                  color: #fd8c61;
                  letter-spacing: 0.33px;
                  text-align: center;
                  width: 82px;
                  padding: 2px 5px;
                }

                .drafted {
                  background: #f5f5f5;
                  border: 1px solid #d8d8d8;
                  border-radius: 5px;
                  font-size: 12px;
                  color: #787878;
                  letter-spacing: 0.33px;
                  text-align: center;
                  padding: 5px 10px;
                  width: auto; //148px insteadof auto
                  display: inline;
                  font-weight: 600;
                }

                .pending_approval {
                  background: #ffe9e9;
                  border: 1px solid rgba(240, 117, 117, 0.2);
                  border-radius: 5px;
                  font-size: 12px;
                  color: #f07575;
                  letter-spacing: 0.33px;
                  text-align: center;
                  padding: 5px 10px;
                  width: auto; //148px insteadof auto
                  display: inline;
                  font-weight: 600;
                }

                .invalid {
                  background: #ffe9e9;
                  border: 1px solid rgba(240, 117, 117, 0.2);
                  border-radius: 5px;
                  font-weight: 600;
                  font-size: 12px;
                  color: #f07575;
                  letter-spacing: 0.33px;
                  text-align: center;
                  width: 82px;
                  padding: 2px 5px;
                }

                .green {
                  width: 8px;
                  height: 8px;
                  display: inline-flex;
                  border-radius: 50%;
                  background-color: $themeGreen;
                  margin-right: 5px;
                }

                .red {
                  width: 8px;
                  height: 8px;
                  display: inline-flex;
                  border-radius: 50%;
                  background-color: $themeColor1;
                  margin-right: 5px;
                }

                .yellow {
                  width: 8px;
                  height: 8px;
                  display: inline-flex;
                  border-radius: 50%;
                  background: $themeYellow;
                  margin-right: 5px;
                }

                &.close_expand {
                  height: 0px;
                  -moz-transition: height 1s ease;
                  -webkit-transition: height 1s ease;
                  -o-transition: height 1s ease;
                  transition: height 1s ease;
                  opacity: 0;
                  visibility: hidden;
                }

                &.open_expand {
                  height: 200px;
                  opacity: 1;
                  visibility: visible;
                }

                ul {
                  list-style: none;
                  padding: 0;
                  margin: 10px 0px 0px 0px;
                  display: inline-block;
                  width: 100%;

                  li {
                    float: left;
                    padding: 0px 0px 0px 15px;
                    margin: 0px 0px 0px 0px;
                    color: $themeColor11;
                    font-size: 14px;
                    font-weight: 500;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    &:first-child {
                      background: url(../../../assets/images/ic_keyresult_bullet.svg) no-repeat 0px 6px;
                      width: 730px;
                    }

                    &.key_value {
                      width: 230px;
                    }
                  }
                }

                a {
                  font-weight: 600;
                  color: $primaryText;
                }

                &:global(.action-dropdown-wrapper) {
                  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
                  border-top: 0px solid rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
        }
      }
    }
    // }
  }
}

.expand {
  cursor: pointer;
}

.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.show {
    max-height: 99em;
    transition: max-height 0.5s ease-in-out;
  }
}

.block {
  margin-top: 10px;
  background: #f5f5f5;
  padding: 0;
  // height: 250px;

  &__content {
    border: 1px solid #ccc;
    padding: 1.5em;
    height: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  :global(.subNavigation) {
    + .myobjectives_wrapper {
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .myobjectives_wrapper {
    .allobjectives_tabs {
      :global(.nav-tabs) {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        margin-top: 10px;
      }
      .tab_container_wrapper {
        .allobjective_table {
          overflow-x: auto;
          .tab_table_responsive {
            width: 1120px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  :global(.subNavigation) {
    + .myobjectives_wrapper {
      :global(.container) {
        :global(.text-right.col-lg-6) {
          text-align: left !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  :global(.subNavigation) {
    + .myobjectives_wrapper {
      margin-top: 20px;
      padding-top: 0;
    }
  }
  .myobjectives_wrapper {
    .allobjectives_tabs {
      .nav-tabs {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .myobjectives_wrapper {
    .allobjectives_tabs {
      :global(.nav-tabs) {
        max-width: 100%;
      }
    }
  }

  .tab_container_wrapper {
    .allobjective_table {
      :global(.custom-table) {
        overflow-x: scroll;

        .tab_table_responsive {
          width: 1000px;
        }
      }
    }
  }
}
