.mBody {
  position: relative;
  padding-top: 25px !important;
  :global(.box-light-gradient) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .lists_wrapper {
    position: relative;
    margin: 25px 25px 0 25px;
    overflow-y: scroll;
    height: 300px;
    ul{
      li{
        position: relative;
        padding-left: 5px;
        color: #322e46;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }
}
