@import "theme";

.noteBox {
  display: flex;
  background-color: #f9f8fc;
  color: #928fa0;
  font-weight: 700;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: auto;
  margin: auto;
  padding: 10px;
  margin-bottom: 4%;
  box-shadow: none !important;
}

.questions_box {
  padding-bottom: 0;
  .dynamic_question {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    :global(.box) {
      border-radius: 0;
      box-shadow: unset;
      margin-bottom: 0;
      padding: 0 0 25px 0;
    }
  }
}

.workload_rating_guide {
  font-size: 15px;
  color: $textShade1;
  font-weight: 500;
  padding-bottom: 7px;
  margin-top: 20px;
}

.workload_rating {
  list-style: none;
  padding: 0;
  @include flexbox;
  width: 100%;
  @include justify-content(space-evenly);
  // margin-bottom: 30px;   // text box apper then add this
  margin-bottom: 0px;

  li {
    border: 1px solid #b8b4cc;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #928fa0;
    line-height: 46px;
    cursor: default;
    @include transition(all, 0.5s, ease-out);

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.active,
    &:hover {
      background: $themeColor10;
      color: $whiteColor;
      border-color: $secondaryColor;
    }

    + li {
      border-left: 0;
    }
  }
  &.noHover {
    li {
      &:hover {
        background: $whiteColor;
        color: #928fa0;
        border-color: #b8b4cc;
      }
    }
  }
}

.workload_rating_result_wrapper {
  @include flexbox;
  &.employee_rating {
    ul {
      margin-left: 0px;
    }
  }
  .workload_rating_current,
  .workload_rating_old {
    margin-bottom: 10px;
    li {
      min-height: 74px;
      @include inline-flex;
      @include flex-direction(column);
      @include justify-content(center);
      padding: 5px;

      &:hover {
        .workload_rating_result,
        .workload_rating_result_duration {
          color: $whiteColor;
        }
      }
    }
  }
  .workload_rating_old {
    margin-right: 0; // its 0 instead of 25
    margin-left: 25px !important; // as per request
  }
  .workload_rating_current {
    @include flex(0 0 150px);
    margin-left: 15px;

    li {
      background: $themeColor10;
      border-color: $themeColor10;

      .workload_rating_result,
      .workload_rating_result_duration {
        color: $whiteColor;
      }
    }
  }

  .workload_rating_result {
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    color: $textShade1;

    span {
      font-weight: normal;
      margin: 0 5px;
    }
  }

  .workload_rating_result_duration {
    font-size: 14px;
    font-weight: 500;
    color: $textShade1;
    line-height: 1;
  }
}

.form_option_list {
  list-style: none;
  padding: 6px 0 0 0;
  margin-bottom: 0;
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);

  li {
    max-width: 50%;
  }
}

.questions_conversion_box {
  .questions_answer_row {
    @include flexbox;
  }
}

.workload_rating_result_wrapper {
  + .questions_answer_row {
    :global(.common_list_items) {
      :global(li) {
        margin: 10px 0 0;
      }
    }
  }
}
// .comment_box_wrapper {
//   @include border-radius(4px);
//   background: rgba($color: $textShade4, $alpha: 0.6);
//   border: 1px solid $borderColor;
//   margin-top: 22px;
//   margin-bottom: 10px;

//   .comment_box_overview {
//     background: #F2F1FB;

//     .comment_box_overview_icon {
//       margin-right: 10px;

//       svg {
//         g {
//           fill: $primaryColor;
//         }
//       }
//     }

//     @include flexbox;
//     padding: 21px 15px 21px 24px;
//     border-bottom:1px solid $borderColor;
//     font-style: 14px;
//     font-weight: 500;

//     span {
//       font-size: 12px;
//       color: $textShade1;
//     }
//   }

//   .comment_box_row {
//     padding: 15px;
//     @include flexbox;
//     position: relative;

//     .comment_box_user_pics {
//       width: 40px;
//       @include flex(0 0 40px);
//       margin-right: 10px;
//       border-radius: 50%;
//       overflow: hidden;

//       img {
//         width: 40px;
//         height: 40px;
//         object-fit: contain;
//       }
//     }

//     .comment_box_user_comment_wrapper {
//       font-size: 16px;
//       font-weight: normal;
//       color: $primaryText;
//       @include flex(2);

//       .comment_box_user_name {
//         font-size: 16px;
//         color: $primaryText;
//         letter-spacing: 0.36px;
//         font-weight: 600;
//       }

//       .comment_box_user_comment_time {
//         font-size: 12px;
//         color: $textShade1;
//         font-weight: 500;
//         margin-left: 5px;
//       }
//     }

//     .comment_box_user_comment_remove {
//       position: absolute;
//       top: 10px;
//       right: 15px;
//       cursor: pointer;

//       &:hover {
//         svg {

//           circle,
//           polygon {
//             stroke: $primaryColor;
//           }
//         }
//       }
//     }
//   }
// }

.questions_answer_privacy {
  font-size: 14px;
  color: $textShade1;
  @include flexbox;
  font-weight: 500;
  padding-top: 5px;

  svg,
  img {
    text-align: left;
    @include flex(0 0 12px);
    margin-right: 10px;
  }
}

.lock_icon_wrapper {
  &:hover {
    + .info_lock_tooltip {
      opacity: 1;
      visibility: visible;
      top: 32px;
    }
    svg {
      g {
        fill: $primaryColor;
      }
    }
  }
}

.info_lock_tooltip {
  padding: 10px 15px;
  border: 1px solid #eaeaea;
  min-width: 330px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 45px;
  right: -19px;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transition: 0.5s all;
  pointer-events: none;

  &:before {
    right: 20px;
    height: 7px;
    width: 7px;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    transform: rotate(-45deg);
    top: -4px;
    background-color: #fff;
    margin: auto;
    content: "";
    position: absolute;
    box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
  }

  :global(.attachment-title) {
    color: #7033ff;
    font-weight: 600;
    font-size: 15px;
  }

  ul {
    margin: 5px 0 15px;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 15px;
      color: #322e46;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b4b0c8;
        bottom: 0;
      }
    }
  }

  :global(.attachment-note) {
    color: #322e46;
    font-weight: 600;
  }

  p {
    margin: 3px 0 0;
    font-size: 13px;
    color: #928fa0;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .workload_rating_result_wrapper {
    flex-direction: column;
    .workload_rating_current,
    .workload_rating_old {
      margin-bottom: 10px;
      li {
        min-height: none;
      }
    }
    .workload_rating_old {
      margin-left: 0 !important; // as per request
    }
    .workload_rating_current {
      @include flex(0 0 75px);
    }

    .workload_rating_result {
      font-size: 16px;
    }

    .workload_rating_result_duration {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}
