@import "../../theme.scss";
.win_challenges {
  :global(.card_details) {
    .to_do_row {
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 0; //0 instead of 60px 20px 20px 20px
      max-height: none; //temporary none instead of 200px
      //   overflow-y: auto;// temporary remover instad of overflow-y
      margin: 0px;
    }
    .subtitle {
      font-size: 18px;
      font-weight: 500;
    }
    .under_subtitle {
      font-size: 14px;
      font-weight: 400;
    }
    .details {
      margin-top: 10px;
    }
    .wc_data {
      display: block;
      .data_date {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 0 0 0;
        color: $textShade1;
        display: flex;
        cursor: auto;
        a {
          color: $textShade1;
          line-height: 20px;
        }
        a:hover {
          color: $primaryColor;
        }
        span {
          color: #555;
        }
      }
      ul {
        padding: 20px 20px 20px 20px;
        margin: 0px;
        list-style: none;
        margin-bottom: 0;
        // min-height: auto;
        min-height: 100px; // to fix drag and drop
        height: 280px; //height instead of max-height
        // max-height: 360px; // to fix drag and drop
        @include transition(all, 0.4s, ease-out);
        border-radius: 4px;
        border: 1px dashed transparent;
        overflow-y: auto;
        &:global(.heightAuto) {
          height: auto;
          padding-top: 10px;
          li {
            &:global(.no-record-wc) {
              background-image: none;
              align-items: center;
              display: flex;
              justify-content: center;
              padding: 25px;
              color: $textShade1;
              pointer-events: none;
              border: 1px solid rgba($color: #000000, $alpha: 0.1);
              font-size: 14px;
            }
          }
        }
        &[data-draggable="target"][aria-dropeffect="move"] {
          border-color: rgba(0, 0, 0, 0.1);
          background-color: rgba(229, 233, 236, 0.5);
          max-height: none;
          li {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 30px;
            background-position: 90.5% 14px;
            .ic_dot {
              top: 17px;
              left: 10px;
            }
            .dropdown_move_list {
              top: 10px;
              right: 10px;
            }
          }
        }

        li {
          padding: 0px 75px 0 20px;
          margin: 0px 0px 15px 0px;
          list-style: none;
          position: relative;
          color: $primaryText;
          font-size: 16px;
          font-weight: 500;
          background-image: url(../../assets/images/ic_move.svg);
          background-repeat: no-repeat;
          background-position: 92.5% 4px;
          @include transition(all, 0.4s, ease-out);
          border-radius: 4px;
          border: 1px solid transparent;
          cursor: move;
          &:first-child {
            :global(.custom-tooltip) {
              :global(.info-attach-file) {
                top: auto;
                bottom: -100px;
                &:before {
                  top: -6px;
                  bottom: auto;
                  transform: rotate(-45deg);
                }
              }
              :global(.box-info) {
                &:hover {
                  + :global(.info-attach-file) {
                    bottom: -85px;
                  }
                }
              }
            }
          }
          &[data-draggable="item"][aria-grabbed="true"] {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 30px;
            border-color: rgba(0, 0, 0, 0.1);
            background-position: 90.5% 14px;
            background-color: rgba(246, 245, 250, 0.8);
            .ic_dot {
              top: 17px;
              left: 10px;
            }
            .dropdown_move_list {
              top: 10px;
              right: 10px;
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }

          :global(.wc-text) {
            cursor: auto;
          }
          :global(.action-dropdown-wrapper) {
            :global(.dropdown-item) {
              :global(.dropdown_icon) {
                position: relative;
                top: -2px;
              }
            }
          }

          .ic_dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $textShade1;
            position: absolute;
            left: 0;
            top: 7px;
            @include transition(all, 0.4s, ease-out);
          }

          .dropdown_move_list {
            position: absolute;
            right: 0;
            top: 0;
            @include transition(all, 0.4s, ease-out);
          }
          .move {
            display: none; //its none instead of inline-block
            margin: 0px 10px 0px 5px;
            cursor: move;
          }
          .dots {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #d2d0e0;
            margin-right: 5px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

:global(.challenges-wrapper) {
  :global(.action-dropdown-wrapper) {
    :global(.dropdown-item) {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

:global(.wins-wrapper) {
  .dropdown_move_list {
    display: none;
  }
  ul {
    li {
      background-image: none !important;
      pointer-events: none;
      a {
        pointer-events: auto;
      }
    }
  }
}

:global(.collective-wrapper) {
  > .win_challenges {
    margin-bottom: 0;
  }
}

/* drop target focus and dragover state */
[data-draggable="target"][aria-dropeffect="move"]:focus,
[data-draggable="target"][aria-dropeffect="move"].dragover {
  outline: none;

  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
}

:global(.custom-tooltip) {
  &:global(.custom-tooltip-top) {
    :global(.box-info) {
      pointer-events: auto;
    }
  }
}

/* items focus state */
// [data-draggable="item"]:focus
// {
// 	outline:none;

// 	box-shadow:0 0 0 2px #68b, inset 0 0 0 1px #ddd;
// }

@media only screen and (max-width: 1024px) {
  .win_challenges {
    :global(.card_details) {
      .wc_data {
        ul {
          li {
            background-image: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  .win_challenges {
    :global(.card_details) {
      .wc_data {
        ul {
          li {
            :global(.custom-tooltip) {
              :global(.info-attach-file) {
                right: auto;
                &:before {
                  right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .win_challenges {
    :global(.card_details) {
      .wc_data {
        ul {
          li {
            padding-right: 0;
            &:first-child {
              :global(.custom-tooltip) {
                :global(.box-info) {
                  &:hover {
                    + :global(.info-attach-file) {
                      bottom: -75px;
                    }
                  }
                }
              }
            }
            :global(.custom-tooltip) {
              &.custom-tooltip-top {
                :global(.box-info) {
                  margin: 0;
                  &:hover {
                    + :global(.info-attach-file) {
                      top: -75px;
                    }
                  }
                }
              }
              :global(.info-attach-file) {
                right: auto;
                left: -180px;
                min-width: 265px;
                padding: 10px;
                top: -75px;
                &:before {
                  right: auto;
                  left: 181px;
                }
                :global(.tTitle) {
                  font-size: 12px;
                }
                :global(.tDateBox) {
                  :global(.tDate) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          &:global(.heightAuto) {
            min-height: 70px;
            li {
              &:global(.no-record-wc) {
                padding: 15px;
              }
            }
          }
        }
      }
    }
  }
}
