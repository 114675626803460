.filter_info_tooltip {
  text-align: left;
  margin-top: -3px;
  display: inline-block;
  :global(.info-attach-file) {
    min-width: 300px;
    :global(.attachment-title) {
      color: #b4b0c8;
    }
    :global(ul) {
      margin: 10px 0;
      :global(li) {
        line-height: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .filter_info_tooltip {
    :global(.info-attach-file) {
      min-width: 230px;
      left: -180px;
      &::before {
        left: 189px;
      }
    }
  }
}
