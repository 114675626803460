@import "theme";

.myskill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 120px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}
.endrosement_tabs {
  :global(.tab-content) {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
  &:global(.custom-tabs) {
    :global(.nav-tabs) {
      @include justify-content(flex-start);
      :global(.nav-link) {
        padding: 15px 30px;
        text-align: center;
        flex: none !important;
        min-width: 180px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .myskill_wrapper {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .res_title_btn {
    text-align: left !important;
    margin-top: 15px;
  }
  .endrosement_tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    margin-top: 10px;
  }
}
