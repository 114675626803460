@import "../../../theme.scss";
@keyframes myAnim {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
.hr_dashboard_wrapper {
  :global(.date_range_calendar_wrapper) {
    &:global(.form-group) {
      margin-bottom: 0;
    }

    :global(.DateRangePicker) {
      width: 250px;
      :global(.DateRangePicker_picker) {
        top: 46px !important;
        left: -16px !important;
        @include mobile {
          left: -13px !important;
        }
      }
      :global(.DateRangePickerInput__withBorder) {
        border: none;
        padding: 0 10px 0 0;
        margin-right: 40px;
        :global(.DateRangePickerInput_calendarIcon) {
          top: 0;
        }
        // :global(.DateRangePickerInput_arrow) {
        //   display: none;
        // }
      }
    }
  }
  .filters_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-end;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
    .filter_dashboard_wrapper {
      display: flex;
      align-items: flex-end;
      @include mobile {
        flex-direction: column;
      }
      .badge_wrapper {
        width: 220px;
        padding: 25px 20px;
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: space-between;
        &.date_picker_badge {
          width: 280px;
          flex-direction: column;
          padding: 13px 15px;
          align-items: flex-start;
          @include mobile {
            width: 100%;
          }
        }
        @include mobile {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
        }
        .badge_title {
          color: #322e46;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.45px;
          line-height: 24px;
        }
      }

      .filter_clear_button {
        margin-right: 20px;
      }
    }
  }

  .filter_wrapper {
    position: relative;
    @include mobile {
      width: 100%;
    }
    .dashboard_dropdown_wrapper {
      width: 240px;
      display: flex;
      position: absolute;
      top: 80px;
      z-index: 999;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-timing-function: ease-in;
      animation: myAnim 0.5s ease 0s 1 normal forwards;
      &.subMenu_dropdown {
        left: 245px;
        max-height: 340px;
        overflow: auto;
        animation: myAnim 0.5s ease 0s 1 normal forwards;
        @include mobile {
          left: 110px;
        }
        :global(form) {
          padding: 15px 0.5rem 5px;
        }
      }
      &:global(.box) {
        padding: 0;
      }
      ul {
        list-style-type: none;
        width: 100%;
        padding: 0 0.5rem;
        margin: 0;
        li {
          padding-left: 0.5rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-child {
            border-bottom: none;
          }
          .right_side {
            display: flex;
            cursor: pointer;
            align-items: center;
            .count {
              height: 25px;
              width: 25px;
              background-color: #f6f2ff;
              border-radius: 50%;
              margin-right: 12px;
              text-align: center;
              font-weight: 600;
              color: $primaryColor;
              line-height: 26px;
            }
          }

          :global(.custom-checkbox) {
            margin: 0.5rem 0;
            label {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}
.quarter_info {
  margin: 0 0 25px;
  text-align: center;
  padding-right: 70px;
  h6 {
    text-transform: uppercase;
    font-weight: 600;
    color: $primaryText;
  }
  span {
    margin-top: 6px;
    display: inline-block;
    font-weight: 500;
    color: $primaryText;
    span {
      font-weight: 600;
      margin-right: 5px;
      margin-top: 0;
    }
  }
}
.date_range_wrapper {
  :global(.box-heading-wrapper) {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &.graph_wrapper {
    :global(.box-heading-wrapper) {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      padding-bottom: 25px;
    }
    :global(.date_picker_time_wrapper) {
      :global(.date_range_calendar_wrapper) {
        width: 750px;
      }
    }
  }
  :global(.date_picker_time_wrapper) {
    :global(.date_range_calendar_wrapper) {
      width: 570px;
      @include flexbox;
      @include align-items(center);
      margin-right: 0;
      :global(.react-datepicker-wrapper) {
        :global(.form-control) {
          padding-left: 12px;
          padding-right: 40px;
          background-position: 96.5% center;
        }
      }
    }
  }
  :global(.cycle-list-wrapper) {
    :global(.form-group) {
      width: 350px;
      margin-bottom: 0px;
      margin-right: 0px;
    }
    :global(.custom-default-dd-box) {
      margin-right: 0px;
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
        border-width: 1px;
      }
    }
  }
}

.box_btn_grp {
  @include border-radius(8px);
  padding: 25px;
  :global(.btn) {
    font-weight: 500;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

.graph_wrapper {
  .right_text_title {
    padding-left: 83px;
  }
}

.legent_wrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  .lenegd_list {
    list-style-type: none;
    padding: 0;
    margin: 35px 0 10px;
    @include flexbox;
    .legend_item {
      font-weight: 500;
      font-size: 13px;
      color: $primaryText;
      @include flexbox;
      @include align-items(center);
      margin-right: 22px;
      &:last-child {
        margin-right: 0;
      }
      .legend_color {
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .graph_wrapper {
    :global(.box-inner-pad) {
      :global(.d-flex) {
        flex-wrap: wrap;
      }
    }
  }
  .legent_wrapper {
    .lenegd_list {
      flex-wrap: wrap;
      @include justify-content(center);
      margin-top: 15px;
      margin-bottom: 0;
      .legend_item {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .date_range_wrapper {
    :global(.box-heading-wrapper) {
      flex-direction: column;
      @include align-items(flex-start);
      :global(.date_picker_time_wrapper) {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hr_dashboard_wrapper {
    :global(.container) {
      margin-top: 0px !important;
    }
  }
  .date_range_wrapper {
    :global(.box-heading-wrapper) {
      :global(.date_picker_time_wrapper) {
        :global(.date_range_calendar_wrapper) {
          width: 100%;
          flex-wrap: wrap;
          :global(.DateRangePicker) {
            width: 100%;
            margin-bottom: 15px;
            + :global(.btn) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
