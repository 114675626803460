@import "../../theme.scss";

.paginationBox {
  padding: 18px 25px 18px 25px; // change as per request
  :global(.btn-secondary) {
    border-radius: 5px;
  }
}

:global(.pagination-col) {
  margin-top: 20px;
  padding-left: 0;
  padding-right: 0;
  &:global(.inside-box-pagination) {
    margin-top: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    :global(.box) {
      @include border-radius(8px);
    }
  }
}

:global(.pagination-info) {
  display: flex;
  align-items: center;
  span {
    color: #928fa0;
    margin-right: 15px;
    font-weight: 500;
  }
}
:global(.pagination-no) {
  margin: 0 3px;
}
:global(.pagination-btn) {
  padding: 8px 14px;
  border: 1px solid transparent;
  margin-left: 5px;
  border-radius: 4px;
  line-height: 17px;
  font-weight: 500;
  cursor: pointer;
  &:global(.disabled) {
    cursor: not-allowed;
    color: inherit;
    opacity: 0.5;
    pointer-events: unset;
  }
  &:global(.current-btn) {
    color: #7033ff !important;
    border-color: #7033ff;
    font-weight: 700;
  }
  &:global(.prev-btn) {
    display: inline-block;
    vertical-align: middle;
    height: 6px;
    width: 10px;
    background: url(../../assets/images/ic_down_arrow_black.svg) no-repeat center;
    font-size: 0;
    background-size: 12px;
    transform: rotate(90deg);
  }
  &:global(.next-btn) {
    display: inline-block;
    vertical-align: middle;
    height: 6px;
    width: 10px;
    background: url(../../assets/images/ic_down_arrow_black.svg) no-repeat center;
    font-size: 0;
    background-size: 12px;
    transform: rotate(-90deg);
  }
}

/*start responsive*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .paginationBox {
    flex-direction: column;
    @include justify-content(center);
    :global(.ml-auto) {
      margin-left: initial !important;
    }
    :global(.mr-auto) {
      margin-right: initial !important;
    }
    :global(.pagination-full-number) {
      margin-top: 15px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .paginationBox {
    flex-direction: column;
  }
  :global(.pagination-info) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    :global(.dropdown-menu) {
      left: auto !important;
      right: 0;
    }
  }
  :global(.pagination-full-number) {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 15px;
  }
  :global(.pagination-btn) {
    padding: 5px 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  :global(.pagination-no) {
    margin: 0;
    display: inline-block;
    word-break: break-word;
    > a {
      margin: 2px 0;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 480px) {
  :global(.pagination-no) {
    :global(.pagination-btn) {
      display: none;
      &:first-child {
        margin-left: 5px;
      }
      &:global(.current-btn) {
        display: initial;
      }
    }
  }
}
