@import "theme";

.cycleListWrapper {
  display: block;
  .create_new_btn {
    padding: 10px 20px;
  }
  :global(.box) {
    @include border-radius(8px 8px 0 0);
  }
  :global(.outer-table-wrapper) {
    min-height: 300px;
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    &.hr_table {
      .tab_table_responsive {
        thead {
          th {
            width: 15%;
            &:nth-child(2) {
              width: 45%;
            }
            &:nth-child(4) {
              width: 10%;
            }
          }
        }
        tbody {
          td {
            .edit_cycle {
              margin-left: 10px;
            }
            .emp_cycle {
              margin-left: 50px;
            }
            .ended {
              background-color: #f5f5f5;
              color: #787878;
              border-color: rgba(0, 0, 0, 0.1);
            }
            .saved {
              color: #05ada6;
              border-color: rgba(36, 218, 210, 0.23);
              background-color: #f5ffff;
            }
          }
        }
      }
    }
    .tab_table_responsive {
      margin-bottom: 0;
      thead {
        th {
          padding: 10px;
          border: none;
          width: 15%;
          &:nth-child(2) {
            width: 25%;
          }
        }
      }
      tbody {
        td {
          padding: 20px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          a {
            font-weight: 600;
            font-size: 16px;
            color: $themeColor11;
            letter-spacing: 0.36px;
            &:hover {
              color: $primaryColor;
            }
          }
          span {
            font-size: 16px;
            font-weight: 500;
            color: $themeColor11;
          }
          .ended,
          .completed,
          .inprogress,
          .overdue,
          .saved,
          .submitted {
            font-size: 12px;
            font-weight: 600;
            padding: 4px 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: inline-block;
            min-width: 86px;
            text-align: center;
            background-color: #f5f5f5;
            color: #787878;
          }
          .completed,
          .ended {
            color: #fd8c61;
            background: #fcf6f4;
            border-color: #fce6dd;
          }
          .inprogress {
            color: #33c5ff;
            border-color: #cff1ff;
            background-color: #f0fafd;
          }
          .submitted {
            background-color: #f9f7ff;
            border-color: rgba(149, 106, 251, 0.23);
            color: #814cff;
          }
          .saved {
            color: #05ada6;
            border-color: rgba(36, 218, 210, 0.23);
            background-color: #f5ffff;
          }
          // .ended{
          //     color: #FD8C61;
          //     background: #FCF6F4;
          //     border-color: #FCE6DD;
          // }
          .overdue {
            background: #f5f5f5;
            border-color: #d8d8d8;
            color: #787878;
          }
          .edit_cycle {
            cursor: pointer;
            display: inline-block;
          }
          .emp_cycle {
            cursor: pointer;
            display: inline-block;
            &:hover {
              svg {
                > g {
                  > g {
                    > g {
                      path {
                        &:first-child {
                          fill: $primaryColor;
                        }
                        &:nth-child(2) {
                          stroke: $primaryColor;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
  .switches {
    display: inline;
    :global(.custom-switch) {
      padding-left: 0;
      :global(.custom-control-input) {
        &:focus {
          ~ :global(.custom-control-label) {
            &:before {
              border-color: #e9e9eb;
              box-shadow: none;
              outline: none;
            }
          }
        }
        &:checked {
          ~ :global(.custom-control-label) {
            &:before {
              background-color: #00c4bc;
              border-color: #00c4bc;
            }
            &:after {
              right: 14px;
              box-shadow: 0 2px 2px 0 rgba(45, 45, 45, 0.2);
              border: none;
            }
          }
        }
      }
      :global(.custom-control-label) {
        font-weight: 600;
        color: #322e46;
        letter-spacing: 0.47px;
        line-height: 24px;
        padding-right: 55px;
        &:before {
          left: auto;
          right: 0;
          width: 40px;
          height: 24px;
          border-radius: 25px;
          background: #ffffff;
          border: 1px solid #e9e9eb;
          top: 0;
        }
        &:after {
          right: 17px;
          left: auto;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          top: 2px;
          background: #ffffff;
          border: 1px solid rgba(45, 45, 45, 0.2);
        }
      }
    }
  }

  :global(.pagination-col) {
    margin-top: 30px;
    :global(.box) {
      @include border-radius(8px);
    }
  }
  .filter_info_tooltip {
    text-align: left;
    margin-left: 5px;
    display: inline-block;
    top: -2px;

    :global(.box-info) {
      margin: 0;
      :global(svg) {
        * {
          fill: $themeColor1;
        }
      }
    }

    :global(.info-attach-file) {
      min-width: 250px;
      left: auto;
      right: -160px;
      box-shadow: none;
      padding: 10px;

      &::before {
        right: 165px;
        left: auto;
        box-shadow: none;
      }

      :global(.attachment-title) {
        color: #b4b0c8;
      }

      :global(p) {
        margin: 0;
        font-size: 16px;
        color: $themeColor11;
        font-weight: 500;
      }
    }
  }
}

:global(.employee-manager-modal) {
  h4 {
    color: #322e46;
    letter-spacing: 0.5px;
  }
  p {
    font-family: Gilroy-Medium;
    font-size: 14px;
    color: #a3a0b2;
    letter-spacing: 0.39px;
    width: 100%;
    padding: 15px 0 20px 0 !important;
  }
  :global(.custom-default-dd-box) {
    width: 230px;
    max-width: 100%;
    :global(.select__control) {
      min-height: 50px;
      border-color: rgba(0, 0, 0, 0.1);
    }
    :global(.select__indicator-separator) {
      display: none;
    }
    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 992px) {
  .cycleListWrapper {
    .custom_table {
      :global(.table-responsive) {
        overflow-x: auto !important;
      }
      .tab_table_responsive {
        width: 890px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .cycleListWrapper {
    :global(.m-text-left) {
      text-align: left !important;
      margin: 0 0 20px;
    }
  }
}
.edit_cycle_disable{
  cursor: not-allowed! important;
}
