@import "theme";

.details_view_popup_wrapper {
  .modal_header {
    padding: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .heading_wrapper {
      margin-left: 10px;

      h2 {
        font-size: 18px;
        font-weight: 600;
      }

      h6 {
        padding-top: 5px;
        font-size: 15px;
      }
    }
  }

  :global(.modal-body) {
    padding: 0;

    .data_container {
      margin-top: 12px;
      width: 100%;
      padding: 34px 24px;

      :global(.tab-content) {
        box-shadow: none;
      }

      nav {
        justify-content: flex-start !important;
      }

      a {
        text-align: center;
        min-width: 180px;
        flex: none !important;
        height: 62px;
      }

      :global(.nav-link.active) {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      table {
        width: 100%;
        margin-bottom: 0;

        thead {
          tr {
            .heading {
              font-weight: 600 !important;
            }
          }
        }

        tbody {
          tr {
            &:hover {
              background-color: rgba(112, 51, 255, 0.1);
            }

            &.active {
              background-color: rgba(112, 51, 255, 0.1);
            }

            td {
              padding: 15px 20px 8px 24px;

              .question_text {
                font-size: 18px;
                font-weight: 600;
              }

              .question_result {
                font-size: 16px;
                margin-left: -22px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .btn_close {
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 21px;
    font-size: 14px;
    background-color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }

  .modal_footer {
    text-align: left;
    justify-content: flex-start;
    border-top: none;
    padding: 0 34px 34px 34px;
  }
}

.greyText {
  color: #928fa0;
}

.your_feedback,
.manager_feedback {
  border: 1px solid rgba(0, 0, 0, 0.1);

  table {
    th {
      border-top: none;
      border-bottom: none;
      padding-left: 24px;
    }
    td {
      li::marker {
        color: #B5AFCA;
      }
    }
  }
}

.your_feedback {
  border-radius: 0 8px 0 0;
  border-bottom: none;
}

.manager_feedback {
  border-top: none;
  border-radius: 0 0 8px 8px;

  table {
    th {
      padding-top: 50px;
    }
  }
}

.slick_slider {
  :global(.slick-track) {
    margin-left: 0;
  }

  :global(.slick-slide) {
    padding: 0 6px;
    border-right: none;
    position: relative;
    width: auto !important;

    &:first-child {
      padding: 0 6px 0 0;
    }

    &:last-child:not(:first-child) {
      padding: 0 0 0 6px;
    }

    &:after {
      content: "";
      position: absolute;
      height: 25px;
      width: 1px;
      background: rgba(184, 180, 204, 0.5);
      right: 15px;
      top: 2px;
    }
  }
}

.slick-slider {
  width: calc(100% - 80px);
  margin: 0 40px 10px;
}

:global(.slick-prev),
:global(.slick-next) {
  height: 22px;
}
:global(.slick-slide:after){
  display: none;
}
:global(.slick-prev.slick-disabled:before) {
  content: "";
  background-image: url(../../../../assets/images/ic_leftArrow_disabled.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-prev:before) {
  content: "";
  background-image: url(../../../../assets/images/ic_leftArrow.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-next.slick-disabled:before) {
  content: "";
  background-image: url(../../../../assets/images/ic_rightArrow_disabled.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-next:before) {
  content: "";
  background-image: url(../../../../assets/images/ic_rightArrow.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  :global(.custom-tabs .nav-tabs) {
    overflow-x: unset;
    overflow-y: unset;
  }
}
