.card_info_wrapper {
  padding: 25px 36px !important;
}

.resource_item_wrapper {
  margin: 30px;
  .text_content_wrapper {
    flex-direction: column;
  }
  .text_content {
    font-size: 14px;
  }
}

.objective_items_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: space-between;
}

.objective_wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 0;
  width: 31.5%;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);


  div:first-child {
    flex-grow: 1;
    h3 {
      color: #37324B;
    }
  }

  img {
    width: 100%;
  }
}

.pdf_text_wrapper {
  font-weight: 600;
  border: 1px solid #7033ff;
  padding: 8px 15px;
  border-radius: 25px;
  cursor: pointer;
  .icon {
    margin-right: 10px;
  }
}

.thumbnail_wrapper {
  padding: 20px !important;
  img {
    border-radius: 8px;
  }
}

.image_mask {
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    margin: 20px;
    overflow: hidden;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.40);
    img {
      width: auto;
    }
}

@media only screen and (max-width: 1024px) {
  .objective_wrapper {
    width: 48%;
  }
}

@media only screen and (max-width: 767px) {
  .objective_wrapper {
    width: 100%;
  }
}