@import "theme";
.navigation_wrapper {
  ul.nav_links {
    list-style: none;
    @include flexbox;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    li {
      margin-right: 50px;
      white-space: nowrap;
      a {
        @include flexbox;
        padding: 15px 0;
        color: $textShade2;
        font-weight: 500;
        font-size: 16px;

        &:global(.active) {
          // background: $themeColor15;
          // @include box-shadow(0, 0, 1px, rgba(255, 255, 255, 0.72), true);
          font-weight: 600;
          color: $whiteColor;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            height: 15px;
            width: 15px;
            background: -moz-linear-gradient(
              45deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 51%,
              rgba(255, 255, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
              45deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 51%,
              rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
              45deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 51%,
              rgba(255, 255, 255, 0) 100%
            );
            left: 0;
            right: 0;
            margin: auto;
            bottom: -8px;
            transform: rotate(135deg);
            -ms-transform: rotate(135deg);
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .dropdown {
        float: left;
        overflow: hidden;
        &:hover {
          transition: ease-in;
          a.dropbtn {
            color: $whiteColor;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              height: 15px;
              width: 15px;
              background: -moz-linear-gradient(
                45deg,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
              );
              background: -webkit-linear-gradient(
                45deg,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
              );
              background: linear-gradient(
                45deg,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 51%,
                rgba(255, 255, 255, 0) 100%
              );
              left: 0;
              right: 0;
              margin: auto;
              bottom: -8px;
              transform: rotate(135deg);
              -ms-transform: rotate(135deg);
            }
          }
          .dropdown_content {
            display: block;
            opacity: 1;
            visibility: visible;
            z-index: 9999;
          }
        }

        a.dropbtn {
          &.active {
            position: relative;
            font-weight: 600;
            color: $whiteColor;
          }
        }
        .dropdown_content {
          opacity: 0;
          display: none;
          visibility: hidden;
          position: absolute;
          transition: all 0.3s ease-in;
          background-color: #fff;
          width: 100%;
          z-index: 9999;
          left: 0;
          @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
          z-index: 1;
          padding: 10px 0 15px;
          :global(.row) {
            display: block;
            :global(.col) {
              width: 20%;
              float: left;
              .sub_menu_label {
                color: $textShade1;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.53px;
                line-height: 28px;
              }
              a {
                color: #3e3266;
                padding: 4px 0;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.47px;
                line-height: 16px;
                &:hover {
                  color: $primaryColor;
                }
              }
            }
          }
        }
      }

      /* Create three equal columns that floats next to each other */
    }
  }

  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
    border-radius: 2.5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}
/*start responsive*/
@media (max-width: 1024px) {
  :global(.navbar-dark) {
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 21; // 21 instead of 2
    :global(.navbar-brand) {
      margin: 0 auto;
      position: absolute;
      width: 174px;
      left: 0;
      right: 0;
      :global(img) {
        max-width: 100%;
      }
    }
    :global(.navbar-toggler) {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  :global(.navbar-dark) {
    height: auto;
    // position: relative;
    padding: 15px;
    :global(.navbar-brand) {
      width: 117px;
      height: 32px;
    }
  }
}
