@import "theme";

.reviewer_profile_box {
  padding-bottom: 0;
  :global(.box-heading-wrapper) {
    min-height: 50px;
    border-bottom: 0;
  }
}

.cerfication_info {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  text-align: center;
  padding: 21px 0;

  .cerfication_img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .cerfication_text {
    h3 {
      font-weight: 500;
      font-size: 22px;
      color: #322e46;
      letter-spacing: 0;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0.39px;
      margin-top: 5px;
      display: block;
    }
  }
}
