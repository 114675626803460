@import "theme";
.teams_assessment_chart {
  :global(.recharts-cartesian-axis-line) {
    stroke: rgba(0, 0, 0, 0.1) !important;
  }
  :global(.recharts-cartesian-axis-tick) {
    :global(.customized-axis-tick-text) {
      color: #000;
      opacity: 0.7;
      font-weight: 500;
      font-size: 12px;
    }
    :global(.customized-axis-tick-text-span) {
      color: #000;
      opacity: 0.7;
      font-weight: 500;
      font-size: 10px;
    }
    :global(.recharts-cartesian-axis-tick-value) {
      text-transform: none;
    }
  }
  :global(.recharts-text.recharts-label) {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
  }
  .legend_wrapper {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    .legend_list {
      list-style-type: none;
      padding: 0;
      margin: 10px 0 10px 30px;
      @include flexbox;
      flex-wrap: wrap;
      .legend_item {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 5px;
        color: $primaryText;
        @include flexbox;
        @include align-items(center);
        margin-right: 22px;
        &:last-child {
          margin-right: 0;
        }
        .legend_color {
          height: 13px;
          width: 13px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 7px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
  :global(.recharts-tooltip-custom-wrapper) {
    :global(.recharts-tooltip-value) {
      color: #928fa0;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.39px;
      line-height: 17px;
    }
  }
}
@media screen and (max-width: 376px) {
  .teams_weekly_chart {
    :global(.recharts-layer) {
      :global(.recharts-cartesian-axis-ticks) {
        :global(.customized-axis-tick-text) {
          font-size: 10px;
        }
      }
      :global(.recharts-text.recharts-label) {
        font-size: 9px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .teams_assessment_chart {
    border-bottom: 0;
  }
}
