@import "theme";
.campaign_question_answer_table {
  padding: 0 10px;
  .thead {
    padding: 0 25px 15px;
    list-style: none;
    margin-left: -25px;
    margin-bottom: 0;
    .question_name {
      font-size: 14px;
      font-weight: 600;
      color: #37324b;
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 600;
      width: 75%;
      display: inline-block;
      vertical-align: top;
      .question_name_heading {
      }
      .question_name_description {
        color: #928fa0;
        font-weight: 500;
      }
    }
    .question_answer {
      width: 25%;
      padding-left: 19px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      color: #37324b;
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 600;
    }
  }
  .tbody {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    .td_wrapper {
      padding: 15px 0;
      font-weight: 500;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .td_question_name {
        width: 75%;
        display: inline-block;
        position: relative;
        padding: 0 25px 0 15px;
        &::after {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #b4b0c8;
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
        }
      }
      .td_question_answer {
        width: 25%;
        display: inline-flex;
        vertical-align: top;
        .arrow_svg {
          margin-left: 10px;
        }
        &.answerTooltip {
          position: relative;

          .answerTooltipInfo {
            z-index: 1;
            position: absolute;
            left: -20px;
            width: 240px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 5px;
            top: 40px;
            opacity: 0;
            visibility: hidden;
            @include transition(0.5s all);
            @include border-radius(4px);
          }

          span {
            cursor: pointer;

            &:hover {
              + .answerTooltipInfo {
                opacity: 1;
                visibility: visible;
                top: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .campaign_question_answer_table {
    .td_question_answer {
      &.answerTooltip {
        .answerTooltipInfo {
          left: -74px !important;
          width: 150px !important;
        }
      }
    }
  }
}
