@import "../../theme.scss";

.checkin_activites {
  padding: 20px;
  :global(.card_details) {
    .to_do_row {
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 20px 0 0;
    }
    .subtitle {
      font-size: 18px;
      font-weight: 500;
    }
    .under_subtitle {
      font-size: 14px;
      font-weight: 400;
    }
    .details {
      margin-top: 10px;
      .meter_wrapper {
        min-height: 225px;
        .ceckin_meter {
          width: 174px;
          height: 174px;
          margin: 0 auto;
          position: relative;
        }
        .meter_inertext {
          font-size: 12px;
          color: #322e46;
          font-weight: 600;
          position: absolute;
          top: 100px;
          text-align: center;
          line-height: 12px;
          width: 77%;
          left: 20px;
        }
        .submition_time {
          color: #928fa0;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          & span {
            color: #00c4bc;
          }
        }
      }
    }
  }
}
