@import "./theme.scss";

body {
  margin: 0;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bodyColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.contentArea.hover {
  opacity: 0.5;
}
