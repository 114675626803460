@import "theme";

.performance_dashboard_wrapper {
  .review_graph_wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    .switch_wrapper {
      padding: 25px 0 0 60px;
    }
    :global(.row) {
      padding: 10px 24px 30px;
    }
    :global(.customized-axis-tick-text) {
      opacity: 1;
      font-weight: 600;
    }
    :global(.recharts-wrapper) {
      :global(svg) {
        cursor: pointer !important;
        pointer-events: visible !important ;
      }
    }
    :global(.select_dropdown_text) {
      width: 140px !important;
    }
  }
  .dynamic_table_wrapper {
    :global(.pagination-col) {
      margin-top: 0;
    }
    :global(.tab_filter) {
      :global(.row) {
        display: flex;
        justify-content: space-between;
      }
    }
    .dropdown_wrapper {
      display: flex;
      justify-content: flex-end;
      :global(.select_dropdown) {
        :global(.select__control) {
          align-items: flex-start;
          flex-wrap: wrap;
          align-content: flex-start;
        }
        :global(.select__menu) {
          top: 17px;
        }
      }
    }
    .dropdown_wrapper1 {
      display: flex;
      justify-content: flex-end;
      :global(.select_dropdown_text) {
        width: 130px;
      }
      :global(.select_dropdown) {
        :global(.select__control) {
          align-items: flex-start;
          flex-wrap: wrap;
          align-content: flex-start;
        }
        :global(.select__menu) {
          top: 17px;
        }
      }
    }
    .table_heading {
      padding: 25px 20px 0;
    }
    .data_container {
      width: 100%;
    }
  }
  .tooltip_wrapper {
    padding: 10px;
    .name {
      font-size: 16px;
      font-weight: 500;
    }
    .sub_text {
      color: #928fa0;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.44px;
      line-height: 19px;
      span {
        color: #00c4bc;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .performance_dashboard_wrapper {
    .review_graph_wrapper {
      :global(.select_dropdown_text) {
        width: 125px !important;
        float: left !important;
        :global(.select__single-value) {
          right: unset;
          margin-left: -5px;
        }
      }
    }
    .dynamic_table_wrapper {
      .data_container {
        overflow-x: auto;
        :global(.ml-5) {
          margin-left: 0;
        }
        :global(table) {
          width: 800px;
        }
      }
      .dropdown_wrapper1 {
        justify-content: flex-start;
        :global(.select_dropdown) {
          :global(.select__control) {
            align-content: center;
          }
        }
      }
    }
  }
}
