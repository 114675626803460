@import "theme";

.not_applicable_comment_wrapper {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
  margin: 0px 0px 20px 0px;
  .not_applicable_top {
    display: inline-flex;
  }
  .notify_icon {
    position: relative;
  }
  .comment_wrapper {
    margin-top: 0;
    margin: 20px 0 20px 0;
    width: 96%;
    :global(.view_comment_box_row) {
      :global(.fg-comment-wrapper) {
        :global(.form_group_comment) {
          margin-bottom: 0;
        }
        :global(.form-group) {
          :global(textarea.form-control.manager_comments) {
            min-height: 70px !important;
          }
        }
        :global(.textarea-wrapper) {
          width: 98%;
        }
      }
    }
  }
  .btn_group {
    display: inline-block;
    width: 100%;
    padding-left: 25px;
  }
  span {
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }
  .note_txt {
    font-weight: 500;
    color: #928fa0;
    letter-spacing: 0.32px;
    margin: 0;
  }
  p {
    width: 96%;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
    margin-bottom: 5px;
  }
  button {
    min-width: 127px;
    height: 40px;
    margin-right: 20px;
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 127px;
    height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .not_applicable_comment_wrapper {
    .comment_wrapper {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
