@import '../../../../theme';

:global(.custom_modal_popup){
  .skill_category_delete_modal{
    padding:30px;
    :global(.custom_modal_button){
      padding-left:0;
      padding-bottom: 0;
      border-top: 0;
      :global(.btn){
        font-weight: 600;
      }
    }
  }
}
.mHeader{
  display: block;
  padding: 0;
  border-bottom: 0;
  :global(.modal-title){
    font-weight: 500;
    font-size: 18px;
    color: #37324B;
    letter-spacing: 0.26px;
  }
}
.mBody{
  position: relative;
  p{
    font-weight: 500;
    font-size: 14px;
    color: #A3A0B2;
    letter-spacing: 0.39px;
    padding-top: 10px !important;
  }
}



