@import "theme.scss";
.quiz_result_wrapper {
  .quiz_not_attempted {
    padding-bottom: 110px;
  }
}
.startButton {
  margin-top: 20px;
  width: 217px;
  height: 60px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33px;
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:focus {
    outline: none;
  }
}
.home_tabs {
  margin-bottom: 30px;
  :global(.nav-tabs) {
    width: 690px;
    height: 60px;
    :global(.nav-link) {
      padding: 16px 12px;
      text-align: center;
    }
  }
}
.firstAttemptTab {
  margin-bottom: 30px;
  :global(.nav-tabs) {
    width: 190px;
    height: 60px;
    :global(.nav-link) {
      padding: 16px 12px;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 767px) {
  .startButton {
    margin-left: auto;
    margin-top: auto;
    width: 150 px;
    margin-bottom: 10px;
  }
  .home_tabs {
    margin-bottom: 30px;
    :global(.nav-tabs) {
      width: 450px;
      height: 50px;
      :global(.nav-link) {
        padding: 14px 12px;
        font-size: 10px;
        min-width: 20px;
        font-weight: 500;
        text-align: center;
        width: 20px;
      }
    }
  }
}
