@import "theme";
.currentQuestionsHeading {
  padding-left: 25px;
  color: #928fa0;
  font-weight: 600;
  font-size: 16px;
  .questionNumber {
    color: #3e3266;
  }
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 8px;
}

progress::-webkit-progress-value {
  border: 8px;
}

progress::-moz-progress-bar { 
  background-color: #7033ff !important;
}

progress::-webkit-progress-bar {
  background-color: white;
  width: 100%;
}
progress {
  background-color: white;
}
progress {
  border-radius: 8px;
}
progress::-webkit-progress-bar {
  border-radius: 8px;
}
progress::-webkit-progress-value {
  border-radius: 8px;
}
progress::-webkit-progress-value {
  background-color: #7033ff !important;
}
.QuestionTemplate {
  :global(.box) {
    margin-bottom: 0px;
    box-shadow: none;
    :global(.box-heading-wrapper) {
      border-bottom: none;
    }
    :global(.box-inner-pad) {
      border-bottom: none;
    }
    :global(.form-group label) {
      margin-bottom: 16px;
    }
  }
}
.nextButton {
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  border-radius: 33px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 240px;
  height: 66px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33.25px;
  outline: none;
  &:focus {
    outline: none;
  }
}
.firstPageButton {
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  border-radius: 33px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 240px;
  height: 66px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33.25px;
  outline: none;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
}
.backButton {
  margin-left: 300px;
  margin-right: 39px;
  background-color: white;
  color: #7033ff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 240px;
  height: 66px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33.25px;
  &:focus {
    outline: none;
  }
}
.timeTracking {
  text-align: right;
  font-size: 14px;
  float: right;
  padding-right: 25px;
  font-weight: 500;
  color: #928fa0;
  .timeNumber {
    color: #3e3266;
  }
}
.timeTrackingIcon {
  gap: 7px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 11px;
  display: flex;
}
.progressBar {
  width: 100%;
  height: 18px;
  margin-bottom: 30px;
  border: null;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .backButton {
    margin-left: 180px;
    margin-right: 39px;
    background-color: white;
    color: #7033ff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 200px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
  .firstPageButton {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
    border-radius: 33px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 200px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
  .nextButton {
    background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
    border-radius: 33px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 200px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
}
@media only screen and (max-width: 768px) {
  .progressBar {
    width: 100%;
    height: 12px;
    margin-bottom: 30px;
    border: null;
  }
  .backButton {
    margin-left: 50px;
    margin-right: 20px;
    background-color: white;
    color: #7033ff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 180px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
  .firstPageButton {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
    border-radius: 33px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 180px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
  .nextButton {
    background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
    border-radius: 33px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 180px;
    height: 66px;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 33.25px;
  }
}
