.global_comment_main_wrapper{
  // padding-bottom: 0;
  :global(.view_comment_box_wrapper){
    margin: 22px
  }
  .global_comment_wrapper{
    padding: 0px !important;
    .glabal_comment_box{
      border: 0;
      background: #FFF;
      margin: 0;
      :global(.view_comment_box_row){
        padding: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      :global(.no-record-found){
        padding: 25px !important;
      }
      :global(.viewall){
        margin: 25px;
        display: inline-block;
        font-size: 14px;
        cursor: pointer;
        color: #7033FF;
        font-weight: 500;
      }
    }
  }
}