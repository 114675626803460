.auto-c-posi-rel {
  position: relative;

  .auto-c-input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: url(../../assets/images/ic-down-arrow-sm.svg) no-repeat 96.5% center;
    background-size: 12px;
  }

  .suggestions {
    border: 1px solid rgba(0, 0, 0, 0.1);
    list-style: none;
    max-height: 210px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 55px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
    background: #fff;
    z-index: 1;

    li {
      padding: 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.suggestion-active,
      &:hover {
        background-color: #deebff;
        color: #322e46;
        cursor: pointer;
        font-weight: 500;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
