@import "../../../../theme.scss";
.table_layout {
  margin-bottom: 10px;

  .employee_list {
    list-style: none;
    padding: 0;
    margin: 0;

    .list_item {
      @include flexbox;
      @include align-items(center);
      padding-bottom: 15px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 15px;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .employee_list_header {
    list-style: none;
    padding: 0 0 10px;
    margin: 0 0 20px;
    position: relative;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    li {
      @include flexbox;
      @include align-items(center);
      font-weight: 600;
      font-size: 14px;
      color: $primaryText;
      letter-spacing: 0.47px;

      .name {
        width: 50%;
        padding-left: 64px;
      }
      .project_name {
        width: 25%;
      }
      .deallocation_date {
        width: 15%;
      }
      .status {
        width: 10%;
        text-align: center;
      }
    }
  }

  .tab_team_member_info {
    position: relative;
    width: 50%;
    padding-left: 20px;

    .team_member_info {
      position: relative;
      color: $primaryText;
      padding-left: 0;
      @include flexbox;
      @include align-items(center);

      .team_member_img {
        width: 52px;
        height: 52px;
        @include border-radius(50%);
        @include flex(0 0 52px);
        margin-right: 12px;
        overflow: hidden;
        background: #ccc;
        text-align: center;
        line-height: 52px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;

        img {
          width: 52px;
          height: 52px;
          object-fit: cover;
          position: relative;
          top: -2px;
        }
      }

      .team_member_name {
        font-size: 16px;
        font-weight: 600;
        color: $themeColor11;
        line-height: 22px;
        letter-spacing: 0.44px;

        a {
          color: $primaryText;

          &:hover {
            color: #a962ff;
          }
        }
      }

      .team_member_designation {
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
        color: $primaryText;
        display: block;
      }
    }
  }
  .tab_project_name {
    width: 25%;
    .project_name {
      color: #37324b;
      font-size: 16px;
      font-weight: 600;
      padding-right: 10px;
      overflow-wrap: break-anywhere;
    }
    .duration {
      color: #322e46;
      font-size: 14px;
    }
  }
  .tab_date {
    width: 15%;
    color: #37324b;
    font-size: 14px;
    font-weight: 500;
  }
  .st_wrapper {
    width: 10%;
    position: relative;
    text-align: center;
    span {
      border-radius: 5px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.33px;
      display: inline-block;
      padding: 5px 10px;
    }

    .status_pending {
      background: #fcf6f4;
      border: 1px solid #fce6dd;
      color: #fd8c61;
    }

    .status_submitted {
      background: #f9f7ff;
      border: 1px solid rgba(149, 106, 251, 0.23);
      color: #814cff;
    }
  }
}

@media only screen and (max-width: 991px){
  .table_layout{
    :global(.box-inner-pad){
      overflow-x: auto;
      .employee_list_wrapper{
        overflow-x: auto;
        overflow-y: hidden;
        width: 880px;
      }
    }
  }
}

