@import "../../../theme.scss";

// Completed  Objective Notification to Manager
.mng_obj_notification {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 25px 25px 25px;
  margin: 0px 0px 20px 0px;

  span {
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }

  p {
    width: 96%;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
  }

  button {
    min-width: 127px;
    height: 40px;
    margin-right: 20px;
  }

  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 127px;
    height: 40px;
  }
}

// Completed  Objective Notification to Manager
.teamsobjective__outer_wrapper {
  // overflow-x: auto;
  position: relative;
}

.teamsobjective_wrapper {
  width: 100%;
  display: block;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;

    li {
      font-size: 14px;
      font-weight: 600;
      color: $primaryText;
      padding: 5px 5px 10px 0px;
      display: inline-block;

      &.plus_space,
      .plus_space {
        width: 3%;
      }

      &.name,
      .name {
        width: 38%;
      }

      &.objective_status,
      .objective_status {
        width: 12%;
      }

      .objective_status {
        &.managerTooltip {
          position: relative;

          .managerTooltipInfo {
            z-index: 1;
            position: absolute;
            left: -20px;
            width: 160px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 5px;
            top: 40px;
            opacity: 0;
            visibility: hidden;
            @include transition(0.5s all);
            @include border-radius(4px);
          }

          > span {
            cursor: pointer;

            &:hover {
              + .managerTooltipInfo {
                opacity: 1;
                visibility: visible;
                top: 25px;
              }
            }
          }
        }
      }

      &.status,
      .status {
        width: 13%;
      }

      &.progress,
      .progress {
        width: 10%;
      }

      &.action,
      .action {
        width: 7%;
      }

      .plus_space,
      .name,
      .objective_status,
      .status,
      .progress,
      .action {
        display: inline-block;
        padding-right: 5px;
        vertical-align: middle;
      }
    }
  }

  .tHead {
    display: block;
    width: 100%;

    .progress {
      padding-left: 10px;
    }
  }

  .tBody {
    > li {
      width: 100%;
      padding: 15px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      position: static;

      .progress {
        .percentage {
          font-weight: 600;
          font-size: 16px;
          display: inline-block;
          width: 50px;
          text-align: left;
          padding-left: 10px;
        }
      }

      .plus_space {
        div {
          cursor: pointer;
        }
      }

      .user_img {
        width: 52px;
        height: 52px;
        @include border-radius(50%);
        @include flex(0 0 52px);
        margin-right: 12px;
        overflow: hidden;
        flex: 0 0 52px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 52px;
        background-color: rgb(184, 180, 204);
        text-align: center;
        align-items: center;
        justify-content: center;
        display: inline-flex;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .username {
        display: inline-flex;
        flex-direction: column;
        height: 52px;
        vertical-align: top;
        justify-content: center;
        align-self: center;
        width: calc(100% - 65px);

        h2 {
          font-size: 16px;
          color: $themeColor11;
          font-weight: 600;
        }

        h3 {
          font-size: 14px;
          color: $themeColor11;
          font-weight: 400;
          margin-top: 2px;
        }
      }

      .assigned {
        background: #f9f7ff;
        border: 1px solid rgba(149, 106, 251, 0.23);
        border-radius: 4px;
        font-size: 12px;
        color: #814cff;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //77px insteadof auto
        display: inline;
      }

      .notassigned {
        background: #fcf6f4;
        border: 1px solid #fce6dd;
        border-radius: 5px;
        font-size: 12px;
        color: #fd8c61;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .pending_approval {
        background: #ffe9e9;
        border: 1px solid #f07575;
        border-radius: 5px;
        font-size: 12px;
        color: #f07575;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .drafted {
        background: #f5f5f5;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        font-size: 12px;
        color: #787878;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .inprogress {
        background: #f5ffff;
        border: 1px solid #24dad2;
        border-radius: 5px;
        font-size: 12px;
        color: #00c4bc;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .not_assigned_yl {
        background: #fffcf0;
        border: 1px solid #ffcb00;
        border-radius: 5px;
        font-size: 12px;
        color: #ffcb00;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .completed {
        background: #f0fafd;
        border: 1px solid #cff1ff;
        border-radius: 5px;
        font-size: 12px;
        color: #33c5ff;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .strikeout {
        background: #ffffff;
        border: 1px solid #b5afca;
        border-radius: 5px;
        font-size: 12px;
        color: #b5afca;
        letter-spacing: 0.33px;
        text-align: center;
        padding: 5px 10px;
        width: auto; //148px insteadof auto
        display: inline;
      }

      .green_status {
        background: $themeGreen;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-flex;
        margin: 0px 2px;
      }

      .yellow_status {
        background: $mode3Dark;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-flex;
        margin: 0px 2px;
      }

      .red_status {
        background: $themeColor1;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-flex;
        margin: 0px 2px;
      }

      :global(.action-dropdown-wrapper) {
        z-index: auto;

        :global(.dropdown) {
          z-index: auto;
        }

        a,
        :global(.dropdown-item) {
          font-weight: 400;
          width: 100%;

          &:hover {
            color: $primaryColor;

            * {
              color: $primaryColor;
              fill: $primaryColor;
            }
          }
        }
      }

      &.keyresult_wrapper {
        width: 97%;
        background: #ffffff;
        border-radius: 2px;
        margin-left: 3%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 20px;

        &:hover {
          background: rgba(246, 245, 250, 0.5);
        }

        &.open_keyresult {
          .keyresults {
            .keyres {
              .arrow {
                background-image: url(../../../assets/images/ic-accordion-arrow-up.svg);
                transform: rotate(360deg);
              }
            }
          }
        }

        .keyresult_container {
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          position: static; //as per new requirement

          .keyresult_titlewrapper {
            display: inline-block;
            vertical-align: top;
            width: 55%;
            padding-right: 15px;

            h2 {
              color: $themeColor11;
              font-size: 16px;
              font-weight: 600;

              a {
                color: $themeColor11;

                &:hover {
                  color: $primaryLightColor;
                }
              }
            }

            h3 {
              font-size: 13px;
              font-weight: 500;
              color: $textShade1;
              margin-top: 5px;

              span {
                position: relative;
                display: inline-block;
                padding-left: 15px;
                margin: 0 0 0 12px;
                width: auto;

                &:before {
                  content: "";
                  background: #b5afca;
                  height: 5px;
                  width: 5px;
                  border-radius: 50%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
          }
        }

        .keyresults {
          display: inline-block;
          vertical-align: top;
          width: 35.5%;

          .keyres {
            font-weight: 500;
            font-size: 16px;
            color: #322e46;
            letter-spacing: 0.36px;
            cursor: pointer;

            .arrow {
              background: url(../../../assets/images/ic-accordion-arrow-down.svg) no-repeat center center;
              background-size: 100%;
              display: inline-block;
              width: 14px;
              height: 7px;
              @include transition(all, 0.4s, ease-out);
              position: relative;
              top: -2px;
              margin-left: 3px;
            }
          }
        }

        .obj_status {
          width: 0;
          display: inline-block;
          vertical-align: top;

          .status_tooltip {
            text-align: left;
            margin-left: 5px;
            display: inline-block;

            :global(.info-attach-file) {
              min-width: 280px;
              padding: 5px 10px;
              left: auto;
              right: -50px;

              &::before {
                right: 54px;
                left: auto;
              }
            }
          }
        }

        .status_in_days {
          display: inline-block;
          width: 47px;
          vertical-align: top;
          padding-bottom: 12px;
          height: 34px;
          left: 6px;
          position: relative;

          :global(.status) {
            //   position: absolute;
            //   left: -8px;
            //   top: 7px;
            position: relative;
            display: inline-block;
            margin-left: -5px;
            left: -5px;
            top: -1px;
          }

          :global(.progress_kr) {
            font-weight: 600;
            font-size: 16px;
            color: #37324b;
            letter-spacing: 0;
            padding-left: 0;
            position: absolute;
            left: auto;
            right: 0;
          }

          :global(.days) {
            font-weight: 500;
            font-size: 13px;
            color: #928fa0;
            letter-spacing: -0.3px;
            display: block;
            line-height: normal;
            width: 130px;
            position: absolute;
            right: 0;
            text-align: right;
            position: absolute;
            top: 20px;
          }
        }

        .action_dd_kr {
          display: inline-block;
          width: 119px;
          text-align: right;
        }

        ~ .keyresult_wrapper {
          margin-top: 10px;
        }

        + :global(.team-obj-listing) {
          margin-top: 10px;
        }
      }

      &.keyresult_dropdown {
        display: block;
        width: 97%;
        margin-left: 3%;
        border-width: 0 1px 1px;
        border-style: none solid solid;
        border-color: transparent rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
        border-radius: 0 0 2px 2px;
        padding: 0;

        :global(.common_list_items) {
          width: 100%;
          padding: 20px 30px;
          position: static;

          :global(li) {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 0 20px 20px;
            margin-bottom: 20px;

            // position: static;
            :global(.title_kr) {
              font-weight: 500;
              font-size: 14px;
              color: #37324b;
              letter-spacing: 0.32px;
              display: inline-block;
              width: 58.5%;
              padding-right: 15px;
              vertical-align: top;
              position: static;
            }

            :global(.weightage_kr) {
              font-weight: 500;
              font-size: 13px;
              color: #928fa0;
              letter-spacing: 0.3px;
              display: inline-block;
              width: 25.5%;
              padding-left: 2px;
              position: static;
            }

            :global(.progress_kr) {
              font-weight: 500;
              font-size: 14px;
              color: #37324b;
              letter-spacing: 0;
              padding-left: 7px;
              position: static;
            }

            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: 0;
            }
          }
        }

        + :global(.team-obj-listing) {
          margin-top: 10px;
        }
      }

      &:global(.team-obj-listing) {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.date_range_wrapper {
  :global(.box-heading-wrapper) {
    border-bottom: 0;
    padding-bottom: 0;
  }

  :global(.date_picker_time_wrapper) {
    :global(.date_range_calendar_wrapper) {
      width: 630px;
      @include flexbox;
      @include align-items(center);
      margin-right: 0;

      :global(.react-datepicker-wrapper) {
        :global(.form-control) {
          padding-left: 12px;
          padding-right: 40px;
          background-position: 96.5% center;
        }
      }
    }
  }
}
.box_btn_grp {
  @include border-radius(8px);
  padding: 25px;
  :global(.btn) {
    font-weight: 700;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 1024px) {
  .teamsobjective__outer_wrapper {
    overflow-x: auto;

    .teamsobjective_wrapper {
      overflow-x: auto;
      width: 1050px;

      .tBody {
        > li {
          &.keyresult_wrapper {
            .action_dd_kr {
              width: 86px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .date_range_wrapper {
    :global(.box-heading-wrapper) {
      flex-direction: column;
      @include align-items(flex-start);

      :global(.box-heading) {
        margin-bottom: 15px;
      }

      :global(.react-datepicker-popper) {
        z-index: 12;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .date_range_wrapper {
    :global(.box-heading-wrapper) {
      :global(.date_picker_time_wrapper) {
        :global(.date_range_calendar_wrapper) {
          flex-direction: column;
          width: 100%;
          @include align-items(flex-start);
          :global(.calendar_wrapper) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
