@import '../../theme';

.university_wrapper{
    display: inline-block;
    width: 100%;
}
@media only screen and (max-width:767px){
    .university_wrapper{
        :global(.box-inner-pad){
            &:global(.position-relative){
                &:global(.d-flex){
                    flex-wrap: wrap;
                }
            }
        }        
    }
}