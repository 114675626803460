@import "theme";

.myskill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 120px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.decline_req_wrapper {
  .endorsement_skill_table {
    .skill_name {
      width: 15%;
      display: inline-block;
      vertical-align: middle;
    }

    .endorser_name {
      display: inline-block;
      vertical-align: middle;
      width: 24%;
    }

    .cmp_lvl {
      vertical-align: middle;
      display: inline-block;
      width: 12%;
    }

    .req_date {
      vertical-align: middle;
      display: inline-block;
      width: 13%;
    }

    .dec_date {
      vertical-align: middle;
      display: inline-block;
      width: 16%;
    }

    .comments {
      vertical-align: middle;
      display: inline-block;
      width: 20%;
    }

    .thead {
      padding: 0 25px 15px;
      list-style: none;
      width: calc(100% + 50px);
      margin-left: -25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0;

      span {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        border-top: 0;
        border-bottom-width: 1px;
        font-weight: 600;
      }
    }

    .tbody {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        padding: 20px 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }

      .skill_name,
      .req_date,
      .dec_date {
        font-size: 15px;
        font-weight: 500;
        color: #37324b;
      }

      .endorser_name {
        color: #74798c;
        font-size: 15px;
        font-weight: 500;
        position: relative;

        .mmg_tooltip {
          position: absolute;
          right: 15px;
          top: 10px;

          :global(.box-info) {
            margin: 0;
          }

          :global(.info-attach-file) {
            min-width: 260px;
            left: auto;
            right: -14px;
            width: 260px;
            padding: 5px;
            white-space: normal;

            &::before {
              right: 20px;
              left: auto;
            }

            p {
              color: #37324b;
              text-align: center;
            }
          }

          &:global(.mmg_table_tooltip) {
            display: inline-block;
            margin-left: 5px;
            position: relative;
            right: 0;
            top: 0;

            :global(.info-attach-file) {
              right: -170px;

              &::before {
                right: 174px;
              }
            }
          }
        }
      }

      .comments {
        position: relative;
      }

      .comment_txt {
        // width: 55%;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // display: inline-block;
        // vertical-align: top;
        font-size: 16px;
        font-weight: 500;
        color: #37324b;
        position: relative;
      }

      .description_tooltip {
        position: absolute;
        padding: 7px 10px;
        background-color: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
        z-index: 1;
        right: 0;
        font-weight: 500;
        top: 50px;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        @include transition(0.4s, all);
        word-break: break-word;
        width: 250px;

        &:before {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          border-left: 1px solid #eaeaea;
          border-top: 1px solid #eaeaea;
          top: -5px;
          background: #fff;
          border-radius: 2px;
          right: 18px;
          transform: rotate(44deg);
        }
      }

      .more {
        font-weight: 500;
        font-size: 16px;
        color: #7033ff;
        letter-spacing: 0.36px;
        text-align: center;
        cursor: pointer;

        &:hover {
          + .description_tooltip {
            top: 30px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.yellow) {
    color: #ffcb00;
    border-color: rgba($color: #ffcb00, $alpha: 0.25);
    background-color: #fffcf0;
  }

  &:global(.red) {
    color: #fd6161;
    border-color: rgba($color: #fce6dd, $alpha: 1);
    background-color: #fcf6f4;
  }
}

@media only screen and (max-width: 1024px) {
  .decline_req_wrapper {
    .skill_table {
      overflow-x: auto;
      display: block;
      width: 100%;

      .endorsement_skill_table {
        min-height: 300px;
        width: 1000px;
      }
    }
  }
}
