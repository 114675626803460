.slider_date_wrapper {
  span {
    height: 14px;
    width: 160px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
  }
  :global(.slick-slider) {
    width: 160px;
    :global(.slick-list) {
      width: 160px;
      text-align: center;
    }
  }
  :global(.slick-prev.slick-disabled:before) {
    content: "";
    background-image: none;
    position: relative;
    display: inline-block;
  }
  :global(.slick-prev:before) {
    content: "";
    background-image: url(../../assets/images/ic-down-arrow-sm.svg);
    transform: rotate(90deg);
    position: relative;
    top: 6px;
    height: 100%;
    width: 100%;
    display: inline-block;
    background-repeat: no-repeat;
  }
  :global(.slick-next.slick-disabled:before) {
    content: "";
    display: inline-block;
    background-image: none;
  }
  :global(.slick-next:before) {
    content: "";
    background-image: url(../../assets/images/ic-down-arrow-sm.svg);
    transform: rotate(-90deg);
    position: relative;
    display: block;
    top: -5px;
    height: 100%;
    width: 100%;
    display: inline-block;
    background-repeat: no-repeat;
  }
}
