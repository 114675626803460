@import "theme";

.skill_master_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  :global(.page-title) {
    padding-bottom: 5px;
    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.skm_wrapper {
  border-radius: 8px 8px 0 0;
  :global(.check_in_search_bar) {
    min-width: 320px;
  }
  :global(.tab_check_in_status) {
    min-width: 260px;
  }
  :global(.box-heading-wrapper) {
    :global(.box-info) {
      position: static;
    }
    form {
      display: inline-block;
      width: 89%;
    }
    .filter_info_tooltip {
      text-align: left;
      margin-left: 10px;
      display: inline-block;
      :global(.info-attach-file) {
        min-width: 280px;
        left: auto;
        right: -50px;
        &::before {
          right: 54px;
          left: auto;
        }
        :global(.attachment-title) {
          color: #b4b0c8;
        }
        :global(ul) {
          margin: 10px 0;
          :global(li) {
            line-height: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.skm_outer_table_wrapper {
  &:global(.outer-table-wrapper) {
    min-height: auto;
  }
}
.skill_table {
  margin: 20px;
  width: calc(100% - 40px);
  display: inline-block;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 4px;
  overflow: hidden;
  table {
    margin-bottom: 0;
  }
  thead {
    padding: 0;
    list-style: none;
    background-color: #fcfaff;
    th {
      font-size: 14px;
      font-weight: 600;
      color: #37324b;
      border-top: 0;
      border-bottom-width: 1px;
      width: 12.5%;
      &:first-child {
        width: 60%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:last-child {
        width: 5%;
      }
    }
  }
  tbody {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    td {
      font-size: 15px;
      font-weight: 500;
      color: #37324b;
      &:first-child {
        font-weight: 600;
      }
      .add_action {
        font-weight: 500;
        font-size: 14px;
        color: #7033ff;
        > * {
          cursor: pointer;
          display: inline-block;
        }
        .edit_wrapper {
          @include flexbox;
          > * {
            cursor: pointer;
            margin-right: 15px;
            &:last-child {
              margin-right: 0;
            }
          }
          .icon_edit {
            height: 12px;
            width: 7px;
            border-right: 2px solid #b5afca;
            border-bottom: 2px solid #b5afca;
            transform: rotate(45deg);
            display: inline-block;
            position: relative;
            top: 1px;
          }
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
      :global(.form-group) {
        margin-bottom: 0;
        width: 250px;
        :global(.form-control) {
          height: 40px;
          font-size: 14px;
        }
      }
      :global(.form-control) {
        height: 40px;
        font-size: 15px;
        width: 250px;
        color: #322e46;
        font-weight: 500;
        &:focus {
          box-shadow: none;
          border-color: $primaryColor;
        }
      }
    }
  }
}
.switches {
  display: inline;
  :global(.custom-switch) {
    padding-left: 15px;
    :global(.custom-control-input) {
      &:focus {
        ~ :global(.custom-control-label) {
          &:before {
            border-color: #e9e9eb;
            box-shadow: none;
            outline: none;
          }
        }
      }
      &:checked {
        ~ :global(.custom-control-label) {
          &:before {
            background-color: #00c4bc;
            border-color: #00c4bc;
          }
          &:after {
            right: 14px;
            box-shadow: 0 2px 2px 0 rgba(45, 45, 45, 0.2);
            border: none;
          }
        }
      }
    }
    :global(.custom-control-label) {
      padding-right: 25px;
      font-size: 0;
      &:before {
        left: auto;
        right: 0;
        width: 40px;
        height: 24px;
        border-radius: 25px;
        background: #ffffff;
        border: 1px solid #e9e9eb;
        top: 0;
      }
      &:after {
        right: 17px;
        left: auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        top: 2px;
        background: #ffffff;
        border: 1px solid rgba(45, 45, 45, 0.2);
      }
    }
  }
}
.category_wrapper {
  position: relative;
  @include flexbox;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 25px 25px 5px;
  .category_item {
    @include flexbox;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &:last-child {
      border-bottom: 0;
    }
    .category_topbar {
      @include flexbox;
      @include justify-content(space-between);
      width: 100%;
      padding: 15px 20px 15px 5px;
      &:global(.active) {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        background-color: rgba(246, 245, 250, 0.5);
      }
      .cetegory_info {
        width: 90%;
        padding-right: 20px;
      }

      .expand_collapse_wrapper {
        position: relative;
        top: -1px;
        cursor: pointer;
      }
      .category_name {
        margin-left: 15px;
        font-weight: 600;
        font-size: 15px;
        color: #322e46;
        display: inline-block;
        width: 80%;
        vertical-align: top;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .skill_master_wrapper {
    width: 100%;
  }
  .skm_wrapper {
    :global(.check_in_search_bar) {
      margin-bottom: 0;
    }
  }
  .skill_table {
    thead {
      th {
        width: 20%;
        &:first-child {
          width: 40%;
        }
        &:nth-child(4),
        &:last-child {
          width: 10%;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .skill_master_wrapper {
    :global(.box) {
      &:global(.box-flex-center) {
        flex-wrap: wrap;
        :global(.pagination-info) {
          width: 100%;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
        }
        :global(.pagination-full-number) {
          margin-top: 15px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .skill_table {
    overflow-x: auto;
    :global(.table) {
      width: 770px;
    }
  }
  .skm_wrapper {
    :global(.box-heading-wrapper) {
      flex-wrap: wrap;
      justify-content: flex-start !important;
      :global(.box-heading) {
        margin-bottom: 15px;
      }
    }
    :global(.check_in_search_bar) {
      margin-right: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .res_title_btn {
    text-align: left !important;
    margin-bottom: 20px;
  }
  .skill_table {
    margin: 20px 0;
    width: 100%;
  }
  .skm_wrapper {
    :global(.box-heading-wrapper) {
      flex-direction: column;
      :global(.check_in_search_bar) {
        min-width: 100%;
        margin: 0;
      }
      :global(.tab_check_in_status) {
        width: 100%;
      }
      .filter_info_tooltip {
        :global(.info-attach-file) {
          left: auto;
          right: -5px;
          &::before {
            left: auto;
            right: 9px;
          }
        }
      }
    }
  }
}
