@import '../../../../theme.scss';

.mHeader{
  display: block;
  padding: 0;
  :global(.modal-title){
    font-weight: 600;
    font-size: 20px;
    color: #37324B;
    letter-spacing: 0.26px;
    :global(span){
      font-weight: 500;
      display: block;
      font-size: 16px;
      color: #928FA0;
      letter-spacing: 0.36px;
    }
  }
}
.mHeader{
  padding: 20px 25px;
}
.mBody{
  position: relative;
  padding:20px 25px;
  :global(.box-light-gradient){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.category_add_wrapper{
  position: relative;
  padding: 20px 25px 10px;
  .dd_title{
    font-weight: 600;
    font-size: 14px;
    color: #322E46;
    letter-spacing: 0.47px;
  }  
}
