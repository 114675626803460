.acknowledgement_wrapper {
  display: block;
  padding: 25px;
  .title {
    color: #322e46;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.36px;
    line-height: 19px;
  }
  .comment_box {
    border: 0;
    background: #fff;
    margin: 0;
    :global(.view_comment_box_row) {
      padding: 20px 0;
    }
  }
  .btn {
    min-width: 146px;
    margin-top: 20px;
  }
}
