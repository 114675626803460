@import "../../theme.scss";

// Process indicate

.objectiveuserstatus {
  .employeeObjectives_process_indicate {
    @include inline-flex;
    @include justify-content(center);
    @include align-items(center);
    margin-top: 3px;
    margin-right: 5px;
    position: relative;
    color: #000000 !important;
    float: left;
    .emp_objective_tooltip {
      top: -14px !important;
      left: 25px !important;
      min-width: 160px !important;
      &:before {
        border-right: 1px solid #eaeaea !important;
        border-top: 1px solid #eaeaea !important;
        left: -6px !important;
        top: -8px !important;
        transform: rotate(-135deg) !important;
      }
    }
    .employeeObjectives_step {
      width: 20px;
      height: 20px;
      @include border-radius(50%);
      border: 1px solid $textShade3;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      position: relative;
      + .employeeObjectives_step {
        margin-left: 8px;
      }

      .step1,
      .step2,
      .step3 {
        width: 5px;
        height: 5px;
        @include border-radius(50%);
        background: $textShade1;
        @include transition(all, 0.2s, ease);
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
      }

      &:hover .step,
      .active {
        width: 16px;
        height: 16px;
      }

      .step1 {
        background: #6dd400;
      }

      .step2 {
        background: #ffcc40;
      }

      .step3 {
        background: #f07575;
      }
    }
  }
}
