.allocationFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .label {
    margin: 0;
  }

  .options {
    margin: 0 0 0 10px;
    min-width: 125px;
  }
}

.checkinStatusWrapper {
  margin-top: 8px;
}

.searchWrapper {
  padding-right: 24px !important;
}