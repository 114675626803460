.pdfviewer_wrapper {
  overflow-y: hidden;
  .modal_header {
    padding: 12px 30px;
    background-color: #000000;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 1;

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}

.model_body_wrapper{
  padding: 0;
}


.pdf_title_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  img {
    padding: 9px;
  }
}

.pdf_content_wrapper {
  overflow: auto;
  canvas {
    width: auto;
    margin: 0 auto;
  }
}

.pdf_content_wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  canvas {
    width: auto;
    margin: 0 auto;
  }
  > div {
    background-color: #E6E6E6;
    > div {
      margin: 0 0 12px 0;
      &:not():first-child {
        margin: 12px 0 12px 0;
      }
      > div { 
        margin: 0 auto;
      }
    }
  }
}

.pdf_header_controls {
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      color: #FFFFFF;
      font-size: 38px;
    }
}

.pdf_footer_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video_viewer_wrapper {
  .modal_header {
    padding: 15px 30px 15px 30px;
    background-color: #000000;

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}

.video_popup_body {
  padding: 0px;
  margin-bottom: -5px;
}

.close {
  position: absolute;
  right: -25px;
  top: -17px;
  color: black;
  padding: 8px 10px;
  background: white;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
}

.modal_header_wrapper {
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
}

.iframe_wrapper {
  height: 90vh;
}

.pdfviewer_wrapper{
  :global(.modal-dialog) {
    max-width: 80%;
  }
}

.video_viewer_wrapper {
  :global(.modal-dialog) {
    max-width: 70%;
  }
}