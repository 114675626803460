@import '../../../theme';

.roll_based_skill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928FA0;
      letter-spacing: 0;
    }
  }
}

.define_add_skills_wrapper {
  padding-bottom: 10px;

  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322E46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.form-control) {
    min-height: 50px;
  }

  :global(.custom-default-dd-box) {
    :global(.select__control) {
      min-height: 50px;
      border-color: rgba(0, 0, 0, 0.1);
    }

    :global(.select__indicator-separator) {
      display: none;
    }

    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .skills_outer_wrapper {
    display: block;

    ~.skills_outer_wrapper {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
  }

  .skill_set_wrapper {
    margin: 30px 0 0;

    .skill_set_list {
      border-radius: 4px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 20px;
      position: relative;

      .andtxt {
        display: none;
      }

      ~.skill_set_list {
        margin-top: 30px;

        .andtxt {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          top: -45px;
          font-weight: 500;
          font-size: 16px;
          color: #928FA0;
          letter-spacing: 0.36px;
          text-transform: uppercase;
          > span {
            padding: 10px 20px;
            background-color: #EFEAFB;
            border-radius: 4px;
            color: #7033FF;
            width: 80px;
            margin-left: -18px;
          }
        }
      }
    }
  }

  .add_set_skill {
    font-weight: 600;
    font-size: 14px;
    color: #7033FF;
    letter-spacing: 0;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.delete_category {
  text-align: right;

  .dc {
    display: inline-block;
    cursor: pointer;

    img {
      @include transition(0.4s all);
      width: 10px;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }
}

.skill_topbar {
  padding: 15px 20px;

  .skill_topbar_list {
    @include flexbox;
    @include justify-content(space-between);
    flex-wrap: wrap;

    .skill_item {
      width: 47%;
      @include flexbox;
      margin-bottom: 15px;
      position: relative;

      .dd_wrapper {
        width: 44%;
        margin-right: 2.5%;
      }

      .delete {
        display: inline-block;
        cursor: pointer;
        margin-top: 40px;
        margin-left: 1%;

        * {
          @include transition(0.4s all);
          width: 10px;

          &:hover {
            transform: rotate(90deg);
          }
        }
      }

      .ortxt {
        font-weight: 500;
        font-size: 16px;
        color: #7033FF;
        letter-spacing: 0.36px;
        display: none;
        position: absolute;
        left: -67px;
        top: 27px;
        background: #EFEAFB;
        height: 52px;
        width: 52px;
        border-radius: 50%;
        text-align: center;
        line-height: 52px;
      }

      &:nth-child(2n) {
        .ortxt {
          display: inline-block;
        }
      }
    }
  }
}

.skill_bottombar {
  padding: 15px 20px;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.saveBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.save_box_left {
  :global(.btn) {
    min-width: 150px;
    font-size: 14px;

    :global(strong) {
      font-weight: 600;
    }
  }
}

.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #B4B0C8;

  span {
    color: #928FA0;
    font-weight: 500;
  }
}

.save_box_center {
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #B4B0C8;

  span {
    color: #928FA0;
    font-weight: 500;
  }
}

@media only screen and (min-width:1024px){
  .define_add_skills_wrapper{
    .skill_set_wrapper{
      margin-left: -25px;
      margin-bottom: 20px;
      width: calc(100% + 50px);
      padding: 0 25px !important;
      .skill_set_list{
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width:1024px){
  .roll_based_skill_wrapper{
    display: inline-block;
    width: 100%;
    margin-top: 30px;
  }
}
@media only screen and (min-width:993px) and (max-width:1024px){
  .define_add_skills_wrapper{
    .dd_title{
      font-size: 14px;
    }
  }
  .skill_topbar {
    .skill_topbar_list {
      .skill_item {
        .ortxt {
          left: -50px;
          top: 30px;
          line-height: 40px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px){
  .skill_topbar {
    .skill_topbar_list {
      .skill_item {
        width: 100%;
        .dd_wrapper{
          width: 46%;
        }
        ~ .skill_item{
          margin-top: 30px;
        }  
        .ortxt {
          left: 44%;
          top: -38px;
          line-height: 40px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          font-size: 14px;
        }
      }
    }
  }  
}

@media only screen and (max-width:767px){
  .roll_based_skill_wrapper{
    :global(.text-right){
      margin-top: 20px;
      text-align: left !important;
    }
  }
  .define_add_skills_wrapper{
    position: relative;
    :global(.box-inner-pad){
      :global(.row){
        > :global(div){
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .delete_category{
      position: absolute;
      right: 20px;
      top: 15px;
      width: auto;
      padding: 0;
    }
  }
  .saveBox {
    .save_box_right {
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 479px){
  .skill_topbar {
    .skill_topbar_list {
      .skill_item {
        flex-wrap: wrap;
        padding-right: 30px;
        position: relative;
        .dd_wrapper{
          width: 100%;
          margin: 0 0 15px;
        }
        ~ .skill_item{
          margin-top: 30px;
        }  
        .delete{
          position: absolute;
          right: 0px;
          top: 90px;
          margin: auto;
        }
        .ortxt {
          left: 38%;
          top: -40px;
        }
      }
    }
  }  
}