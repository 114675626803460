@import "theme";

:global(.custom-tooltip.custom-tooltip-top) {
  :global(.info-attach-file) {
    left: -170px;
    &::before {
      left: 172px;
    }
  }
  :global(.box-info) {
    &:hover {
      + :global(.info-attach-file) {
        top: -114px;
      }
    }
  }
}

.dot {
  width: 18px;
  height: 18px;
  border: 1px solid transparent;
  @include border-radius(50%);
  line-height: 18px;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include transition(all, 0.4s, ease-out);
  cursor: pointer;

  span {
    width: 8px;
    height: 8px;
    @include flex(8px 0 0);
    line-height: 8px;
    background: $themeColor10;
    list-style: none;
    @include border-radius(50%);
    @include transition(all, 0.4s, ease-out);
    position: relative;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      line-height: 10px;
      display: block;
      @include position(absolute, -1px, auto, auto, -1px);
      @include border-radius(50%);
      @include transition(all, 0.1s, ease-out);
      transform: scale(0);
      background: $themeColor10;
    }
  }

  &.tab_checkin_delay {
    span,
    span:after {
      background: $themeColor1;
    }
  }

  &:hover,
  &.active {
    border: 1px solid #dfdfdf;
    background: $whiteColor;
    @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.2));

    span {
      &:after {
        transform: scale(1);
      }
    }
  }
}
