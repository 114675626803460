@import "../../theme.scss";

.checkin_activites {
  :global(.card_details) {
    .checkin_streak_wrapper {
      position: relative;
      padding: 20px;
      z-index: 1;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      .check_in_steak_heading {
        color: $themeColor11;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.41px;
        line-height: 21px;
        margin-bottom: 20px;
      }
      :global(.check_in_streak_box) {
        padding: 0;
        :global(.check_in_streak_container) {
          margin: 0;
          align-items: center;
          :global(.checking_streak_caption) {
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
    }
    .to_do_row {
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 20px;
    }
    .subtitle {
      font-size: 18px;
      font-weight: 500;
    }
    .under_subtitle {
      font-size: 14px;
      font-weight: 400;
    }
    .details {
      margin-top: 10px;
      .meter_wrapper {
        min-height: 272px;
        .ceckin_meter {
          width: 174px;
          height: 174px;
          margin: 0 auto 0px;
          position: relative;
        }
        .meter_inertext {
          font-size: 12px;
          color: #322e46;
          font-weight: 600;
          position: absolute;
          top: 100px;
          text-align: center;
          line-height: 12px;
          width: 77%;
          left: 20px;
        }
        .submition_time {
          color: #928fa0;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          & span {
            color: #00c4bc;
          }
        }
      }
      .teams_weekly_checkin_wrapper {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        align-self: baseline;
        .team_checkin_submit_wrapper {
          padding: 10px;
          .checkin_slider_wrapper {
            padding: 0 20px;
          }
        }
      }
    }

    .checkin_submit_wrapper {
      border: 1px solid #ededed;
      border-radius: 4px;
    }
    .checkin_submit {
      // border: 1px solid #ededed;
      width: 100%;
      thead {
        tr {
          border-bottom: 1px solid #ededed;
          th {
            padding: 17px 20px;
            font-size: 16px;
            color: #37324b;
            font-weight: 600;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #ededed;
          &:hover {
            background: rgba(246, 245, 250, 0.5);
          }
          td {
            padding: 0px 0px 0px 20px;
            height: 47px;
            font-size: 14px;
            font-weight: 600;
            color: #928fa0;
            &.date_between {
              line-height: 47px;
            }
            .tooltip_wrapper {
              position: relative;

              .tooltip_info {
                z-index: 1999;
                position: absolute;
                left: -20px;
                color: #322e46;
                width: 240px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                background: #fff;
                padding: 10px;
                font-weight: 500;
                letter-spacing: 0.53px;
                font-size: 14px;
                top: 40px;
                opacity: 0;
                visibility: hidden;
                box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
                @include transition(0.5s all);
                @include border-radius(4px);
                span {
                  font-size: 15px;
                  font-weight: 600;
                }
                &::before {
                  left: 24px;
                  height: 7px;
                  width: 7px;
                  border-top: 1px solid #eaeaea;
                  border-right: 1px solid #eaeaea;
                  transform: rotate(-45deg);
                  top: -4px;
                  background-color: #fff;
                  margin: auto;
                  content: "";
                  position: absolute;
                  box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
                }
              }

              > span {
                cursor: pointer;

                &:hover {
                  + .tooltip_info {
                    opacity: 1;
                    visibility: visible;
                    top: 25px;
                  }
                }
              }
            }
            .team_checkin_status {
              font-size: 12px;
              font-weight: 600;
              padding: 4px 10px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              display: inline-block;
            }
            .ready {
              color: #33c5ff;
              background: #f0fafd;
              border-color: #cff1ff;
            }

            .pending {
              color: #fd8c61;
              background: #fcf6f4;
              border-color: #fce6dd;
            }

            .reviewed {
              color: #00c4bc;
              background: #f5ffff;
              border-color: rgba(36, 218, 210, 0.23);
            }
            .lapsed {
              color: $textShade2;
              background: white;
              border-color: $textShade2;
            }
            a {
              color: #928fa0;
              &:hover {
                color: #7033ff;
              }
            }
          }
        }
      }
      tfoot {
        tr {
          border-bottom: none;
          td {
            padding: 0px 0px 0px 20px;
            height: 57px;
            line-height: 57px;
            font-size: 14px;
            color: #37324b;
            font-weight: 600;
          }
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.commentStack {
  width: calc(100% - 40px);
  @include flexbox;
  position: relative;
  z-index: 1;
  padding: 20px 15px 10px 10px;
  background: $whiteColor;
  border-radius: 0 0 4px 4px;
  margin: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .user_img {
    width: 40px;
    height: 40px;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }

  .detailArea {
    width: 100%;
    @include flexbox;

    .headArea {
      width: 100%;
      @include flexbox;
      @include justify-content(space-between);
      margin: 0 0 10px 0;
    }
    .namespace {
      @include flexbox;
      @include align-items(baseline);

      .name {
        font-size: 16px;
        font-weight: 600;
        color: $primaryText;
        margin: 0 5px 0 0;
      }
      .time {
        font-weight: 600;
        font-size: 12px;
        color: $textShade1;
        position: relative;
        top: 2px;
      }
      p {
        margin-bottom: 0;
        font-weight: 400;
      }
    }
    .actinArea {
      @include flexbox;
      @include justify-content(flex-end);

      a {
        margin: 0 0 0 10px;
      }
    }
  }
}
:global(.made-my-d-h-info) {
  display: flex;
  flex-direction: column;
  :global(.mmd-info-title) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 22px;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    position: relative;
    :global(.select_dropdown_text) {
      width: 155px;
      :global(.select__control) {
        min-height: auto;
      }
    }
  }
}
:global(.mmd-info-profile) {
  display: flex;
  justify-content: space-between;
  padding: 22px;
  align-items: center;
  :global(.mmd-profile-detail) {
    display: flex;
    width: 50%;
    :global(.user_img) {
      height: 40px;
      width: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #d8d8d8;
      color: $whiteColor;
      font-weight: 600;
      :global(img) {
        max-width: 100%;
      }
    }
    :global(.user_info) {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      justify-content: center;
      :global(.user_name) {
        font-weight: 600;
        font-size: 16px;
        color: #37324b;
        letter-spacing: 0.36px;
        line-height: normal;
      }
      :global(.user_designation) {
        font-size: 14px;
        color: #322e46;
        letter-spacing: 0.39px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  :global(.send-received-detail) {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    :global(.sr-info) {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      color: #37324b;
      padding-right: 10px;
      :global(span) {
        font-weight: 500;
        font-size: 14px;
        color: #928fa0;
        letter-spacing: 0.39px;
      }
      &:nth-child(2) {
        margin-left: 95px;
      }
    }
  }
}
:global(.made-my-d-h-list) {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: hidden;
  .commentStack {
    &:first-child {
      margin-top: 0;
    }
  }
}
:global(.show-scroll) {
  overflow-y: auto;
}
:global(.recentlyReceived) {
  :global(.card_details) {
    padding-bottom: 10px;
  }
  .commentStack {
    padding: 20px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    ~ .commentStack {
      margin-top: 0;
    }
  }
  .user_img_wrapper {
    display: flex;
    margin-right: 10px;
    .user_img {
      border-radius: 50%;
      overflow: hidden;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-right: 0;
      background-color: #d8d8d8;
      color: $whiteColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      img {
        height: 100%;
        max-width: 100%;
      }
      &:nth-child(2) {
        box-shadow: -2px 0 0 0 $whiteColor;
        margin-left: -2px;
      }
    }
  }
  .detailArea {
    .headArea {
      margin: 0;
    }
    .namespace {
      flex-direction: column;
      > p {
        span {
          font-size: 16px;
          line-height: 19px;
          color: $primaryColor;
          font-weight: 500;
        }
      }
      .nameinfotext {
        display: flex;
        .name {
          font-size: 14px;
        }
      }
      .received_info {
        margin-bottom: 0;
        font-weight: 400;
        a {
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .checkin_activites {
    :global(.card_details) {
      .checkin_submit {
        thead {
          tr {
            td {
              padding: 0px 0px 0px 15px;
              font-size: 15px;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 0px 0px 0px 15px;
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .checkin_activites {
    .details {
      .meter_wrapper {
        min-height: 250px;
        .ceckin_meter {
          margin-top: 0;
        }
      }
      .teams_weekly_checkin_wrapper {
        border-left: none !important;
      }
      .checkin_submit {
        thead {
          tr {
            td {
              padding: 0px 0px 0px 10px;
              font-size: 14px;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 0px 0px 0px 10px;
              font-size: 12px;
              .tooltip_wrapper {
                .tooltip_info {
                  right: 0 !important;
                  left: unset;
                  &::before {
                    right: 24px !important;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  :global(.mmd-info-profile) {
    flex-direction: column;
    :global(.mmd-profile-detail) {
      width: 100%;
    }
    :global(.send-received-detail) {
      width: 100%;
      justify-content: flex-start;
      margin-top: 15px;
      :global(.sr-info) {
        &:nth-child(2) {
          margin-left: 40px;
        }
      }
    }
  }
  :global(.recentlyReceived) {
    .commentStack {
      padding: 15px 15px 30px;
    }
    .detailArea {
      .headArea {
        margin: 0;
      }
      .namespace {
        > p {
          margin-bottom: 0;
          margin-left: -88px;
          margin-top: 10px;
          width: calc(100% + 88px);
          font-size: 14px;
          display: inline-block;
          span {
            font-size: 14px;
          }
        }
        .nameinfotext {
          .name {
            margin-right: 0px;
            min-height: 38px;
          }
        }
      }
    }
  }
  .commentStack {
    .detailArea {
      .namespace {
        .time {
          position: absolute;
          left: 15px;
          bottom: 10px;
          top: auto;
        }
      }
    }
  }
}
