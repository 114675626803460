@import "theme";

.boxWrapper{
  :global(.box) {
    padding: 25px 0px 5px 0px !important;
  }
}

.create_objective_wrapper {
  margin-top: 25px;
  &:first-child {
    margin-top: 0;
  }
  .create_objective_data_wrapper {
    .errorBox{
      border-color: red !important;
    }
    .formGroup{
      margin-bottom: 0px !important;
      .errorBox{
        border-color: red !important;
      }
    }
    .create_objective_title{
      margin-bottom: 0px !important;
    }
    .weightageBox{
      border: 1px solid #ccc;
      background-color: #FFFFFF !important;
        text-align: left;
    }
    .textbox_wrapper {
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: auto; //auto instead of 48px
      // overflow: hidden;
      transition: height 0.5s;
      &:hover {
        // border: 1px solid $textShade3;
        border-color: #828282;
      }
      &.open {
        border: 1px solid $primaryColor;
        box-shadow: 0 2px 4px 0 #e8deff;
        height: auto;
        .open_objectives {
          display: block;
        }
      }
      input[type="text"] {
        background: $whiteColor;
        border: 0px solid rgba(0, 0, 0, 0.1);
        padding-right: 80px;
        &:hover,
        &:focus {
          background: $whiteColor;
          border-radius: 4px;
        }
      }
      .co_dustbin,
      .co_setting {
        position: absolute;
        top: 10px;
        visibility: hidden;
        opacity: 0;
        &:hover {
          svg {
            g {
              fill: $primaryColor;
            }
          }
        }
      }
      .co_dustbin {
        right: 10px;
      }
      .co_setting {
        right: 20px;
      }
      &:hover {
        .co_dustbin,
        .co_setting {
          visibility: visible;
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.self_assign_note {
  font-weight: 500;
  font-size: 18px;
  color: #928fa0;
  letter-spacing: 0.41px;
  position: relative;
  margin-bottom: 20px;
  span {
    color: #3e3266;
  }
}

.open_objectives {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  padding: 12px 10px;
  position: relative;
}
.objective_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:global(.se_bt_wrapper) {
  position: relative;
}
.base_target_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  span {
    color: #928fa0;
    font-size: 12px;
    font-weight: 500;
  }
}
.objective_bottom_bar {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(flex-end);
  margin: 30px 0 0 -10px;
  padding: 15px 15px 5px;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  width: calc(100% + 20px);
  :global(.radio_1on1_wrapper) {
    :global(.form-group) {
      :global(label) {
        font-size: 16px;
      }
    }
    .form_option_list {
      margin-top: 10px;
    }
  }
}
:global(.text_link) {
  font-size: 16px;
  color: $primaryColor;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  &:global(.disabled) {
    pointer-events: none;
    color: $textShade3;
  }
}
.objectives_criteria {
  display: flex;
  > .oc {
    width: 50px;
    height: 40px;
    display: flex;
    position: relative;
    text-align: center;
    &:first-child {
      label {
        border-radius: 4px 0 0 4px;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      label {
        svg {
          * {
            stroke: #322e46;
          }
        }
        &:hover {
          svg {
            * {
              stroke: $whiteColor;
            }
          }
        }
      }
      input {
        &:checked + label {
          svg {
            * {
              stroke: $whiteColor;
            }
          }
        }
      }
    }
    &:last-child {
      label {
        border-right: 1px solid #e5e5e5;
        border-radius: 0 4px 4px 0;
        svg {
          * {
            fill: #322e46;
          }
        }
        &:hover {
          svg {
            * {
              fill: $whiteColor;
            }
          }
        }
      }
      input {
        &:checked + label {
          svg {
            * {
              fill: $whiteColor;
            }
          }
        }
      }
    }
    label {
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      line-height: 40px;
      border: 1px solid #e5e5e5;
      border-right: none;
      @include transition(0.4s, all);
      font-size: 14px;
      &:hover {
        background-color: $themeColor10;
        opacity: 1;
        z-index: 1;
        color: $whiteColor;
        border-color: $themeColor10 !important;
      }
      svg {
        height: 14px;
        position: relative;
        top: -1px;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: 1;
    &:checked + label {
      background-color: $themeColor10;
      opacity: 1;
      z-index: 1;
      color: $whiteColor;
      border-color: $themeColor10 !important;
    }
  }
}

.start_end_wrapper {
  display: flex;
}

.sten_wrapper {
  display: inline-flex;
  margin-right: 0;
  .label {
    color: $primaryText;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    min-width: 67px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px 0px 0px 4px;
    display: inline-flex;
    padding: 10px 15px;
    background: rgba(246, 245, 250, 0.61);
  }
  .value {
    color: $primaryText;
    font-size: 15px;
    font-weight: 600;
    width: 70px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: none;
    border-radius: 0px 4px 4px 0px;
    display: inline-flex;
    input[type="text"] {
      width: 100%;
      height: 37px;
      padding: 10px !important;
    }
  }
  &:global(.start-wrapper) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .value {
      border: none;
      border-radius: 0;
      height: 39px;
    }
    .label {
      border-width: 0 1px;
      border-style: none solid;
      border-color: transparent rgba(0, 0, 0, 0.1);
      border-radius: 0;
      text-align: center;
      min-width: auto;
      height: 39px;
    }
  }
  ~ .base_target_wrapper {
    span {
      min-width: 70px;
      text-align: center;
    }
    &.range_wrapper {
      span {
        min-width: 100%;
      }
    }
  }
}

:global(.expectation_wrapper) {
  :global(.comp-incompn) {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    background: url(../../../assets/images/ic_cb_obj.svg) no-repeat left 3px;
    background-size: 14px;
    padding-left: 20px;
  }
}

.objective_right_box {
  .pre_created_objectives {
    background: rgba(255, 255, 255, 0.61);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 15px;
    margin: 20px 0px 0px 0px;
    h1 {
      color: $primaryText;
      font-size: 16px;
      font-weight: 400;
    }
    h2 {
      color: $textShade1;
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
    }
  }
  .other_details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    &:last-child {
      border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    }
    h1 {
      color: $primaryText;
      font-size: 16px;
      font-weight: 400;
    }
    h2 {
      color: $textShade1;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}
:global(.radio_1on1_wrapper) {
  :global(.form-group) {
    :global(label) {
      margin-bottom: 0;
    }
  }
  .form_option_list {
    list-style: none;
    padding-left: 0;
    margin: 15px 0 0;
    li {
      display: inline-block;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
    :global(.custom-checkbox) {
      margin-bottom: 0;
      cursor: auto;
      input {
        &:disabled {
          + :global(label) {
            &:after {
              background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
              background-size: 100%;
              -ms-background-size: 100%;
              -moz-background-size: 100%;
            }
          }
        }
      }
      :global(label) {
        font-size: 14px;
        padding-left: 30px;
        line-height: 20px;
        cursor: pointer;
        &::before {
          height: 20px;
          width: 20px;
        }
        &:after {
          height: 20px;
          width: 20px;
          background-image: url(../../../assets/images/ic_radio_active_2.svg);
        }
      }
    }
    li {
      &:last-child {
        :global(.custom-checkbox) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .objective_top_bar {
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: baseline;
  }
  :global(.se_bt_wrapper) {
    margin-bottom: 35px;
    margin-right: 25px;
  }
  .objective_bottom_bar {
    position: static;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .objective_top_bar {
    justify-content: center;
    align-items: center;
  }
  :global(.se_bt_wrapper) {
    margin-right: 0;
    width: 100%;
    display: inline-block;
  }
  .objectives_criteria {
    display: flex;
    width: 100%;
    > .oc {
      width: 100%;
    }
  }
  :global(.hours_wrapper),
  :global(.expectation_wrapper) {
    margin-bottom: 25px;
  }
  .objective_bottom_bar {
    @include align-items(flex-start);
    flex-direction: column;
    padding-bottom: 0;
    :global(.text_link) {
      margin-top: 20px;
    }
  }
}
