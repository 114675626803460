@import "theme";

.roll_based_skill_wrapper {
  // display: none;
  :global(.check_in_search_bar) {
    min-width: 400px;

    :global(form) {
      display: inline-block;
      vertical-align: middle;
      min-width: 89%;
    }

    .filter_info_tooltip {
      text-align: left;
      margin-left: 10px;
      display: inline-block;

      :global(.box-info) {
        position: static;
      }

      :global(.info-attach-file) {
        min-width: 280px;
        left: auto;
        right: -50px;

        &::before {
          right: 54px;
          left: auto;
        }

        :global(.attachment-title) {
          color: #b4b0c8;
        }

        :global(ul) {
          margin: 10px 0;

          :global(li) {
            line-height: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.role_based_skills_wrapper {
  padding-bottom: 10px;

  li {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);

    .skill_name {
      width: 80%;
    }

    .skillcompetency {
      width: 20%;
    }
  }

  > :global(.box-inner-pad) {
    padding-top: 10px;
    padding-bottom: 0;
  }

  :global(.custom-accordion-2) {
    :global(.card) {
      background-color: transparent;

      :global(.card-header) {
        background-color: transparent;
        padding-left: 0;
        @include flexbox;
        @include justify-content(space-between);

        &::before {
          top: 27px;
        }

        .edit {
          position: relative;
          top: 2px;
          cursor: pointer;
        }

        .clone {
          svg {
            height: 17px;
            width: 17px;
          }

          margin-right: 20px;
        }

        :global(button) {
          padding-left: 35px;
          border-radius: 0;
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          color: #322e46;
          font-weight: 600;
          top: 1px;
          text-align: left;

          .department {
            font-size: 14px;
            color: #322e46;
            letter-spacing: 0.39px;
            font-weight: 400;
            margin-top: 2px;
          }
        }
      }

      :global(.show) {
        &::before {
          top: 27px;
        }
      }
    }
  }
}

:global(.inner-accordion) {
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 4px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  :global(.inner-accordion-title) {
    padding: 15px;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.36px;
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;

    :global(.arrow-up-down) {
      width: 21px;
      height: 10px;
      position: absolute;
      right: 15px;
      top: 22px;

      &::before {
        content: "";
        background: url(../../../assets/images/ic-accordion-arrow-down.svg);
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        transition: 0.5s all;
      }

      &::after {
        content: "";
        background: url(../../../assets/images/ic-accordion-arrow-up.svg);
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        transform: rotate(-180deg);
        opacity: 0;
        transition: 0.5s all;
      }
    }
  }

  &:global(.active) {
    :global(.inner-accordion-title) {
      :global(.arrow-up-down) {
        &::before {
          opacity: 0;
          transform: rotate(180deg);
        }

        &::after {
          opacity: 1;
          transform: rotate(0);
        }
      }
    }

    .skill_gaps_table {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      height: auto;
      overflow: visible;
      padding-top: 12px;
      padding-bottom: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 20px;

      .skills_body {
        li {
          margin-top: 0;
        }
      }
    }
  }
}

.skill_gaps_table {
  height: 0;
  overflow: hidden;
  transition: 0.5s all;
  width: calc(100% - 40px);
  margin-left: 20px;

  > h5 {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    text-transform: uppercase;
    padding-left: 15px;
    font-weight: 400;
  }

  .skills_head {
    padding: 15px 0;
    list-style: none;
    position: relative;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    li {
      padding-left: 15px;

      > * {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
      }

      .skill_name {
        padding-left: 15px;
      }
    }
  }

  .skills_body {
    padding: 0 15px;
    list-style: none;
    margin-bottom: 0;

    li {
      font-weight: 500;
      font-size: 15px;
      color: #74798c;
      position: relative;
      padding: 15px 0 15px 15px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-top: -10px;
      transition: 0.5s all;

      > * {
        font-size: 15px;
        font-weight: 600;
        color: #37324b;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
}

@media only screen and (max-width: 1024px) {
  .roll_based_skill_wrapper {
    width: 100%;
  }

  .skill_gaps_table {
    overflow-x: auto;
    overflow-y: visible;

    > ul {
      width: 100%;

      .skill_name {
        width: 65%;
      }

      .skillcompetency {
        width: 35%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .roll_based_skill_wrapper {
    :global(.text-right) {
      margin-bottom: 20px;
      text-align: left !important;
    }
  }

  .role_based_skills_wrapper {
    :global(.box-heading-wrapper) {
      flex-wrap: wrap;

      :global(.check_in_search_bar) {
        margin: 15px 0 0;
        min-width: 100%;

        .filter_info_tooltip {
          :global(.info-attach-file) {
            left: auto;
            right: -5px;

            &::before {
              left: auto;
              right: 9px;
            }
          }
        }
      }
    }
  }

  .skill_gaps_table {
    > ul {
      width: 100%;
    }

    .skills_head {
      display: none;
    }

    .skills_body {
      li {
        padding: 10px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin: 10px 0 0;
        border-radius: 4px;
        box-shadow: none;
        background-color: rgba(246, 245, 250, 0.7);
        flex-wrap: wrap;

        &:before {
          display: none;
        }

        > * {
          width: 100%;
          padding-bottom: 15px;
          font-size: 14px;

          &::before {
            content: attr(data-title);
            width: 101px;
            display: inline-block;
            color: #37324b;
            font-weight: 500;
            font-size: 13px;
          }

          &:last-child {
            width: 100%;
            padding-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
  }

  :global(.inner-accordion) {
    margin: 15px 0;

    &:last-child {
      margin-bottom: 15px;
    }

    &:global(.active) {
      .skill_gaps_table {
        .skills_body {
          li {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
