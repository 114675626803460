@import "theme";

:global(.tab_filter) {
  padding: 15px;
}

:global(.check_in_search_bar) {
  :global(.form-group) {
    :global(.form-control) {
      font-size: 14px;
      padding-right: 50px;
    }
  }
}

:global(.tab_check_in_status) {
  text-align: right;
  :global(.custom-checkbox) {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 8px;

    input {
      + :global(label) {
        font-size: 14px;
      }
    }
    + :global(.custom-checkbox) {
      margin-left: 24px;
    }
  }
}

.item_list_option {
  display: block;
  margin: 5px 0 0;
  float: right;
  border: 1px solid $borderColor;
  overflow: hidden;
  @include border-radius(4px);

  li {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;

    a {
      width: 48px;
      height: 30px;
      display: block;
      text-align: center;
      line-height: 30px;

      img,
      svg {
        opacity: 0.5;
      }

      &:hover,
      &.active {
        background: $textShade4;
      }
    }

    + li {
      border-left: 1px solid $borderColor;
    }
  }
}

.tab_team_member_info {
  position: relative;
  .team_member_info {
    position: relative;
    color: $primaryText;
    padding-left: 32px;
    @include flexbox;
    @include align-items(center);

    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .private_notification {
      background: #7033ff;
      border: 2.5px solid #ffffff;
      position: absolute;
      top: 0px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      left: 66px;
    }
    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      display: flex;
      align-items: center;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
      :global(.custom-tooltip) {
        :global(.box-info) {
          margin: 0px;
          &:hover {
            + :global(.info-attach-file) {
              top: -145px;
            }
          }
        }
        :global(.info-attach-file) {
          top: -155px;
          left: -12px;
          &:before {
            top: auto;
            bottom: -4px;
            transform: rotate(135deg);
          }
          ul {
            li {
              line-height: 20px;
            }
          }
        }
      }
    }

    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }

    .team_member_info_collapse {
      width: 17px;
      height: 17px;
      line-height: 12px;
      border: 1px solid $textShade3;
      display: block;
      @include border-radius(50%);
      text-align: center;
      cursor: pointer;
      @include position(absolute, 18px, auto, auto, 0);
    }
  }
}

:global(.expandable-table) {
  margin-bottom: 0;
  tbody {
    td {
      &:nth-child(3) {
        padding-left: 7px;
      }
    }
  }
  .team_member_details {
    width: 235px;
    // border: 1px solid #f00;
  }
  .tab_team_member_info {
    padding-left: 50px;
    .team_member_info {
      // padding-left: 33px;
    }
  }
}

.tab_checkin_patterns {
  @include flexbox;
  @include align-items(center);
  padding: 0;
  margin-bottom: 0;
  .dot {
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    @include border-radius(50%);
    line-height: 18px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include transition(all, 0.4s, ease-out);
    cursor: pointer;

    span {
      width: 8px;
      height: 8px;
      @include flex(8px 0 0);
      line-height: 8px;
      background: $themeColor10;
      list-style: none;
      @include border-radius(50%);
      @include transition(all, 0.4s, ease-out);
      position: relative;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        line-height: 10px;
        display: block;
        @include position(absolute, -1px, auto, auto, -1px);
        @include border-radius(50%);
        @include transition(all, 0.1s, ease-out);
        transform: scale(0);
        background: $themeColor10;
      }
    }

    &.tab_checkin_delay {
      span,
      span:after {
        background: $themeColor1;
      }
    }

    &:hover,
    &.active {
      border: 1px solid #dfdfdf;
      background: $whiteColor;
      @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.2));

      span {
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.team_checkin_status {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid $borderColor;
  @include border-radius(4px);
  display: inline-block;

  &.ready {
    color: #33c5ff;
    background: #f0fafd;
    border-color: #cff1ff;
  }

  &.pending {
    color: #fd8c61;
    background: #fcf6f4;
    border-color: #fce6dd;
  }

  &.reviewed {
    color: #00c4bc;
    background: #f5ffff;
    border-color: rgba(36, 218, 210, 0.23);
  }
}

.team_member_table {
  // margin-left: 45px;
  // @include calc(width, '100% - 45px');

  .team_member_info {
    padding-left: 0;
  }
}

.lastEntity td {
  border-bottom: 0 !important;
}

.tab_table_responsive {
  /* thead {
    th {
      border-top: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      font-size: 14px;
      font-weight: 600;
      &:first-child{
        padding-left: 42px;
      }
    }
  } */

  table {
    table-layout: fixed;
  }
}

.collapseClose {
  background-color: $textShade3;
}

:global(.outer-table-wrapper) {
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
  background: #fff;
  // @include border-radius(0 8px 8px);
  @include border-radius(0 0 8px 8px);
  min-height: 600px;
  z-index: 1;
  position: relative;
}

/* ====================
  All media query start
 ==================== */
@media screen and (max-width: 1200px) {
  :global(.check_in_search_bar) {
    margin-bottom: 15px;
  }
  :global(.table-theme-1.pt40) {
    :global(.table-responsive) {
      overflow-x: auto !important;
    }
    :global(.tab_table_responsive) {
      width: 1070px;
    }
  }
}

@media screen and (max-width: 992px) {
  :global(.tab_check_in_status) {
    text-align: left;
    :global(.custom-checkbox) {
      + :global(.custom-checkbox) {
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  :global(.check_in_search_bar) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  :global(.tab_check_in_status) {
    :global(.custom-checkbox) {
      display: block;

      + :global(.custom-checkbox) {
        margin-left: 0;
      }
    }
  }

  .tab_team_member_info {
    .team_member_info {
      .team_member_name {
        font-size: 16px;
      }
      .team_member_designation {
        font-size: 14px;
      }
    }
  }

  .tab_table_responsive {
    width: 767px;
    thead {
      th {
        padding-top: 40px;
      }
    }
  }
}

/* ====================
  All media query end
 ==================== */
