@import "../../../../theme.scss";

.emp_proj_detail_Wrapper {
  .project_box {
    :global(.box-heading-topbar) {
      padding: 20px 25px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 20px;

      h3 {
        font-weight: 600;
        color: $themeColor11;
        letter-spacing: 0.26px;
      }
    }
    .objective_tracking_note_wrapper {
      box-sizing: border-box;
      height: 55px;
      font-size: 16px;
      border: 1px solid #d9d9d9;
      padding: 14px 30px;
      font-weight: 500;
      margin: 20px 25px 5px 25px;
      color: #928fa0;
      border-radius: 4px;
      background-color: rgba(246, 245, 250, 0.61);
      span {
        font-weight: 600;
      }
    }

    .question_wrapper {
      padding: 20px 25px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      &:last-child {
        border-bottom: 0;
      }

      h4 {
        font-weight: 600;
      }

      .workload_rating_guide {
        font-size: 15px;
        color: #928fa0;
        font-weight: 500;
        padding-bottom: 7px;
        margin-top: 32px;
      }

      .radio_wrapper {
        margin: 15px 0 7px;
        display: inline-block;
        width: 100%;
      }
      :global(.custom-checkbox) {
        margin-bottom: 5px;
        cursor: auto;

        input {
          &:disabled {
            + :global(label) {
              &:after {
                background: url(../../../../assets/images/ic_radio_disabled_2.svg) no-repeat center;
                background-size: 100%;
                -ms-background-size: 100%;
                -moz-background-size: 100%;
              }
            }
          }
        }

        :global(label) {
          padding-left: 30px;
          line-height: 20px;
          cursor: pointer;

          &::before {
            height: 20px;
            width: 20px;
          }

          &:after {
            height: 20px;
            width: 20px;
            background: url(../../../../assets/images/ic_radio_active_2.svg) no-repeat center;
          }
        }
      }

      :global(.form-group) {
        :global(.form-control) {
          min-height: 50px !important;
        }

        :global(.textarea-wrapper) {
          :global(.attached-pin-icon) {
            right: 15px;
          }
        }
      }
    }

    .btn_box {
      padding: 25px 25px 0;

      :global(.btn) {
        min-width: 120px;
        padding: 10px 20px;
      }
    }
  }

  .employeeObjectives_process_indicate {
    // @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin-top: 3px;
    margin-right: 5px;
    position: absolute;
    color: #000000 !important;
    left: 10px;

    .emp_objective_tooltip {
      top: -14px !important;
      left: 25px !important;
      min-width: 160px !important;

      &:before {
        border-right: 1px solid #eaeaea !important;
        border-top: 1px solid #eaeaea !important;
        left: -6px !important;
        top: -8px !important;
        transform: rotate(-135deg) !important;
      }
    }

    .employeeObjectives_step {
      width: 20px;
      height: 20px;
      @include border-radius(50%);
      border: 1px solid $textShade3;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      position: relative;
      overflow: hidden;

      + .employeeObjectives_step {
        margin-left: 8px;
      }

      .step1,
      .step2,
      .step3 {
        width: 5px;
        height: 5px;
        @include border-radius(50%);
        background: $textShade1;
        @include transition(all, 0.2s, ease);
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
      }

      &:hover .step,
      .active {
        width: 16px;
        height: 16px;
      }

      .step1 {
        background: #6dd400;
      }

      .step2 {
        background: #ffcc40;
      }

      .step3 {
        background: #f07575;
      }
    }
  }
  .view_all_objective_comment {
    display: inline-block;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    span {
      color: #7033ff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .member_obj {
    :global(.card) {
      padding: 20px 25px 25px;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

      :global(.card-header) {
        padding-top: 0;
        border-top: none;
        :global(.btn) {
          padding: 0;
        }
        :global(.indicator_manager_view) {
          padding: 0 30px 0 0;
          margin-left: 30px;
          position: relative;
          width: calc(100% - 30px);
        }
        .error {
          :global(.custom-checkbox) {
            input[type="radio"] {
              + :global(label) {
                padding-left: 26px;

                &::before {
                  width: 18px;
                  height: 18px;
                  top: 3px;
                  border-color: #f07575;
                }
              }
            }
          }
        }
      }

      :global(.card-body) {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 25px;
        padding-left: 0;
      }

      &:last-child {
        border-bottom: none !important;
        padding-bottom: 0;
      }

      :global(.accordion-icon) {
        right: 0;
      }
    }
    .idp_info_list {
      :global(.card) {
        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
          padding-bottom: 0;
        }
      }
    }

    .key_initiative_wrapper {
      display: inline-block;
      padding-left: 33px;
      margin: 3px 0;
      > span {
        display: inline-block;
        color: #928fa0;
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #979797;
        line-height: 15px;
        font-size: 14px;

        &:last-child {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }

        &.initiative_wrapper {
          cursor: pointer;
          @include transition(0.2s all);

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
    .objective_update_info {
      position: relative;
      padding-left: 50px;
      margin: 3px 0;
      font-weight: 600;
      font-size: 14px;
      &::before {
        content: "";
        position: absolute;
        background: #b5afca;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        left: 34px;
        top: 7px;
      }

      > span {
        color: #928fa0;
        line-height: 15px;
        font-size: 14px;
        font-weight: 500;
      }
      span.text_bold {
        font-weight: 600;
      }
    }

    .timeframe_wrapper {
      margin: 10px 0 20px 31px;
      .timeframe_info {
        display: flex;
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        color: $textShade1;
        letter-spacing: 0.32px;
        span {
          font-size: 16px;
          color: #393f51;
          letter-spacing: 0.36px;
        }
        .edit_button {
          cursor: pointer;
          line-height: 16px;
          width: 17px;
          height: 17px;
          margin-left: 20px;
          svg {
            cursor: pointer;
            * {
              fill: #928fa0;
            }
          }
        }
      }

      .timeframe_edit_box {
        box-sizing: border-box;
        min-height: 119px;
        /* position: relative; */
        width: 90%;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-top: 15px;
        .timeframe {
          display: inline-block;
          position: relative;
          top: -47px;
          margin: 0 10px;
        }
        .date_picker_wrapper {
          display: inline-block;
          margin-top: 18px;
          :global(.calendar_wrapper) {
            display: inline-block;
            margin-right: 19px;
          }
          .btn_round {
            display: inline-block;
            cursor: pointer;
            margin: 12px 12px 0px 0px;
          }
        }
      }
    }

    :global(.custom-checkbox) {
      margin-left: 33px;
      :global(label) {
        color: #928fa0;
        font-size: 16px;
        font-weight: 500;
        padding-left: 30px;
        line-height: 20px;
        cursor: pointer;
      }
      .ownership_select_box_wrapper {
        box-sizing: border-box;
        height: 82%;
        width: 599px;
        cursor: auto;
        border: 1px solid #d9d9d9;
        padding: 20px 28px;
        margin: 15px 0;
        border-radius: 4px;
        background-color: rgba(246, 245, 250, 0.61);
        .ownership_select_heading {
          color: #3e3266;
          font-size: 14px;
          margin-bottom: 15px;
        }
        .ownership_select_dropdown {
          :global(.custom-default-dd-box) {
            width: 230px;
            max-width: 100%;
            :global(.select__control) {
              min-height: 50px;
              border-color: rgba(0, 0, 0, 0.1);
            }
            :global(.select__indicator-separator) {
              display: none;
            }
            :global(.select__single-value),
            :global(.select__placeholder) {
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
      .commentbox_wrapper {
        cursor: auto;
        :global(.view_comment_box_heading) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  // Heading right
  .employeeObjectives_process_complete {
    font-size: 21px;
    line-height: 25px;
    font-weight: 600;
    color: $primaryText;
  }

  .employeeObjectives_end_days {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.36px;
    color: $textShade1;
  }

  .emp_obj_end_days {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.36px;
    color: $textShade1;
    position: absolute;
    right: 0;
    bottom: -19px;
    width: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .emp_detail_wrapper {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .emp_proj_detail_Wrapper {
    .member_obj {
      :global(.card) {
        :global(.card-header) {
          :global(.accordion-heading) {
            :global(.accordion-heading-right) {
              .emp_obj_end_days {
                right: -29px;
              }
            }
          }
        }
      }
      .timeframe_wrapper {
        .timeframe_edit_box {
          text-align: center;
          .timeframe {
            position: static;
          }
          .date_picker_wrapper {
            :global(.calendar_wrapper) {
              margin: 5px;
            }
          }
        }
      }
    }
  }
}
