@import '../../theme';

.tag_box{
    padding: 20px 0px;
    .tag_heading{
        padding: 0px 20px 20px 20px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
        h2{
            font-size: 22px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.26px;
            color: #322E46;
        }
    }
    .tag_list{
        margin:0;
        padding: 0 20px;
        list-style: none;
        li{
            display: inline-block;
            padding: 7px 10px;
            border:1px solid #E0DFE2;
            border-radius: 16px;
            color: #322E46;
            font-weight: 600;
            margin:10px 10px 0 0;
        }
    }
}