@import "../../theme.scss";

.member_certificate_wrapper {
  // display: none;
  :global(.check_in_search_bar) {
    min-width: 320px;
  }
  .create_new_btn {
    padding: 10px 20px;
    min-width: 120px;
  }
  :global(.page-title) {
    padding-bottom: 5px;
    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.certficate_overview_box {
  padding-top: 15px;
  padding-bottom: 0;
  :global(.box-heading-wrapper) {
    padding-bottom: 15px;
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;
  min-width: 75px;
  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }
  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }
  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }
  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }
  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
  &:global(.yellow) {
    color: #ffcb00;
    border-color: rgba($color: #ffcb00, $alpha: 0.25);
    background-color: #fffcf0;
  }
  &:global(.red) {
    color: #fd6161;
    border-color: rgba($color: #fce6dd, $alpha: 1);
    background-color: #fcf6f4;
  }
}

.certificate_count_wrapper {
  position: relative;
  .certificate_head {
    @include flexbox;
    @include justify-content(space-between);
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 25px;
    margin-left: -25px;
    width: calc(100% + 50px);
    padding-left: 25px;
    padding-right: 25px;
    span {
      font-weight: 600;
      font-size: 14px;
      color: #322e46;
      letter-spacing: 0.47px;
      width: 90%;
      &:last-child {
        width: 10%;
      }
    }
  }
  .certificate_list_wrapper {
    @include flexbox;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    .certificate_item {
      @include flexbox;
      width: 100%;
      flex-direction: column;
      .item_topbar {
        @include flexbox;
        @include justify-content(space-between);
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        &:global(.active) {
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          background-color: rgba(246, 245, 250, 0.5);
        }
        .certificate_info {
          width: 90%;
          padding-right: 20px;
        }
        .count_text {
          width: 10%;
        }
        .expand_collapse_wrapper {
          position: relative;
          top: -1px;
          cursor: pointer;
        }
        .certificate_name {
          margin-left: 15px;
          font-weight: 600;
          font-size: 15px;
          color: #322e46;
          display: inline-block;
          width: 80%;
          vertical-align: top;
        }
        .count_text {
          font-weight: 600;
          font-size: 15px;
          color: #322e46;
        }
      }
      .item_body {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding-bottom: 10px;
        .member_certificate_table {
          position: relative;
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          border-radius: 2px;
          table {
            margin-bottom: 0;
            thead {
              th {
                border-bottom: 0;
                width: 42.5%;
                border-top: 0;
                background-color: #fcfaff;
                font-size: 16px;
                font-weight: 600;
                &:last-child {
                  width: 15%;
                }
              }
            }
            tbody {
              tr {
                td {
                  font-weight: 500;
                  font-size: 14px;
                  color: #322e46;
                }
              }
            }
          }
        }
      }
      &:first-child {
        .item_topbar {
          padding-top: 0;
        }
      }
      &:last-child {
        .item_topbar {
          padding-bottom: 10px;
          border-bottom: 0;
        }
        .item_body {
          border-bottom: 0;
          border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
          margin-top: 0;
          padding-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .member_certificate_wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .certificate_count_wrapper {
    .certificate_list_wrapper {
      margin-bottom: 10px;
      .certificate_item {
        .item_body {
          .member_certificate_table {
            table {
              thead {
                th {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .certificate_head {
      margin-left: -15px;
      width: calc(100% + 30px);
    }
  }
}
