@import "theme";

.note_text {
  white-space: normal;
  word-break: break-word;
  color: #74798C;
  margin: 0 60px;
  padding-top: 25px;
}

.inner_header {
  display: flex;
  justify-content: space-between;
}

.details_wrapper {
  padding-top: 5px;
}

.inner_container {
  margin: 40px 60px;
}

.empname_wrapper {
  display: flex;
  
  span:first-child {
    min-width: 88px;
  }
}

.greyText {
  color: #928fa0;
}

.question_inner_box {
  padding: 25px;
}

:global(card):nth-child(n) {
  border-radius: 25px;
}


.questions_main_wrapper {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  margin-bottom: 30px;
  padding: 25px 0 25px;
}

.profile_card {
  margin-bottom: 0;
  border-radius: 0;
  border: 0;

  .profile_card_info {
    padding: 25px 25px 10px 25px;
    border-top: 1px solid #ededed;

    // border-bottom: 0px;
    .profile_card_title {
      width: 100%;
      margin-bottom: 0;

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.26px;
        color: #322e46;
      }

      .details_wrapper>div {
        margin-bottom: 12px;
      }
    }
  }
}

.profile_card_top_radius {
  border-radius: 8px 8px 0 0;
}

.profile_card_bottom_radius {
  border-radius: 0 0 8px 8px;
}

.multimanager_wrapper {
  :global(.card_action) {
    margin: 0 15px 10px 0;
    font-weight: 600;
    max-width: 100px!important;
  }
}

.question_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  .question_text {
    font-size: 16px;
    font-weight: 600;
  }
  .question_text_error {
    color: #dc3545;
  }
}

.buttons_wrapper {
  margin: 30px auto 0 auto;
  text-align: center;

  button {
    margin-bottom: 10px;
  }
}

.submit_button {
  min-width: 140px;
  margin: 0 12px;
}

.clear_button {
  min-width: 120px;
  margin: 0 12px;
}

.question_options_list {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: end;

  li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  :global(.custom-checkbox) {
    margin-bottom: 0;
    cursor: auto;

    input {
      &:disabled {
        + :global(label) {
          &:after {
            background-image: url(../../../../assets/images/ic_radio_disabled_2.svg) !important;
            background-size: 100%;
            -ms-background-size: 100%;
            -moz-background-size: 100%;
          }
        }
      }
    }

    :global(label) {
      font-size: 14px;
      padding-left: 30px;
      // line-height: 20px;
      cursor: pointer;

      &::before {
        height: 20px !important;
        width: 20px !important;
        background-color: #E0DFE2 !important;
      }

      &:after {
        height: 20px !important;
        width: 20px !important;
        background-image: url(../../../../assets/images/ic_radio_active_2.svg) !important;
      }
    }
  }

  li {
    &:last-child {
      :global(.custom-checkbox) {
        margin-bottom: 0;
      }
    }
  }
}

input[type="radio"].readonly {
  pointer-events: none;
}

label.readonly {
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .submit_button{
      margin-bottom: 10px;
  }
  .note_text {
    margin: 0 24px;
  }

  .inner_container {
    margin: 12px 24px;
  }

  .question_options_list {
    padding-top: 12px;
    justify-content: start;
  }
}
