@import "theme";
:global(.box) {
  :global(.box-inner-pad) {
    &.custom_form_ctrl_wrapper {
      > * {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.box_heading_wrapper {
  display: flex;
  justify-content: space-between;
  :global(.tab_check_in_status) {
    :global(.custom-checkbox) {
      margin-top: 0 !important;
    }
  }
}

.custom_form_ctrl_wrapper {
  display: block;
  :global(.dropdown-multiselect) {
    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      width: 100%;
      margin-top: 10px;
      :global(.multi-select) {
        :global(.dropdown-container) {
          border-color: rgba(0, 0, 0, 0.1);
          height: 47px;
          :global(.dropdown-heading) {
            height: 100%;
            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 15px;
            }
            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }
        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }
            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }
              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }
                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }
            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;
              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }
            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  :global(.form-group) {
    margin-bottom: 0px;
    :global(.form-control) {
      width: 70%;
      &:global(.limit-reached) {
        border: 1px solid #f07575;
      }
    }
    :global(label) {
      font-size: 16px;
    }
    :global(.text-muted) {
      font-size: 14px;
      color: #322e46;
      letter-spacing: 0.32px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
  :global(.cycle-list-wrapper) {
    :global(.form-group) {
      width: 640px;
      margin-right: 40px;
      :global(label) {
        margin-bottom: 0;
      }
    }
    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      width: 100%;
      margin-top: 10px;
    }
    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value) {
        font-size: 16px;
      }
    }
  }
  :global(.radio-wrapper) {
    :global(.form-group) {
      width: 300px;
      margin-right: 40px;
      :global(label) {
        margin-bottom: 0;
      }
    }
    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      width: 100%;
      margin-top: 10px;
    }
    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value) {
        font-size: 16px;
      }
    }
    + span {
      color: #928fa0;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .cycle_btn_submit {
    padding: 10px 20px;
    min-width: 150px;
  }
  .textNote {
    font-size: 14px;
    color: #dc3545;
    font-weight: normal;
  }
}

@media only screen and (max-width: 1024px) {
  form {
    > .custom_form_ctrl_wrapper {
      &:nth-child(2) {
        padding: 0;
        .cycle_calendars {
          :global(.form-group) {
            &:global(.calendar_outer_wrapper) {
              margin-bottom: 0;
              padding: 25px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
              :global(.calendar_wrapper) {
                width: 48%;
                margin-right: 4%;
                + :global(.calendar_wrapper) {
                  margin-right: 0;
                }
              }
              .select_que_btn {
                margin: 20px 0 0;
              }
              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }
        .file_info {
          margin-left: 0;
          max-width: 100%;
          top: 0;
          margin-top: 15px;
        }
      }
      &:nth-child(3) {
        &.active_cycle_wrapper {
          padding: 0;
          .cycle_calendars {
            display: block;
            :global(.form-group) {
              &:global(.calendar_outer_wrapper) {
                width: 100%;
                padding: 25px !important;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                margin-bottom: 0;
              }
            }
            :global(.radio-wrapper) {
              width: 100%;
              padding: 25px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            }
          }
        }
      }
      .cycle_btn_submit {
        padding: 10px 20px;
        min-width: 150px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box_heading_wrapper {
    display: block;
    justify-content: space-between;
    :global(.tab_check_in_status) {
      :global(.custom-checkbox) {
        margin-top: 5px !important;
      }
    }
  }
  form {
    > .custom_form_ctrl_wrapper {
      &:first-child {
        padding: 0;
        > :global(.form-group) {
          width: 100%;
          padding: 15px;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          margin-bottom: 0;
          > * {
            width: 100%;
          }
        }
        :global(.dropdown-multiselect) {
          display: block !important;
          padding: 15px 15px 0;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          margin-bottom: 0;
          :global(.form-group) {
            width: 100%;
            margin-bottom: 15px;
            > * {
              width: 100%;
            }
          }
        }
      }
      .cycle_btn_submit {
        padding: 10px 20px;
        min-width: 150px;
        margin-left: 15px;
        margin-top: 30px;
      }
      span {
        margin-left: 15px;
      }
    }
  }
}
