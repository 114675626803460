@import "theme";

.one_on_one_teams_wrapper {
  position: absolute;
  top: 0px;
  padding: 30px;
  background: white;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  :global(.card_details) {
    margin-bottom: 20px;
  }
  .meetingNotes_wrapper {
    .title_item {
      margin-bottom: 30px;
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .title1 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 28px;
    }
  }
  .text_area_wrapper {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    :global(.form-group .form-control) {
      border: none;
      min-height: auto !important ;
      &:active {
        border: none;
      }
      &:hover {
        border: none;
      }
    }
  }
  .add_meeting_btn {
    font-size: 12px;
    cursor: pointer;
    padding-bottom: 30px;
  }
}
