@import "theme";

.tab_filter {
  padding: 15px;
}

.check_in_search_bar {
}

.tab_check_in_status {
  :global(.custom-checkbox) {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 8px;

    + :global(.custom-checkbox) {
      margin-left: 24px;
    }
  }
}

.item_list_option {
  display: block;
  margin: 5px 0 0;
  float: right;
  border: 1px solid $borderColor;
  overflow: hidden;
  @include border-radius(4px);

  li {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;

    a {
      width: 48px;
      height: 30px;
      display: block;
      text-align: center;
      line-height: 30px;

      img,
      svg {
        opacity: 0.5;
      }

      &:hover,
      &.active {
        background: $textShade4;
      }
    }

    + li {
      border-left: 1px solid $borderColor;
    }
  }
}

.tab_team_member_info {
  position: relative;

  .team_member_info {
    position: relative;
    color: $primaryText;
    padding-left: 32px;
    @include flexbox;
    @include align-items(center);

    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;

      img {
        width: 52px;
        height: 52px;
        object-fit: cover;
      }
    }

    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
    }

    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }

    .team_member_info_collapse {
      width: 17px;
      height: 17px;
      line-height: 12px;
      border: 1px solid $textShade3;
      display: block;
      @include border-radius(50%);
      text-align: center;
      cursor: pointer;
      @include position(absolute, 18px, auto, auto, 0);
    }
  }
}

:global(.expandable-table) {
  margin-bottom: 0;
}

.tab_checkin_patterns {
  @include flexbox;
  @include align-items(center);
  padding: 0;
  margin-bottom: 0;
  .dot {
    width: 18px;
    height: 18px;
    border: 1px solid transparent;
    @include border-radius(50%);
    line-height: 18px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include transition(all, 0.4s, ease-out);
    cursor: pointer;

    span {
      width: 8px;
      height: 8px;
      @include flex(8px 0 0);
      line-height: 8px;
      background: $themeColor10;
      list-style: none;
      @include border-radius(50%);
      @include transition(all, 0.4s, ease-out);
      position: relative;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        line-height: 10px;
        display: block;
        @include position(absolute, -1px, auto, auto, -1px);
        @include border-radius(50%);
        @include transition(all, 0.1s, ease-out);
        transform: scale(0);
        background: $themeColor10;
      }
    }

    &.tab_checkin_delay {
      span,
      span:after {
        background: $themeColor1;
      }
    }

    &:hover,
    &.active {
      border: 1px solid #dfdfdf;
      background: $whiteColor;
      @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.2));

      span {
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.team_checkin_status {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid $borderColor;
  @include border-radius(4px);
  display: inline-block;

  &.ready {
    color: #33c5ff;
    background: #f0fafd;
    border-color: #cff1ff;
  }

  &.pending {
    color: #fd8c61;
    background: #fcf6f4;
    border-color: #fce6dd;
  }

  &.reviewed {
    color: #00c4bc;
    background: #f5ffff;
    border-color: rgba(36, 218, 210, 0.23);
  }
}

.team_member_table {
  margin-left: 40px;
  @include calc(width, "100% - 40px");

  .team_member_info {
    padding-left: 0;
  }
}

.lastEntity td {
  border-bottom: 0 !important;
}

.tab_table_responsive {
  thead {
    th {
      border-top: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;
      font-weight: 600;
    }
  }

  table {
    table-layout: fixed;
  }
}

.collapseClose {
  background-color: $textShade3;
}

:global(.my-history-item) {
  position: absolute;
  top: 6px;
  right: auto;
  bottom: auto;
  left: -17px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  z-index: 1;
  background: #ccc;
  :global(.pending-from-member) {
    background: #ff9066;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  :global(.reviewed) {
    background: #7b1cff;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  :global(.pending-for-review) {
    background: #00c8bd;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  &:hover {
    :global(.pending-from-member) {
      animation: ripple-1 1s linear infinite;
    }
    :global(.reviewed) {
      animation: ripple-2 1s linear infinite;
    }
    :global(.pending-for-review) {
      animation: ripple-3 1s linear infinite;
    }
  }
}

:global(.active-cycle) {
  :global(.my-history-item) {
    :global(.pending-from-member) {
      animation: ripple-1 1.2s linear infinite;
    }
    :global(.reviewed) {
      animation: ripple-2 1.2s linear infinite;
    }
    :global(.pending-for-review) {
      animation: ripple-3 1.2s linear infinite;
    }
  }
}

@keyframes ripple-1 {
  0% {
    box-shadow: 0 0 0 0 rgba(#ff9066, 0.3), 0 0 0 7px rgba(#ff9066, 0.3), 0 0 0 8px rgba(#ff9066, 0.3);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(#ff9066, 0.3), 0 0 0 8px rgba(#ff9066, 0.3);
  }
}
@keyframes ripple-2 {
  0% {
    box-shadow: 0 0 0 0 rgba(#7b1cff, 0.3), 0 0 0 7px rgba(#7b1cff, 0.3), 0 0 0 8px rgba(#7b1cff, 0.3);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(#7b1cff, 0.3), 0 0 0 8px rgba(#7b1cff, 0.3);
  }
}
@keyframes ripple-3 {
  0% {
    box-shadow: 0 0 0 0 rgba(#00c8bd, 0.3), 0 0 0 7px rgba(#00c8bd, 0.3), 0 0 0 8px rgba(#00c8bd, 0.3);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(#00c8bd, 0.3), 0 0 0 8px rgba(#00c8bd, 0.3);
  }
}
/* ====================
  All media query start
 ==================== */
@media screen and (max-width: 1200px) {
  .check_in_search_bar {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 992px) {
  .tab_check_in_status {
    :global(.custom-checkbox) {
      + :global(.custom-checkbox) {
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .check_in_search_bar {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tab_check_in_status {
    :global(.custom-checkbox) {
      display: block;

      + :global(.custom-checkbox) {
        margin-left: 0;
      }
    }
  }
}

/* ====================
  All media query end
 ==================== */
