@import "theme";

.reviewer_profile_box {
  padding-bottom: 0;
  :global(.box-heading-wrapper) {
    min-height: 50px;
    border-bottom: 0;
  }
}
.reviewer_profile {
  text-align: center;
  margin-top: -60px;
  .reviewer_profile_img {
    width: 78px;
    height: 78px;
    @include border-radius(50%);
    overflow: hidden;
    line-height: 78px;
    margin: 0 auto;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    background-color: #ff5479;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    img {
      width: 78px;
      height: 78px;
      object-fit: fill;
    }
  }
  .reviewer_profile_name {
    font-size: 18px;
    line-height: 22px;
    color: $primaryText;
    font-weight: 600;
    margin: 10px 0 1px;
  }
  .reviewer_profile_deg {
    font-size: 16px;
    line-height: 19px;
    color: $primaryText;
    font-weight: normal;
    span {
      color: $textShade1;
      font-weight: normal;
      display: block;
      margin-top: 4px;
    }
  }
  .other_reviewer {
    margin-top: 28px;
    display: inline-block;
    font-weight: 500;
  }
}

:global(.reviewer-profile-box) {
  :global(.box-heading-wrapper) {
    :global(h3.box-heading-light) {
      font-weight: 500;
      font-size: 16px;
      color: #928fa0;
      letter-spacing: 0.36px;
      margin-top: 5px;
    }
  }
  :global(.box-inner-pad) {
    &:hover {
      :global(.box-upper-slider) {
        :global(.slick-prev) {
          left: -60px;
          opacity: 1;
        }
        :global(.slick-next) {
          right: -60px;
          opacity: 1;
        }
      }
    }
  }
}
:global(.box-profile-info-slider) {
  margin-top: -5px;
}
:global(.box-upper-slider) {
  margin: 0 auto 8px;
  width: 192px;
  :global(.slick-slide) {
    height: 64px;
    @include border-radius(50%);
    overflow: hidden;
    text-align: center;
    background: transparent;
    border: 2px solid $whiteColor;
    @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.11), false);
    @include transition(all, 0.5s);
    width: 64px !important;
    img {
      max-width: 100%;
      height: 100%;
    }
    .reviewer_profile_img {
      background-color: #13bb8d;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      letter-spacing: 0.21px;
      text-align: center;
      line-height: 64px;
      height: 100%;
    }
    &:global(.slick-center) {
      transform: scale(1.32);
      z-index: 1;
    }
    div {
      height: 100%;
      width: 100%;
    }
  }
  :global(.slick-track) {
    padding: 20px 0;
  }
  :global(.slick-prev) {
    left: -55px;
    position: absolute;
    @include transition(all, 0.5s);
    opacity: 0;
    height: 22px;
    width: 11px;
    &:before {
      opacity: 1;
    }
  }
  :global(.slick-next) {
    right: -55px;
    position: absolute;
    @include transition(all, 0.5s);
    opacity: 0;
    height: 22px;
    width: 11px;
    &:before {
      opacity: 1;
    }
  }
}

:global(.box-lower-slider) {
  .reviewer_profile_name {
    font-weight: 600;
    font-size: 18px;
    color: $primaryText;
    letter-spacing: 0.41px;
    text-align: center;
  }
  .reviewer_profile_deg {
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.36px;
    text-align: center;
  }
}

.certificate_wrapper {
  padding: 0px 20px 20px 20px;
  .certificate_item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    margin: 0px 0px 10px 0px;
    border-radius: 4px;
    .title {
      color: $themeColor11;
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
      margin-left: 10px;
      width: 90%;
      line-height: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .add_info {
      color: $textShade1;
      font-size: 12px;
      font-weight: 400;
      margin-left: 22px;
      line-height: 12px;
    }
  }
}

.awards_wrapper {
  padding: 0px 20px 20px 20px;
  .awards_item {
    padding: 0px 0px 0px 5px;
    margin: 0px 0px 20px 0px;
    border-radius: 4px;
    .title {
      color: $themeColor11;
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
      margin-left: 0px;
      width: 90%;
      line-height: 16px;
    }
    .add_info {
      color: $textShade1;
      font-size: 14px;
      font-weight: 400;
      margin-left: 0px;
      line-height: 14px;
    }
    .date {
      color: $textShade1;
      font-size: 13px;
      font-weight: 400;
      margin-left: 0px;
      line-height: 13px;
      margin-top: 4px;
    }
  }
}

.journey_wrapper {
  padding: 0px 20px 20px 20px;
  .journey_item {
    padding: 0px 0px 0px 5px;
    margin: 0px 0px 20px 0px;
    border-radius: 4px;
    .title {
      color: $themeColor11;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      margin-left: 0px;
      width: 90%;
      line-height: 16px;
    }
    .add_info {
      color: $textShade1;
      font-size: 14px;
      font-weight: 400;
      margin-left: 0px;
      line-height: 14px;
    }
    .date {
      color: $textShade1;
      font-size: 13px;
      font-weight: 400;
      margin-left: 0px;
      line-height: 13px;
      margin-top: 4px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  :global(.box-upper-slider) {
    :global(.slick-prev) {
      left: -30px;
      opacity: 1;
    }
    :global(.slick-next) {
      right: -30px;
      opacity: 1;
    }
  }
  :global(.reviewer-profile-box) {
    :global(.box-inner-pad) {
      &:hover {
        :global(.box-upper-slider) {
          :global(.slick-prev) {
            left: -30px;
          }
          :global(.slick-next) {
            right: -30px;
          }
        }
      }
    }
  }
}
