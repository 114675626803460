@import "../../theme.scss";
.pir_chart_wrapper {
  padding: 10px;
  .legend_wrapper {
    padding: 0 15px;
    // height: 60px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    .legend_list {
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      @include flexbox;
      .legend_item {
        margin-top: 5px;
        font-weight: 500;
        font-size: 13px;
        color: $primaryText;
        // @include flexbox;
        @include align-items(center);
        margin-right: 22px;
        &:last-child {
          margin-right: 0;
        }
        .legend_color {
          height: 10px;
          width: 10px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 7px;
          position: relative;
          top: 0px;
        }
      }
    }
  }
  :global(.graph-item) {
    border-right: none;
    h5 {
      text-transform: none;
      font-size: 18px;
      clear: both;
      font-weight: 500;
      display: block;
      height: 42px;
      text-align: left;
      letter-spacing: 0.21px;
      line-height: 21px;
      padding-left: 10px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .pir_chart_wrapper {
    .legend_list {
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 600px) {
  .pir_chart_wrapper {
    :global(.graph-item) {
      h5 {
        display: block;
        height: auto;
      }
    }
  }
}
