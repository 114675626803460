@import '../../theme.scss';

.key_description {
  margin: 0px;
  display: inline-block;
  width: 82%;
}

.key_percentage {
  display: inline-block;
  vertical-align: top;
  width: 12%;
  text-align: right;
  margin-top: 5px;
}

.keyresult_wrapper {
  .keyresult_item {
    background: rgba(255, 255, 255, 0.61);
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    font-size: 16px;
    color: $themeColor11;
    letter-spacing: 0.36px;
    padding: 15px; //15px each instead of 25px 15px
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
  }

  &:last-child {
    .keyresult_item {
      margin-bottom: 0;
    }
  }
}

.key_values {
  font-weight: 500;
  display: block;
  margin-top: 0; //0 instead of 5px
  font-size: 13px;
  color: #74798C;

  &.Key_n_value {
    display: inline-block;
    margin-right: 20px;
    padding-left: 12px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #B5AFCA;
      left: 0;
      top: 6px;
    }

    >* {
      display: inline-block;
    }

    +* {
      +* {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

.percentage_weightage_wrapper {
  position: absolute;
  right: 10px;
  width: 18%;
  top: 10px;
  text-align: right;

  >* {
    width: 100% !important;
  }

  .weightage {
    font-weight: 500;
    font-size: 13px;
    color: #74798C;
    display: inline-block;
  }
}

@media only screen and (max-width:1023px) {
  :global(.custom_modal_container) {
    .key_description {
      width: 100%;
    }

    .percentage_weightage_wrapper {
      position: static;
      right: 10px;
      width: auto;
      top: 0;
      text-align: left;

      >* {
        width: auto !important;
        vertical-align: middle;
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }
}