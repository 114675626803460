@import "theme";

.notificationlist {
  :global(.nonotification_wrapper) {
    width: 100%;
    text-align: center;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 18px;
      font-weight: 18px;
      color: $primaryText;
      margin-top: 28px;
      font-weight: 600;
    }
    h4 {
      font-size: 18px;
      font-weight: 18px;
      color: $textShade1;
      margin-top: 10px;
      font-weight: 500;
    }
  }
  :global(.notification_list_wrapper) {
    overflow-x: hidden;
    :global(.notification_list) {
      :global(ul) {
        :global(li.notification) {
          padding-right: 50px;
          position: relative;
          &:hover {
            :global(.delete-notification) {
              opacity: 1;
              visibility: visible;
              right: 25px;
            }
          }
        }
      }
    }
  }
  .align_items_center {
    align-items: center;
  }
  .header_caption {
    font-size: 22px;
    font-weight: 600;
    color: $primaryText;
    display: flex;
    align-items: center;
    .notification_badge {
      width: 25px;
      height: 25px;
      background-color: $themeColor1;
      border-radius: 50%;
      line-height: 25px;
      display: inline-block;
      color: $whiteColor;
      font-size: 14px;
      text-align: center;
      margin: 0px 0px 0px 10px;
    }
  }
  :global(.text-right) {
    span {
      border-right: 1px solid rgba(151, 151, 151, 0.5);
      padding-right: 15px;
      cursor: pointer;
      margin-right: 15px;
      font-weight: 600;
      color: #322e46;
      @include transition(all, 0.5s, ease-out);
      &:hover {
        color: #7033ff;
      }
      &:last-child {
        margin: 0;
        padding: 0;
        border-right: 0;
      }
    }
  }
  :global(.delete-notification) {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 10px;
    margin: auto;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    @include transition(all, 0.5s, ease-out);
    svg {
      vertical-align: top;
    }
    img {
      height: 100%;
      display: block;
    }
  }
}

:global(.notification) {
  @include transition(all, 0.5s, ease-out);
}

:global(.removed-item) {
  animation: removed-item-animation 0.8s cubic-bezier(0.65, -0.02, 0.72, 0.29);
}

@keyframes removed-item-animation {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  30% {
    opacity: 1;
    transform: translateX(50px);
  }

  80% {
    opacity: 0;
    transform: translateX(-800px);
  }

  100% {
    opacity: 0;
    transform: translateX(-800px);
    // display: none;
  }
}

@media (max-width: 767px) {
  .notificationlist {
    padding: 20px 0 0;
    :global(.text-right) {
      a {
        font-size: 13px;
      }
    }
    .header_caption {
      font-size: 16px;
      display: inline-block;
      position: relative;
      padding-right: 30px;
      .notification_badge {
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
    :global(.box-heading-wrapper) {
      padding: 0 15px 20px;
    }
    :global(.notification_list_wrapper) {
      :global(.notification_list) {
        :global(.title) {
          padding-left: 15px;
        }
        :global(ul) {
          :global(li) {
            height: auto;
            padding-left: 15px;
            :global(.delete-notification) {
              opacity: 1;
              visibility: visible;
              right: 20px;
              bottom: auto;
              top: 21px;
            }
          }
        }
      }
    }
  }
}
