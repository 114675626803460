@import '../../theme.scss';

.user_profile_box {
    :global(.box-edit) {
        @include position(absolute, 15px, 35px, auto, auto);
    }
}

.user_data {
    padding: 0 20px;
    @include flexbox;
    
    .user_img {
        width: 78px;
        height: 78px;
        margin: 0 15px 0 0;
        -moz-flex: 0 0 78px;
        font-size: 26px;
        font-weight: 600;
        color: #ffffff;
        line-height: 72px;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        @include flex(0 0 78px);
        overflow: hidden;
        @include border-radius(50%);
        img {
        max-width: 100%;
        max-height: 100%;
        }
    }

    .user_details {
        width:100%;
        @include flexbox;
        @include justify-content(space-between);

        .leftSide {
            width: 50%;
            word-break: break-all;
            padding-right: 10px;
            .create_new_btn {
                margin-top: 20px;
                min-width: 88px;
                height: 32px;
                display: flex;
                padding: 5px 15px 5px 15px;
                span {     
                    color: $textShade1;                      
                    margin-right: 20px;
                }
            }
            .disabled_btn {
                margin-top: 20px;
                min-width: 88px;
                height: 32px;
                display: flex;
                padding: 5px 15px 5px 15px;
                opacity: 0.5;
                cursor: not-allowed;
                span {     
                    color: $textShade1;                      
                    margin-right: 20px;
                }
                &:hover {
                    +.description_tooltip {
                      top: 145px;
                      left: 107px;
                      opacity: 1;
                      visibility: visible;
                    }
                }
            }
            .resume_box{
                margin-bottom: 15px;
                .file_time{
                    font-size: 12px;
                    color: #74798C;
                    letter-spacing: 0.27px;
                }
            } 
            .description_tooltip {
                position: absolute;
                padding: 7px 10px;
                background-color: #fff;
                border: 1px solid #EAEAEA;
                box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
                z-index: 1;
                right: 0;
                font-weight: 500;
                top: 50px;
                border-radius: 4px;
                opacity: 0;
                visibility: hidden;
                word-break: break-word;
                width: 146px;
        
                &:before {
                  content: '';
                  position: absolute;
                  height: 10px;
                  width: 10px;
                  border-left: 1px solid #EAEAEA;
                  border-top: 1px solid #EAEAEA;
                  top: -5px;
                  background: #fff;
                  border-radius: 2px;
                  right: 68px;
                  transform: rotate(44deg);
                }
              }           
        }

        .rightSide {
            width: 50%;
            padding: 0;
            word-break: break-all;
        }

        :global(.row) {
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .label {
            color: $textShade1;
        }
        .value {
            font-size: 16px;
            font-weight: 500;
            word-break: break-word;
        }

        .userName {
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
            color: $themeColor11;
        }
        .user_designation {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            color: $primaryText;
            margin-top: 3px;
        }
        .user_degree {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: $primaryText;
        }
        .user_address {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: $primaryText;
            margin-top: 3px;
        }
        .email {
            margin: 5px 0 0 0;
            font-weight: 500;
            align-items: center;
            color: $textShade1;

            span {
                margin: 0 0 0 5px;
            }
        }
        .socialBox {
            margin: 10px 0 35px 0;
            padding: 0;
            list-style: none;
            @include flexbox;
            li {
                margin: 0 5px 0 0;

                a {
                    color: $textShade1;
                    border:1px solid rgba(184, 180, 204, 0.50);
                    @include border-radius(16px);
                    width: 32px;
                    height: 32px;
                    @include inline-flex;
                    @include justify-content(center);
                    @include align-items(center);

                    &:hover {
                        background: linear-gradient(182deg, $primaryLightColor 0%, $primaryColor 100%);
                        color:$whiteColor;
                        svg {
                            path{ 
                            fill: $whiteColor !important;
                            }
                        }
                    }
                }
                &.resume {
                    a {
                        line-height: 32px;
                        padding: 0 15px;
                        width: auto;

                        span {
                            
                            font-weight: 600;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }    
}

@media only screen and (max-width:767px){
    .user_data{
        .user_img{
            width: 60px;
            height: 60px;
            -moz-flex: 0 0 60px;
            font-size: 22px;
            line-height: 58px;
            @include flex(0 0 60px);
        }
        .user_details{
            flex-wrap: wrap;
            .userName{
                font-size: 16px;
            }
            .user_designation{
                font-size: 14px;
            }
            .user_address{
                font-size: 14px;
                line-height: 20px;
            }
            :global(.gap40){
                height: 0;
            }
            .email{
                margin: 15px 0 0;
                width: calc(100% + 68px);
                margin-left: -68px;
            }
            .leftSide{
                padding-right: 0;
                width: 100%;
            }
            .rightSide{
                width: calc(100% + 68px);
                padding: 0;
                margin-left: -68px;
                margin-top: 20px;
            }
        }
    }
}