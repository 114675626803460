@import "theme";

.quesion_builder_wrapper {
  :global(.custom-checkbox) {
    margin-bottom: 35px !important;
  }
  display: block;
  .filter_box {
    width: 300px;
    margin-top: 20px;
    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322e46;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }
    :global(.tags_wrapper) {
      position: relative;
      border: 0 solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      :global(.select__control) {
        min-height: 46px;
        border-color: #0000001a;
        :global(.select__placeholder) {
          color: #aaa;
          font-size: 15px;
          font-weight: 500;
        }
        :global(.select__indicators) {
          :global(.select__indicator-separator) {
            display: none;
          }
          :global(.select__dropdown-indicator) {
            color: #aaa;
          }
        }
      }
    }
  }
  :global(.box) {
    @include border-radius(8px 8px 0 0);
  }
  .custom_labels {
    padding: 25px 25px 0;
    margin-bottom: 0;
    :global(label) {
      margin-bottom: 0;
    }
  }
}
.quesion_inner_wrapper {
  display: block;
  .add_new_question {
    font-weight: 500;
    font-size: 16px;
    color: #7033ff;
    margin: 25px 25px 0;
    display: inline-block;
    cursor: pointer;
  }
}
// :global(.text_link){
//     font-size: 16px;
//     color: #7033FF;
//     letter-spacing: 0;
//     text-align: center;
//     font-weight: 600;
//     cursor: pointer;
//     &:global(.disabled){
//         pointer-events: none;
//         color: #B8B4CC;
//     }
// }
:global(.error) {
  border: 1px solid #f07575 !important;
}
.quesion_l_wrapper {
  @include flexbox;
  @include align-items(center);
  padding: 30px 20px;
  border: 1px solid #c6c6c6;
  @include border-radius(4px);
  background: rgba($color: #f6f5fa, $alpha: 0.61);
  .quesion_counter {
    font-weight: 600;
    font-size: 22px;
    color: #322e46;
    letter-spacing: 0.26px;
    margin-right: 15px;
  }
  .quesion_textbox {
    width: 70%;
    display: flex;
    align-items: center;
    :global(.form-control) {
      border-radius: 4px 0 0 4px;
    }
  }
  .quesion_textbox_dd {
    width: 30%;
    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 46px;
        border-color: rgba(0, 0, 0, 0.1);
        border-radius: 0 4px 4px 0;
        border-left: none;
      }
      :global(.select__indicator-separator) {
        display: none;
      }
      :global(.select__single-value) {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
.answer_l_wrapper {
  margin-top: 15px;
}
.input_text {
  width: 95%;
}
.action {
  @include flexbox;
  margin-left: 10px;
  margin-bottom: 35px;
  > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-left: 10px;
    &.action_plus {
      background: url(../../assets/images/ic_expand.svg) no-repeat center;
    }
    &.action_minus {
      background: url(../../assets/images/ic_expand_dash.svg) no-repeat center;
    }
  }
}
.checkbox_wrapper,
.radio_wrapper {
  @include flexbox;
  @include align-items(center);
  margin-top: 30px;
  ~ .checkbox_wrapper,
  ~ .radio_wrapper {
    margin-top: 10px;
  }
}
.textbox_wrapper {
  display: block;
  margin-top: 30px;
}
.mandtory_field_wrapper {
  @include flexbox;
  @include align-items(center);
  margin-top: 30px;
}
.other_field_wrapper {
  @include flexbox;
  @include align-items(flex-start);
  flex-direction: column;
  margin-top: 30px;
  .other_text_box {
    width: 70%;
    padding-left: 38px;
    margin-top: 10px;
    :global(.form-label) {
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
}
.cmt_with_attach_field_wrapper {
  @include flexbox;
  margin-top: 30px;
  flex-direction: column;
  .attchment_wrapper {
    padding-left: 38px;
    margin-top: 10px;
  }
  .cb_box_wrapper {
    @include flexbox;
    margin-top: 10px;
    :global(.custom-checkbox) {
      margin-bottom: 0;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.multi_cb_wrapper {
  @include flexbox;
  flex-direction: column;
  margin-top: 25px;
  .cb_box_wrapper {
    @include flexbox;
    margin-top: 15px;
    :global(.custom-checkbox) {
      margin-bottom: 0;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    + :global(.custom-checkbox) {
      margin-top: 15px;
    }
  }
}
.category_title_wrapper {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  width: 100%;
  margin-bottom: 5px;
  .modal_text {
    font-weight: 500;
    font-size: 16px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
  .dd_title {
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
  }
}
.quesion_category_wrapper {
  margin-top: 30px;
  .category_title_wrapper {
    width: 70%;
  }
  :global(.custom-default-dd-box) {
    width: 70%;
    max-width: 100%;
    :global(.select__control) {
      min-height: 50px;
      border-color: rgba(0, 0, 0, 0.1);
    }
    :global(.select__indicator-separator) {
      display: none;
    }
    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.scale_field_wrapper {
  margin-top: 30px;
  .scale_dropdown_wrapper {
    position: relative;
  }
  .scale_dd_topbar {
    padding: 10px;
    height: 50px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    cursor: pointer;
    h5 {
      font-weight: 500;
      letter-spacing: 0.36px;
    }
    svg {
      * {
        fill: hsl(0, 0%, 80%);
      }
    }
  }
  .scale_dd_menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 110%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    z-index: 1;
    overflow: hidden;
    .scale_dd_list {
      max-height: 300px;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      overflow-y: auto;
      li {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        @include transition(all, 0.4s);
        padding: 12px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        &:hover,
        &:global(.active) {
          background-color: #fcfaff;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
      .item_left_content {
        width: 90%;
        cursor: pointer;
        h5 {
          font-weight: 500;
        }
        span {
          font-size: 12px;
          color: #928fa0;
          letter-spacing: 0.27px;
        }
      }
      .item_right_content {
        font-weight: 500;
        font-size: 12px;
        color: #7033ff;
        letter-spacing: 0.27px;
        cursor: pointer;
      }
    }
  }
}
.que_btn_group {
  @include flexbox;
  margin-top: 30px;
  :global(.btn) {
    min-width: 120px;
    font-weight: 600;
    font-size: 14px;
    margin-right: 15px;
  }
}

.save_btn {
  display: block;
  margin-top: 30px;
  padding-left: 25px;
  :global(.btn) {
    min-width: 120px;
    font-weight: 600;
    font-size: 14px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .quesion_l_wrapper {
    flex-direction: column;
    .quesion_textbox {
      width: 100%;
    }
    .quesion_textbox_dd {
      width: 100%;
      margin-top: 5px;
      :global(.custom-default-dd-box) {
        :global(.select__control) {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
