@import "theme";

.roll_based_skill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.define_admin_roles_wrapper {
  padding-bottom: 10px;
  .fg_text_box {
    width: 320px;
  }
  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
    .dd_note {
      font-size: 14px;
      font-weight: normal;
    }
  }

  :global(.form-control) {
    min-height: 47px;
  }
  .form_option_list {
    list-style: none;
    padding-left: 0;
    margin: 10px 0;
    @include flexbox;
    &:global(.limit-reached) {
      :global(.custom-checkbox) {
        :global(label) {
          color: #f07575;
        }
      }
    }
    :global(.custom-checkbox) {
      margin-bottom: 10px;
      cursor: auto;
      input {
        &:disabled {
          + :global(label) {
            &:after {
              background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
              background-size: 100%;
              -ms-background-size: 100%;
              -moz-background-size: 100%;
            }
          }
          &:checked {
            + :global(label) {
              &::after {
                background-image: url(../../../assets/images/ic_radio_active_2.svg);
              }
            }
          }
        }
      }
      :global(label) {
        font-size: 14px;
        padding-left: 30px;
        line-height: 20px;
        cursor: pointer;
        &::before {
          height: 20px;
          width: 20px;
        }
        &:after {
          height: 20px;
          width: 20px;
          background-image: url(../../../assets/images/ic_radio_active_2.svg);
        }
      }
    }
    li {
      &:last-child {
        :global(.custom-checkbox) {
          margin-bottom: 0;
          margin-left: 20px;
        }
      }
    }
  }
  :global(.dropdown-multiselect) {
    :global(.form-group) {
      margin-right: 50px;
    }
    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      width: 280px;
      margin-top: 10px;
      :global(.multi-select) {
        :global(.dropdown-container) {
          border-color: rgba(0, 0, 0, 0.1);
          height: 47px;
          :global(.dropdown-heading) {
            height: 100%;
            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 15px;
            }
            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }
        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }
            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }
              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }
                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }
            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;
              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }
            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }
      :global(.multiselect-container) {
        width: 280px;
        height: 47px;
      }
      :global(.custom-default-dd-box) {
        :global(.select__control) {
          min-height: 47px;
          border-color: rgba(0, 0, 0, 0.1);
        }

        :global(.select__indicator-separator) {
          display: none;
        }

        :global(.select__single-value) {
          font-size: 16px;
        }
      }
    }
  }
}

.saveBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.save_box_left {
  :global(.btn) {
    min-width: 150px;
    font-size: 14px;

    :global(strong) {
      font-weight: 600;
    }
  }
}

.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;

  span {
    color: #928fa0;
    font-weight: 500;
  }
}

.save_box_center {
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;

  span {
    color: #928fa0;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1024px) {
  .roll_based_skill_wrapper {
    display: block;
    width: 100%;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .define_admin_roles_wrapper {
    .fg_text_box {
      padding: 15px 15px 0;
    }
    :global(.dropdown-multiselect) {
      display: block !important;
      padding: 15px 15px 0;
      margin-bottom: 0;
      :global(.form-group) {
        width: 100%;
        margin-bottom: 15px;
        > * {
          width: 100%;
        }
      }
    }
  }
}
