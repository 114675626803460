@import "../../../../../theme";

.mHeader {
  display: block;
  padding: 0;

  :global(.modal-title) {
    font-weight: 600;
    font-size: 20px;
    color: #37324b;
    letter-spacing: 0.26px;

    :global(span) {
      font-family: 500;
      display: block;
      font-size: 16px;
      color: #928fa0;
      letter-spacing: 0.36px;
    }
  }
}

.mBody {
  position: relative;
  padding-top: 25px !important;

  :global(.box-light-gradient) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.heading {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  padding: 20px 25px;
  position: relative;
  margin: 0 25px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .heading_left {
    @include flexbox;
    @include align-items(flex-start);
    text-align: left;
    width: 75%;

    h3 {
      margin-bottom: 0;
    }
  }

  .heading_right {
    text-align: right;
  }
}

// Process indicate
.idp_process_indicate {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  margin-top: 3px;
  margin-right: 10px;

  .idp_step {
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    border: 1px solid $textShade3;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: relative;

    + .idp_step {
      margin-left: 8px;
    }

    .step1,
    .step2,
    .step3 {
      width: 16px;
      height: 16px;
      @include flexbox;
      @include border-radius(50%);
      background: $textShade1;
      @include transition(all, 0.2s, ease);
    }

    &:hover .step,
    .active {
      width: 14px;
      height: 14px;
    }

    .step1 {
      background: #6dd400;
    }

    .step2 {
      background: #ffcc40;
    }

    .step3 {
      background: #f07575;
    }
  }
}

.idp_heading_wrapper {
  font-weight: 500;
  font-size: 20px;
  color: #322e46;
  word-break: break-word;
  letter-spacing: 0.45px;
}

// Heading right
.idp_process_complete {
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: $primaryText;
}

.idp_end_days {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.36px;
  color: $textShade1;
}

.mObjective_wpr {
  display: inline-block;
  width: 100%;
  padding: 0 25px;

  .mObjective_list {
    display: inline-block;
    width: 100%;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    :global(.custom-checkbox) {
      margin-bottom: 0;
    }

    :global(.list-of-objective) {
      :global(li) {
        background-position: 39px 10px;
        padding-top: 10px;
        padding-left: 65px;
        border: none;

        :global(span) {
          color: #928fa0;
        }
      }
    }
  }
}

//key resultComment modal

.key_result_title {
  letter-spacing: 0.23px;
  margin-bottom: 20px;
  font-weight: 600;
}

.activites_item {
  position: relative;
  padding: 0px 25px;
  display: block;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }

  .user_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $primaryColor;
    color: $whiteColor;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-block;
    margin-right: 10px;
    line-height: 38px;
    vertical-align: top;

    img {
      width: auto;
      height: 100%;
    }
  }

  .activites_data {
    color: $primaryText;
    font-size: 16px;
    font-weight: 400;
    width: 92%;
    display: inline-block;

    span {
      font-weight: 600;
    }

    .activites_hours {
      color: $textShade1;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.reject_comment_wrapper {
  position: relative;
  margin-top: 20px;

  :global(.view_comment_box_row) {
    ~ .reject_cb_box {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
  }

  .reject_cb_box {
    padding-top: 20px;

    :global(.fg-comment-wrapper) {
      :global(.textarea-wrapper) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .heading {
    padding: 10px;

    .heading_left {
      h3 {
        font-size: 16px;
      }
    }
  }

  .idp_process_complete {
    font-size: 18px;
  }
}
