@import "theme";

.test {
  background-color: white;
}

.noteBox {
  display: flex;
  background-color: #f9f8fc;
  color: #928fa0;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 66px;
  margin: auto;
  padding: 10px;
  margin-bottom: 2%;
  box-shadow: none !important;
}

.campaign_quiz_instructions {
  :global(.form-control) {
    &:hover,
    &:focus {
      border-color: #928fa0;
    }
  }

  .dd_title {
    margin-left: 25px;
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  .desc_box {
    width: 70%;
    margin-left: 25px;
    font-size: 16px;
    font-weight: 500;
    // outline: none;
  }
}

:global(.box) {
  :global(.box-inner-pad) {
    &.custom_form_ctrl_wrapper {
      > *:not(.select_que_btn):not(a):not(.import_que_btn) {
        // margin-bottom: 30px; // problem line
        position: relative;
      }

      .sample_que_btn,
      .import_que_btn {
        margin-left: 15px;
      }
    }

    :global(.form-group) {
      margin-bottom: 30px;
    }
  }
}

.location_wise_filter_wrapper {
  padding: 25px;

  .filter_top_bar {
    @include flexbox;
    position: relative;
    margin-top: 25px;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .delete {
      position: absolute;
      top: -25px;
      right: 20px;
      cursor: pointer;
      margin-top: 40px;
      margin-left: 1%;

      * {
        @include transition(0.4s all);
        width: 10px;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }

    .filter_box {
      .dd_title {
        font-weight: 600;
        font-size: 16px;
        color: #322e46;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        display: block;
      }

      :global(.tags_wrapper) {
        position: relative;
        border: 0 solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: auto;
        transition: height 0.5s;

        :global(.multi-select) {
          :global(.dropdown-container) {
            border-color: rgba(0, 0, 0, 0.1);
            height: 47px;

            :global(.dropdown-heading) {
              height: 100%;

              :global(.dropdown-heading-value) {
                font-weight: 500;
                font-size: 15px;
              }

              :global(.dropdown-heading-dropdown-arrow) {
                padding-top: 0 !important;
              }
            }
          }

          :global(.select-panel) {
            :global(.select-item) {
              margin-bottom: 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);

              :global(span) {
                width: 90%;
                vertical-align: top;
                position: relative;
              }

              :global(.item-renderer) {
                input[type="checkbox"] {
                  opacity: 0;
                }

                :global(span) {
                  position: relative;

                  &::after {
                    content: "";
                    transform: scale(0);
                    transition: 0.2s linear all;
                    cursor: pointer;
                    left: -16px;
                    position: absolute;
                    top: 0;
                    background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                    width: 17px;
                    height: 17px;
                    opacity: 0;
                    border-radius: 4px;
                    background-size: 9px;
                  }

                  &::before {
                    border: 1px solid $textShade1;
                    content: "";
                    cursor: pointer;
                    display: inline-block;
                    height: 17px;
                    left: -16px;
                    position: absolute;
                    transition: 0.2s linear all;
                    width: 17px;
                    background-color: $whiteColor;
                    border-radius: 4px;
                  }
                }
              }

              &:global(.selected) {
                :global(.item-renderer) {
                  :global(span) {
                    &::after {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }
                }
              }
            }

            :global(li) {
              :global(label) {
                width: 100%;
                margin-bottom: 0;

                &:global(.selected),
                &:hover {
                  background-color: #fcfaff;
                }
              }

              &:last-child {
                :global(label) {
                  border-bottom: none;
                }
              }
            }
          }
        }

        :global(.select__control) {
          min-height: 46px;
          border-color: rgba(0, 0, 0, 0.1);

          &:global(.select__control--is-disabled) {
            background: white;
          }

          :global(.select__placeholder) {
            color: #aaa;
            font-size: 15px;
            font-weight: 500;
          }

          :global(.select__indicators) {
            :global(.select__indicator-separator) {
              display: none;
            }

            :global(.select__dropdown-indicator) {
              color: #aaa;
            }
          }
        }
      }

      :global(.date_picker_time_wrapper) {
        padding: 0px !important;

        :global(.form-group) {
          width: 100% !important;
        }
      }

      :global(.multi-select) {
        :global(.dropdown-heading) {
          min-height: 44px;
        }
      }
    }

    .save_filter {
      position: absolute;
      right: 20px;
      bottom: 15px;
    }
  }

  .add_location_set_text {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    color: #7033ff;
    letter-spacing: 0;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.custom_form_ctrl_wrapper {
  display: block;

  :global(div[id*="multi-select-search-dd-box"]) {
    :global(.searchBox) {
      font-size: 16px;
      font-weight: 500;

      &::placeholder {
        color: #928fa0;
      }
    }

    > div:first-child {
      > span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  :global(.form-group) {
    margin-bottom: 0px;

    :global(.tags_wrapper) {
      :global(.form-control) {
        width: 100%;
        border: 1px solid #ced4da;
        text-align: left;
        font-weight: 500;
        font-size: 16px;

        &:hover {
          border-color: #928fa0;
          box-shadow: none;
        }

        &:disabled {
          box-shadow: none;
          background-color: #fbfbfb;
          color: #928fa0;
          border-color: #e1e1e1;
        }

        &:global(.limit-reached) {
          border: 1px solid #f07575;
        }
      }
    }

    :global(.form-control) {
      width: 70%;
      font-weight: 500;
      font-size: 16px;

      &:disabled {
        box-shadow: none;
        background-color: #fbfbfb;
        color: #928fa0;
        border-color: #e1e1e1;
      }

      &:global(.limit-reached) {
        border: 1px solid #f07575;
      }
    }

    :global(label) {
      font-size: 16px;
    }

    :global(.text-muted) {
      font-size: 14px;
      color: #322e46;
      letter-spacing: 0.32px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  :global(.radio-wrapper) {
    align-items: flex-end;
    flex-wrap: wrap;

    :global(.form-group) {
      margin-bottom: 25px;

      :global(label) {
        margin-bottom: 0;
      }
    }

    .form_option_list {
      list-style: none;
      padding-left: 0;
      margin: 10px 0;

      &:global(.limit-reached) {
        :global(.custom-checkbox) {
          :global(label) {
            color: #f07575;
          }
        }
      }

      :global(.custom-checkbox) {
        margin-bottom: 10px;
        cursor: auto;

        input {
          &:disabled {
            + :global(label) {
              &:after {
                background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
                background-size: 100%;
                -ms-background-size: 100%;
                -moz-background-size: 100%;
              }
            }

            &:checked {
              + :global(label) {
                &::after {
                  background-image: url(../../../assets/images/ic_radio_active_2.svg);
                }
              }
            }
          }
        }

        :global(label) {
          font-size: 14px;
          padding-left: 30px;
          line-height: 20px;
          cursor: pointer;

          &::before {
            height: 20px;
            width: 20px;
          }

          &:after {
            height: 20px;
            width: 20px;
            background-image: url(../../../assets/images/ic_radio_active_2.svg);
          }
        }
      }

      li {
        &:last-child {
          :global(.custom-checkbox) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  :global(.dropdown-multiselect) {
    :global(.form-group) {
      width: 300px;
    }

    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      width: 100%;
      margin-top: 10px;

      :global(.multi-select) {
        :global(.dropdown-container) {
          border-color: rgba(0, 0, 0, 0.1);
          height: 47px;

          :global(.dropdown-heading) {
            height: 100%;

            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.36px;

              :global(.gray) {
                color: $inputColor;
              }
            }

            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }

        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }

            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }

              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;

                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }

                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }

            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }

          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;

              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }

            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }

      :global(.sub-disabled) {
        :global(.dropdown-container) {
          background-color: #fbfbfb;
          border-color: #e1e1e1;
          height: 47px;

          :global(.dropdown-heading) {
            height: 100%;

            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.36px;
              color: #928fa0;

              :global(.gray) {
                color: #928fa0;
              }
            }

            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }

        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }

            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }

              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;

                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }

                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }

            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }

          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;

              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }

            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }

      :global(.limit-reached) {
        :global(.dropdown-container) {
          border-color: #f07575;
          height: 47px;
          box-shadow: none;

          :global(.dropdown-heading) {
            height: 100%;

            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.36px;

              :global(.gray) {
                color: $inputColor;
              }
            }

            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }

        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }

            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }

              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;

                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }

                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }

            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }

          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;

              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }

            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  :global(.tags_wrapper) {
    position: relative;
    border-radius: 4px;
    height: auto;
    transition: height 0.5s;
    width: 300px;
    margin-top: 15px;
  }

  .cycle_calendars {
    &.quarterly_assessment {
      padding: 30px 0px 0px;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    :global(.form-group) {
      display: block;

      &:global(.calendar_outer_wrapper) {
        width: 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0px;
        }

        :global(label) {
          width: 100%;
        }

        :global(.calendar_wrapper) {
          width: 225px;
          margin-right: 20px;
          display: inline-block;

          &:last-child {
            margin-right: 0;
          }

          :global(.react-datepicker-wrapper) {
            width: 100%;
          }

          :global(.form-control) {
            width: 100%;
          }
        }
      }
    }
  }

  .select_que_btn {
    padding: 10px 10px;
    display: inline-block;
  }

  .cycle_btn_submit {
    padding: 10px 20px;
    min-width: 150px;
  }

  &.active_cycle_wrapper {
    .cycle_calendars {
      @include flexbox;

      :global(.form-group) {
        &:global(.calendar_outer_wrapper) {
          width: auto;
        }
      }
    }

    :global(.radio-wrapper) {
      .form_option_list {
        @include flexbox;
        margin-top: 10px;

        li {
          margin-left: 20px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.file_info {
  cursor: pointer;
  flex: 2;
  padding: 10px 35px 10px 20px;
  display: inline-flex;
  max-width: 272px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #e0dfe2;
  font-size: 14px;
  color: #7033ff;
  letter-spacing: 0;
  border-radius: 20px;
  position: relative;
  font-weight: 700;

  > .delete_item {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 8px;
  }
}

.file_info_published {
  cursor: pointer;
  flex: 2;
  padding: 10px 25px 10px 25px;
  display: inline-flex;
  max-width: 272px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #e0dfe2;
  font-size: 14px;
  color: #7033ff;
  letter-spacing: 0;
  border-radius: 20px;
  position: relative;
  font-weight: 700;
}

@media only screen and (min-width: 1025px) {
  .filter_box {
    width: 20%;
    margin: 0 3% 20px 0;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  form {
    > .custom_form_ctrl_wrapper {
      &:nth-child(2) {
        padding: 0;

        .cycle_calendars {
          :global(.form-group) {
            &:global(.calendar_outer_wrapper) {
              margin-bottom: 0;
              padding: 25px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

              :global(.calendar_wrapper) {
                width: 48%;
                margin-right: 4%;

                + :global(.calendar_wrapper) {
                  margin-right: 0;
                }
              }

              .select_que_btn {
                margin: 20px 0 0;
              }

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }

        .file_info {
          margin-left: 0;
          max-width: 100%;
          top: 0;
          margin-top: 15px;
        }
      }

      &:nth-child(3) {
        &.active_cycle_wrapper {
          padding: 0;

          .cycle_calendars {
            display: block;

            :global(.form-group) {
              &:global(.calendar_outer_wrapper) {
                width: 100%;
                padding: 25px !important;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                margin-bottom: 0;
              }
            }

            :global(.radio-wrapper) {
              width: 100%;
              padding: 25px;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            }
          }
        }
      }

      .cycle_btn_submit {
        margin-left: 25px;
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .filter_box {
    width: 32%;
    margin: 0 1.33% 20px 0;
  }

  .noteBox {
    height: auto;
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .noteBox {
    height: auto;
    font-size: 12px;
    margin: 3%;
  }

  .filter_box {
    width: 36%;
    margin: 0 4% 15px 0;
  }
  :global(.box) {
    :global(.box-inner-pad) {
      &.custom_form_ctrl_wrapper {
        > *:not(.select_que_btn):not(a) {
          margin-bottom: 30px;
          position: relative;
        }

        .select_que_btn,
        .sample_que_btn,
        .import_que_btn {
          margin-bottom: 30px;
          width: 95%;
          margin-left: 2.5%;
        }

        .select_que_btn {
          margin-left: 2.5%;
          width: 95%;
        }
      }
    }
  }

  form {
    > .custom_form_ctrl_wrapper {
      &:first-child {
        padding: 0;

        > :global(.form-group) {
          width: 100%;
          padding: 15px;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          margin-bottom: 0;

          > *,
          a > button {
            width: 100%;
          }
        }

        :global(.dropdown-multiselect) {
          display: block !important;
          padding: 15px 15px 0;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          margin-bottom: 0;

          :global(.form-group) {
            width: 100%;
            margin-bottom: 15px;

            > * {
              width: 100%;
            }
          }
        }

        .active_cycle_wrapper {
          .cycle_calendars {
            :global(.form-group) {
              &:global(.calendar_outer_wrapper) {
                width: 100%;
                padding: 15px;
                margin-bottom: 0;

                :global(.calendar_wrapper) {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        .cycle_calendars {
          :global(.form-group) {
            &:global(.calendar_outer_wrapper) {
              padding: 15px;

              :global(.calendar_wrapper) {
                width: 100%;
                margin-right: 0%;
                margin-bottom: 15px;

                + :global(.calendar_wrapper) {
                  margin-bottom: 0;
                }
              }

              .select_que_btn {
                margin: 15px 0 0;
              }
            }
          }
        }
      }

      .file_info {
        display: block;
        margin-left: 2.5%;
        max-width: 95%;
        top: 0;
        margin-top: 15px;
      }

      .file_info_published {
        display: block;
        margin-left: 2.5%;
        max-width: 95%;
        top: 0;
        margin-top: 15px;
      }

      .select_que_btn {
        margin-left: 0;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .location_wise_filter_wrapper .filter_top_bar {
    padding: 20px 20px 40px 20px;

    .save_filter {
      right: unset;
    }

    .filter_box {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  :global(.box) {
    :global(.box-inner-pad) {
      &.custom_form_ctrl_wrapper {
        > *:not(.select_que_btn):not(a):not(.import_que_btn) {
          // margin-bottom: 30px;
          position: relative;
        }

        .import_que_btn {
          margin-right: none;
        }
      }
    }
  }
}
