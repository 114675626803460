
.help_user{
  width: 40px;
  -moz-flex: 0 0 40px;
  flex: 0 0 40px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  display: inline-flex;
  img{
    width: auto;
    height: 100%;
  }
}
.helptext_wrapper{
  background: #FFFFFF;
  border: 1px solid rgba(184,180,204,0.50);
  border-radius: 4px;
  width: 91%;
  display: inline-flex;
  vertical-align: top;
  height: 40px;
  .helptext_box{
    width: 90%;
    display: inline-flex;
   input[type="text"]{
      border: 0px solid #fff;
      padding: 0px 0px 0px 10px;
      width: 100%;
    }
  }
  .help_icon{
    display: inline-flex;
    vertical-align: top;
    margin: 9px 10px 0px 10px ;
  }
}
.no_diff_planstxt{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: #928FA0;
  text-align: center;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 15px;
  border-radius: 4px;
}