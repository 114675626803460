.tooltip_wrapper {
  padding: 10px;
  .name {
    color: #37324b;
    font-size: 15px;
  }
  .rating {
    font-size: 15px;
    font-weight: 600;
  }
}
:global(.recharts-surface) {
  :global(.recharts-curve) {
    cursor: pointer !important;
    position: visible !important;
  }
  :global(.recharts-layer) {
    :global(.recharts-dot.recharts-line-dot) {
      cursor: pointer !important;
      pointer-events: visible !important ;
    }
  }
}
