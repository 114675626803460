@import "theme";
.objectiveDescription{
  white-space: pre-wrap;
}
.mng_obj_notification {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
  margin: 0px 0px 20px 0px;
  .notify_icon {
    position: relative;
    top: -2px;
  }
  .reject_comment_wrapper {
    margin-top: 0;
    margin-left: 25px;
    :global(.view_comment_box_row) {
      ~ .reject_cb_box {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
    }
    .reject_cb_box {
      padding-top: 20px;
      :global(.fg-comment-wrapper) {
        :global(.textarea-wrapper) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .btn_group {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    padding-left: 25px;
  }
  span {
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }
  .note_txt {
    font-weight: 500;
    color: #928fa0;
    letter-spacing: 0.32px;
    margin: 0;
  }
  p {
    width: 96%;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
  }
  button {
    min-width: 127px;
    height: 40px;
    margin-right: 20px;
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 127px;
    height: 40px;
  }
}
.percentage_weightage_wrapper {
  position: absolute;
  right: 10px;
  width: 16%;
  top: 10px;
  text-align: right;
  > * {
    width: 100% !important;
  }
  .weightage {
    font-weight: 500;
    font-size: 13px;
    color: #74798c;
    display: inline-block;
  }
}
.assignobjectives_wrapper {
  .assign_objective_duration {
    color: $themeColor11;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    span {
      color: $textShade1;
      font-size: 14px;
      font-weight: 500;
    }
    .status_wrapper {
      .status_color_red {
        width: 12px;
        height: 12px;
        background-color: $themeColor1;
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
      }
      .status_color_green {
        width: 12px;
        height: 12px;
        background-color: $themeGreen;
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
      }
      .status_color_yellow {
        width: 12px;
        height: 12px;
        background-color: $themeYellow;
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
      }
      .remaing_days {
        font-size: 16px;
        color: $textShade1;
        font-weight: 500;
      }
    }
  }
  .keyresult_wrapper {
    .keyresult_item {
      background: rgba(255, 255, 255, 0.61);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      font-size: 16px;
      color: $themeColor11;
      letter-spacing: 0.36px;
      padding: 15px; //15px each instead of 25px 15px
      font-weight: 600;
      margin-bottom: 10px;
      position: relative;
    }
    &:last-child {
      .keyresult_item {
        margin-bottom: 0;
      }
    }
  }
  .align_list {
    color: $primaryText;
    font-size: 14px;
    font-weight: 600;
    line-height: 31px;
    margin-bottom: 7px;
  }
  .align_infoneers {
    font-weight: 700;
    div {
      display: inline-flex;
      margin-right: 20px;
    }
  }

  .avatar_list {
    .avatar_container {
      //   width: 200px;
      padding-left: 10px;
    }

    .avatar {
      cursor: pointer;
      position: relative;
      background: $primaryColor;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 2px solid #fff;
      float: left;
      margin-left: -10px;
      transition: all 300ms ease-in-out;
      color: $primaryText;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      box-shadow: 0 1px 2px 0 rgba($color: #000000, $alpha: 0.2);
      .avtar_item {
        height: 100%; //100% instead of 48px
        width: 100%; //100% instead of 48px
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        color: $whiteColor;
        img {
          width: auto;
          height: 100%;
          vertical-align: top;
        }
      }
    }

    .avatar:hover {
      z-index: 1;
      height: 50px;
      width: 50px;
      margin-right: 13px;
    }

    .tooltip {
      position: absolute;
      top: 125%;
      left: 50%;
      width: auto;
      transform: translate(-50%, -50%);
      background: #fff;
      text-align: center;
      padding: 0px 10px;
      opacity: 0;
      border-radius: 2px;
      pointer-events: none;

      transition: all 100ms ease-in-out;
      transition-delay: 50ms;
      font-size: 12px;
      white-space: nowrap;
      height: 30px;
      line-height: 30px;
      border: 1px solid #eee;
    }

    .tooltip:before {
      content: "";
      position: absolute;
      top: -0.4rem;
      left: 50%;
      border-radius: 2px;
      margin-left: -0.5rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      z-index: -1;
      background: #fff;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
    }

    .avatar:hover > .tooltip {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  .header_wrapper {
    height: 60px;
  }
  .owner_img {
    position: absolute;
    width: 78px;
    height: 78px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    border: 2px solid $whiteColor;
    overflow: hidden;
    text-align: center;
    line-height: 74px;
    font-size: 30px;
    font-weight: 700;
    border-radius: 50%;
    color: $whiteColor;
    top: -59px;
    left: 34%;
    img {
      position: relative;
      top: -3px;
      width: 100%;
      height: 100%;
    }
  }
  .owner_detail {
    display: inline-flex;
    margin-top: 40px;
    flex-direction: column;
    width: 100%;
    text-align: center;
    h1 {
      font-size: 18px;
      color: $primaryText;
      font-weight: 600;
    }
    h2 {
      font-size: 16px;
      color: $primaryText;
      font-weight: 400;
      margin: 2px 0;
    }
    h3 {
      font-size: 16px;
      color: #928fa0;
      font-weight: 400;
    }
  }

  .start_end_cal_wrapper {
    .calendar_wrapper {
      background: #ffffff;
      border: 1px solid #b8b4cc;
      border-radius: 4px;
      display: inline-flex;
      width: 228px;
      height: 50px;
      margin-right: 20px;
      padding: 14px;
      span {
        margin-left: 30px;
      }
      .date_picker_wrapper {
        position: relative;
        .date-picker {
          width: 15px;
          height: 15px;
          //  background: $whiteColor url("assets/images/ic_calender_2.svg") no-repeat 5px center;
          border: 0px solid #fff;
          opacity: 0;
          z-index: 1;
          position: absolute;
          border: 1px solid #f00;
          top: -10px;
          left: 2px;
          cursor: pointer;
        }
        .calendar_picker {
          position: absolute;
          top: 0px;
        }
      }
    }
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: $primaryColor;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
  .topic_title {
    font-size: 16px;
    color: $primaryText;
    font-weight: 600;
  }
  .calender_wrapper {
    background: $whiteColor;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: $textShade2;
    font-size: 15px;
    color: $primaryText;
    letter-spacing: 0;
  }
  .duration_txt {
    color: $textShade1;
    font-size: 16px;
    font-weight: 600;
  }

  .no_infoneer_selected_wrapper {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    h1 {
      color: $primaryText;
      font-size: 18px;
      font-weight: 700;
      margin-top: 27px;
    }
    h2 {
      color: $textShade1;
      font-size: 14px;
      font-weight: 400;
      margin-top: 3px;
    }
  }
  .settings_icon {
    position: absolute;
    right: 23px;
    top: 0;
    z-index: 1;
    :global(.dropdown-toggle) {
      svg {
        g {
          g {
            fill: $textShade2;
          }
        }
      }
      &:hover {
        svg {
          g {
            g {
              fill: $primaryColor;
            }
          }
        }
      }
    }
    :global(.dropdown-item) {
      padding: 0px;
      color: $primaryText;
      a {
        padding: 6px 16px;
        color: $primaryText;
        :global(.dropdown_icon) {
          display: inline-flex;
          svg {
            g {
              g {
                fill: $primaryText;
              }
            }
          }
        }
        &:hover {
          color: $primaryColor;
          :global(.dropdown_icon) {
            display: inline-flex;
            svg {
              g {
                g {
                  fill: $primaryColor;
                }
              }
            }
          }
        }
      }
      &.setting_delete {
        padding: 6px 16px 3px 16px;
        :global(.dropdown_icon) {
          display: inline-flex;
          svg {
            g {
              g {
                fill: $primaryText;
              }
            }
          }
        }
        .dropdown_delete {
          line-height: 25px;
        }
      }

      &:hover {
        color: $primaryColor !important;
        :global(.dropdown_icon) {
          svg {
            g {
              g {
                fill: $primaryColor;
              }
            }
          }
        }
      }
    }
    .dropdown_threeitem {
      padding: 6px 16px;
      .dropdown_item {
        line-height: 20px;
      }
    }
  }
  .activites_wrapper {
    .activites_data_wrapper {
      .activites_item {
        display: block;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0px;
        }
        .user_icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $primaryColor;
          color: $whiteColor;
          overflow: hidden;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          align-items: center;
          justify-content: center;
          display: inline-block;
          margin-right: 10px;
          line-height: 38px;
          vertical-align: top;
          img {
            width: auto;
            height: 100%;
          }
        }
        .activites_data {
          color: $primaryText;
          font-size: 16px;
          font-weight: 400;
          width: 92%;
          display: inline-block;
          span {
            font-weight: 600;
          }
          .activites_hours {
            color: $textShade1;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      :global(.viewall) {
        margin: 20px 0 0;
        display: inline-block;
        font-size: 14px;
        cursor: pointer;
        color: #7033ff;
        font-weight: 500;
      }
      :global(.activities) {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 500px;
        overflow-x: hidden;
      }
      :global(.show-scroll) {
        overflow-y: auto;
      }
    }
  }
  .right_hours {
    font-size: 16px;
    font-weight: 600;
    color: #37324b;
    .total_duration {
      font-size: 13px;
      color: #928fa0;
      display: block;
    }
  }
}
.key_values {
  font-weight: 500;
  display: block;
  margin-top: 0; //0 instead of 5px
  font-size: 13px;
  color: #74798c;
  &.Key_n_value {
    display: inline-block;
    margin-right: 20px;
    padding-left: 12px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #b5afca;
      left: 0;
      top: 6px;
    }
    > * {
      display: inline-block;
    }
    + * {
      + * {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
.key_description {
  margin: 0px;
  display: inline-block;
  width: 84%;
}
.key_percentage {
  display: inline-block;
  vertical-align: top;
  width: 12%;
  text-align: right;
  margin-top: 5px;
}
:global(.radio_1on1_wrapper) {
  // :global(.form-group){
  //     :global(label){
  //         margin-bottom: 0;
  //     }
  // }
  .form_option_list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
    :global(.custom-checkbox) {
      margin-bottom: 0;
      cursor: auto;
      input {
        &:disabled {
          + :global(label) {
            &:after {
              background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
              background-size: 100%;
              -ms-background-size: 100%;
              -moz-background-size: 100%;
            }
          }
        }
      }
      :global(label) {
        font-size: 14px;
        padding-left: 30px;
        line-height: 20px;
        cursor: pointer;
        &::before {
          height: 20px;
          width: 20px;
        }
        &:after {
          height: 20px;
          width: 20px;
          background-image: url(../../../assets/images/ic_radio_active_2.svg);
        }
      }
    }
    li {
      &:last-child {
        :global(.custom-checkbox) {
          margin-bottom: 0;
        }
      }
    }
  }
}
.key_result_list_wrapper {
  display: block;
  width: 100%;
  margin-top: 25px;
  .key_result_item {
    display: inline-block;
    width: 100%;
    background: rgba(255, 255, 255, 0.61);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .key_result_item_topbar {
      @include flexbox;
      padding: 20px 25px;
      @include justify-content(space-between);
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      span {
        font-size: 16px;
        font-weight: 500;
        width: 25%;
        text-align: right;
      }
    }
    .key_result_item_content {
      padding: 25px;
      position: relative;
      .key_result_rate_wrapper {
        background: rgba(246, 245, 250, 0.61);
        border: 1px solid #e0dfe2;
        border-radius: 10px !important;
        padding: 10px 10px 10px 20px;
        margin-top: 22px;

        .card_ft_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > span {
            font-size: 16px;
            color: #928fa0;
            font-weight: 500;
          }
          .card_action {
            min-width: 230px;
            :global(.custom-default-dd-box) {
              :global(.select__control) {
                min-height: 50px;
              }
              :global(.select__placeholder),
              :global(.select__single-value) {
                font-size: 15px;
                font-weight: 500;
              }
              :global(.select__indicator-separator) {
                display: none;
              }
            }
          }
        }
      }
      .key_stretch_result_info {
        display: inline-block;
        width: 100%;
        > * {
          display: inline-block;
          vertical-align: top;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
        }
        .key_result_text {
          font-weight: 500;
          font-size: 14px;
          color: #928fa0;
          letter-spacing: 0.32px;
          position: relative;
          &:before {
            content: "";
            height: 5px;
            width: 5px;
            display: inline-block;
            background-color: #b5afca;
            border-radius: 50%;
            margin-right: 7px;
            vertical-align: middle;
            position: relative;
            top: -1px;
          }
        }
        .keyresult_value {
          display: block;
          font-weight: 500;
          padding-left: 13px;
          margin-top: 2px;
          > * {
            display: inline-block;
          }
          i {
            margin-right: 5px;
            position: relative;
            top: -1px;
            color: #b5afca;
            font-style: normal;
            font-weight: 500;
          }
          svg {
            margin-right: 5px;
            position: relative;
            top: -1px;
            * {
              stroke: #b5afca;
            }
          }
        }
      }
      :global(.fg-comment-wrapper) {
        :global(.textarea-wrapper) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .btn_group {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    button {
      min-width: 127px;
      height: 40px;
      margin-right: 20px;
    }
  }
}

.view_detail_link {
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
  color: #7033ff;
  letter-spacing: 0;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .assignobjectives_wrapper {
    .owner_detail {
      h2 {
        font-size: 14px;
        margin: 2px 0 4px;
      }
      h3 {
        font-size: 14px;
      }
    }
    .assign_objective_duration {
      text-align: left !important;
      &:nth-child(4) {
        margin-top: 25px;
      }
    }
    .owner_img {
      left: 0;
      right: 0;
      margin: auto;
    }
    .activites_wrapper {
      .activites_data_wrapper {
        .activites_item {
          .activites_data {
            width: calc(100% - 55px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .assignobjectives_wrapper {
    .assign_objective_duration {
      margin-bottom: 15px;
    }
  }
  :global(.notice_image_page) {
    :global(.user_details) {
      padding-right: 30px;
    }
  }
  :global(.date_picker_time_wrapper) {
    :global(.cl_t_wrapper) {
      :global(.calendar_wrapper) {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  .key_description {
    width: 100%;
  }
  .percentage_weightage_wrapper {
    text-align: left;
    width: 100%;
    margin: 10px 0;
    position: static;
  }
  .key_percentage {
    text-align: left;
    margin-top: 0;
  }
}
