@import "theme";

.myskill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .myskill_wrapper {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .myskill_wrapper {
    > :global(.gap30) {
      display: none;
    }
  }
  .res_title_btn {
    text-align: left !important;
    margin-top: 15px;
  }
}
