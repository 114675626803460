@import "../../../../theme.scss";

.assigneepopup_wrapper {
    .modal_header {
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      h1 {
        font-size: 20px;
        font-weight: 600;
        color: $themeColor11;
      }
    }
    .mBody {
        position: relative;
        padding: 20px 25px 25px !important;
        :global(.box-light-gradient) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
        .feedback_wrapper {
            position: relative;
            .label {
                font-size: 16px;
            }
        }
        .managerUL{
          display: flex;
          list-style: none;
          padding-left: 0px;
          flex-wrap: wrap;
          li{
            width: 249px;
            margin-top: 10px;
            .empCode {
              display: block;
            }
          }
        }
      }
}

@media only screen and (max-width: 992px) {
  .managerUL li{
      width: 224px!important;
    }
}
