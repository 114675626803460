@import "theme";

// .subNavigation {
//   background: $whiteColor;
//   @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.05), false);
//   margin: 0 0 30px 0;
//   height: 0px;
//   visibility: visible;
//   opacity: 1;
//   overflow: hidden;
//   &.active{
//   height: 48px;
//   // visibility: visible;
//   // opacity: 1;
//   }
//  }

//  .submenu_transition{
//   transition: height .8s linear .01s ;
//  }

.subNavScroll {
  height: 44px !important;
  > div {
    overflow: hidden !important;
    margin-right: 0 !important;
  }
  .subNav {
    list-style: none;
    @include inline-flex;
    padding: 0;
    margin: 0px 0 0px 0px;
    width: 100%;
    //overflow-x: auto;

    li {
      @include inline-flex;
      float: left;
      &:first-child {
        a {
          margin-left: 0;
        }
      }
      a {
        color: $textShade1;
        display: block;
        text-decoration: none;
        padding: 13px 0;
        margin-left: 35px;
        position: relative;
        font-weight: 500;
        font-size: 13px;
        white-space: nowrap;
        &:global(.blinked_nav_wrapper) {
          margin-left: 55px;
        }
        &:hover {
          color: #203549;

          &:after {
            width: 100%;
          }
        }
        &:global(.active) {
          background: transparent;
          color: $headingColor2;
          font-weight: 600;

          &:after {
            width: 100%;
          }
        }

        &:after {
          background: #7033ff;
          content: "";
          display: inline-block;
          height: 3px;
          margin: auto;
          @include position(absolute, auto, 0, 0, 0);
          @include transition(all, 0.3s, ease);
          width: 0%;
          border-radius: 5px 5px 0 0;
        }
      }

      &:global(.active) {
        a {
          font-weight: 600;
          color: #203549;
          background: transparent;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  .sync_data {
    display: inline-flex;
    font-weight: 600;
  }
}
.blinked_wrapper {
  position: absolute;
  top: 19px;
  right: auto;
  bottom: auto;
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  z-index: 1;
  background: #ccc;

  .blinked {
    background: #82f1dd;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    animation: ripple-2 1.2s linear infinite;
  }
}
.left-to-right li a:after {
  right: inherit;
}
.right-to-left li a:after {
  left: inherit;
}
@keyframes ripple-2 {
  0% {
    box-shadow: 0 0 0 0 rgba(#82f1dd, 0.3), 0 0 0 7px rgba(#82f1dd, 0.3), 0 0 0 8px rgba(#82f1dd, 0.3);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(#82f1dd, 0.3), 0 0 0 8px rgba(#82f1dd, 0.3);
  }
}
