@import "../../theme.scss";
.grade_info_card {
  :global(.card .card_info) {
    width: 99.7%;
    padding: 10px 24px;
  }
  :global(.box-inner-pad) {
    padding: 0px 0px 10px 0px;
  }
  .info_tooltip {
    margin-left: 10px;
    :global(.info-attach-file) {
      color: #322e46;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .gradient_size {
    padding: 20px;
    @include flexbox;
    @include justify-content(flex-end);
    &::before {
      height: 100%;
    }
  }
  .grade_collection {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 1px;
    .grade_card {
      width: 199px;
      height: 200px;
      margin-left: 25px;
      margin-bottom: 24px;
      background-color: #f6f5fa;
      text-align: center;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      flex-direction: column;
      .grade_text {
        display: none;
      }
      @mixin grade_icon {
        width: 78px;
        height: 85px;
        transition: all 0.2s ease-in;
        margin: 30px 0px 4px 0px;
      }
      .grade_a_plus {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_a_plus.svg) no-repeat left center;
      }
      .grade_a {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_a.svg) no-repeat left center;
      }
      .grade_b_plus {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_b_plus.svg) no-repeat left center;
      }
      .grade_b {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_b.svg) no-repeat left center;
      }
      .grade_c {
        @include grade_icon;
        margin-bottom: 1px;
        background: url(../../assets/images/ic_grade_c.svg) no-repeat left center;
      }
    }
    :last-child {
      font-size: 24px;
    }
    :hover {
      background-color: #7033ff;
      cursor: pointer;
      color: #fcfaff;
      .grade_text {
        display: block;
        font-size: 13px;
        margin-top: 2px;
        width: 70%;
      }
      @mixin grade_icon {
        width: 65px;
        height: 70px;
        margin: 12px 0px 2px 0px;
      }
      .grade_a_plus {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_a_plus_on_hover.svg) no-repeat left center;
      }
      .grade_a {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_a_on_hover.svg) no-repeat left center;
      }
      .grade_b_plus {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_b_plus_on_hover.svg) no-repeat left center;
      }
      .grade_b {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_b_on_hover.svg) no-repeat left center;
      }
      .grade_c {
        @include grade_icon;
        background: url(../../assets/images/ic_grade_c_on_hover.svg) no-repeat left center;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .grade_info_card {
    .grade_collection {
      padding-bottom: 20px;
      justify-content: center;
      .grade_card {
        justify-content: center;
        width: 15%;
        height: 130px;
        margin: 0 15px;
        .grade_text {
          display: none;
        }
        @mixin grade_icon {
          margin: 10px 0 4px 0px;
        }
        .grade_a,
        .grade_b,
        .grade_a_plus,
        .grade_b_plus,
        .grade_c {
          @include grade_icon;
        }
      }
      :hover {
        background-color: #7033ff;
        cursor: pointer;
        .grade_text {
          display: block;
          font-size: 10px;
          margin-top: 2px;
        }
        @mixin grade_icon {
          display: none;
        }
        .grade_a,
        .grade_b,
        .grade_a_plus,
        .grade_b_plus,
        .grade_c {
          @include grade_icon;
        }
      }
    }
    .info_tooltip {
      :global(.box-info) {
        + :global(.info-attach-file) {
          left: -154px;
          &::before {
            left: 49px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .grade_info_card {
    .grade_collection {
      padding-bottom: 20px;
      justify-content: center;
      .grade_card {
        background-color: #13bb8d;
        color: white;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin: 0 10px;
        .grade_text {
          display: none;
        }
        @mixin grade_icon {
          display: none;
        }
        .grade_a,
        .grade_b,
        .grade_a_plus,
        .grade_b_plus,
        .grade_c {
          @include grade_icon;
        }
      }
      :hover {
        background-color: #7033ff;
        cursor: pointer;
        .grade_text {
          padding: 5px;
          display: block;
          background-color: white;
          border-radius: 4px;
          color: black;
          position: absolute;
          bottom: 66px;
          box-shadow: 0 2px 4px 0 #e6e6e6;
          width: 112px;
          font-size: 13px;
          margin-top: 2px;
        }
        @mixin grade_icon {
          display: none;
        }
        .grade_a,
        .grade_b,
        .grade_a_plus,
        .grade_b_plus,
        .grade_c {
          @include grade_icon;
        }
      }
    }
    .info_tooltip {
      :global(.box-info) {
        + :global(.info-attach-file) {
          left: -154px;
          &::before {
            left: 49px;
          }
        }
      }
    }
    :global(.card .card_info .card_action) {
      max-width: 45px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .grade_info_card {
    .grade_collection {
      .grade_card {
        width: 40px;
        height: 40px;
      }
    }
    .info_tooltip {
      :global(.box-info) {
        + :global(.info-attach-file) {
          left: auto;
          right: -109px;
          &::before {
            left: 118px !important;
          }
        }
      }
    }
  }
  .grade_info_card {
    .grade_collection {
      justify-content: center;
    }
  }
}
