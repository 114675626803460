.template_selection_btn {
  cursor: pointer;
  flex: 2;
  padding: 10px 35px 10px 20px;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #e0dfe2;
  font-size: 14px;
  color: #7033ff;
  letter-spacing: 0;
  border-radius: 20px;
  position: relative;
  font-weight: 600;
  > .delete_item {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 8px;
  }
}
.select_template_btn {
  padding: 10px 20px;
  height: 40px;
  display: inline-block;
}
