@import "theme";
.note_info_wrapper {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
  margin: 0px 0px 20px 0px;
  .notify_icon {
    position: relative;
    top: -2px;
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }
  .note_txt {
    color: #928fa0;
    letter-spacing: 0.32px;
    margin: 0;
    padding-left: 25px;
    margin-bottom: 5px;
  }
  p {
    width: 96%;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
    margin-bottom: 5px;
  }
  ol {
    li {
      font-weight: 500;
      line-height: 24px;
    }
  }
  :global(.card_action) {
    padding-left: 23px;
    span {
      color: $primaryColor;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
