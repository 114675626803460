@import '../../theme.scss';

.hideRatingBoxLastChild{
    width: 0px !important;
}

.key_result_table_wrapper {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    table {
        border-spacing: 0;
        width: 100%;

        thead {
            background: #FCFAFF;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            @include border-radius(4px 4px 0 0);

            th {
                font-weight: 500;
                font-size: 12px;
                color: $themeColor11;
                letter-spacing: 0.27px;
                padding: 12px 10px 12px 0;

                &:first-child {
                    width: 250px;
                    padding-left: 20px;
                }

                &:last-child {
                    width: 200px;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

                &:last-child {
                    border-bottom: none;
                }
            }

            td {
                font-weight: 500;
                font-size: 13px;
                color: #928FA0;
                letter-spacing: 0.27px;
                padding: 10px 15px 10px 0;

                .key_result_title {
                    position: relative;
                    padding-left: 35px;
                    display: inline-block;
                    color: $themeColor11;
                    font-size: 12px;
                    line-height: 18px;

                    &::before {
                        content: '';
                        position: absolute;
                        background: #B5AFCA;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        left: 20px;
                        top: 6px;
                    }
                }

                .react_select_wrapper {
                    display: inline-block;
                    width: 156px;
                    margin-right: 12px;
                }

                :global(.custom-default-dd-box) {
                    :global(.select__control) {
                        min-height: 34px;
                        border-color: rgba(0, 0, 0, 0.1);
                    }

                    :global(.select__indicator-separator) {
                        display: none;
                    }

                    :global(.select__single-value) {
                        font-size: 12px;
                    }
                }

                .comment_icon {
                    display: inline-block;
                    cursor: pointer;
                }

                .key_achived_tooltip {
                    position: relative;

                    .key_achived_info {
                        position: absolute;
                        border: 1px solid rgba($color:#000000, $alpha:0.1);
                        background-color: $whiteColor;
                        @include border-radius(4px);
                        left: -36px;
                        top: -40px;
                        margin: auto;
                        text-align: center;
                        min-width: 80px;
                        z-index: 1;
                        padding: 5px;
                        opacity: 0;
                        visibility: hidden;
                        @include transition(0.2s all);

                        >* {
                            display: inline-block;
                            vertical-align: middle;

                            &:first-child {
                                margin-right: 5px;

                                svg {
                                    position: relative;
                                    top: -1px;

                                    * {
                                        stroke: #928FA0;
                                    }
                                }
                            }
                        }
                    }

                    >span {
                        cursor: pointer;

                        &:hover {
                            +.key_achived_info {
                                top: -32px;
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                }



            }
        }
    }
}
.stretch_key_result{
    font-weight: 500;
    font-size: 12px;
    color: #928FA0;
    letter-spacing: 0.27px;
    line-height: 18px;
    display: inline-block;
    width: 100%;
    padding-left: 35px
}

@media only screen and (max-width:1024px) {
    .key_result_table_wrapper {
        overflow-y: auto;

        table {
            width: 700px;
        }
    }
}