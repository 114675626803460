@import "theme";

.cycleListWrapper {
  display: block;
  .create_new_btn {
    padding: 10px 20px;
  }
  .box_btn_grp {
    @include border-radius(8px);
    padding: 25px;
    :global(.btn) {
      font-weight: 700;
      padding-left: 25px;
      padding-right: 25px;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .box_2 {
    @include border-radius(8px 8px 0 0);
    margin-bottom: 0;
    padding-bottom: 0;
    :global(.box-heading-wrapper) {
      @include flexbox;
      @include justify-content(space-between);
    }
    .pending_submit_info {
      @include flexbox;
      .pending,
      .submitted {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 10px;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          content: "";
        }
      }
      .pending {
        &:before {
          background-color: #f07575;
        }
      }
      .submitted {
        &:before {
          background-color: #6dd400;
        }
      }
      > span {
        padding-left: 17px;
        @include flexbox;
        flex-direction: column;
        font-weight: 500;
        font-size: 20px;
        color: #3e3266;
        margin-right: 75px;
        > small {
          font-weight: 600;
          letter-spacing: 0.26px;
          font-size: 20px;
          margin-top: 2px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  :global(.outer-table-wrapper) {
    min-height: 300px;
    margin-bottom: 20px;
    .empl_list_filter {
      padding: 15px 25px;
    }
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    .tab_table_responsive {
      margin-bottom: 0;
      .cb_box {
        width: 4%;
      }
      .emp_id {
        width: 6%;
      }
      .emp_name {
        width: 26%;
        position: relative;
        .flag_icon {
          position: absolute;
          left: -15px;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 23px;
          display: none;
        }
        &.emp_flag {
          .flag_icon {
            display: block;
          }
        }
      }
      .manager_list {
        width: 17%;
        &:global(.disabled) {
          * {
            background-color: #fbfbfb;
            border-color: #b8b4cc;
            pointer-events: none;
          }
        }
      }
      .submitted_on {
        width: 10%;
      }
      thead {
        th {
          padding: 10px;
          border: none;
        }
      }
      tbody {
        td {
          padding: 20px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          font-weight: 500;
          font-size: 16px;
          color: #37324b;
          letter-spacing: 0.36px;
          &.emp_id {
            font-weight: 600;
          }
          .tab_team_member_info {
            position: relative;
            .team_member_info {
              position: relative;
              color: $primaryText;
              padding-left: 0;
              @include flexbox;
              @include align-items(center);
              .team_member_img {
                width: 52px;
                height: 52px;
                @include border-radius(50%);
                @include flex(0 0 52px);
                margin-right: 12px;
                overflow: hidden;
                background: #ccc;
                text-align: center;
                line-height: 52px;
                color: #fff;
                font-weight: 700;
                font-size: 18px;
                img {
                  width: 52px;
                  height: 52px;
                  object-fit: cover;
                  position: relative;
                  top: -2px;
                }
              }
              .team_member_name {
                font-size: 16px;
                font-weight: 600;
                color: $themeColor11;
                line-height: 22px;
                letter-spacing: 0.44px;
                a {
                  color: $primaryText;
                  &:hover {
                    color: #a962ff;
                  }
                }
              }
              .team_member_designation {
                font-size: 14px;
                font-weight: normal;
                line-height: 19px;
                color: $primaryText;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .saveBox {
    border-radius: 8px;
    .upper_box_wrapper {
      padding: 0 25px 25px 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .heading {
        font-size: 16px;
        font-weight: 600;
        color: #37324b;
        line-height: 22px;
        letter-spacing: 0.44px;
        margin-bottom: 10px;
      }
      .datepicker_wrapper {
        display: flex;
        .optionalText {
          margin: auto;
          color: #928fa0;
          margin-left: 5px;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }
  .save_box_left {
    padding: 25px 0 0 25px;
    :global(.btn) {
      min-width: 146px;
      font-size: 14px;
    }
  }
}
.objective_status {
  &.managerTooltip {
    position: relative;

    .managerTooltipInfo {
      z-index: 1;
      position: absolute;
      left: -20px;
      width: 180px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px;
      top: 40px;
      opacity: 0;
      visibility: hidden;
      @include transition(0.5s all);
      @include border-radius(4px);
    }

    > span {
      cursor: pointer;

      &:hover {
        + .managerTooltipInfo {
          opacity: 1;
          visibility: visible;
          top: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .cycleListWrapper {
    .custom_table {
      :global(.table-responsive) {
        overflow-x: auto !important;
        .tab_table_responsive {
          width: 1000px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .cycleListWrapper {
    .box_2 {
      :global(.box-heading-wrapper) {
        flex-direction: column;
        align-items: flex-start;
      }
      .pending_submit_info {
        margin-top: 20px;
      }
    }
    .box_btn_grp {
      padding-top: 10px;
      :global(.btn) {
        margin-top: 15px;
        margin-right: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .cycleListWrapper {
    .box_2 {
      .pending_submit_info {
        > span {
          font-size: 18px;
          margin-right: 30px;
          > small {
            font-size: 18px;
          }
        }
      }
    }
    .saveBox {
      .upper_box_wrapper {
        .datepicker_wrapper {
          display: block;
          .optionalText {
            padding-top: 5px;
            margin-left: 0;
          }
        }
      }
    }
    .box_btn_grp {
      padding: 0 15px 15px;
    }
  }
}
