@import '../../../theme';

.skill_master_wrapper{
    :global(.page-title){
      padding-bottom: 5px;
      + :global(span){
        font-weight: 500;
        font-size: 14px;
        color: #928FA0;
        letter-spacing: 0;
      }
    }
    .skill_dd_wrapper{
        display: block;
        .dd_title{
            font-weight:600;
            font-size: 16px;
            color: #322E46;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
            display: block;
        }
        > :global(.box-inner-pad){
            padding-bottom: 5px;
        }
    }
    .saveBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px;
    }
    .save_box_left {
        :global(.btn) {
          min-width: 140px;
          font-size: 14px;
          :global(strong){
              font-weight: 600;
          }
        }
    }
    .save_box_right {
        font-size: 14px;
        line-height: 16px;
        color: #B4B0C8;
        span {
          color: #928FA0;
          font-weight: 500;
        }
    }
    .save_box_center {
        margin: auto;
        font-size: 14px;
        line-height: 16px;
        color: #B4B0C8;
        span {
          color: #928FA0;
          font-weight: 500;
        }
    }
}
.switches {
display: inline;
:global(.custom-switch){
    padding-left: 0;
    :global(.custom-control-input){
    &:focus{
        ~ :global(.custom-control-label){
        &:before{
            border-color: #E9E9EB;
            box-shadow: none;
            outline: none;
        }
        }
    }
    &:checked{
        ~ :global(.custom-control-label){
        &:before{
            background-color: #00C4BC;
            border-color: #00C4BC;
        }
        &:after{
            right: 14px;
            box-shadow: 0 2px 2px 0 rgba(45,45,45,0.20);
            border: none;
        }
        }
    }
    }
    :global(.custom-control-label){
    padding-right: 48px;
    font-size: 0;
    margin-top: 10px;
    &:before{
        left: auto;
        right: 0;
        width:48px;
        height: 28px;
        border-radius: 25px;
        background: #FFFFFF;
        border: 1px solid #E9E9EB;
        top: 0;
    }
    &:after{
        right: 22px;
        left: auto;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        top: 3px;
        background: #FFFFFF;
        border: 1px solid rgba(45,45,45,0.20);
    }
    }
}
}
@media only screen and (max-width: 1024px){
    .skill_master_wrapper{
        display: inline-block;
        width: 100%;
        margin-top: 30px;
    }
}
@media only screen and (max-width:767px){
    .skill_dd_wrapper{
        :global(.row){
            >  :global(div){
                &:last-child{
                    margin-top: 20px;
                }
            }
        }
        .switches {
            :global(.custom-switch){
                :global(.custom-control-label){
                    font-size: 0;
                }
            }
        }
    }
}