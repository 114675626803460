@import "theme";

.mmg_endorsement_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 120px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.role_based_skills_wrapper {
  padding-bottom: 0;
  border-radius: 8px;

  :global(.tab_filter) {
    padding: 25px;
  }

  .roll_based_skill_box_info {
    top: 15px;

    > span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.custom-default-dd-box) {
    :global(.select__control) {
      min-height: 46px;
      border-color: rgba(0, 0, 0, 0.1);
    }

    :global(.select__indicator-separator) {
      display: none;
    }

    :global(.select__single-value),
    :global(.select__placeholder) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .skill_table {
    padding: 25px 25px 0;

    :global(.table) {
      margin-bottom: 0;
      table-layout: fixed;
    }

    thead {
      padding: 0;
      list-style: none;

      th {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        border-top: 0;
        border-bottom-width: 1px;
      }
    }

    tbody {
      td {
        font-size: 15px;
        font-weight: 500;
        color: #37324b;

        .endorse_to,
        .endorse_by {
          color: #74798c;
        }

        .comments {
          position: relative;
        }

        .endorsement_type {
          padding-left: 30px;
        }

        .endorse_tooltip {
          position: relative;

          ~ .endorse_tooltip {
            margin-left: -7px;
          }

          .tool_tip_info {
            position: absolute;
            left: -25px;
            margin: auto;
            background-color: $whiteColor;
            border: 1px solid #eaeaea;
            color: #37324b;
            bottom: -50px;
            padding: 5px 10px;
            border-radius: 4px;
            @include transition(0.2s all);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            min-width: 90px;
            text-align: center;
          }

          > img {
            cursor: pointer;

            &:hover {
              + .tool_tip_info {
                opacity: 1;
                bottom: -45px;
                visibility: visible;
              }
            }
          }
        }

        .comment_txt {
          // width: 90px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // display: inline-block;
          // vertical-align: top;
          font-size: 16px;
          font-weight: 500;
          color: #37324b;
          position: relative;
        }

        .description_tooltip {
          position: absolute;
          padding: 7px 10px;
          background-color: #fff;
          border: 1px solid #eaeaea;
          box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
          z-index: 1;
          right: -20px;
          font-weight: 500;
          top: 50px;
          border-radius: 4px;
          opacity: 0;
          visibility: hidden;
          @include transition(0.4s, all);
          word-break: break-word;
          width: 250px;

          &:before {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-left: 1px solid #eaeaea;
            border-top: 1px solid #eaeaea;
            top: -5px;
            background: #fff;
            border-radius: 2px;
            right: 28px;
            transform: rotate(44deg);
          }
        }

        .more {
          font-weight: 500;
          font-size: 16px;
          color: #7033ff;
          letter-spacing: 0.36px;
          text-align: center;
          cursor: pointer;

          &:hover {
            + .description_tooltip {
              top: 30px;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      tr {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.mmg_search_wrapper {
  @include flexbox;
  @include align-items(center);

  > :global(form) {
    width: 320px;
  }
}

.filter_info_tooltip {
  text-align: left;
  margin-left: 10px;

  :global(.info-attach-file) {
    min-width: 280px;
    left: auto;
    right: -50px;

    &::before {
      right: 54px;
      left: auto;
    }

    :global(.attachment-title) {
      color: #b4b0c8;
    }

    :global(ul) {
      margin: 10px 0;

      :global(li) {
        line-height: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.red) {
    color: #fd6161;
    border-color: rgba($color: #fce6dd, $alpha: 1);
    background-color: #fcf6f4;
  }
}

@media only screen and (max-width: 1024px) {
  .role_based_skills_wrapper {
    :global(.check_in_search_bar) {
      margin-bottom: 0;
    }

    .endorsement_skill_table {
      overflow-x: auto;
      min-height: 200px;

      :global(.table) {
        width: 1110px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .role_based_skills_wrapper {
    :global(.box-inner-pad) {
      :global(div[class*="col-md"]) {
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mmg_endorsement_wrapper {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
  }

  .role_based_skills_wrapper {
    :global(.box-heading-wrapper) {
      :global(.box-heading) {
        width: 88%;
      }
    }

    :global(.box-inner-pad) {
      :global(div[class*="col-md"]) {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
  }

  .filter_info_tooltip {
    :global(.info-attach-file) {
      right: -10px;

      &::before {
        right: 14px;
      }
    }
  }
}
