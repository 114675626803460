@import '../../../../theme';

:global(.custom_modal_popup) {
  .skill_category_modal {
    padding: 0;

    :global(.custom_modal_button) {
      border-top: 0;
      padding: 0 25px 25px;

      :global(.btn) {
        font-weight: 600;
      }
    }

    .mBody {
      position: relative;
      padding: 25px 25px 0;

      p {
        font-weight: 500;
        font-size: 14px;
        color: #A3A0B2;
        letter-spacing: 0.39px;
        padding-top: 10px !important;
      }

      :global(.form-group) {
        width: 320px;
      }
    }

    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322E46;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }

    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value),
      :global(.select__placeholder) {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.mHeader {
  display: block;
  padding: 25px;

  :global(.modal-title) {
    font-weight: 500;
    font-size: 18px;
    color: #37324B;
    letter-spacing: 0.26px;

    span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: #A3A0B2;
      letter-spacing: 0.39px;
    }
  }
}