@import "theme";
.campaign_table_box {
  padding: 25px 0 0 0;

  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    thead {
      th {
        padding: 10px;
        border: none;
      }
    }
    tbody {
      td {
        .ended,
        .saved,
        .submitted {
          font-size: 12px;
          font-weight: 600;
          padding: 4px 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          display: inline-block;
          min-width: 86px;
          text-align: center;
        }
        .submitted {
          color: #814cff;
          background: #f9f7ff;
          border-color: rgba(149, 106, 251, 0.23);
        }
        .ended {
          color: #fd8c61;
          background: #fcf6f4;
          border-color: #fce6dd;
        }
      }
    }
    .tab_team_member_info {
      position: relative;

      .team_member_info {
        position: relative;
        color: $primaryText;
        padding-left: 0;
        @include flexbox;
        @include align-items(center);

        .team_member_img {
          width: 52px;
          height: 52px;
          @include border-radius(50%);
          @include flex(0 0 52px);
          margin-right: 12px;
          overflow: hidden;
          background: #ccc;
          text-align: center;
          line-height: 52px;
          color: #fff;
          font-weight: 700;
          font-size: 18px;

          img {
            width: 52px;
            height: 52px;
            object-fit: cover;
            position: relative;
            top: -2px;
          }
        }

        .team_member_name {
          font-size: 16px;
          font-weight: 600;
          color: $themeColor11;
          line-height: 22px;
          letter-spacing: 0.44px;

          a {
            color: $primaryText;

            &:hover {
              color: #a962ff;
            }
          }
        }

        .team_member_designation {
          font-size: 14px;
          font-weight: normal;
          line-height: 19px;
          color: $primaryText;
          display: block;
        }
      }
    }

    .tab_location {
      font-size: 14px;
      font-weight: 500;
      width: 20%;
    }
  }
}
@media screen and (max-width: 480px) {
  .campaign_table_box {
    .custom_table {
      table {
        thead {
          tr {
            th {
              &:first-child {
                min-width: 280px;
              }
            }
          }
        }
      }
    }
  }
}
