@import "theme.scss";

.mainBreadcrumb {
  :global(.breadcrumb) {
    background: transparent;
    padding: 3px 0 30px 0;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $textShade1;

    a {
      color: $textShade1;

      &:hover {
        color: $headingColor2;
      }
    }
    .active {
      color: $textShade1;
    }
    :global(.breadcrumb-item + .breadcrumb-item) {
      padding-left: 5px;
      &::before {
        content: ">";
        font-weight: 500;
        padding-right: 5px;
        color: $textShade1;
      }
    }
  }
}

.slick_slider {
  width: calc(100% - 80px);
  margin: 0 40px 0;
  :global(.slick-track) {
    margin-left: 0;
  }

  :global(.slick-slide) {
    padding: 0 10px;
    border-right: none;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 25px;
      width: 1px;
      background: rgba(184, 180, 204, 0.5);
      right: 15px;
      top: 2px;
    }

    &:global(.active-cycle),
    :global(.active-cycle) {
      cursor: pointer;
      .date {
        font-weight: 700;
        color: $primaryColor !important;
      }
      .status {
        color: $primaryColor;
      }
    }

    .completed,
    .pending {
      padding-left: 15px;
      .dot {
        display: block;
      }
    }

    :global(.pending_from_member),
    :global(.reviewed-on) {
      padding-left: 15px;
      .dot {
        display: block;
        padding-left: 0;
      }
    }

    :global(.reviewed-on) {
      .dot {
        background-color: $primaryColor;
      }
    }

    .pending {
      .dot {
        background-color: #00c4bc;
      }
    }

    &:hover {
      cursor: pointer;
      .date {
        // font-weight: 700;
        color: #000;
      }
      // .status {
      //   color: $primaryColor;
      // }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
.slick-slider {
  width: calc(100% - 80px);
  margin: 0 40px 10px;
}

:global(.slick-prev),
:global(.slick-next) {
  height: 22px;
}
:global(.slick-prev.slick-disabled:before) {
  content: "";
  background-image: url(../../../assets/images/ic_leftArrow_disabled.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-prev:before) {
  content: "";
  background-image: url(../../../assets/images/ic_leftArrow.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-next.slick-disabled:before) {
  content: "";
  background-image: url(../../../assets/images/ic_rightArrow_disabled.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
:global(.slick-next:before) {
  content: "";
  background-image: url(../../../assets/images/ic_rightArrow.svg);
  height: 100%;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}

.durationRange {
  position: relative;

  .dot {
    @include position(absolute, 8px, auto, auto, -14px);
    width: 8px;
    height: 8px;
    @include border-radius(50%);
    z-index: 1;
    background: $themeColor11;
    font-size: 0;
    display: none;
  }
  .date {
    color: $textShade1;
    font-weight: 600;
    font-size: 15px;
    @include transition(all, 0.4s, ease-out);
  }

  .completed {
    &.dot {
      background: $themeColor13;
    }
    .status {
      color: $themeColor13;
    }
  }
  .pending {
    &.dot {
      background: $themeColor14;
    }
    .status {
      color: $themeColor14;
    }
  }
}
.status {
  color: $textShade1;
  font-size: 14px; //for myhisotry year 12px instead of 14px
  font-weight: 600;
}

/*start media query*/
@media (max-width: 992px) {
  :global(.slick-initialized) {
    :global(.slick-slide) {
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        right: 0;
        left: auto;
      }
    }
  }
}
@media (max-width: 479px) {
  :global(.slick-initialized) {
    :global(.slick-slide) {
      &:after {
        display: none;
      }
      :global(.pending_from_member),
      :global(.reviewed-on) {
        padding-left: 0;
      }
    }
  }
}
