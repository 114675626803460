@import '../../../../theme.scss';

.reume_acknowledge_wrapper {
    padding: 0;

    .notify_icon {
        position: relative;
        top: -2px;
    }

    span {
        margin: 0px 10px 0px 0px;
        display: inline-flex;
    }

    p {
        width: 96%;
        display: inline-flex;
        font-size: 16px;
        font-weight: 500;
        color: $primaryText;
    }

    form{
        padding-left: 25px;
    }

    button {
        min-width: 127px;
        height: 40px;
        margin-top: 20px;
    }
}