@import "../../theme.scss";

:global(.noDataOuterWrapper) {
  margin: 150px auto 0;
  width: 500px;
  text-align: center;
  max-width: 100%;
}
:global(.noRecordOuterWrapper) {
  margin: 60px auto 0;
  width: 500px;
  text-align: center;
  max-width: 100%;
}
:global(.folder-wrapper) {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
:global(.folder) {
  display: flex;
  position: relative;
  top: 2px;
}
:global(.folder-line) {
  display: flex;
}
:global(.folder-inner) {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  animation: folderUnfold 1.25s ease 0s forwards;
  z-index: 10;
}
:global(.folder-paper) {
  position: absolute;
  bottom: 10px;
  height: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /* animation: folderPaperJump .75s ease .75s forwards; */
}

:global(.main-folder) {
  display: flex;
  position: relative;
}

:global(.main-folder:before) {
  content: "";
  height: 100%;
  width: 100%;
  background: red;
  left: 0;
  top: 0;
  z-index: 11;
  position: absolute;
  bottom: auto;
  display: none;
}

:global(.main-folder > img) {
  position: relative;
  z-index: 9;
}
:global(.folder-paper img) {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}
:global(.folder-paper img:first-child) {
  animation: folderPaperJump-1 0.75s ease-in-out 0.4s forwards;
}
:global(.folder-paper img:nth-child(2)) {
  animation: folderPaperJump-2 0.85s ease-in-out 0.8s forwards;
}
:global(.folder-paper img:nth-child(3)) {
  animation: folderPaperJump-3 0.85s ease-in-out 0.8s forwards;
}

:global(.back-bubble) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 17px;
  animation: back-bg 0.75s ease 0.2s forwards, back-bg-1 1.5s linear 2s infinite;
  transform: scale(0);
  /* display: none; */
}

:global(.noDataOuterWrapper h4) {
  font-size: 18px;
  font-weight: 600;
  color: $themeColor11;
  margin: 15px 0 5px;
  text-align: center;
  opacity: 0;
  animation: animate 0.5s ease-in-out forwards;
}
:global(.noRecordOuterWrapper h4) {
  font-size: 18px;
  font-weight: 600;
  color: $themeColor11;
  margin: 15px 0 5px;
  text-align: center;
  opacity: 0;
  animation: animate 0.5s ease-in-out forwards;
}
:global(.noDataOuterWrapper p) {
  color: #928fa0;
  line-height: 16px;
  font-weight: 500;
  animation: animate 0.5s ease-in-out forwards;
  text-align: center;
  overflow: hidden;
  opacity: 0;
  /* display: none; */
  margin-bottom: 0;
}
:global(.noRecordOuterWrapper p) {
  color: #928fa0;
  line-height: 16px;
  font-weight: 500;
  animation: animate 0.5s ease-in-out forwards;
  text-align: center;
  overflow: hidden;
  opacity: 0;
  padding: 0 !important;
  /* display: none; */
  margin-bottom: 0;
}
:global(.folder-wrapper-overlay) {
  height: 0%;
  width: 217px;
  background-color: #fff;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  top: 0;
  animation: foldervisible 0.5s linear 1 forwards;
}

@keyframes foldervisible {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*create animation*/
@keyframes folderShow {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes folderUnfold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-30deg);
  }
}

@keyframes back-bg {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes back-bg-1 {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes folderPaperJump-1 {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-70px);
    opacity: 1;
  }
}
@keyframes folderPaperJump-2 {
  0% {
    transform: rotate(0) translateY(-70px) translateX(0) rotate(0);
    opacity: 0;
  }
  100% {
    transform: rotate(12.5deg) translateY(-50px) translateX(40px) rotate(12.5deg);
    opacity: 1;
  }
}
@keyframes folderPaperJump-3 {
  0% {
    transform: rotate(0) translateY(-70px) translateX(0) rotate(0);
    opacity: 0;
  }
  100% {
    transform: rotate(-12.5deg) translateY(-50px) translateX(-40px) rotate(-12.5deg);
    opacity: 1;
  }
}

.mHeader {
  display: block;
  padding: 0;

  :global(.modal-title) {
    font-weight: 600;
    font-size: 20px;
    color: #37324b;
    letter-spacing: 0.26px;

    :global(span) {
      display: block;
      font-size: 16px;
      color: #928fa0;
      letter-spacing: 0.36px;
      font-weight: 500;
    }
  }
}

.mBody {
  position: relative;
  padding-top: 25px !important;

  :global(.box-light-gradient) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.no_record_wrapper {
    position: relative;
    padding: 0px 0 40px 0;
    display: block;
    margin: 30px 45px 0px 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.mFooter{
    border: 0 !important;
    padding: 30px 45px !important;
    margin-top: 0 !important;
}

@media only screen and (max-width: 1024px) {
  :global(.folder) {
    z-index: 1;
  }
}
