@import '../../theme.scss';

:global(.custom-height){
  height: 90vh;
}
.loginviz_container{
.login_logo{
  text-align: center;
  margin: 0px 0px 34px 0px;
}
.poweredby_icon{
  text-align: center;
  margin: 30px 0px 0px 0px;
}
.loginviz {
  background: $whiteColor;
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  border-radius: 8px;
  width: 386px;
  height: auto;
  margin: 0px auto 0;
  padding: 0px;
  display: flex;
  form{
    width: 100%;
  }
  .loginviz_icon {
    width: 85px;
    height: 85px;
    margin: 0 auto -40px;
    position: relative;
    top: -40px;
  }

  .loginform {
    padding: 45px;
    float: left;
    width: 100%;
    .loginform_cont {
      margin: 0px 0px 20px 0px;
      float: left;
      width: 100%;
    }
    :global(.btn){
      padding: 15px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .username_cont {
      border: 1px solid rgba(184,180,204,0.50);
      border-radius: 4px;
      float: left;
      margin-top: 0px;
      height: 56px;
      width: 100%;
      .ic_username {
        float: left;
        margin: 0px 5px 0px 10px;
        line-height: 54px;
      }
      .input_group {
        position: relative;
        margin: 10px 0 0px;
        width: auto;
        display: flex;
        flex-direction: column;
        width: 89% !important;
      }
      input {
        font-size: 16px;
        // padding: 10px 10px 10px 5px;
        display: block;
        // width: 300px;
        border: none;
        // border-bottom: 1px solid #757575;
        font-weight: 500;
        color: $primaryText;
        margin-top: 1px;
        height: 36px;
        padding-left: 8px;
      }

      input:focus {
        outline: none;
      }

      label {
        color: $textShade1;
        font-size: 13px;
        font-weight: 600;
        position: absolute;
        pointer-events: none;
        left: -1px;
        top: 6px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        background-color: $whiteColor;
        padding: 0px 6px;
      }

      input:focus ~ label,
      input:valid ~ label {
        top: -20px;
        font-size: 13px;
        color: $textShade1;
        font-weight: 600;
      }

      .bar {
        position: relative;
        display: block;
        width: 315px;
      }

      .bar:before,
      .bar:after {
      }

      .bar:before {
        left: 50%;
      }

      .bar:after {
        right: 50%;
      }

      input:focus ~ .bar:before,
      input:focus ~ .bar:after {
        width: 50%;
      }

      .highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
      }

      input:focus ~ .highlight {
      }
    }
    .error_msg {
      font-size: 12px;
      color: $themeColor1;
      font-weight: 500;
      margin: 2px 0px 0px 10px;
      float: left;
    }
    .remember_me {
      font-size: 14px;
      color: $textShade2;
      font-weight: 400;
      &:hover {
        cursor: pointer;
      }
    }
    .forgotpass {
      line-height: 10px;
      margin-top: 30px;
      a, a:hover{
        font-size: 14px;
        color:$primaryColor;
        font-weight: 500;

      }
    }
    .formbottom {
      margin-bottom: 10px;
    }
  }
}
}
@media only screen and (max-width: 479px) {
  .loginviz_container{
    
    .login_logo{
      text-align: center;
      margin: 0px 0px 34px 0px;
    }
    .poweredby_icon{
      text-align: center;
      margin: 30px 0px 0px 0px;
    }
    .loginviz {
      background: $whiteColor;
      box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
      border-radius: 8px;
      width: 100%;
      height: auto;
      margin: 0px auto 0;
      padding: 40px 0px 0px 0px;
      display: inline-block;
      .loginviz_icon {
        width: 85px;
        height: 85px;
        margin: 0 auto -40px;
        position: relative;
        top: -40px;
      }
      .popoverone{
        // height: 50px;
        // width: 150px;
      }
    
      .loginform {
        padding: 30px 20px;
        float: left;
        width: 100%;
        .loginform_cont {
          margin: 0px 0px 15px 0px;
          float: none;
          display: flex;
        }
        :global(.btn){
          padding: 15px;
        }
        .username_cont {
          border: 1px solid rgba(184,180,204,0.50);
          border-radius: 4px;
          float: left;
          margin-top: 0px;
          height: 56px;
          width: 100%;
          .ic_username {
            float: left;
            margin: 0px 5px 0px 10px;
            line-height: 54px;
          }
          .input_group {
            position: relative;
            margin: 10px 0 0px;
            width: auto;
            display: flex;
            flex-direction: column;
            width: 89% !important;
          }
          input {
            font-size: 16px;
            // padding: 10px 10px 10px 5px;
            display: block;
            // width: 300px;
            border: none;
            // border-bottom: 1px solid #757575;
            font-weight: 500;
            color: $primaryText;
            margin-top: 1px;
            height: 36px;
            width: 100%;
          }
    
          input:focus {
            outline: none;
          }
    
          label {
            color: $textShade1;
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            pointer-events: none;
            left: 5px;
            top: 6px;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            background-color: $whiteColor;
            padding: 0px 2px;
          }
    
          input:focus ~ label,
          input:valid ~ label {
            top: -20px;
            font-size: 13px;
            color: $textShade1;
            font-weight: 600;
          }
    
          .bar {
            position: relative;
            display: block;
            width: 315px;
          }
    
          .bar:before,
          .bar:after {
          }
    
          .bar:before {
            left: 50%;
          }
    
          .bar:after {
            right: 50%;
          }
    
          input:focus ~ .bar:before,
          input:focus ~ .bar:after {
            width: 50%;
          }
    
          .highlight {
            position: absolute;
            height: 60%;
            width: 100px;
            top: 25%;
            left: 0;
            pointer-events: none;
            opacity: 0.5;
          }
    
          input:focus ~ .highlight {
          }
        }
        .error_msg {
          font-size: 12px;
          color: $themeColor1;
          font-weight: 500;
          margin: 2px 0px 0px 10px;
          float: left;
        }
        .remember_me {
          font-size: 14px;
          color: $textShade2;
          font-weight: 400;
          &:hover {
            cursor: pointer;
          }
        }
        .forgotpass {
          line-height: 28px;
        }
        .formbottom {
          margin-bottom: 10px;
        }
      }
    }
    }

}