@import "theme";

.note_text {
  white-space: normal;
  word-break: break-word;
  color: #74798C;
  margin: 0 60px;
}

.inner_header {
  display: flex;
  justify-content: space-between;
}

.details_wrapper {
  padding-top: 5px;
  >div {
    margin-bottom: 12px;
  }
}

.inner_container {
  margin: 40px 60px;
}

.empname_wrapper {
  display: flex;
  
  span:first-child {
    min-width: 80px;
  }
}

.greyText {
  color: #928fa0;
}

.questions_main_wrapper {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  margin-bottom: 30px;
  padding: 25px 0 0;
}

.question_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  .question_text {
    font-size: 16px;
    font-weight: 600;
  }
  .question_text_error {
    color: #dc3545;
  }
}

.buttons_wrapper {
  margin: 30px auto 0 auto;
  text-align: center;
}

.submit_button {
  min-width: 140px;
  margin: 0 12px;
}

.clear_button {
  min-width: 120px;
  margin: 0 12px;
}

.question_options_list {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: end;

  li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  :global(.custom-checkbox) {
    margin-bottom: 0;
    cursor: auto;

    input {
      &:disabled {
        + :global(label) {
          &:after {
            background-image: url(../../../../assets/images/ic_radio_disabled_2.svg) !important;
            background-size: 100%;
            -ms-background-size: 100%;
            -moz-background-size: 100%;
          }
        }
      }
    }

    :global(label) {
      font-size: 14px;
      padding-left: 30px;
      // line-height: 20px;
      cursor: pointer;

      &::before {
        height: 20px !important;
        width: 20px !important;
        background-color: #E0DFE2 !important;
      }

      &:after {
        height: 20px !important;
        width: 20px !important;
        background-image: url(../../../../assets/images/ic_radio_active_2.svg) !important;
      }
    }
  }

  li {
    &:last-child {
      :global(.custom-checkbox) {
        margin-bottom: 0;
      }
    }
  }
}

input[type="radio"].readonly {
  pointer-events: none;
}

label.readonly {
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .note_text {
    margin: 0 12px;
  }

  .inner_container {
    margin: 12px;
  }

  .question_options_list {
    padding-top: 12px;
    justify-content: start;
  }
}