@import "../../../theme.scss";

.export_btn {
  padding: 12px 61px;
}

.employee_list_filter_wrapper {
  .filter_info {
    padding: 25px 25px 0px;
    position: relative;
    display: block;
    .filter_top_bar {
      @include flexbox;
      flex-wrap: wrap;
      .filter_box {
        .dd_title {
          font-weight: 600;
          font-size: 16px;
          color: #322e46;
          letter-spacing: 0.3px;
          margin-bottom: 5px;
          display: block;
        }
        :global(.tags_wrapper) {
          position: relative;
          border: 0 solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          height: auto;
          transition: height 0.5s;
          :global(.multi-select) {
            :global(.dropdown-container) {
              border-color: rgba(0, 0, 0, 0.1);
              height: 47px;
              :global(.dropdown-heading) {
                height: 100%;
                :global(.dropdown-heading-value) {
                  font-weight: 500;
                  font-size: 15px;
                }
                :global(.dropdown-heading-dropdown-arrow) {
                  padding-top: 0 !important;
                }
              }
            }
            :global(.select-panel) {
              :global(.select-item) {
                margin-bottom: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                :global(span) {
                  width: 90%;
                  vertical-align: top;
                  position: relative;
                }
                :global(.item-renderer) {
                  input[type="checkbox"] {
                    opacity: 0;
                  }
                  :global(span) {
                    position: relative;
                    &::after {
                      content: "";
                      transform: scale(0);
                      transition: 0.2s linear all;
                      cursor: pointer;
                      left: -16px;
                      position: absolute;
                      top: 0;
                      background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                      width: 17px;
                      height: 17px;
                      opacity: 0;
                      border-radius: 4px;
                      background-size: 9px;
                    }
                    &::before {
                      border: 1px solid $textShade1;
                      content: "";
                      cursor: pointer;
                      display: inline-block;
                      height: 17px;
                      left: -16px;
                      position: absolute;
                      transition: 0.2s linear all;
                      width: 17px;
                      background-color: $whiteColor;
                      border-radius: 4px;
                    }
                  }
                }
                &:global(.selected) {
                  :global(.item-renderer) {
                    :global(span) {
                      &::after {
                        transform: scale(1);
                        opacity: 1;
                      }
                    }
                  }
                }
              }
              :global(li) {
                :global(label) {
                  width: 100%;
                  margin-bottom: 0;
                  &:global(.selected),
                  &:hover {
                    background-color: #fcfaff;
                  }
                }
                &:last-child {
                  :global(label) {
                    border-bottom: none;
                  }
                }
              }
            }
          }
          :global(.select__control) {
            min-height: 46px;
            border-color: rgba(0, 0, 0, 0.1);
            :global(.select__placeholder) {
              color: #aaa;
              font-size: 15px;
              font-weight: 500;
            }
            :global(.select__indicators) {
              :global(.select__indicator-separator) {
                display: none;
              }
              :global(.select__dropdown-indicator) {
                color: #aaa;
              }
            }
          }
        }
        :global(.date_picker_time_wrapper) {
          padding: 0px !important;
          :global(.form-group) {
            width: 100% !important;
          }
        }
        :global(.multi-select) {
          :global(.dropdown-heading) {
            min-height: 44px;
          }
        }
      }
    }
    .filter_btn_group {
      margin-top: 10px;
      :global(.btn) {
        min-width: 115px;
      }
    }
  }
}

.table_layout {
  margin-bottom: 40px 0 25px 25px;
  .deallocation_employee_list_heading {
    padding: 35px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .tab_title {
      h2 {
        color: $primaryText;
        font-size: 22px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 5px;
      }

      h3 {
        color: $textShade1;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .employee_list {
    list-style: none;
    padding: 0;
    margin: 0;
    .internal_list_item {
      @include flexbox;
      @include align-items(center);
      margin-top: 10px;

      &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
      }
    }
    .list_item {
      @include flexbox;
      @include align-items(center);
      padding-top: 15px;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-top: 15px;

      &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
      }
    }
    .question_template_wrapper {
      width: 70%;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin: 35px 0 0 100px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      :global(.box-heading-wrapper) {
        padding: 20px 25px 25px 25px;
      }
      :global(.box) {
        margin-bottom: 0px !important;
        padding: 0 0 25px 0 !important;
      }
    }
    .not_applicable_comment_wrapper {
      width: 70%;
      margin: 35px 0 0 100px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
    }
  }

  .employee_list_header {
    list-style: none;
    padding: 0 0 10px;
    margin: 0 0 20px;
    position: relative;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    li {
      display: inline-block;
      @include align-items(center);
      font-weight: 600;
      font-size: 14px;
      color: $primaryText;
      letter-spacing: 0.47px;

      &.name {
        width: 33%;
        padding-left: 64px;
      }
      &.manager {
        width: 10%;
        padding-left: 10px;
      }
      &.project_name {
        width: 20%;
      }
      &.deallocation_date {
        width: 10%;
        text-align: center;
      }
      &.location {
        width: 10%;
      }
      &.status {
        width: 10%;
        text-align: center;
      }
      &.action {
        width: 7%;
        text-align: center;
      }
    }
  }
  .plus_space {
    cursor: pointer;
    margin-right: 5px;
    width: 3%;
  }
  .tab_team_member_info {
    position: relative;
    width: 30%;

    .team_member_info {
      position: relative;
      color: $primaryText;
      padding-left: 0;
      @include flexbox;
      @include align-items(center);

      .team_member_img {
        width: 52px;
        height: 52px;
        @include border-radius(50%);
        @include flex(0 0 52px);
        margin-right: 12px;
        overflow: hidden;
        background: #ccc;
        text-align: center;
        line-height: 52px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;

        img {
          width: 52px;
          height: 52px;
          object-fit: cover;
          position: relative;
          top: -2px;
        }
      }

      .team_member_name {
        font-size: 16px;
        font-weight: 600;
        color: $themeColor11;
        line-height: 22px;
        letter-spacing: 0.44px;

        a {
          color: $primaryText;

          &:hover {
            color: #a962ff;
          }
        }
      }

      .team_member_designation {
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
        color: $primaryText;
        display: block;
      }
    }
  }
  .tab_project_name {
    width: 20%;
    .project_name {
      color: #37324b;
      font-size: 16px;
      font-weight: 600;
      padding-right: 10px;
      overflow-wrap: break-anywhere;
    }
    .duration {
      color: #322e46;
      font-size: 14px;
    }
  }
  .tab_location {
    font-size: 14px;
    font-weight: 500;
    width: 10%;
  }
  .tab_date {
    font-size: 14px;
    font-weight: 500;
    width: 10%;
    text-align: center;
  }
  .tab_manager_name {
    font-size: 14px;
    font-weight: 500;
    width: 10%;
    padding-left: 10px;
    &.managerTooltip {
      position: relative;

      .managerTooltipInfo {
        z-index: 1;
        position: absolute;
        left: -20px;
        width: 160px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px;
        top: 40px;
        opacity: 0;
        visibility: hidden;
        @include transition(0.5s all);
        @include border-radius(4px);
      }

      > span {
        cursor: pointer;

        &:hover {
          + .managerTooltipInfo {
            opacity: 1;
            visibility: visible;
            top: 25px;
          }
        }
      }
    }
  }
  .st_wrapper {
    width: 10%;
    position: relative;
    text-align: center;

    span {
      border-radius: 5px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.33px;
      display: inline-block;
      padding: 5px 10px;
    }
    .status_submitted {
      background: #f0fafd;
      border: 1px solid #cff1ff;
      color: #33c5ff;
      width: 80px;
      text-align: center;
    }
    .status_submitted_NA {
      background: #f0fafd;
      border: 1px solid #cff1ff;
      color: #33c5ff;
      width: 80px;
      text-align: center;
      cursor: pointer;
    }
    .status_pending {
      background: #fcf6f4;
      border: 1px solid #fce6dd;
      color: #fd8c61;
    }
  }
  .action_wrapper {
    cursor: pointer;
    width: 7%;
    position: relative;
    text-align: center;
  }
}

.no_record_found_box {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 35px 0px 0 100px;
  width: 70%;
  box-shadow: 0 2px 4px 0 rgbh(0, 0, 0, 0.03);
  .mHeader {
    padding: 20px 25px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    .mTitle {
      font-weight: 600;
      font-size: 20px;
      color: #37324b;
      letter-spacing: 0.26px;
      span {
        display: block;
        font-size: 16px;
        color: #928fa0;
        letter-spacing: 0.36px;
        font-weight: 500;
      }
    }
  }
  .mBody {
    position: relative;
    .no_record_wrapper {
      position: relative;
      padding: 0px 0 40px 0;
      display: block;
      margin: 30px 45px 0px 45px;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .filter_box {
    width: 30%;
    margin: 0 3% 20px 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .feedback_wrapper {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .feedback_wrapper {
    :global(.text-right) {
      text-align: left !important;
      margin-bottom: 20px;
    }
  }
  .filter_box {
    width: 46%;
    margin: 0 4% 15px 0;
  }
}

@media only screen and (max-width: 991px) {
  .feedback_wrapper {
    :global(.container) {
      :global(.text-right.col-lg-6) {
        text-align: left !important;
      }
    }
  }
  .table_layout {
    :global(.box-inner-pad) {
      overflow-x: auto;
      .table_wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        width: 880px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .filter_box {
    width: 32%;
    margin: 0 1.33% 20px 0;
  }
}

@media only screen and (max-width: 480px) {
  .feedback_wrapper {
    :global(.container) {
      :global(.text-right) {
        text-align: left !important;
        margin-bottom: 15px !important;
      }
    }
  }
}
