@import "theme";

.roll_wise_user_list_wrapper {
  .role_assignee_table {
    padding: 10px 25px;
    position: relative;
    :global(.table) {
      margin-bottom: 0 !important;
    }
    thead {
      th {
        border-bottom: 0 !important;
        border-top: 0;
      }
    }
  }
}
:global(.dropdown-multiselect) {
  :global(.form-group) {
    margin-right: 50px;
  }
  :global(.tags_wrapper) {
    position: relative;
    border-radius: 4px;
    height: auto;
    transition: height 0.5s;
    width: 250px;
    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 47px;
        border-color: rgba(0, 0, 0, 0.1);
      }
      // If we want to change dropdown item
      // :global(.select__menu-list) {
      //   :global(.select__option) {
      //     color: #322e46;
      //     cursor: pointer;
      //     border-bottom: none;
      //     word-break: break-all;
      //     position: relative;
      //     &::before {
      //       content: "";
      //       position: absolute;
      //       height: 5px;
      //       width: 5px;
      //       border-radius: 50%;
      //       background-color: #b5afca;
      //       left: 0;
      //       margin: auto 2px;
      //     }
      //   }
      // }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value) {
        font-size: 16px;
      }
    }
  }
}
//modal
.mHeader {
  display: block;
  padding: 25px;
  :global(.modal-title) {
    font-weight: 600;
    font-size: 20px;
    color: #37324b;
    letter-spacing: 0.26px;
    :global(span) {
      display: block;
      font-size: 16px;
      color: #928fa0;
      letter-spacing: 0.36px;
    }
  }
}
.mBody {
  position: relative;
  padding: 25px !important;
  :global(.box-light-gradient) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .modal_body_wrapper {
    position: relative;
    :global(.dropdown-multiselect) {
      :global(.form-group) {
        margin-right: 50px;
      }
      :global(.tags_wrapper) {
        position: relative;
        border-radius: 4px;
        height: auto;
        transition: height 0.5s;
        margin-top: 10px;
        width: 300px;
        &.role_dropdown {
          width: 200px;
        }
        :global(.multi-select) {
          :global(.dropdown-container) {
            border-color: rgba(0, 0, 0, 0.1);
            height: 47px;
            :global(.dropdown-heading) {
              height: 100%;
              :global(.dropdown-heading-value) {
                font-weight: 500;
                font-size: 15px;
              }
              :global(.dropdown-heading-dropdown-arrow) {
                padding-top: 0 !important;
              }
            }
          }
          :global(.select-panel) {
            :global(.select-item) {
              margin-bottom: 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              :global(span) {
                width: 90%;
                vertical-align: top;
                position: relative;
              }
              :global(.item-renderer) {
                input[type="checkbox"] {
                  opacity: 0;
                }
                :global(span) {
                  position: relative;
                  font-size: 14px;
                  font-weight: 500;
                  padding-left: 10px;
                  &::after {
                    content: "";
                    transform: scale(0);
                    transition: 0.2s linear all;
                    cursor: pointer;
                    left: -16px;
                    position: absolute;
                    top: 0;
                    background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                    width: 17px;
                    height: 17px;
                    opacity: 0;
                    border-radius: 4px;
                    background-size: 9px;
                  }
                  &::before {
                    border: 1px solid $textShade1;
                    content: "";
                    cursor: pointer;
                    display: inline-block;
                    height: 17px;
                    left: -16px;
                    position: absolute;
                    transition: 0.2s linear all;
                    width: 17px;
                    background-color: $whiteColor;
                    border-radius: 4px;
                  }
                }
              }
              &:global(.selected) {
                :global(.item-renderer) {
                  :global(span) {
                    &::after {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }
                }
              }
            }
            :global(li) {
              :global(label) {
                width: 100%;
                margin-bottom: 0;
                &:global(.selected),
                &:hover {
                  background-color: #fcfaff;
                }
              }
              &:last-child {
                :global(label) {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mFooter {
  padding: 25px;
}
.dd_title {
  font-weight: 600;
  font-size: 16px;
  color: #322e46;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
  display: block;
  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #928fa0;
    letter-spacing: 0.36px;
  }
}

@media screen and (max-width: 767px) {
  .role_assignee_table {
    overflow: auto;
    overflow-y: hidden;
  }
}
