@import "theme";
.legend_wrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  .graphText{
    font-size: 14px !important;
  }
  .legend_list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 10px 30px;
    @include flexbox;
    flex-wrap: wrap;
    .legend_item {
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 5px;
      color: $primaryText;
      @include flexbox;
      @include align-items(center);
      margin-right: 22px;
      &:last-child {
        margin-right: 0;
      }
      .legend_color {
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }
  }
}
