.note_text {
  white-space: normal;
  word-break: break-word;
  color: #74798C;
  margin: 0 60px;
}

.details_wrapper {
  padding-top: 5px;

  >div {
    margin-bottom: 12px;
  }
}

.inner_header {
  display: flex;
  justify-content: space-between;
}

.inner_container {
  margin: 40px 60px;
}

.greyText {
  color: #928fa0;
}

.box_styles {
  margin-bottom: 0;
  border-radius: 8px 8px 0 0;
}

.questions_main_wrapper {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  margin-bottom: 30px;
  padding: 25px 0 0;
}

.question_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 24px;

  .question_text {
    font-size: 16px;
    font-weight: 600;
  }
}

.question_options_list {
  list-style: none;
  display: flex;

  li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  :global(.custom-checkbox) {
    margin-bottom: 0;
    cursor: auto;

    input {
      &:disabled {
        + :global(label) {
          &:after {
            background-image: url(../../../../assets/images/ic_radio_disabled_2.svg) !important;
            background-size: 100%;
            -ms-background-size: 100%;
            -moz-background-size: 100%;
          }
        }
      }
    }

    :global(label) {
      font-size: 14px;
      padding-left: 30px !important;
      cursor: pointer;

      &::before {
        height: 20px !important;
        width: 20px !important;
        background-color: #E0DFE2 !important;
      }

      &:after {
        height: 20px !important;
        width: 20px !important;
        background-image: url(../../../../assets/images/ic_radio_active_2.svg) !important;
      }
    }
  }

  li {
    &:last-child {
      :global(.custom-checkbox) {
        margin-bottom: 0;
      }
    }
  }
}

.select_employees_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px 84px 0 84px;

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  label {
    font-size: 16px;
    font-weight: 600;
  }
}

.employee_checkbox_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 78px 32px 78px;
}

.employee_checkbox {
  border: 1px solid #E0DFE2;
  border-radius: 20px;
  margin: 9px 6px;
}

.employee_checkbox,  .select_all_checkbox{
  label {
    padding-left: 34px !important;
    padding-top: 1.5px;
    padding-right: 8px;
    padding-bottom: 2px;
    font-size: 14px !important;
  }

  label::before {
    background-color: #ededed !important;
    border: 1px solid #d2d2d2 !important;
    width: 21.41px !important;
    height: 21.41px !important;
    left: 7px !important;
    top: 4px !important;
    border-radius: 15px !important;
  }

  label::after{
    left: 7px !important;
    top: 3px !important;
    border-radius: 15px !important;
    width: 23.06px !important;
    height: 23.06px !important;
  }
}

.chips_main_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  margin-top: 6px;
}

.chip_wrapper {
  display: flex;
  border: 1px solid #E0DFE2;
  border-radius: 20px;
  margin: 8px;
  padding: 3px 3px 3px 12px;
  font-weight: 500;
}
.close_icon_wrapper {
  cursor: pointer;
  font-size: 12px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.selected_employees_wrapper {
  padding: 32px 0 12px 32px;
  
  .note_text{
    margin: 0 32px 16px 32px;
    max-width: 770px;
  }

  h4 {
    padding-left: 32px;
    font-weight: 600;
  }
}

.comment_box_wrapper {
  margin: 0 84px;
}

.add_message_text {
  display: flex;
  position: relative;
  justify-content: end;
  margin-right: 48px;
  color: #7033FF;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 18px;

  .add_a_message {
    cursor: pointer;
    position: absolute;
    top: -24px;
  }
  .add_another_message {
    cursor: pointer;
    padding-top:12px
  }
}

.individual_message_box {
  background: rgba(246, 245, 250, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 48px 0 64px;

  .text_main_wrapper {
    display: inline-flex;
  }

  .message_box_header {
    display: flex;
    justify-content: space-between;
    padding: 18px 0;

    .header_wrapper {
      display: flex;
      align-items: baseline;

      .individual_message_text {
        font-size: 14px;
        font-weight: 500;
        padding-left: 8px;
      }

      .select_dropdown {
        min-width: 197px;
        padding-left: 12px;
        height: 34px;

        :global(.select__single-value){
          color: #928fa0;
          font-size: 16px;
          font-weight: 500;
        }

        :global(.select__control) {
          min-height: 34px;
        }

        :global(.select__indicators) {
          height: 34px; 
        }
      }
    }
  }
}

.button_wrapper {
  margin: 34px auto 12px auto;
  text-align: center;
}

.submit_button {
  min-width: 140px;
  margin: 0 12px;
  padding: 12px 14px;
}

.clear_button {
  min-width: 120px;
  margin: 0 12px;
}

@media only screen and (max-width: 992px) {
  .question_options_list {
    padding-top: 12px;
    padding-left: 0;
    margin-left: 0 !important;
    justify-content: start;
  }
}

@media only screen and (max-width: 767px) {
  .note_text {
    margin: 0 12px;
  }

  .inner_container {
    margin: 12px;
  }

  .question_options_list {
    padding-top: 12px;
    padding-left: 0;
    margin-left: 0 !important;
    justify-content: start;
  }

  .chips_main_wrapper {
    margin-left: 8px;
  }

  .selected_employees_wrapper {
    padding: 18px 0 0 0;
  
    h4 {
      padding-left: 18px;
    }

    .note_text {
      margin: 0 18px 16px 18px;
    }
  }

  .select_employees_header_wrapper {
    margin: 0 24px;
  }

  .employee_checkbox_wrapper {
    margin:  0 18px 12px 18px;
  }

  .employee_checkbox,  .select_all_checkbox{
    label {
      padding-top: 4px;
      padding-bottom: 3px;
    }
  }

  .individual_message_box {
    padding: 0 24px 0 24px;

    .header_wrapper {
      flex-direction: column;

      .select_dropdown {
        padding-left: 0 !important;
        padding-top: 12px;
      }
    }
  }

  .comment_box_wrapper {
    margin: 0 24px;
  }

  .add_message_text {
    justify-content: center;
    margin-right: 0;
  }
}

@media only screen and (max-width: 580px) {
  .question_options_list {
    flex-direction: column;
    li {
      padding: 6px 0;
    }
  }
}

@media only screen and (max-width: 320px) {
  .select_dropdown {
    padding-left: 0 !important;
    min-width: 142px !important;
  }
}
