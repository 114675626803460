.mHeader {
  display: block;
  padding: 0;

  :global(.modal-title) {
    font-weight: 600;
    font-size: 20px;
    color: #37324b;
    letter-spacing: 0.26px;

    :global(span) {
      font-family: 500;
      display: block;
      font-size: 16px;
      color: #928fa0;
      letter-spacing: 0.36px;
    }
  }
}

.mBody {
  .not_applicable_comment_wrapper {
    position: relative;
    margin: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  }
  .question_template_wrapper {
    position: relative;
    margin: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
    :global(.box-heading-wrapper) {
      padding: 20px;
    }
    :global(.box) {
      margin-bottom: 0px !important;
      padding: 0 0 25px 0 !important;
    }
  }
}
