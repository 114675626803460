@import "../../../../theme.scss";

.quiz_graph_wrapper {
  :global(.box-heading-wrapper) {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    padding-bottom: 25px;
  }

  :global(.graph-item) {
    position: relative;
    .pie_chart_location_wrapper {
      width: 100%;
      height: 350px;
      position: absolute;
      top: 40%;
      transform: translate(0, -40%);
    }
    .pie_chart_location_wrapper_less_location {
      width: 100%;
      height: 350px;
    }
    .location_pie_legend {
      position: absolute;
      top: 72%;
      left: 40%;
      transform: translate(-40%, -72%);
    }
    .location_wrapper {
      width: 100%;
      height: 88%;
    }
    .subgroup_wrapper {
      width: 100%;
      height: 85%;
    }
  }

  :global(.box-inner-pad) {
    padding-bottom: 25px;
    :global(.graph-item) {
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .quiz_graph_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;

    h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.26px;
      color: #322e46;
    }
  }

  .sub_group_wrapper {
    position: relative;
    .quiz_sub_header {
      padding: 25px;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.26px;
      color: #322e46;
      border-bottom: 1px solid rgba($color: #979797, $alpha: 0.2);
    }
  }

  .right_text_title {
    padding-left: 83px;
  }

  .bar_chart_sub_header {
    padding-top: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #322e46;

    > span {
      font-weight: 700;
    }
  }

  .legent_wrapper {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    .lenegd_list {
      list-style-type: none;
      padding: 0;
      margin: 35px 0 10px;
      @include flexbox;
      .legend_item {
        font-weight: 500;
        font-size: 13px;
        color: $primaryText;
        @include flexbox;
        @include align-items(center);
        margin-right: 22px;
        &:last-child {
          margin-right: 0;
        }
        .legend_color {
          height: 13px;
          width: 13px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 7px;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .location_dropdown_wrapper {
    display: flex;
    justify-content: space-between;
  }

  :global(.cycle-list-wrapper) {
    :global(.form-group) {
      width: 240px;
      margin-bottom: 0px;
      margin-right: 0px;
    }
    :global(.custom-default-dd-box) {
      margin-right: 0px;
      :global(.select__control) {
        min-height: 47px;
        border-color: rgba(0, 0, 0, 0.1);
        border-width: 1px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .quiz_graph_wrapper {
    :global(.graph-item) {
      .location_wrapper {
        width: 100%;
        height: 88%;
      }
      .subgroup_wrapper {
        width: 100%;
        height: 85%;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .location_dropdown_wrapper {
    flex-direction: column;
  }
  .quiz_graph_wrapper {
    :global(.cycle-list-wrapper) {
      padding-left: 25px;
      padding-top: 10px;
    }
    :global(.graph-item) {
      .location_wrapper,
      subgroup_wrapper {
        width: 100%;
        height: 78%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .quiz_graph_wrapper {
    :global(.box-inner-pad) {
      :global(.d-flex) {
        flex-wrap: wrap;
      }
    }
    :global(.cycle-list-wrapper) {
      padding-top: 25px;
    }
    :global(.graph-item) {
      h5.right_text_title {
        padding-top: 25px;
      }
      position: static;
      .pie_chart_location_wrapper {
        position: static;
        top: 0;
        transform: none;
      }
      .location_pie_legend {
        position: static;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }
  .legent_wrapper {
    .lenegd_list {
      flex-wrap: wrap;
      @include justify-content(center);
      margin-top: 15px;
      margin-bottom: 0;
      .legend_item {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .location_dropdown_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .quiz_graph_wrapper {
    :global(.graph-item) {
      h5.right_text_title {
        padding-top: 15px;
      }
    }
    :global(.cycle-list-wrapper) {
      margin-top: 10px;
      padding-top: 0;
    }
  }
}

@media only screen and (max-width: 479px) {
  .location_dropdown_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .quiz_graph_wrapper {
    :global(.cycle-list-wrapper) {
      width: 70%;
      margin-top: 10px;
    }
  }
}
