@import "../../../../theme.scss";

.noteBox {
  display: flex;
  background-color: #f9f8fc;
  color: #928fa0;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: auto;
  margin: 15px;
  margin-top: 24px;
  padding: 10px;
  padding-top: 17px;
  margin-bottom: 2%;
  box-shadow: none !important;
}

.assigneepopup_wrapper {
  .modal_header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: $themeColor11;
    }
    h2 {
      font-size: 16px;
      font-weight: 400;
      color: $themeColor11;
    }
    input[type="text"] {
      padding-right: 50px;
    }
  }
  :global(.modal-body) {
    padding: 0;

    .data_container {
      margin-top: 20px;
      width: 100%;
      height: 300px;
      overflow-y: scroll;
      padding: 30px 0px;
      table {
        width: 100%;
        thead {
          tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            td {
              color: $primaryText;
              font-size: 14px;
              font-weight: 700;
              padding-bottom: 20px;
              padding-left: 10px;
              &.selectAll{
                width: 8%;
                padding: 0px 0px 20px 10px;
                .checkSelectAll{
                  margin-right: 5px;
                  cursor: pointer;
                }
              }
              &.name {
                width: 40%;
              }
              &.band {
                width: 15%;
              }
              &.objective_assigned {
                width: 20%;
              }
              .status {
                width: 17%;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:hover {
              background-color: rgba(112, 51, 255, 0.1);
            }
            &.active {
              background-color: rgba(112, 51, 255, 0.1);
            }
            td {
              padding: 15px 10px;
              position: relative;
              .user_img {
                width: 56px;
                height: 56px;
                font-size: 18px;
                color: $whiteColor;
                letter-spacing: 0.21px;
                text-align: center;
                line-height: 52px;
                font-weight: 700;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                margin-right: 10px;
                overflow: hidden;
                border: 3px solid #fff;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .user_name {
                display: inline-flex;
                flex-direction: column;
                vertical-align: top;
                margin-top: 10px;
                h1 {
                  font-size: 16px;
                  font-weight: 600;
                  color: $themeColor11;
                }
                h2 {
                  font-size: 14px;
                  font-weight: 400;
                  color: $primaryText;
                }
              }
              .info_wrapper {
                display: inline-flex;
                :global(.info-attach-file) {
                  top: -55px;
                }
              }
              .assigned {
                background: #f9f7ff;
                border: 1px solid rgba(149, 106, 251, 0.23);
                border-radius: 4px;
                font-weight: 600;
                font-size: 12px;
                color: #814cff;
                letter-spacing: 0.33px;
                text-align: center;
                max-width: 75px;
                padding: 5px 5px;
              }
              .not_assigned {
                background: #fcf6f4;
                border: 1px solid #fce6dd;
                border-radius: 5px;
                font-weight: 600;
                font-size: 12px;
                color: #fd8c61;
                letter-spacing: 0.33px;
                text-align: center;
                max-width: 90px;
                padding: 5px 5px;
              }
              .check_infoneer {
                position: absolute;
                top: 0px;
                // label{
                //     width: 400px;
                //     height: 93px;
                //     border: 1px solid #f00;
                //     input{
                //         position: absolute;
                //         left: 36px;
                //         top: 20px;

                //     }
                // }
                .container_checkbox {
                  display: block;
                  position: relative;
                  padding-left: 35px;
                  margin-bottom: 12px;
                  cursor: pointer;
                  font-size: 22px;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  width: 400px;
                  height: 92px;
                }

                /* Hide the browser's default checkbox */
                .container_checkbox input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                  position: absolute;
                  top: 15px;
                  left: 36px;
                  height: 17px;
                  width: 17px;
                  background-color: #eee;
                  border-radius: 50%;
                  border: 2px solid #fff;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
                }

                /* On mouse-over, add a grey background color */
                .container_checkbox:hover input ~ .checkmark {
                  background-color: #ccc;
                }

                /* When the checkbox is checked, add a blue background */
                .container_checkbox input:checked ~ .checkmark {
                  background-color: #7033ff;
                  // border:1px solid #7033FF ;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }

                /* Show the checkmark when checked */
                .container_checkbox input:checked ~ .checkmark:after {
                  display: block;
                }

                /* Style the checkmark/indicator */
                .container_checkbox .checkmark:after {
                  // left: 3px;
                  // top: 0px;
                  // width: 5px;
                  // height: 10px;
                  // border: solid white;
                  // border-width: 0 2px 2px 0;
                  // -webkit-transform: rotate(45deg);
                  // -ms-transform: rotate(45deg);
                  // transform: rotate(45deg);
                  background: url(../../../../assets/images/ic_radio_purple.svg) no-repeat center;
                  height: 100%;
                  width: 100%;
                  background-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
  .btn_save {
    margin-right: 20px;
    min-width: 146px;
  }
  .assigned_modal_footer {
    text-align: left;
    justify-content: flex-start;
  }
}

.filterRow {
  display: flex;
  justify-content: space-between;

  .search_bar {
    margin-top: 20px;
    .label{
      font-size: 14px;
    }
    :global(.tags_wrapper) {
      margin-left: 90px;
      display: flex;
    }

    :global(.dropdown-multiselect) {
      :global(.tags_wrapper) {
        :global(.custom-default-dd-box) {
          width: 100%;
          :global(.select__control) {
            min-height: 1px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .noteBox {
    background-color: #f9f8fc;
    color: #928fa0;
    font-weight: 500;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: auto;
    width: auto;
    margin: 15px;
    padding: 10px;
    padding-top: 17px;
    margin-bottom: 4%;
    box-shadow: none !important;
  }
  .assigneepopup_wrapper {
    .modal_header {
      h2 {
        margin: 5px 0 15px;
      }
    }
    :global(.modal-body) {
      .data_container {
        margin-top: 20px;
        table {
          tbody {
            tr {
              &:hover {
                background-color: transparent;
              }
              &.active {
                background-color: rgba(112, 51, 255, 0.1);
              }
              td {
                padding: 10px;
                .user_img {
                  vertical-align: middle;
                }
                .user_name {
                  margin-top: 0;
                  width: calc(100% - 62px);
                  vertical-align: middle;
                }
                .check_infoneer {
                  .checkmark {
                    top: 8px;
                  }
                  .container_checkbox {
                    width: 450px;
                    height: 76px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .filterRow {
    display: block;
    justify-content: space-between;
    .search_bar {
      margin-top: 20px;
      :global(.tags_wrapper) {
        width: 100% !important;
        margin-left: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .assigneepopup_wrapper {
    :global(.modal-body) {
      .data_container {
        margin-top: 20px;
        overflow-x: auto;
        height: calc(100vh - 255px);
        table {
          width: 100%;
          thead {
            display: none;
          }
          tbody {
            tr {
              border: 1px solid rgba(0, 0, 0, 0.1);
              td {
                display: block;
                &:first-child {
                  padding-bottom: 0;
                }
                &:nth-child(2) {
                  width: 60%;
                  display: inline-block;
                  vertical-align: middle;
                }
                .not_assigned,
                .assigned {
                  display: inline-block;
                }
                .check_infoneer {
                  left: 0;
                  height: 100%;
                  width: 100%;
                  .container_checkbox {
                    width: 100%;
                  }
                  .checkmark {
                    left: 40px;
                  }
                }
                .check_no_of_obj {
                  position: relative;
                  &:before {
                    content: "Objective Assigned :";
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 5px;
                  }
                }
                .check_band {
                  position: relative;
                  &:before {
                    content: "Band :";
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .assigned_modal_footer {
      :global(.btn) {
        min-width: 100px;
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .filterRow {
    display: block;
    justify-content: space-between;
  }
}
