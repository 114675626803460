@import "theme";

.keyresult_accordion_wrapper {
  :global(.card) {
    margin: 0px 0px 13px 0px;
    background: rgba(255, 255, 255, 0.61);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-bottom: solid !important;
    border-bottom-width: 1px !important;
    border-bottom-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    overflow: visible;

    // &:last-child {
    //   margin-bottom: 0;
    // }
    :global(.self-private-rating) {
      position: absolute;
      right: 20px;
      font-size: 16px;
      width: 50%;
      top: -7px;
      text-align: right;
      font-weight: 500;
      span {
        display: block;
      }
    }
    :global(.limit-reached-card) {
      border: 1px solid #f07575 !important;
    }

    &:global(.template-rating) {
      margin-bottom: 0;
      margin-top: 8px !important;
      border-bottom-width: 0 !important;
    }
  }
  :global(.self-private-rating) {
    position: absolute;
    right: 20px;
    font-size: 16px;
    width: 50%;
    top: -7px;
    text-align: right;
    font-weight: 500;
    span {
      display: block;
    }
  }
  :global(.limit-reached-card) {
    border: 1px solid #f07575 !important;
  }
  :global(.card-header) {
    background: rgba(255, 255, 255, 0.61);
    border: 0px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0px;
    letter-spacing: 0.47px;
    text-align: right;
    color: #37324b;
    letter-spacing: 0.36px;
    margin-bottom: 0px;
    padding: 14px 0px 10px 0px !important;
  }

  :global(.card-body) {
    padding: 0px;
  }

  button {
    width: 100%;

    &:global(.btn-link) {
      padding-right: 20px !important;
    }

    :global(.accordion-icon) {
      position: absolute;
      top: 50%;
      left: 16px;
      margin-top: -10px;
      display: block;
      width: 10px;
      height: 10px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .accordion_title {
    clear: both;
    padding-left: 26px;

    .title_left {
      float: left;
      color: $themeColor11;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      text-align: left;
      :global(.manager_rating_wrapper) {
        float: right;
        :global(.rating_info) {
          margin-right: 10px;
        }
      }
    }
  }

  :global(.private-assessment-wrapper) {
    padding: 0;
    box-shadow: none;
    @include border-radius(0px);

    :global(.box-light-gradient) {
      display: none;
    }

    :global(.box-heading-wrapper) {
      padding: 25px;
    }

    :global(.box-inner-pad) {
      :global(.form-group) {
        position: relative;
      }
    }
  }
}

.manager_list_wrapper {
  list-style: none;
  padding: 0;
  margin: 0;

  .manager_list_item {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    padding: 0 15px;

    .manager_list_topbar {
      @include flexbox;
      padding: 20px 0;

      > * {
        margin-right: 15px;
        letter-spacing: 0.41px;
        font-weight: 600;
      }

      .expand_collapse_wrapper {
        cursor: pointer;

        + h3 {
          color: $textShade1;
        }
      }
    }

    .manager_list_body {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-left: -15px;
      @include calc("width", "100% + 30px");
    }
  }
}

@media only screen and (max-width: 767px) {
  .keyresult_accordion_wrapper {
    :global(.self-private-rating) {
      position: unset !important;
      width: 100% !important;
      text-align: left !important;
    }
    :global(.card) {
      .accordion_title {
        text-align: left;

        .title_left {
          :global(.manager_rating_wrapper) {
            float: none;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .keyresult_accordion_wrapper {
    :global(.box) {
      :global(.box-heading-wrapper) {
        flex-direction: column;
        @include justify-content(flex-start);
        @include align-items(flex-start);

        :global(.box-info) {
          position: static;
          margin-top: 15px;
        }
      }
    }

    :global(.card) {
      :global(.card-header) {
        padding-top: 10px !important;
      }

      button {
        padding-right: 10px !important;
      }

      .accordion_title {
        text-align: left;
      }
    }
  }
}
