@import "theme";

.heading_wrapper {
  display: flex;
  margin: 0;
  padding: 0 12px 22px 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: 600;
    font-size: 20px;
  }
}

.date_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;

  >div {
    margin: 0 0 0 8px;
    width: 130px;
    font-weight: 500;
    font-size: 16px;

    :global(.multi-select) {
      :global(.dropdown-container) {
        border-color: rgba(0, 0, 0, 0.1);
        height: 38px;

        :global(.dropdown-heading) {
          height: 100%;

          :global(.dropdown-heading-value) {
            font-weight: 500;
            font-size: 15px;
          }

          :global(.dropdown-heading-dropdown-arrow) {
            padding-top: 0 !important;
          }
        }
      }

      :global(.select-panel) {
        :global(.select-item) {
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          :global(span) {
            vertical-align: top;
            position: relative;
          }

          :global(.item-renderer) {
            input[type="checkbox"] {
              opacity: 0;
            }

            :global(span) {
              position: relative;

              &::after {
                content: "";
                transform: scale(0);
                transition: 0.2s linear all;
                cursor: pointer;
                left: -16px;
                position: absolute;
                top: 4px;
                background: #7033ff url(../../../../assets/images/checkbox-arrow.svg) no-repeat center;
                width: 15px;
                height: 15px;
                opacity: 0;
                border-radius: 4px;
                background-size: 9px;
              }

              &::before {
                border: 1px solid $textShade1;
                content: "";
                cursor: pointer;
                display: inline-block;
                height: 15px;
                left: -16px;
                top: 4px;
                position: absolute;
                transition: 0.2s linear all;
                width: 15px;
                background-color: $whiteColor;
                border-radius: 4px;
              }
            }
          }

          &:global(.selected) {
            :global(.item-renderer) {
              :global(span) {
                &::after {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }

        :global(li) {
          :global(label) {
            width: 100%;
            margin-bottom: 0;

            &:global(.selected),
            &:hover {
              background-color: #fcfaff;
            }
          }

          &:last-child {
            :global(label) {
              border-bottom: none;
            }
          }
        }
      }
    }

    :global(.select__control) {
      min-height: 46px;
      border-color: rgba(0, 0, 0, 0.1);

      :global(.select__placeholder) {
        color: #aaa;
        font-size: 15px;
        font-weight: 500;
      }

      :global(.select__indicators) {
        :global(.select__indicator-separator) {
          display: none;
        }

        :global(.select__dropdown-indicator) {
          color: #aaa;
        }
      }
    }
  }

  .select_wrapper {
    :global(.select_year__value-container) {
      padding-left: 28px;
      margin-left: 12px;
      background: #ffffff url('../../../../assets//images/ic_calendar_2.svg') no-repeat left;
    }
  }
}

.greyText {
  color: #928fa0;
}

.table_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 40px 30px;

  thead {
    height: 75px;

    th {
      vertical-align: middle;
      padding-left: 18px;
      border-bottom: none;
      border-top: none;
    }
  }

  tbody {
    td {
      height: 60px;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 500;
      color: #37324b;
      letter-spacing: 0.36px;
      padding-left: 18px;
    }
  }
}

.COMPLETED {
  border-radius: 5px;
  font-size: 12px;
  color: #00c4bc;
  background: #f5ffff;
  border-color: rgba(36, 218, 210, 0.23);
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.TOTAL_PENDING {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}
.PARTIALLY_PENDING {
  color: #fd8c61;
  background: #fcf6f4;
  border-color: #fce6dd;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.eye_icon {
  &:hover {
    svg {
      g {
        g {
          fill: #7033ff;
        }
      }
    }

    cursor: pointer;
  }
}

:global(.past-checkins-box) {
  margin-top: 25px;
}
.main_table_wrapper {
  margin: 0 24px;
}

.tab_table_responsive {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  background: transparent;
  border-radius: 0;
  // z-index: 1;
  position: relative;

  thead {
    tr {
      border-bottom: 1px solid #dee2e6;

      .check_in_period {
        padding: 24px 40px;
        width: 65%;
        font-weight: 600;
        font-size: 16px;
      }

      .status {
        padding: 24px 40px 24px 12px;
        font-weight: 600;
        font-size: 16px;
      }

      

      th {
        border-bottom: none;
        border-top: none;
      }

      td {
        font-size: 14px;
        color: $primaryText;
        font-weight: 600;
        border-top: 0px solid #dee2e6;

        .status {
          text-align: center;
        }

        a {
          color: $themeColor11;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;

      .check_in_period {
        padding-left: 40px;
        padding-top: 24px;
      }

      .status {
        padding-top: 24px;
      }

      .table_cell {
        padding: 0;
      }

      & .active {
        background-color: rgba(246, 245, 250, 0.5);
      }

      td {
        font-size: 16px;
        font-weight: 500;
        color: $primaryText;
        border-bottom: 0px solid rgba(0, 0, 0, 0.1);

        &:first-child {
          color: $primaryText;
          font-weight: 600;
          border-bottom: 0px solid rgba(0, 0, 0, 0.1);

          div > span {
            position: absolute;
            left: 10px;
            cursor: pointer;
          }
        }

        &.close_expand {
          height: 0px;
          -moz-transition: height 1s ease;
          -webkit-transition: height 1s ease;
          -o-transition: height 1s ease;
          transition: height 1s ease;
          opacity: 0;
          visibility: hidden;
        }

        &.open_expand {
          height: 200px;
          opacity: 1;
          visibility: visible;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 10px 0px 0px 0px;
          display: inline-block;
          width: 100%;

          li {
            float: left;
            padding: 0px 0px 0px 15px;
            margin: 0px 0px 0px 0px;
            color: $themeColor11;
            font-size: 14px;
            font-weight: 500;

            &:last-child {
              margin-bottom: 0;
            }

            &:first-child {
              background: url("../../../../assets/images/ic_keyresult_bullet.svg") no-repeat 0px 6px;
              width: 730px;
            }

            &.key_value {
              width: 230px;
            }
          }
        }

        a {
          font-weight: 600;
          color: $primaryText;
        }

        &:global(.action-dropdown-wrapper) {
          border-bottom: 0px solid rgba(0, 0, 0, 0.1);
          border-top: 0px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.eye_icon {
  &:hover {
    svg {
      g {
        g {
          fill: #7033ff;
        }
      }
    }

    cursor: pointer;
  }
}

.inner_table {
  padding: 0;
  margin-bottom: 0;

  thead {
    th {
      font-size: 16px;
      font-weight: 600;
    }
  }

  thead, tbody {
    .name {
      padding: 24px 40px;
      width: 35%;
      vertical-align: top;
    }
    .submitted_date {
      width: 200px;
      width: 30%;
      padding: 24px 0;
      font-size: 16px;
    }
    .status {
      width: 20%;
    }
    .status, .action {
      padding: 24px 40px 24px 12px;
      vertical-align: top;
    }
  }

  tbody {
    .status {
      padding: 24px 40px 24px 12px;
    }

    .submitted_date_value, .status_value, .action_icon {
      padding: 40px 12px;
    }
  }
}

.tab_team_member_info {
  position: relative;
  .team_member_info {
    position: relative;
    color: $primaryText;
    @include flexbox;
    @include align-items(center);

    .team_member_img {
      width: 52px;
      height: 52px;
      @include border-radius(50%);
      @include flex(0 0 52px);
      margin-right: 12px;
      overflow: hidden;
      flex: 0 0 52px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 52px;
      background-color: rgb(184, 180, 204);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .team_member_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
      line-height: 22px;
      letter-spacing: 0.44px;
      display: flex;
      align-items: center;
      a {
        color: $primaryText;
        &:hover {
          color: #a962ff;
        }
      }
      :global(.custom-tooltip) {
        :global(.box-info) {
          margin: 0px;
          &:hover {
            + :global(.info-attach-file) {
              top: -145px;
            }
          }
        }
        :global(.info-attach-file) {
          top: -155px;
          left: -12px;
          &:before {
            top: auto;
            bottom: -4px;
            transform: rotate(135deg);
          }
          ul {
            li {
              line-height: 20px;
            }
          }
        }
      }
    }

    .team_member_designation {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: $primaryText;
    }
  }
}

:global(.expandable-table) {
  margin-bottom: 0;
  tbody {
    td {
      &:nth-child(3) {
        padding-left: 7px;
      }
    }
  }
  .team_member_details {
    width: 235px;
  }
  .tab_team_member_info {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .heading_wrapper {
    padding: 0 0 22px 0;

    h2 {
      padding-left: 8px;
    }
  }

  .date_wrapper {
    flex-direction: column;
    width: 100%;
    padding-left: 18px;

    >div {
      margin: 8px;
      width: 250px;
    }
  }

  .main_table_wrapper {
    margin: 0;
  }

  .tab_table_responsive {
    width: 100%;
    margin: 0;

    thead {
      tr {
        .check_in_period {
          width: auto;
          padding: 24px;
        }
      }
    }

    tbody {
      tr {
        .check_in_period {
          padding-left: 48px;
        }

        td:first-child {
          div > span {
            left: 18px;
          }
        }
      }
    }
  }

  .inner_table_wrapper {
    overflow-x: auto;
  }

  .inner_table {
    thead, tbody {
      .submitted_date {
        padding: 24px 12px;
      }
    }
  }

  .box_wrapper {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 1024px){
  :global(.past-checkins-box) {
    :global(.folder) {
        z-index: 0;
    }
  }
}