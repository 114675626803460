@import "theme";

.heading_wrapper {
  display: flex;
  margin: 0;
  padding: 0 12px 22px 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: 600;
    font-size: 20px;
  }
}

.date_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;

  >div {
    margin: 0 0 0 8px;
    width: 130px;
    font-weight: 500;
    font-size: 16px;

    :global(.multi-select) {
      :global(.dropdown-container) {
        border-color: rgba(0, 0, 0, 0.1);
        height: 38px;

        :global(.dropdown-heading) {
          height: 100%;

          :global(.dropdown-heading-value) {
            font-weight: 500;
            font-size: 15px;
          }

          :global(.dropdown-heading-dropdown-arrow) {
            padding-top: 0 !important;
          }
        }
      }

      :global(.select-panel) {
        :global(.select-item) {
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          :global(span) {
            vertical-align: top;
            position: relative;
          }

          :global(.item-renderer) {
            input[type="checkbox"] {
              opacity: 0;
            }

            :global(span) {
              position: relative;

              &::after {
                content: "";
                transform: scale(0);
                transition: 0.2s linear all;
                cursor: pointer;
                left: -16px;
                position: absolute;
                top: 4px;
                background: #7033ff url(../../../../assets/images/checkbox-arrow.svg) no-repeat center;
                width: 15px;
                height: 15px;
                opacity: 0;
                border-radius: 4px;
                background-size: 9px;
              }

              &::before {
                border: 1px solid $textShade1;
                content: "";
                cursor: pointer;
                display: inline-block;
                height: 15px;
                left: -16px;
                top: 4px;
                position: absolute;
                transition: 0.2s linear all;
                width: 15px;
                background-color: $whiteColor;
                border-radius: 4px;
              }
            }
          }

          &:global(.selected) {
            :global(.item-renderer) {
              :global(span) {
                &::after {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }

        :global(li) {
          :global(label) {
            width: 100%;
            margin-bottom: 0;

            &:global(.selected),
            &:hover {
              background-color: #fcfaff;
            }
          }

          &:last-child {
            :global(label) {
              border-bottom: none;
            }
          }
        }
      }
    }

    :global(.select__control) {
      min-height: 46px;
      border-color: rgba(0, 0, 0, 0.1);

      :global(.select__placeholder) {
        color: #aaa;
        font-size: 15px;
        font-weight: 500;
      }

      :global(.select__indicators) {
        :global(.select__indicator-separator) {
          display: none;
        }

        :global(.select__dropdown-indicator) {
          color: #aaa;
        }
      }
    }
  }

  .select_wrapper {
    :global(.select_year__value-container) {
      padding-left: 28px;
      margin-left: 12px;
      background: #ffffff url('../../../../assets//images/ic_calendar_2.svg') no-repeat left;
    }
  }
}

.greyText {
  color: #928fa0;
}

.table_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 40px 30px;

  thead {
    height: 75px;

    th {
      vertical-align: middle;
      padding-left: 18px;
      border-bottom: none;
      border-top: none;
    }
  }

  tbody {
    td {
      height: 60px;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 500;
      color: #37324b;
      letter-spacing: 0.36px;
      padding-left: 18px;
    }
  }
}

.completed {
  background: #F9F7FF;
  border: 1px solid #956AFB;
  border-radius: 5px;
  font-size: 12px;
  color: #956AFB;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.pending {
  background: #fcf6f4;
  border: 1px solid #fce6dd;
  color: #fd8c61;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  width: auto;
  display: inline;
}

.eye_icon {
  &:hover {
    svg {
      g {
        g {
          fill: #7033ff;
        }
      }
    }

    cursor: pointer;
  }
}

:global(.past-checkins-box) {
  margin-top: 25px;
}

@media only screen and (max-width: 767px) {
  .heading_wrapper {
    h2 {
      padding-left: 6px;
    }
  }

  .date_wrapper {
    flex-direction: column;
    width: 100%;
    padding-left: 12px;

    >div {
      margin: 8px;
      width: 250px;
    }
  }
}