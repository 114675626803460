@import "theme";
.notification_filter_wrapper {
  padding: 25px 25px 0px;
  position: relative;
  display: block;
  .title_text_wrapper,
  .subject_text_wrapper {
    margin-bottom: 20px;
    padding: 0 0;
    :global(.form-group) {
      margin-bottom: 0;
      :global(label) {
        font-weight: 600;
        font-size: 16px;
        color: #322e46;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        display: block;
      }
    }
  }
  .text_box_wrapper {
    display: block;
    width: 70%;
    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322e46;
      letter-spacing: 0.3px;
      margin-bottom: 10px;
      display: block;
    }
  }
  .filter_btn_group {
    margin-top: 25px;
    :global(.btn) {
      min-width: 115px;
    }
  }
}
.bot_notification_wrapper {
  :global(.box) {
    border-radius: 8px 8px 0 0;
  }
  :global(.outer-table-wrapper) {
    min-height: 400px;
  }
  .custom_table {
    position: relative;
    padding: 30px 20px 5px;
    .tab_table_responsive {
      margin-bottom: 0;
      thead {
        th {
          padding: 10px;
          border: none;
          &:last-child {
            width: 15%;
          }
        }
      }
      tbody {
        td {
          padding: 20px 10px;
          border-bottom: none;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          &.date {
            font-size: 16px;
            font-weight: 500;
            color: #37324b;
          }
          &.title {
            font-size: 16px;
            font-weight: 500;
            color: $themeColor11;
            letter-spacing: 0.36px;
            cursor: pointer;
            a {
              color: $themeColor11;
              &:hover {
                color: $primaryColor;
              }
            }
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .notification_filter_wrapper {
    .text_box_wrapper {
      display: block;
      width: 100%;
    }
    .subject_text_wrapper {
      :global(.info-attach-file) {
        left: -68px;
        &::before {
          left: -32px !important;
        }
      }
    }
  }
}
