@import "theme";

.modal_header {
  padding: 50px 0 20px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .heading_wrapper {
    h2 {
      font-weight: 600;
    }

    h6 {
      padding-top: 5px;
      font-size: 15px;
    }
  }
}

.employees_main_wrapper {
  padding: 32px 0 0 32px;
  margin-bottom: -18px;
}


.employee_names_wrapper {
  display: flex;
  flex-wrap: wrap;
  
  div {
    border: 1px solid #E0DFE2;
    border-radius: 20px;
    margin: 9px 8px;
    padding: 6px 17px;
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(246, 245, 250, 0.61);
  }
}

.common_employee_names_wrapper {
  padding-left: 56px;
  margin-top: -12px;
}

.comment_box_wrapper {
  margin: 0 42px;
}

.employees_inner_wrapper {
  h4 {
    padding: 12px 0 8px 50px;
    font-weight: 600;
  }

  .employee_names_wrapper {
    padding-left: 42px;
  }
}

.text_main_wrapper {
  display: inline-flex;
}

.individual_feedback_wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  li {
    font-size: 16px;
    margin-left: 36px;
    font-weight: 500;
    padding-bottom: 8px;
    overflow-wrap: break-word;
    padding-right: 38px;
  }

  li::marker {
    color: #B5AFCA;
  }
}

.message_box_header {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;

  .header_wrapper {
    display: flex;
    align-items: center;
    padding-left: 56px;

    .individual_message_text {
      font-size: 14px;
      font-weight: 500;
      padding-left: 8px;
    }
  }
}

:global(.comment_box_wrapper) {

  li {
    font-size: 16px;
    margin-left: -22px;
    font-weight: 500;
    padding-bottom: 8px;
    overflow-wrap: break-word;
    padding-right: 38px;
  }

  li::marker {
    color: #B5AFCA;
  }
}

:global(.modal-body) {
  padding: 0;
}

.content_wrapper {
  padding: 42px;
}

.modal_footer {
  text-align: left;
  justify-content: flex-start;
  border-top: none;
  padding: 42px;

  .btn_close {
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 21px;
    font-size: 14px;
    background-color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
}

.your_feedback {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  table {
    margin-bottom: 0;

    th {
      border-top: none;
      border-bottom: none;
      padding: 24px;

      h3 {
        font-weight: 600;
      }
    }

    td {
      padding: 24px;

      p {
        font-size: 18px;
        font-weight: 600;
      }

      ul {
        margin-bottom: 0;

        li {
          font-size: 16px;
          margin-left: -22px;
          font-weight: 500;
        }

        li::marker {
          color: #B5AFCA;
        }
      }
    }
  }
}
