.header {
  justify-content: flex-start;
  align-items: center;
  color: #e24c5e;
  padding: 30px 20px;

  span {
    margin: 0 15px;
    svg {
      width: 22px;
      height: auto;
    }
  }
  div {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}

.modalBody {
  padding: 35px;

  .empContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .empInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 20px 0;
      align-items: center;
      min-width: 33%;

      .empImg {
        min-width: 55px;
        min-height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        margin: 0 10px 0 0;

        img {
          width: 55px;
          border-radius: 50%;
        }
      }

      .empDetail {
        h6 {
          font-weight: 600;
          width: 177px;
        }
      }
    }
  }

  .infoMsg {
    color: #b4b0c8;
    font-size: 12px;
    padding: 0 10px;
    width: 85%;
    letter-spacing: 0.5%;

    .link {
      font-weight: 600;
      color: blue;
      cursor: pointer;
    }
  }

  .btnContainer {
    padding: 15px 0;

    button {
      margin-right: 10px;
      width: 150px;
    }
  }
}
