@import "../../theme.scss";

.employee_list {
  .table_data {
    color: $themeColor11;
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
  }
  td {
    padding: 15px 10px;
    position: relative;
    .plus_space {
      align-self: center;
      margin-right: 10px;
      cursor: pointer;
    }
    .user_profile {
      display: inline-flex;
      .user_img {
        width: 56px;
        height: 56px;
        flex: 0 0 56px;
        font-size: 18px;
        color: $whiteColor;
        letter-spacing: 0.21px;
        text-align: center;
        line-height: 52px;
        font-weight: 700;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        margin-right: 10px;
        overflow: hidden;
        border: 3px solid #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user_name {
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        justify-content: center;
        cursor: pointer;
        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: $themeColor11;
        }
        h2 {
          font-size: 14px;
          font-weight: 400;
          color: $primaryText;
        }
      }
    }
  }
}
