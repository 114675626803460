@import "theme.scss";

.assigneepopup_wrapper {
  .modal_header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: $themeColor11;
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.36px;
      color: $textShade1;
    }
    input[type="text"] {
      padding-right: 50px;
    }
  }
  :global(.modal-body) {
    padding: 0;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: 0 2px 4px 0 #e6e6e6;
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $themeColor16;
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $themeColor16;
    }
    .data_container {
      width: 100%;
      height: 390px;
      overflow-y: auto;
      padding: 30px 0px;
      table {
        width: 100%;
        thead {
          tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            td {
              color: $primaryText;
              font-size: 14px;
              font-weight: 700;
              padding-bottom: 20px;
              padding-left: 10px;
              &.name {
                padding-left: 45px;
                width: 60%;
              }
              &.objective_assigned {
                width: 200px;
              }
              &.status {
                width: 10%;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &.inside_row {
              &:hover {
                background-color: white !important;
              }
            }
            &:hover {
              background-color: rgba(112, 51, 255, 0.1);
            }
            &.active {
              background-color: rgba(112, 51, 255, 0.1);
            }
            td {
              padding: 15px 10px;
              position: relative;
              .plus_space {
                align-self: center;
                margin-right: 10px;
              }
              .user_profile {
                display: inline-flex;
                .user_img {
                  width: 56px;
                  height: 56px;
                  font-size: 18px;
                  color: $whiteColor;
                  letter-spacing: 0.21px;
                  text-align: center;
                  line-height: 52px;
                  font-weight: 700;
                  border-radius: 50%;
                  display: inline-flex;
                  justify-content: center;
                  margin-right: 10px;
                  overflow: hidden;
                  border: 3px solid #fff;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .user_name {
                  display: inline-flex;
                  flex-direction: column;
                  vertical-align: top;
                  margin-top: 10px;
                  h1 {
                    font-size: 16px;
                    font-weight: 600;
                    color: $themeColor11;
                  }
                  h2 {
                    font-size: 14px;
                    font-weight: 400;
                    color: $primaryText;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
  .btn_save {
    margin-right: 20px;
    min-width: 146px;
  }
  .assigned_modal_footer {
    text-align: left;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  .assigneepopup_wrapper {
    .modal_header {
      h2 {
        margin: 5px 0 15px;
      }
    }
    :global(.modal-body) {
      .data_container {
        table {
          tbody {
            tr {
              &:hover {
                background-color: transparent;
              }
              &.active {
                background-color: rgba(112, 51, 255, 0.1);
              }
              td {
                padding: 10px;
                .user_img {
                  vertical-align: middle;
                }
                .user_name {
                  margin-top: 0;
                  width: calc(100% - 62px);
                  vertical-align: middle;
                }
                .check_infoneer {
                  .checkmark {
                    top: 8px;
                  }
                  .container_checkbox {
                    width: 450px;
                    height: 76px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .assigneepopup_wrapper {
    :global(.modal-body) {
      .data_container {
        overflow-x: hidden;
        height: calc(100vh - 270px);
        table {
          width: 100%;
          thead {
            display: none;
          }
          tbody {
            tr {
              td {
                display: block;
                &:nth-child(2) {
                  float: right;
                  vertical-align: middle;
                  &::before {
                    content: "|";
                    margin-right: 20px;
                  }
                }
                &:nth-child(3) {
                  float: right;
                  vertical-align: middle;
                }
                .not_assigned,
                .assigned {
                  display: inline-block;
                }
                .check_infoneer {
                  left: 0;
                  height: 100%;
                  width: 100%;
                  .container_checkbox {
                    width: 100%;
                  }
                  .checkmark {
                    left: 40px;
                  }
                }
                .check_no_of_obj {
                  position: relative;
                  &:before {
                    content: "Objective Assigned :";
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .assigned_modal_footer {
      :global(.btn) {
        min-width: 100px;
        strong {
          font-weight: 600;
        }
      }
    }
  }
}
