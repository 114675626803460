@import "theme.scss";

.card_wrapper {
  margin-bottom: 30px;

  .card_container {
    background-color: $whiteColor;
    padding: 25px;
    border-radius: 8px;

    :global(.graph-item) {
      border-right: 0;

      .card_title {
        min-height: 43.19px;
      }

      .export {
        text-align: end;
        padding-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        color: $primaryColor;

        span {
          cursor: pointer;
        }
      }
    }
  }
}

.legent_wrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  .lenegd_list {
    list-style-type: none;
    padding: 0;
    margin: 0px 0 20px;
    @include flexbox;

    .legend_item {
      font-weight: 500;
      font-size: 13px;
      color: $primaryText;
      @include flexbox;
      @include align-items(center);
      margin-right: 22px;

      &:last-child {
        margin-right: 0;
      }

      .legend_color {
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .card_wrapper {
    :global(.graph-item) {
      border-bottom: 0;
    }

    :global(.graph-item > div) {
      display: block;
    }
  }
}