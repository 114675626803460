.savecancle_box {
  padding: 18px 25px 18px 25px;
  .btn_save {
    margin-right: 20px;
    min-width: 146px;
  }
  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 146px;
  }
}

.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;
  span {
    color: #928fa0;
    font-weight: 500;
  }
}
@media only screen and (max-width: 480px) {
  .savecancle_box {
    display: flex !important;
    justify-content: center !important;
    .btn_save {
      margin-right: 0px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
