.saveBox {
  padding: 18px 25px 18px 25px; // change as per request
}
.save_box_left {
  .btn {
    min-width: 146px;
  }
  .btn_reviewed {
    min-width: 200px;
  }
}
.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;
  span {
    color: #928fa0;
    font-weight: 500;
  }
}
.save_box_center {
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;
  span {
    color: #928fa0;
    font-weight: 500;
  }
}

/* Media Query Responsive */

@media only screen and (max-width: 479px) {
  :global(.box) {
    &:global(.box-flex-center) {
      display: block;
      text-align: center;
    }
  }
  .save_box_left {
    .btn {
      min-width: 130px;
    }
  }
  .save_box_right {
    margin-top: 15px;
  }
}
