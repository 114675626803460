@import "theme";
.checkin_extension_wrapper {
  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.tags_wrapper) {
    width: 289px;
  }

  :global(.box) {
    :global(.box-inner-pad) {
      :global(.form-group) {
        margin-bottom: none !important;
      }
    }
  }

  :global(.date_picker_time_wrapper) {
    :global(.calendar_wrapper) {
      :global(.react-datepicker-wrapper) {
        :global(.form-control) {
          padding-left: 10px !important;
          background: #ffffff url("/static/media/ic_calendar_2.5e55b623.svg") no-repeat 172px center;
        }
      }
    }
  }

  .save_box_left {
    :global(.btn) {
      min-width: 150px;
      font-size: 14px;

      :global(strong) {
        font-weight: 600;
      }
    }
  }
  .form_group_wrapper {
    display: flex;
  }

}

.checkin_extension_wrapper_table{
      :global(.box-heading-wrapper) {
        border-bottom: none !important;
    }
    td {
      font-weight: 500;
      color: #322E46;
      padding: 1.2rem !important;
    }
    th {
      font-weight: 600;
      color: #322E46;
      padding: 1.2rem !important;
      border-bottom: none !important;
    }
  }


@media only screen and (max-width: 767px) {
  .form_group_wrapper {
    display: block !important;
  }
  .checkin_extension_wrapper {
    :global(.date_picker_time_wrapper) {
      :global(.calendar_wrapper) {
        :global(.react-datepicker-wrapper) {
          :global(.form-control) {
            padding-left: 10px !important;
            background: #ffffff url("/static/media/ic_calendar_2.5e55b623.svg") no-repeat 152px center;
          }
        }
      }
    }
  }
}
