@import "theme";
//employee comparison
.data_container {
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        td {
          color: $primaryText;
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 20px;
          &.name {
            padding-left: 45px;
            width: 450px;
          }
          &.count {
            width: 240px;
            .show_cycleName_tooltip {
              position: relative;
              display: inline-block;
              .info_icon {
                margin-left: 10px;
                cursor: pointer;
                &:hover {
                  + .show_cycleName_tooltip_info {
                    opacity: 1;
                    visibility: visible;
                    top: 35px;
                  }
                }
              }
              .show_cycleName_tooltip_info {
                padding: 10px 15px;
                border: 1px solid #eaeaea;
                min-width: 210px;
                box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
                background-color: #fff;
                position: absolute;
                z-index: 2;
                font-weight: 600;
                top: 45px;
                left: -10px;
                opacity: 0;
                visibility: hidden;
                border-radius: 4px;
                transition: 0.5s all;
                pointer-events: none;
                span {
                  color: #928fa0;
                  font-weight: normal;
                  display: block;
                }
                &:before {
                  left: 24px;
                  height: 7px;
                  width: 7px;
                  border-top: 1px solid #eaeaea;
                  border-right: 1px solid #eaeaea;
                  transform: rotate(-45deg);
                  top: -4px;
                  background-color: #fff;
                  margin: auto;
                  content: "";
                  position: absolute;
                  box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
          .location {
            width: 240px;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: rgba(112, 51, 255, 0.1);
        }
        &.active {
          background-color: rgba(112, 51, 255, 0.1);
        }
      }
    }
  }
  .table_export_btn {
    margin-top: 20px;
    margin-right: 20px;
    min-width: 146px;
  }
}

.export_button {
  min-width: 146px;
  margin: 20px 20px 20px 0;
}
