@import "theme";

.roll_based_skill_wrapper {
  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.define_admin_roles_wrapper {
  padding-bottom: 10px;

  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }

  :global(.form-control) {
    min-height: 50px;
  }

  :global(.dropdown-multiselect) {
    :global(.form-group) {
      margin-right: 50px;
    }
    :global(.tags_wrapper) {
      position: relative;
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      margin-top: 10px;
      width: 300px;
      &.role_dropdown {
        width: 200px;
      }
      :global(.multi-select) {
        :global(.dropdown-container) {
          border-color: rgba(0, 0, 0, 0.1);
          height: 47px;
          :global(.dropdown-heading) {
            height: 100%;
            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 15px;
            }
            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
        }
        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }
            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }
              :global(span) {
                position: relative;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }
                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }
            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;
              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }
            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.roles_history_wrapper {
  margin-bottom: 7px;
  + :global(.pagination-col) {
    margin-top: 0;
  }

  > :global(.box-heading-wrapper) {
    :global(.box-heading) {
      width: 70%;
    }
  }
  .defined_roles_table {
    padding: 15px 0 0;
    position: relative;

    :global(.table) {
      margin-bottom: 0;
    }

    thead {
      padding: 0;
      list-style: none;

      th {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        border-top: 0;
        border-bottom-width: 1px;
        &.roles {
          padding-left: 25px;
          width: 13%;
        }
        &.department {
          width: 13%;
        }
        &.location {
          width: 14%;
        }
        &.band {
          width: 12%;
        }
        &.indiaDD {
          width: 14%;
        }
        &.modules {
          width: 14%;
        }
        &.action {
          width: 5%;
        }
        &.right {
          width: 14%;
        }
      }
    }

    tbody {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      td {
        font-size: 15px;
        font-weight: 500;
        color: #37324b;
        position: static;
        &:first-child {
          padding-left: 25px;
        }
      }
    }
  }
}

.save_box_left {
  :global(.btn) {
    min-width: 150px;
    font-size: 14px;

    :global(strong) {
      font-weight: 600;
    }
  }
}

.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;

  span {
    color: #928fa0;
    font-weight: 500;
  }
}

.save_box_center {
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;

  span {
    color: #928fa0;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1024px) {
  .roll_based_skill_wrapper {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .roll_based_skill_wrapper {
    .defined_roles_table {
      overflow: auto;
      overflow-y: hidden;
    }
  }
}

@media screen and (max-width: 767px) {
  .roll_based_skill_wrapper {
    .res_title_btn {
      text-align: left !important;
      margin-bottom: 20px;
    }
    .define_admin_roles_wrapper {
      :global(.dropdown-multiselect) {
        display: block !important;
        :global(.form-group) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
