@import "../../../theme";

.cycleTeamWrapper {
  display: block;
  :global(.box) {
    :global(.box-heading) {
      :global(h3) {
        margin-top: 5px;
      }
    }
  }
}
:global(.private-assessment-wrapper) {
  :global(.form-group) {
    :global(label) {
      line-height: 22px;
      margin-bottom: 0px;
      :global(.form-text) {
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }
  &:global(.box) {
    :global(.ratingBarBox) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 25px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
  .workload_rating_guide {
    font-size: 15px;
    color: #928fa0;
    font-weight: 500;
    padding-bottom: 7px;
    margin-top: 20px;
  }
  :global(.textarea-wrapper) {
    :global(.attached-pin-icon) {
      right: 20px;
    }
  }
  :global(.radio-wrapper) {
    :global(.form-group) {
      :global(label) {
        margin-bottom: 0;
      }
    }
    .cb_pv_wrapper {
      margin-top: 20px;
      &:global(.limit-reached) {
        :global(.custom-checkbox) {
          :global(label) {
            color: #f07575;
          }
        }
      }
      .rank_value_wrapper {
        @include flexbox;
        margin-bottom: 8px;
        @include align-items(flex-start);
        &:last-child {
          margin-bottom: 0;
        }
      }
      .rank_value {
        margin-left: 10px;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.36px;
        line-height: 22px;
        color: $primaryText;
      }
      .correct_wrong_answer {
        display: inline-block;
        margin-left: 10px;
      }
    }
    .form_option_list {
      list-style: none;
      padding-left: 0;
      margin: 20px 0 0;
      &:global(.limit-reached) {
        :global(.custom-checkbox) {
          :global(label) {
            color: #f07575;
          }
        }
      }
      li {
        display: inline-block;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        &:global(.w-100) {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
      :global(.custom-checkbox) {
        margin-bottom: 0;
        cursor: auto;
        input {
          &:disabled {
            + :global(label) {
              &:after {
                background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
                background-size: 100%;
                -ms-background-size: 100%;
                -moz-background-size: 100%;
              }
            }
          }
          &:checked {
            + :global(label) {
              &:after {
                background-image: url(../../../assets/images/ic_radio_active_2.svg);
              }
            }
          }
        }
        :global(label) {
          font-size: 14px;
          padding-left: 30px;
          line-height: 20px;
          cursor: pointer;
          &::before {
            height: 20px;
            width: 20px;
          }
          &:after {
            height: 20px;
            width: 20px;
            background-image: url(../../../assets/images/ic_radio_active_2.svg);
          }
        }
      }
      li {
        &:last-child {
          :global(.custom-checkbox) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.saveBox {
  padding: 18px 25px 18px 25px; // change as per request
}
.save_box_left {
  .btn {
    min-width: 146px;
  }
  .btn_reviewed {
    min-width: 200px;
  }
}
.save_box_right {
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;
  span {
    color: #928fa0;
    font-weight: 500;
  }
}
.save_box_center {
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #b4b0c8;
  span {
    color: #928fa0;
    font-weight: 500;
  }
}
.categoryTitle {
  font-weight: 600;
  color: #322e46;
  letter-spacing: 0.19px;
  padding-top: 30px;
  padding-left: 25px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .categoryTitle {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .save_box_right {
    margin-top: 15px;
  }
}
