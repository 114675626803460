@import "theme";

.certificte_box {
  display: flex;
  flex-direction: column;
  width: 60%;

  &:last-child {
    width: 40%;
    padding-left: 25px;
  }

  > :global(span) {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.41px;
    margin-bottom: 10px;
  }
  .cf_title,
  .file_info_tooltip {
    display: inline-block;
    :global(.box-info) {
      margin-top: 1px;
    }
  }

  :global(.file-listing) {
    :global(li) {
      width: 100%;
    }
  }

  :global(.card_action) {
    min-width: 100%;

    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value) {
        font-size: 16px;
      }
    }
  }

  .selected_file {
    :global(.btn-secondary) {
      min-width: 120px;
      font-size: 14px;
    }
  }
}

.save_box_btn {
  :global(.btn) {
    min-width: 160px;
    font-size: 14px;
  }
}

.form_option_list {
  list-style: none;
  padding-left: 0;
  margin: 0 10px 0 0;

  :global(.custom-checkbox) {
    margin-bottom: 0;
    cursor: auto;

    input[type="radio"] {
      + :global(label) {
        font-size: 15px;
        padding-left: 25px;
        line-height: 20px;
        font-weight: 600;
        color: #928fa0;
        cursor: pointer;

        &::before {
          height: 20px;
          width: 20px;
        }

        &:after {
          height: 20px;
          width: 20px;
          background-color: #fff;
          background-image: url(../../assets/images/ic_radio_purple.svg);
        }
      }
    }
  }

  li {
    display: inline-block;

    &:last-child {
      :global(.custom-checkbox) {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}

.days365 {
  font-weight: 500;
  font-size: 16px;
  color: #928fa0;
  letter-spacing: 0.36px;
}

:global(.certificate-date-wrapper) {
  @include flexbox;
  @include align-items(center);
  flex-wrap: wrap;
}

.date_title {
  margin-bottom: 15px !important;
}

.cl_t_wrapper {
  display: inline-block;

  :global(.date_picker_time_wrapper) {
    :global(.calendar_wrapper) {
      width: 205px;
      margin-right: 4px;

      :global(.form-control) {
        padding-right: 5px !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  :global(.certificate-date-wrapper) {
    .cl_t_wrapper {
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .certificte_box {
    width: 100%;

    &:last-child {
      width: 100%;
      margin-top: 15px;
      padding-left: 0;
    }
  }
  .cl_t_wrapper {
    :global(.date_picker_time_wrapper) {
      :global(.calendar_wrapper) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
  .file_info_tooltip {
    :global(.info-attach-file) {
      left: -163px;
      &::before {
        left: 90px;
      }
    }
  }
}
