.date_time_selection_wrapper {
  :global(.time_zone_wrapper) {
    width: 400px;
    :global(.select__control) {
      border: none;
      :global(.select__value-container) {
        padding: 0 !important;
      }
      :global(.select__single-value) {
        font-weight: 400;
      }
      :global(.select__indicator.select__dropdown-indicator) {
        background-image: url(../../../assets/images/ic-accordion-arrow-down.svg);
        background-repeat: no-repeat;
        width: 24px;
        svg {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .date_time_selection_wrapper {
    :global(.time_zone_wrapper) {
      width: 300px;
    }
  }
}
