@import "theme";

.to_do_wrapper {
  .todo_svg {
    top: 3px;
    position: relative;
    margin-right: 10px;
  }
  .to_do_card_wrapper {
    padding: 20px;
    position: relative;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .to_do_item_wrapper {
      padding-bottom: 20px;
      display: flow-root;
    }
    .scheduling_heading {
      padding-bottom: 10px;
      color: #3e3266;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.36px;
      line-height: 19px;
    }
    .to_do_heading {
      padding-bottom: 18px;
      color: #3e3266;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.36px;
      line-height: 19px;
    }
    .highlight_to_do_subheading {
      position: relative;
      color: #37324b;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.32px;
      line-height: 17px;
      span {
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: #7033ff;
        }
      }
    }
    .to_do_subheading {
      position: relative;
      color: #37324b;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.32px;
      line-height: 17px;
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: #b5afca;
        left: -19px;
        top: 5px;
      }
    }
    .to_do_description {
      padding-bottom: 10px;
      font-weight: 500;
      letter-spacing: 0.32px;
      line-height: 16px;
      .single_link {
        color: #7033ff;
      }
    }
    .avatar_list {
      margin: 5px 0 0 15px;
      .avatar_container {
        //   width: 200px;
        padding-left: 10px;
      }

      .avatar {
        cursor: pointer;
        position: relative;
        background: $primaryColor;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 2px solid #fff;
        float: left;
        margin-left: -10px;
        transition: all 300ms ease-in-out;
        color: $primaryText;
        line-height: 50px;
        text-align: center;
        font-weight: 700;
        box-shadow: 0 1px 2px 0 rgba($color: #000000, $alpha: 0.2);
        .avtar_item {
          height: 100%; //100% instead of 48px
          width: 100%; //100% instead of 48px
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          top: 0px;
          left: 0px;
          color: $whiteColor;
          img {
            width: auto;
            height: 100%;
            vertical-align: top;
          }
        }
      }

      .avatar:hover {
        z-index: 1;
        height: 50px;
        width: 50px;
        margin-right: 13px;
      }

      .tooltip {
        position: absolute;
        top: 125%;
        left: 50%;
        width: auto;
        transform: translate(-50%, -50%);
        background: #fff;
        text-align: center;
        padding: 0px 10px;
        opacity: 0;
        border-radius: 2px;
        pointer-events: none;

        transition: all 100ms ease-in-out;
        transition-delay: 50ms;
        font-size: 12px;
        white-space: nowrap;
        height: 30px;
        line-height: 30px;
        border: 1px solid #eee;
      }

      .tooltip:before {
        content: "";
        position: absolute;
        top: -0.4rem;
        left: 50%;
        border-radius: 2px;
        margin-left: -0.5rem;
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
        z-index: -1;
        background: #fff;
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
      }

      .avatar:hover > .tooltip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
    :global(.card_action) {
      :global(.select_dropdown .select__control) {
        min-height: 25px !important;
      }
    }
  }
}
