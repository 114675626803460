.percentage_view_box_wrapper {
  .percentage_info_wrapper {
    position: relative;
    padding: 25px 25px 0 25px;
    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.6px;
      line-height: 22px;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        padding-right: 40px;
        &:last-child {
          padding-right: 0;
        }
        .percentage_dot {
          background: #6dd400;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-flex;
          margin: 7px 7px;
        }
        .percentage_info_name {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          padding-bottom: 10px;
          line-height: 21px;
        }
        .percentage_info_number {
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0.26px;
          line-height: 24px;
        }
      }
    }
  }
}
