.simple_container{
    width:400px;
    height: 350px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 100px auto;
    padding: 30px 20px ;
    font-family: Arial, Helvetica, sans-serif;
  }
  .p{
    font-size: 22px;
    font-weight: 600;
    margin: 2px 0px 20px 0px;
  }
  .label{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    display: block;
    margin: none;
    
  }
  .input {
    width: 100%;
    padding: 12px 10px;
    margin: 8px 0;
    box-sizing: border-box;
  }
  .nestLoginButton{
    min-width: 140px;
    margin-top: 20px;
  }