@import "theme";

.feeling-box-wrapper {
  position: relative;
  .feeling-error-box {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    left: 0;
    margin: auto;
    right: 0;
    width: 170px;
    text-align: center;
    top: -48px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
    &::after {
      content: "";
      background-color: #fff;
      height: 7px;
      width: 7px;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    span {
      color: #050505;
      font-weight: 500;
      font-size: 14px;
      background: url(../../assets/images/ic_info_pink.svg) no-repeat left center;
      padding: 3px 0 4px 25px;
    }
  }
}

.feeling-box {
  overflow-x: hidden;
  border: 1px solid transparent;
  // border: 1px solid $themeColor2;
  //box-shadow: inset 0 0 0 1px $whiteColor;
  background: $whiteColor;
  @include transition(all, 1s, ease);

  // &.one {
  //   background: $whiteColor;
  //   border-color: $themeColor5;
  // }

  // &.two {
  //   background: $whiteColor;
  //   border-color: $mode2Dark;
  // }

  // &.three {
  //   background: $whiteColor;
  //   border-color: $themeColor6;
  // }

  // &.four {
  //   background: $whiteColor;
  //   border-color: $themeColor7;
  // }

  // &.five {
  //   background: $whiteColor;
  //   border-color: $secondaryColor;
  // }

  .main_title {
    font-size: 22px;
    font-weight: 500;
    color: $headingColor2;
    text-align: center;
    margin: 0 0 30px 0;
  }
}

.rating {
  display: flex;
  @include justify-content(space-around);
  position: relative;
  margin: 0 25px 3px 25px;
  padding: 0;

  li {
    display: flex;
    // padding: 0 20px 0 0;
    position: relative;
    @include transition(all, 1s, ease);

    &:last-child {
      padding-right: 0;
    }

    &.opacity {
      opacity: 0;
      transform: scale(0);
      pointer-events: none;
    }

    &.opened {
      @include transition-delay(0.5s);

      .outer-panel {
        @include transition-delay(1.3s);
        opacity: 1;
        left: 75px;
      }

      &:first-child .outer-panel {
        @include transition-delay(1s);
      }
    }

    &.active {
      z-index: 1;
    }
  }
}

.rating .opacity {
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}

.outer-panel {
  @include position(absolute, 0, auto, auto, 90px);
  z-index: -9999;
  margin: auto;
  min-width: 300px;
  min-height: 20px;
  background-color: transparent;
  @include border-radius(5px);
  @include transition(all, 0.8s, ease);
  overflow: visible;
  opacity: 0;
}

.rating li:after {
  position: absolute;
  /* content: ''; */
  height: 50px;
  width: 50px;
  left: 0;
  top: 0;
  @include border-radius(50%);
  transform: scale(0);
  @include transition(all, 0.5s, ease);
  background: linear-gradient(217deg, rgba(169, 98, 255, 1) 0%, rgba(112, 51, 255, 1) 90%);
}

li.active:after {
  transform: scale(1);
}

.rating li .count {
  height: 50px;
  width: 50px;
  border: 1px solid $textShade3;
  display: inline-block;
  @include border-radius(50%);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background-color: #fff;
  color: #b8b4cc;
  cursor: pointer;
  @include transition(all, 0.5s, ease-in-out);
  position: relative;
  top: 2px;
  left: 2px;
  z-index: 1;
}

.rating li.active .count {
  background: #00c4bc;
  background: linear-gradient(217deg, rgba(113, 220, 201, 1) 0%, rgba(0, 196, 188, 1) 90%);
  color: #fff;
  z-index: 2;
  border-color: transparent;
  transform: scale(1.35);
}
.rating li.active .one .count {
  background: linear-gradient(217deg, $mode1Dark 0%, $themeColor8 90%);
}
.rating li.active .two .count {
  background: linear-gradient(217deg, $mode2Light 0%, $mode2Dark 90%);
}
.rating li.active .three .count {
  background: linear-gradient(217deg, $mode3Light 0%, $mode3Dark 90%);
}
.rating li.active .four .count {
  background: linear-gradient(217deg, $mode4Light 0%, $mode4Dark 90%);
}
.rating li.active .five .count {
  background: linear-gradient(217deg, $mode5Light 0%, $mode5Dark 90%);
}

.one .rating li:first-child .one .count {
  background: linear-gradient(217deg, $mode1Dark 0%, $themeColor8 90%);
  border-color: $themeColor8;
  color: #fff;
  transform: scale(1.25);
}
.two .rating li:nth-child(2) .two .count {
  background: linear-gradient(217deg, $mode2Light 0%, $mode2Dark 90%);
  border-color: $mode2Dark;
  color: #fff;
  transform: scale(1.25);
}
.three .rating li:nth-child(3) .three .count {
  background: linear-gradient(217deg, $mode3Light 0%, $mode3Dark 90%);
  border-color: $mode3Dark;
  color: #fff;
  transform: scale(1.25);
}
.four .rating li:nth-child(4) .four .count {
  background: linear-gradient(217deg, $mode4Light 0%, $mode4Dark 90%);
  border-color: $mode4Dark;
  color: #fff;
  transform: scale(1.25);
}
.five .rating li:nth-child(5) .five .count {
  background: linear-gradient(217deg, $mode5Light 0%, $mode5Dark 90%);
  border-color: $mode5Dark;
  color: #fff;
  transform: scale(1.25);
}

// .four .rating li:not(.active) .four .count-text{
//   opacity: 1;
//   visibility: visible;
// }

.rating li.active:hover .count {
  color: $whiteColor;
}

.rating li .count:before,
.rating li .count:after {
  position: absolute;
  content: "";
  display: block;
  width: 200%;
  height: 100%;
  left: -50%;
  z-index: -1000;
  @include transition(all, 0.5s, ease-in-out);
  background-repeat: no-repeat;
}

.rating li .count:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #00c4bc 20%, transparent 30%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%), radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #00c4bc 15%, transparent 20%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%), radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%), radial-gradient(circle, #00c4bc 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.rating li .count:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #00c4bc 15%, transparent 20%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%), radial-gradient(circle, #00c4bc 20%, transparent 20%),
    radial-gradient(circle, #00c4bc 20%, transparent 20%), radial-gradient(circle, #00c4bc 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.rating li .count:active {
  transform: scale(0.9);
  background-color: #00c4bc;
  @include box-shadow(0, 2px, 25px, rgba(255, 96, 0, 0.2), false);
}

.rating li .count.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}

.rating li .count.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.count-wrapper {
  display: table;
  @include flex-direction(column);
  @include align-items(center);
  position: relative;
  margin: 0 0 30px 0;
  width: 54px;
  height: 54px;
  padding: 0;
  @include border-radius(50%);
  box-shadow: none;
  vertical-align: middle;
}

.count-wrapper.one:hover .count {
  color: $themeColor8;
}
.count-wrapper.one:hover::before {
  border-top-color: $themeColor8;
  border-right-color: $themeColor8;
  border-bottom-color: $themeColor8;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}
.count-wrapper.one:hover::after {
  border-top-color: $themeColor8;
}

.count-wrapper.two:hover .count {
  color: $mode2Dark;
}
.count-wrapper.two:hover::before {
  border-top-color: $mode2Dark;
  border-right-color: $mode2Dark;
  border-bottom-color: $mode2Dark;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}
.count-wrapper.two:hover::after {
  border-top-color: $mode2Dark;
}

.count-wrapper.three:hover .count {
  color: $themeColor6;
}
.count-wrapper.three:hover::before {
  border-top-color: $themeColor6;
  border-right-color: $themeColor6;
  border-bottom-color: $themeColor6;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}
.count-wrapper.three:hover::after {
  border-top-color: $themeColor6;
}

.count-wrapper.four:hover .count {
  color: $themeColor7;
}
.count-wrapper.four:hover::before {
  border-top-color: $themeColor7;
  border-right-color: $themeColor7;
  border-bottom-color: $themeColor7;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}
.count-wrapper.four:hover::after {
  border-top-color: $themeColor7;
}

.count-wrapper.four:hover .count {
  color: $secondaryColor;
}
.count-wrapper.four:hover::before {
  border-top-color: $secondaryColor;
  border-right-color: $secondaryColor;
  border-bottom-color: $secondaryColor;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}
.count-wrapper.four:hover::after {
  border-top-color: $secondaryColor;
}

.count-wrapper:hover::before {
  border-top-color: #00c4bc;
  border-right-color: #00c4bc;
  border-bottom-color: #00c4bc;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}

.count-wrapper:hover::before {
  border-top-color: #00c4bc;
  border-right-color: #00c4bc;
  border-bottom-color: #00c4bc;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}

.count-wrapper:hover::before {
  border-top-color: #00c4bc;
  border-right-color: #00c4bc;
  border-bottom-color: #00c4bc;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}

.count-wrapper::before {
  border: 4px solid transparent;
}

.count-wrapper::before,
.count-wrapper::after {
  top: 0;
  left: 0;
  @include border-radius(100%);
  @include transition(all, 0.5s, ease-out);
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.count-wrapper:hover::after {
  border-top: 4px solid #00c4bc;
  border-left-width: 3px;
  border-right-width: 3px;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
  transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
  transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
}

.count-wrapper::after {
  border: 0 solid transparent;
}

.count-wrapper:hover .count {
  border-color: transparent;
  color: #00c4bc;
}

.count-text {
  display: inline-block;
  text-align: center;
  width: 110px;
  color: $textShade1;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: -25px;
  @include transition(all, 0.5s, ease-in-out);
  opacity: 0;
  left: -25px;
  visibility: hidden;
  white-space: nowrap;
  text-align: center;
}

.active.opened .count-wrapper:hover .count-text {
  visibility: hidden;
  opacity: 0;
  bottom: -25px;
}

.count-wrapper:hover .count-text {
  opacity: 1;
  visibility: visible;
  bottom: -20px;
}

.panel-body .questions {
  font-size: 17px;
  font-weight: 500;
  color: $themeColor9;
  cursor: pointer;
}

.question-form {
  display: none;
  position: absolute;
  width: 100%;
  top: -5px;
  left: 0;
  bottom: 0;
  form {
    height: 100%;
    .form-group {
      height: 100%;
      textarea {
        height: 100% !important;
      }
    }
  }
  .form-group {
    display: inline-block;
    width: 100%;
    textarea {
      height: 65px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
      resize: none;
      overflow-y: auto;
      font-size: 14px;
      @include border-radius(4px);

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
    .float-right {
      color: #928fa0;
      font-size: 14px;
      font-weight: 500;
      margin-top: 3px;
    }
  }
}

li.active.opened.staticActive {
  left: 0;
  position: absolute;
  /* pointer-events: none; */
}
.resizeActive * {
  transition-delay: 0s !important;
  transition: 0s !important;
  transition-property: none !important;
}
.resizeActive li.active.opened {
  transform: translateX(0) !important;
  position: absolute;
  left: 0;
}
.resizeActive li.active.opened .outer-panel {
  width: 100px !important;
}
// li.active.opened.staticActive .question-form form .form-group textarea{
//   height: auto !important;
// }
/*start responsive*/
@media (min-width: 991px) {
  .feeling-box-wrapper {
    height: calc(100% - 30px);
    .feeling-box {
      height: 100%;
    }
  }
}
@media (max-width: 1100px) {
  .feeling-box {
    .main_title {
      font-size: 18px;
    }
  }
  .one .rating li:first-child .one .count,
  .two .rating li:nth-child(2) .two .count,
  .three .rating li:nth-child(3) .three .count,
  .four .rating li:nth-child(4) .four .count,
  .five .rating li:nth-child(5) .five .count {
    transform: scale(1);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1179px) {
  .outer-panel {
    min-width: 220px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .outer-panel {
    min-width: 550px;
  }
}
@media (max-width: 767px) {
  .outer-panel {
    min-width: 370px;
  }
  .feeling-box {
    .main_title {
      margin-bottom: 20px;
    }
  }
  .rating {
    margin: 0 15px;
  }
  .count-wrapper {
    margin-bottom: 20px;
  }
}
@media (max-width: 479px) {
  .outer-panel {
    min-width: 190px;
  }
}
