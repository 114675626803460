@import "theme";
.date_range_wrapper {
  :global(.box-heading-wrapper) {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &.graph_wrapper {
    .heading_wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left_side_heading {
        width: 50%;
        text-align: center;
      }
      h5,
      h6 {
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
    :global(.box-heading-wrapper) {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      padding-bottom: 25px;
    }
    :global(.date_picker_time_wrapper) {
      :global(.date_range_calendar_wrapper) {
        width: 750px;
      }
    }
    .graph_view {
      :global(.graph-item) {
        border-right: none;
      }
    }
  }
  :global(.date_picker_time_wrapper) {
    :global(.date_range_calendar_wrapper) {
      width: 570px;
      @include flexbox;
      @include align-items(center);
      margin-right: 0;
      :global(.react-datepicker-wrapper) {
        :global(.form-control) {
          padding-left: 12px;
          padding-right: 40px;
          background-position: 96.5% center;
        }
      }
    }
  }
  :global(.cycle-list-wrapper) {
    :global(.form-group) {
      width: 350px;
      margin-bottom: 0px;
      margin-right: 0px;
    }
    :global(.custom-default-dd-box) {
      margin-right: 0px;
      :global(.select__control) {
        min-height: 47px;
        border-color: rgba(0, 0, 0, 0.1);
        border-width: 1px;
      }
    }
  }
}

.graph_wrapper {
  .right_text_title {
    padding-left: 83px;
  }
}

.legent_wrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  .lenegd_list {
    list-style-type: none;
    padding: 0;
    margin: 35px 0 10px;
    @include flexbox;
    .legend_item {
      font-weight: 500;
      font-size: 13px;
      color: $primaryText;
      @include flexbox;
      @include align-items(center);
      margin-right: 22px;
      &:last-child {
        margin-right: 0;
      }
      .legend_color {
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        position: relative;
        top: -1px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .graph_wrapper {
    :global(.box-inner-pad) {
      :global(.d-flex) {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
      }
    }
  }
  .legent_wrapper {
    .lenegd_list {
      flex-wrap: wrap;
      @include justify-content(center);
      margin-top: 15px;
      margin-bottom: 0;
      .legend_item {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .date_range_wrapper {
    .heading_wrapper {
      flex-wrap: wrap;
      .left_side_heading {
        width: 100% !important;
      }
    }
  }
}
