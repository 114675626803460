.checkins_submited {
  p {
    margin: 0;
    padding: 40px 0px;
    text-align: center;
    font-size: 20px;
    color: #322e46;
    letter-spacing: 0.23px;
    text-align: center;
    font-weight: 600;
    span {
      color: #7b1cff;
    }
  }
}
