@import '../../theme.scss';

:global(.card)
{
    :global(.card_info)
    {
        border-bottom: 1px solid #ededed;
    }
}
.to_do_row {
    width:100%;
    position: relative;
    z-index: 1;
    padding: 20px;

    .title {
        color: $textShade1;
        margin: 0 0 15px 0;
    }

    .details {
        width: 100%;
        @include flexbox;
        @include flex-direction(column);
        .point {
            color: $themeColor11;
            font-weight: 500;
            font-size: 16px;
            // margin: 0 0 5px 0;
        }
        .subPoint {
            color: $themeColor11;
            font-weight: 400;
            font-size: 14px;
            a {
                font-weight: 500;
            }
        }

    }
    
}

.objectiveBox {
    padding: 19px 15px;
    background: $whiteColor;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    margin: 0 0 20px 0;
    justify-content: space-between;
    display: flex;
    &:last-child{
        margin-bottom: 0;
    }
    .objectiveLeft{
        display: flex;
        width: 70%;
        flex-wrap: wrap;
        align-items: center;
        .name{
            display: inline-block;
            width: calc(100% - 30px);
        }
    }
    .objectiveRight{
        display: flex;
        width: 30%;
        justify-content: flex-end;
        align-items: center;
    }

    .status { 
        width:14px;
        height: 14px;
        @include border-radius(50%);
        background: $borderColor;
        margin: 0 10px 0 0;
        display: inline-block;
        &:global(.red) {
            background: $themeRed;   
        }
        &:global(.green) {
            background: $themeGreen;
        }
        &:global(.yellow) {
            background: $themeYellow;
        }
    }

    .duration {
        @include flexbox;
        @include align-items(center);
        min-width: 100px;

        :global(.img) {
            margin-right: 5px;
        }

        .text {
            font-size: 14px;
            color: $textShade1;
            position: relative;
            top: 1px;
        }
    }

    :global(.select_dropdown_text) {
        width:170px;
        margin-right: 15px;

        :global(.select__control) {
            min-height: 30px;
        }
    }

    .percentage {
        font-weight: 600;
        font-size: 18px;
        position: relative;
        top: 1px;
    }
}
.commentStack {
    width: calc(100% - 40px);
    @include flexbox;
    position: relative;
    z-index: 1;
    padding: 20px 15px 10px 10px;
    background: $whiteColor;
    border-radius:5px;
    margin: 20px 20px 10px;
    border: 1px solid rgba(0, 0, 0, 0.10);


    .user_img {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
        @include flex(0 0 40px);
        overflow: hidden;
        @include border-radius(50%);
        font-size: 16px;
        font-weight: 600;
        color: $whiteColor;
        line-height: 38px;
        text-align: center;
        img {
          max-width: 100%;
          height: 100%;
          width: 100%;
        }
      }

    .detailArea {
        width: 100%;
        @include flexbox;

        .headArea {
            width: 100%;
            @include flexbox;
            @include justify-content(space-between);
            margin: 0 0 10px 0;
        }
        .namespace {
            @include flexbox;
            @include align-items(baseline);

            .name {
                font-size: 16px;
                font-weight: 600;
                color: $primaryText;
                margin: 0 5px 0 0;
            }
            .time {
                font-weight: 500;
                font-size: 12px;
                color: $textShade1;
            }
        }
        .actinArea {
            @include flexbox;
            @include justify-content(flex-end);

            a {
                margin: 0 0 0 10px;
            }
        }
    }

}

:global(.transforming_better){
    margin-bottom: 0;
    .commentStack{
        flex-direction: column;
        .commentStackList{
            @include flexbox;    
        }
        .headArea{
            flex-direction: column;
            .received_info{
                margin-top: 5px;
                margin-bottom: 0;
                font-weight: 400;
            }
        }
        .namespace{
            justify-content: space-between;
            .nameinfotext{
                display: flex;
                align-items: center;
            }
        }     
        // Add comment and View comment box start
        :global(.comment_box_wrapper) {
            margin: 7px 0 15px 0;
            background: $whiteColor;
            padding: 0;
            border: none;
            width: 100%;
            align-items: center;
            :global(.user_img) {
                width: 40px;
                height: 40px;
                @include flex(0 0 40px);
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            :global(.form_group_comment) {
                margin-bottom: 0;
                :global(.form-control){
                    height: 46px !important;
                }
            }
        }
    }
}

:global(.recentlyReceived){
    .commentStack{
        padding: 20px 15px;
        border-radius: 4px;
        margin-bottom: 10px;
        ~ .commentStack{
            margin-top: 0;
        }
    }
    .user_img_wrapper{
        display: flex;
        margin-right: 10px;
        .user_img{
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
            margin-right: 0;
            &:nth-child(2){
                box-shadow: -4px 0 0 0 #fff;
                margin-left: -2px;
            }
        }
    }
    .detailArea{
        .headArea{
            margin: 0;
        }
        .namespace{
            flex-direction: column;
            .nameinfotext{
                display: flex;
                .name{
                    font-size: 14px;
                }
            }
            .received_info{
                margin-bottom: 0;
                font-weight: 400;
                a{
                    font-weight: 500;
                }
            }
        }
    }
}
:global(.viewall) {
    margin: 10px 0 20px 20px;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    color: #7033FF;
    font-weight: 500;
}

@media only screen and(max-width: 767px){
    .objectiveBox{
        flex-direction: column;
        padding: 15px;
        .objectiveLeft{
            width: 100%;
        }
        .objectiveRight{
            width: 100%;
            margin-top: 10px;
            @include justify-content(flex-start);
        }
    }
}