@import "theme.scss";

svg {
  pointer-events: none;
}

* {
  outline: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// svg {
//   pointer-events: none;
// }

.cursor_default {
  cursor: default !important;
}

a {
  cursor: pointer;
}

.fs-500 {
  font-weight: 500;
}

/*for sticky footer & footer-module.scss*/
html {
  position: relative;
  min-height: 100%;

  body {
    padding-bottom: 110px;
    height: 100%;
  }
}

/*******************/

body {
  background: $bodyColor;
  color: $primaryText;
  line-height: 1.4;
  font-size: 14px;
  font-weight: normal;
}

.overflow-visible {
  overflow: visible !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

a {
  color: $primaryColor;
  @include transition(all, 0.4s, ease-out);
  text-decoration: none;

  &:hover {
    color: $primaryLightColor;
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.breakall {
  word-break: break-all !important;
}

pre {
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: $primaryText;
  margin-bottom: 0px;
  font-family: Gilroy;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.no-border {
  border-width: 0 !important;
}

.svg * {
  @include transition(all, 0.1s, ease-in-out);
}
.cursor-pointer {
  cursor: pointer;
}
// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

.gap30 {
  height: 30px;
}

.gap40 {
  height: 40px;
}

.gap80 {
  height: 80px;
}

.gap120 {
  height: 120px;
}

.plr-25 {
  padding: 0 25px;
}

.semi-bold {
  font-weight: 600;
}

.page-title {
  font-size: 20px;
  font-weight: 500;
  color: $primaryText;
  padding-bottom: 30px;

  span {
    color: $textShade1;
  }
}

.st-breadcrumb {
  font-weight: 500;
  font-size: 14px;
  color: $textShade1;
  margin: 5px 0 30px;
  display: block;

  a {
    color: $textShade1;
    &:hover {
      color: $primaryColor;
    }
  }

  span {
    margin-left: 10px;
    padding-left: 16px;
    position: relative;

    &::before {
      content: " ";
      height: 7px;
      width: 7px;
      border-top: 1px solid #928fa0;
      border-right: 1px solid #928fa0;
      transform: rotate(45deg);
      position: absolute;
      left: -3px;
      top: 5px;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
}

.rating-with-svg {
  display: flex;
  justify-content: center;
  span {
    margin-left: 15px;
    margin-top: -2px;
  }
}

input::-ms-clear {
  display: none;
}
.word-break {
  word-break: break-word !important; // break-word instead of break-all
}
// List items
.common_list_items {
  margin-bottom: 0;
  padding-left: 0;
  word-break: break-word !important; // break-word instead of break-all

  li {
    font-size: 16px;
    color: $primaryText;
    letter-spacing: 0.36px;
    line-height: 19px;
    list-style: none;
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;

    &:last-child {
      &.mb-3 {
        margin-bottom: 0 !important;
      }
    }

    &::after {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $textShade2;
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
    }
  }
}

/* ====================
 Form Elements Start
 ==================== */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
}

input[type="number"] {
  border-width: 0 0 1px;
  border-style: none none solid;
  border-color: transparent transparent #b5afca;
  text-align: center;
  &.limit-reached {
    border-color: #f07575;
  }
}
input[type="text"] {
  &.limit-reached {
    border-color: #f07575;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
}

input[type="number"] {
  border-width: 0 0 1px;
  border-style: none none solid;
  border-color: transparent transparent #b5afca;
  text-align: center;
}
.form-group {
  label {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 19px;
    color: $labelColor;
    margin-bottom: 10px;

    .optional {
      font-size: 14px;
      font-weight: 500;
      color: $labelOptional;
    }

    .form-text {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.36px;
      color: $textShade1;
      font-weight: 500;
    }
  }

  .textarea-wrapper {
    position: relative;

    &::after {
      background: $whiteColor url("assets/images/ic_textarea.svg") no-repeat 5px center;
      height: 16px;
      width: 18px;
      content: "";
      bottom: 1px;
      right: 2px;
      pointer-events: none;
      position: absolute;
      pointer-events: none;
    }

    .link_icon {
      position: absolute;
      right: 60px;
      top: 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        svg {
          g {
            fill: $primaryColor;
          }
        }
      }
    }

    &:hover .link_icon {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }

    .dustbin_icon {
      position: absolute;
      right: 20px;
      top: 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        svg {
          g {
            fill: $primaryColor;
          }
        }
      }
    }

    &:hover .dustbin_icon {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }

    .private-lock-icon {
      position: absolute;
      right: 20px;
      top: 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }

    &:hover .private-lock-icon {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }

    .attached-pin-icon {
      position: absolute;
      right: 40px;
      top: 4px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }
    .only-attached-icon {
      position: absolute;
      right: 20px;
      top: 4px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }

    &:hover .attached-pin-icon {
      opacity: 1;
      visibility: visible;
    }
    &:hover .only-attached-icon {
      opacity: 1;
      visibility: visible;
    }

    &.locked {
      .private-lock-icon {
        opacity: 1;
        visibility: visible;

        .lock-icon-privacy {
          * {
            fill: $primaryColor;
          }
        }
      }
    }
  }

  textarea {
    &.form-control {
      min-height: 70px !important;
      resize: vertical;
      max-height: 200px;
      &.with-file-attachment {
        padding-right: 35px;
      }
      &.manager_comments {
        min-height: 47px !important;
        resize: vertical;
        padding-right: 40px;
      }

      &:hover {
        border: 1px solid #828282;
        box-shadow: 0 2px 4px 0 #e6e6e6;
      }

      &:focus {
        border: 1px solid #7134ff;
        box-shadow: 0 2px 4px 0 #e8deff;
      }

      &.limit-reached {
        border: 1px solid #f07575;
        box-shadow: 0 2px 4px 0 #ffd9da;
      }

      &.private-comment {
        padding-right: 65px;
      }
    }
  }

  .form-control {
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 0.36px;
    line-height: 19px;
    color: $primaryText;
    font-weight: 500;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 13px 12px;
    height: 46px;

    &:disabled {
      background-color: #fbfbfb;
      border-color: #b8b4cc;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $inputColor;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $inputColor;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: $inputColor;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $inputColor;
    }

    &:focus,
    &:hover {
      border-color: $textShade1;
      box-shadow: none;
    }

    &.form-control-rounded {
      border-radius: 24px;
      padding: 10px 24px;
      height: 40px;
    }

    &.form-control-sm {
      height: 34px;
      font-size: 16px;
      font-weight: 500;
      padding: 7px 14px;
    }
  }

  .auto-save-input {
    position: relative;
    margin-top: 12px;

    .form-control {
      padding-right: 40px;
    }

    img,
    .svg {
      visibility: hidden;
      position: absolute;
      top: 50%;
      z-index: 1;
      right: 15px;
      margin-top: -8px;
      @include transition(all, 0.4s, ease-out);
    }

    &.saved {
      .form-control {
        background: $textShade4;
        border-color: rgba(0, 0, 0, 0.1);
      }

      img,
      .svg {
        visibility: visible;
      }
    }

    + .auto-save-input {
      margin-top: 20px;
    }
  }

  select {
    cursor: pointer;
    @include x-appearance(none);
    padding-right: 10px;
    background: $whiteColor url("assets/images/ic_select_arrow.svg") no-repeat 95% 50%;
  }
}

.form-postfix {
  position: relative;

  input {
    &:after {
      content: "";
    }
  }

  .form-postfix-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/* ====================
 Form Elements End
 ==================== */

/* *******************
Help to Grow 
********************** */
.helptogrow_comments {
  font-size: 16px;
  color: $primaryText;
  letter-spacing: 0.36px;
  line-height: 19px;
  list-style: none;
  position: relative;
  padding-left: 16px;
  margin-bottom: 10px;
}

.quiz_result_align {
  padding-left: 30px !important;
}

.user_comment_details {
  font-size: 16px;
  font-weight: normal;
  color: $primaryText;
  @include flex(2);

  .user_name {
    font-size: 16px;
    color: $primaryText;
    letter-spacing: 0.36px;
    font-weight: 600;
  }

  .comment_time {
    font-size: 12px;
    color: $textShade1;
    font-weight: 500;
    margin-left: 5px;
  }

  .private-comment-selected {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #928fa0;
    font-weight: 500;
    margin-top: 10px;

    img {
      margin: 0 5px 0 0;
      position: relative;
      top: -1px;
    }
  }
}

/* ====================
 Button style Start
 ==================== */
.btn {
  border-radius: 24px;
  line-height: 19px;
  padding: 11px;
  @include transition(all, 0.6s, ease-out);
  font-size: 14px;

  &:focus {
    box-shadow: none;
  }

  &.dw-obj-btn {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
  }

  &.btn-no-border {
    border-color: transparent;
  }

  &.btn-primary {
    border: 0;
    //@include gradient(#A962FF, #7033FF, vertical);
    background: rgb(112, 51, 255);
    // background: linear-gradient(182deg, $primaryLightColor 0%, $primaryColor 100%);
    background-image: linear-gradient(180deg, #a962ff 0%, #7033ff 100%);
    @include transition(all, 0.6s, ease-out);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;

    &::after {
      content: "";
      border-radius: 24px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include box-shadow(1px, 1px, 8px, rgba(120, 58, 255, 0.3), false);
      opacity: 0;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:focus,
    &:active,
    &:hover {
      @include gradient($primaryLightColor, $primaryColor, vertical);
      -webkit-transform: scale(1.25, 1.25);
      transform: scale(1.25, 1.25);
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;

      &::after {
        opacity: 1;
      }
    }
  }

  &.btn-link {
    &:hover,
    &:focus,
    &:focus:active {
      text-decoration: none;
    }
  }

  &.btn-border {
    &.btn-primary {
      position: relative;
      background: $whiteColor;
      z-index: 1;
      color: $primaryColor;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 15px;
      border: 1px solid lighten($textShade3, 11.9%);
      @include transition(all, 0.6s, ease-in);

      &::after {
        @include box-shadow(0, 3px, 15px, rgba(0, 0, 0, 0.2), false);
      }

      &:hover {
        //background: $primaryColor;
        //color: $whiteColor;
        //font-weight: 600;
        &::after {
          opacity: 1;
        }
      }
    }

    &.btn-secondary {
      background: $whiteColor;
      border: 1px solid rgba(184, 180, 204, 0.5);
      border-radius: 21px;
      font-size: 14px;
      color: $primaryColor;
      letter-spacing: 0;
      text-align: center;

      &:hover,
      &:focus {
        background: $whiteColor;
        border: 1px solid rgba(184, 180, 204, 0.5);
        box-shadow: 1px 1px 8px 0 rgba(195, 195, 195, 0.3);
        color: $primaryColor;
      }
    }
  }

  &.btn-disabled {
    cursor: not-allowed;
    background: #f1f1f1;
    border-radius: 20px;
    font-weight: 500;
    color: #afafaf;
    letter-spacing: 0;
    text-align: center;
    opacity: 1;
    pointer-events: none;

    &:hover,
    &:focus,
    &:active {
      background: #f1f1f1;

      &:after {
        box-shadow: none;
      }
    }
  }
}

.btn {
  border-radius: 24px;
  line-height: 19px;
  padding: 11px;
  @include transition(all, 0.6s, ease-out);

  &.btn-secondary {
    background: $whiteColor;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: $primaryColor;
    letter-spacing: 0;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      background: $whiteColor !important;
      border: 1px solid rgba(184, 180, 204, 0.5) !important;
      box-shadow: 1px 1px 8px 0 rgba(195, 195, 195, 0.3) !important;
      color: $primaryColor !important;
    }
  }
}

/* ====================
 Button style End
 ==================== */

/* ===================
 ERROR BUBBLE
===================== */

.tooltip {
  margin-left: 9px;
  min-width: 260px;
  max-width: 310px;
  top: 0px !important;

  .tooltip-inner {
    padding: 10px 10px;
    max-width: 310px;
    min-width: 260px;

    .error-bubble-txt {
      font-size: 14px;
      color: #050505;
      letter-spacing: 0;
      text-align: left;
      display: inline-flex;
      width: 83%;
      word-wrap: normal;
    }

    .error-icon {
      display: inline-flex;
      margin-right: 10px;
    }
  }

  &.skill_error_tooltip {
    min-width: 150px;
    max-width: 150px;

    .tooltip-inner {
      border-color: #eaeaea;
      max-width: 150px;
      min-width: 150px;

      .error-bubble-txt {
        width: auto;
        font-weight: 500;
      }

      .error-icon {
        position: relative;
        top: -1px;
        margin-right: 5px;
      }
    }
  }
}

.tooltiptop {
  top: 18px !important;
}

/* ====================
No Data Found 
======================*/
.nodata-found-wrapper {
  text-align: center;
}

.no-record-found-wrpr {
  font-size: 14px;
  font-weight: 500;
  color: #bdbcc3;
  letter-spacing: 0.32px;
  display: flex;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  justify-content: center;
  margin: 0 15px 15px;
  width: calc(100% - 30px);
}

.no-record-found {
  justify-content: center;
  position: relative;
  display: flex;
  font-size: 14px;
  color: #bdbcc3;
  font-weight: 500;
}

.optional-text {
  padding-left: 0.5rem !important;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.36px;
  color: #928fa0;
}

/* ====================
 Box/Card layout Start
 ==================== */
.box {
  background: $whiteColor;
  border-radius: 8px;
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
  margin-bottom: 30px;
  padding: 25px 0 25px; // change as penew request

  .box-edit {
    //position: absolute;
    //right: 35px;
    //top: 15px;
    @include position(absolute, 0, 20px, auto, auto);
    z-index: 1;

    &.box-edit-sm {
      right: 17px;
    }

    &:hover {
      svg {
        g {
          fill: $primaryColor;
        }
      }
    }
  }

  &.box-pad,
  .box-inner-pad {
    // padding: 20px; change as per new request
    padding: 25px;
  }

  .box-inner-pad {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .form-group {
      margin-bottom: 0;

      //margin-top: 4px;
      &.form-group-bottom-space {
        margin-bottom: 20px;
      }
    }

    &.ratingBarBox {
      padding-bottom: 0;
      border-bottom: 0;

      &.nRatingBarBox {
        padding-bottom: 25px;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &.box-flex-center {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
  }
}

.box_inner_title {
  font-size: 18px;
  color: $primaryText;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 12px 0;

  span {
    font-size: 14px;
    color: $textShade1;
    font-weight: 500;
  }
}

.box-light-gradient {
  position: relative;

  * {
    position: relative;
    z-index: 0;
  }

  &:before {
    content: "";
    @include position(absolute, 0, 0, auto, auto);
    @include gradient(#f6f5fa, #ffffff, vertical);
    width: 100%;
    height: 78px;
    display: block;
  }

  &.h38 {
    &::before {
      height: 38px;
    }
  }
}

.box-heading-wrapper {
  padding: 0px 25px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  position: relative;

  &.noborder {
    border-bottom: 0;
  }

  .box-heading {
    width: 100%;

    h2,
    h3 {
      margin-bottom: 0;
    }

    h2 {
      //font-size: 22px;
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.26px;
      color: $headingColor2;

      span {
        &.optional {
          font-weight: 400;
          line-height: 16px !important;
          color: $textShade1;
          font-size: 14px;
        }
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0.36px;
      color: $headingColor3;

      &.box-heading-light {
        font-weight: normal;
        color: $primaryText;
      }
    }
  }

  .box-info {
    position: absolute;
    right: 23px;
    top: 0;
    z-index: 1;

    span {
      color: #7033ff;
      cursor: pointer;
    }

    &.box-info-sm {
      right: 17px;
    }

    &:hover {
      svg {
        g {
          fill: $primaryColor;
        }
      }
    }
  }
  .card_action {
    max-width: 100px;
    @include flex(0 0 200px);
    text-align: right;

    span {
      color: #7033ff;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

.card {
  border: 1px solid #ededed;
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09));
  font-size: 16px;
  color: $primaryText;
  font-weight: 500;
  letter-spacing: 0.36px;
  margin: 0 0 25px 0;

  .card_info {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    min-height: 74px;
    padding: 10px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .card_title {
      @include flexbox;
      @include align-items(center);
      width: 100%;

      .icon-svg {
        top: 3px;
        position: relative;
        margin-right: 10px;
      }

      .card_title_icon {
        max-width: 16px;
        @include flex(0 0 16px);
        margin-right: 10px;
        line-height: 0;
      }

      .card_title_text {
        font-size: 20px;
        font-weight: 600;
      }

      .card_title_description {
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        color: #928fa0;
        letter-spacing: 0.34px;
      }

      &.flex-wrap {
        + .card_action {
          max-width: 170px;
          @include flex(0 0 170px);
        }
      }
    }

    .card_action {
      max-width: 200px;
      @include flex(0 0 200px);
      text-align: right;

      span {
        color: #7033ff;
        cursor: pointer;
      }
    }
  }

  .card_details {
    min-height: 78px;
  }

  .card-footer {
    background: rgba(246, 245, 250, 0.61);
    border: 1px solid #e0dfe2;
    border-radius: 10px !important;
    padding: 10px 10px 10px 20px;
    margin-top: 15px;

    .card-ft-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        font-size: 16px;
        color: $textShade1;
        font-weight: 500;
      }
      .reporting-wrapper {
        flex-direction: column;
        > span {
          font-size: 16px;
          color: #928fa0;
          font-weight: 500;
          width: 100%;
          display: inline-block;
        }
        .reporting-name {
          position: relative;
          font-size: 13px;
          margin-top: 5px;
          padding-left: 15px;
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 2px;
            margin: auto;
            background-color: #b5afca;
            height: 5px;
            width: 5px;
            content: "";
            border-radius: 50%;
          }
        }
      }

      .card_action {
        min-width: 230px;

        .custom-default-dd-box {
          .select__control {
            min-height: 50px;
          }

          .select__indicator-separator {
            display: none;
          }
        }
        .custom-rating-dropdown {
          width: 100%;
          display: inline-block;
          margin-right: 5%;
          + span {
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//adding custom dropdown color
.custom-default-dd-box {
  &.error-box {
    .select__control {
      border-color: #f07575 !important;
    }
  }
  .select__control {
    cursor: pointer;
    box-shadow: none;
  }
  .select__single-value {
    font-size: 15px;
    font-weight: 500;
  }

  .select__menu-list {
    .select__option {
      color: #322e46;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      word-break: break-all;

      &:last-child {
        border-bottom: 0;
      }
    }

    .select__option--is-selected {
      background-color: #fcfaff;
      color: #322e46;
    }
  }
}

.question-drop-down {
  margin-top: 15px;
  z-index: 2;

  &.limit-reached {
    .select__control {
      border-color: #f07575;
    }
  }

  .select__control {
    min-height: 50px;
    border-color: rgba(0, 0, 0, 0.1);
  }

  .select__indicator-separator {
    display: none;
  }

  .select__single-value,
  .select__placeholder {
    font-size: 16px;
    font-weight: 500;
  }
}

.dropdown-menu-right.show {
  .dropdown-item.active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa !important;
  }
}

div[id*="multi-select-dd-box"] {
  display: block;
  height: 44px;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  width: 190px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  .search-wrapper {
    // &::before {
    //   // background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
    //   content: "Select...";
    //   height: 38px;
    //   position: absolute;
    //   width: 25px;
    //   color: #928fa0;
    //   left: 8px;
    //   background-size: 16px;
    //   // background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
    //   // background-size: 18px;
    // }
    &.multiselect-wrapper-added {
      &::before {
        content: "";
        height: 38px;
        position: absolute;
        width: 25px;
        left: 8px;
        background-size: 16px;
      }
    }
  }
  > div {
    height: 100%;
  }

  > div {
    &:first-child {
      border: none;
      white-space: nowrap;
      padding-right: 0;
      text-overflow: ellipsis;
      border-radius: 0;
      overflow: hidden;
      position: static;
      padding-left: 10px;
      line-height: 32px;
      cursor: pointer;

      > span {
        background-color: transparent;
        color: #322e46;
        margin: 0;
        padding: 0;
        font-size: 15px;
        display: inline;
        position: relative;

        ~ span {
          &::before {
            content: ",";
            display: inline-block;
            margin: 0 1px 0 3px;
          }
        }

        > i {
          display: none;
        }
      }
    }

    .searchBox {
      position: absolute;
      right: 1px;
      width: 30px;
      top: 0;
      background: #fff url(assets/images/ic-accordion-arrow-down.svg) no-repeat center;
      background-size: 18px;
      font-size: 0;
      height: 90%;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }
  }

  .optionContainer {
    background-color: #fff;

    li {
      color: #322e46;

      &.highlight,
      &:hover {
        color: #322e46;
        background-color: #fcfaff;
      }
    }
  }
}

div[id*="multi-select-dd-box-with-placeholder"] {
  display: block;
  height: 44px;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  width: 190px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  .search-wrapper {
    &::before {
      // background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
      content: "Select...";
      height: 38px;
      position: absolute;
      width: 25px;
      color: #928fa0;
      left: 8px;
      background-size: 16px;
      // background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
      // background-size: 18px;
    }
    &.multiselect-wrapper-added {
      &::before {
        content: "";
        height: 38px;
        position: absolute;
        width: 25px;
        left: 8px;
        background-size: 16px;
      }
    }
  }
  > div {
    height: 100%;
  }

  > div {
    &:first-child {
      border: none;
      white-space: nowrap;
      padding-right: 0;
      text-overflow: ellipsis;
      border-radius: 0;
      overflow: hidden;
      position: static;
      padding-left: 10px;
      line-height: 32px;
      cursor: pointer;

      > span {
        background-color: transparent;
        color: #322e46;
        margin: 0;
        padding: 0;
        font-size: 15px;
        display: inline;
        position: relative;

        ~ span {
          &::before {
            content: ",";
            display: inline-block;
            margin: 0 1px 0 3px;
          }
        }

        > i {
          display: none;
        }
      }
    }

    .searchBox {
      position: absolute;
      right: 1px;
      width: 30px;
      top: 0;
      background: #fff url(assets/images/ic-accordion-arrow-down.svg) no-repeat center;
      background-size: 18px;
      font-size: 0;
      height: 90%;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }
  }

  .optionContainer {
    background-color: #fff;

    li {
      color: #322e46;

      &.highlight,
      &:hover {
        color: #322e46;
        background-color: #fcfaff;
      }
    }
  }
}

div[id*="multi-select-search-dd-box"] {
  display: block;
  height: 50px;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 4px;
  background-color: #fff;
  .search-wrapper {
    &::before {
      background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
      content: "";
      height: 38px;
      position: absolute;
      width: 25px;
      right: 8px;
      background-size: 16px;
      // background: #fff url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
      // background-size: 18px;
    }
  }
  > div {
    height: 100%;
  }

  > div {
    &:first-child {
      border: none;
      white-space: nowrap;
      margin: 0 40px 0 80px;
      padding-right: 0;
      // text-overflow: ellipsis;
      scrollbar-width: thin;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 0;
      position: static;
      padding-left: 10px;
      line-height: 32px;
      cursor: pointer;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      > span {
        background-color: transparent;
        color: #322e46;
        margin: 0;
        top: 3px;
        padding: 0;
        font-size: 14px;
        display: inline;
        position: relative;

        ~ span {
          &::before {
            content: ",";
            display: inline-block;
            margin: 0 1px 0 3px;
          }
        }
        > i {
          display: none;
        }
      }
    }
  }

  .searchBox {
    position: absolute;
    left: 5px;
    width: 80px;
    top: 0;
    height: 90%;
    bottom: 0;
    margin: auto;
    &::placeholder {
      color: #aaa;
      font-weight: 500;
    }
  }
  > i {
    display: block;
    // [class^="icon_"]:before{

    // }
    &::before {
      font-size: 0;
      background: url(assets/images/ic_search_reset.svg) no-repeat right center;
      height: 10px;
      width: 15px;
      vertical-align: middle;
    }
  }

  .optionContainer {
    background-color: #fff;

    li {
      color: #322e46;

      &.highlight,
      &:hover {
        color: #322e46;
        background-color: #fcfaff;
      }
    }
  }
}

div[id*="multi-select-search-dd-box-disabled"] {
  display: block;
  height: 50px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  opacity: 1;
  background-color: #fbfbfb;
  .search-wrapper {
    &::before {
      background: #fbfbfb url(/static/media/ic-accordion-arrow-down.6e0f35b4.svg) no-repeat center;
      content: "";
      height: 38px;
      position: absolute;
      width: 25px;
      right: 8px;
      background-size: 16px;
    }
  }
  > div {
    height: 100%;
  }

  > div {
    &:first-child {
      border: none;
      white-space: nowrap;
      margin: 0 40px 0 80px;
      padding-right: 0;
      scrollbar-width: thin;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 0;
      position: static;
      padding-left: 10px;
      line-height: 32px;
      cursor: pointer;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      > span {
        background-color: transparent;
        color: #928fa0;
        margin: 0;
        top: 3px;
        padding: 0;
        font-size: 14px;
        display: inline;
        position: relative;

        ~ span {
          &::before {
            content: ",";
            display: inline-block;
            margin: 0 1px 0 3px;
          }
        }
        > i {
          display: none;
        }
      }
    }
  }

  .searchBox {
    position: absolute;
    left: 5px;
    width: 80px;
    top: 0;
    height: 90%;
    bottom: 0;
    margin: auto;
    &::placeholder {
      color: #aaa;
      font-weight: 500;
    }
  }
  > i {
    display: block;
    &::before {
      font-size: 0;
      background: url(assets/images/ic_search_reset.svg) no-repeat right center;
      height: 10px;
      width: 15px;
      vertical-align: middle;
    }
  }

  .optionContainer {
    background-color: #fff;

    li {
      color: #322e46;

      &.highlight,
      &:hover {
        color: #322e46;
        background-color: #fcfaff;
      }
    }
  }
}

#multi-select-dd-box-groups {
  height: auto;
  border: none;
  width: 100%;
  border-radius: 4px;
  padding: 2px 30px 2px 0;

  &:after {
    position: absolute;
    right: 1px;
    width: 30px;
    top: 0;
    background: #fff url(assets/images/ic-accordion-arrow-down.svg) no-repeat center 19px;
    background-size: 18px;
    height: 95%;
    cursor: pointer;
    content: "";
  }

  > div {
    &:first-child {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
      width: 100%;

      > span {
        color: #322e46;
        margin: 0;
        padding: 5px 10px;
        display: inline-block;
        background: rgba(246, 245, 250, 0.61);
        border: 1px solid #e0dfe2;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        margin-right: 10px;

        ~ span {
          &::before {
            display: none;
          }
        }

        > i {
          display: block;

          &::before {
            font-size: 0;
            background: url(assets/images/ic_search_reset.svg) no-repeat right center;
            height: 10px;
            width: 15px;
            vertical-align: middle;
          }
        }
      }
    }

    .searchBox {
      width: auto;
      background: none;
      position: static;
      font-size: 16px;
    }
  }

  .optionContainer {
    li {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.question-dropdown-icon {
  .select__menu-list {
    .select__option {
      padding-left: 40px;
      &:first-child {
        padding-left: 12px;
      }
      &[id*="-option-1"] {
        background: url(assets/images/multichoice_ic_q.svg) no-repeat 15px center;
        background-size: 18px 18px;
      }
      &[id*="-option-2"] {
        background: url(assets/images/singlechoice_ic_q.svg) no-repeat 15px center;
        background-size: 18px 18px;
      }
      &[id*="-option-3"] {
        background: url(assets/images/open_ended_ic_q.svg) no-repeat 15px center;
        background-size: 16px 16px;
      }
      &[id*="-option-4"] {
        background: url(assets/images/droodown_ic_q.svg) no-repeat 15px center;
        background-size: 18px 18px;
      }
      &[id*="-option-5"] {
        background: url(assets/images/slider_ic_q.svg) no-repeat 15px center;
        background-size: 20px 8px;
      }
      &[id*="-option-6"] {
        background: url(assets/images/scaled_ic_q.svg) no-repeat 15px center;
        background-size: 20px 20px;
      }
      &[id*="-option-7"] {
        background: url(assets/images/nps_ic_q.svg) no-repeat 15px center;
        background-size: 18px 18px;
      }
      &[id*="-option-8"] {
        background: url(assets/images/rank_ic_q.svg) no-repeat 15px center;
        background-size: 18px 18px;
      }
      &.select__option--is-selected,
      &:hover {
        background-color: #fcfaff !important;
      }
    }
  }
}

/* ====================
 Box/Card layout End
 ==================== */

/* ====================
 Radio&Checkbox Button Start
 ==================== */
.custom-checkbox {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;

  input[type="checkbox"],
  input[type="radio"] {
    margin: 0;
    opacity: 0;
    @include position(absolute, 0, auto, auto, 0);

    & + label {
      cursor: pointer;
      padding-left: 38px;
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.36px;
      margin-bottom: 0;

      &::before {
        border: 1px solid $textShade1;
        content: "";
        cursor: pointer;
        display: inline-block;
        height: 26px;
        left: 0;
        position: absolute;
        transition: 0.2s linear all;
        width: 26px;
        background-color: $whiteColor;
        border-radius: 4px;
      }

      &::after {
        content: "";
        transform: scale(0);
        transition: 0.2s linear all;
        color: #3bb6b6;
        cursor: pointer;
        left: 0;
        position: absolute;
        top: 0;
        background: #00c4bc url(assets/images/checkbox-arrow.svg) no-repeat center;
        width: 26px;
        height: 26px;
        opacity: 0;
        border-radius: 4px;
      }
    }

    &:checked + label::before {
      background-color: #fbebeb;
      border-style: solid;
      transform: scale(1.2);
      border-color: transparent;
      transition: 0.2s linear all;
      opacity: 0;
    }

    &:checked + label::after {
      transform: scale(1);
      opacity: 1;
      transition: 0.2s linear all;
    }

    &:disabled {
      cursor: not-allowed;

      + label {
        cursor: not-allowed;

        // color: $textShade1;
        &::after,
        &::before {
          cursor: not-allowed;
        }

        &::after {
          background: #eeeeee url(assets/images/ic_checkbox_disabled.svg) no-repeat center;
          transform: scale(1);
          opacity: 1;
        }

        &::before {
          border: none;
        }
      }

      &:checked {
        + label {
          &::after {
            background: #00c4bc url(assets/images/checkbox-arrow.svg) no-repeat center;
          }
        }
      }
    }
  }

  &.custom-checkbox-rounded {
    input[type="checkbox"],
    input[type="radio"] {
      & + label {
        &::before,
        &::after {
          border-radius: 50%;
        }
      }
    }
  }

  &.custom-checkbox-theme1 {
    input[type="checkbox"],
    input[type="radio"] {
      & + label {
        &::after {
          background-color: #7033ff;
        }
      }
    }
  }

  &.custom-checkbox-theme-2 {
    input[type="checkbox"] {
      &:disabled {
        cursor: not-allowed;

        + label {
          cursor: not-allowed;

          &::after,
          &::before {
            cursor: not-allowed;
          }

          &::after {
            background: #eeeeee url(assets/images/ic_checkbox_disabled.svg) no-repeat center;
            transform: scale(1);
            opacity: 1;
          }

          &::before {
            border: none;
          }
        }

        &:checked {
          + label {
            &::after {
              background: #7033ff url(assets/images/checkbox-arrow.svg) no-repeat center;
            }
          }
        }
      }

      &:checked {
        + label {
          &::after {
            background: #7033ff url(assets/images/checkbox-arrow.svg) no-repeat center;
          }
        }
      }
    }
  }

  &.custom-checkbox-sm {
    input[type="checkbox"] + label,
    input[type="radio"] + label {
      padding-left: 26px;

      &::before {
        width: 18px;
        height: 18px;
        top: 3px;
      }

      &::after {
        width: 18px;
        height: 18px;
        top: 3px;
        background-size: 10px !important;
      }
    }
  }

  &.custom-checkbox-theme-1 {
    input[type="checkbox"] + label,
    input[type="radio"] + label {
      &::after {
        background: $primaryColor url(assets/images/checkbox-arrow.svg) no-repeat center;
      }
    }
  }

  &.custom-checkbox-light {
    input[type="checkbox"] + label,
    input[type="radio"] + label {
      color: $textShade1;
      font-weight: 600;
    }
  }
}

/* ====================
 Radio&Checkbox Button End
 ==================== */

/* ====================
 React Select Dropdown Start
 ==================== */
.auto_complete_wrapper {
  z-index: 99999;

  &.appreciate_tbody {
    border-radius: 4px;
    // border: 1px solid $borderColor;
    padding: 0px;
    font-size: 16px;
    margin-top: 0px;
    line-height: 19px;
    color: $primaryText;
    position: relative;
    cursor: text;
    width: 100%;
    margin-bottom: 20px;

    textarea {
      min-height: 45px !important;
      padding: 5px 12px;
    }
  }
}

.tribute-container {
  background: #ffffff;
  border: 1px solid rgba(184, 180, 204, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  line-height: 45px;
  width: 100%;
  z-index: 2;
  max-height: 248px; //max-height instead of height
  min-height: 62px;
  overflow-y: scroll;
  top: 50px !important;
  position: absolute;
  left: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      &:hover,
      &.highlight {
        background: rgba(246, 245, 250, 0.5);
      }

      .appreciate_item {
        border-bottom: 1px solid rgba(184, 180, 204, 0.5);
        display: flex;
        flex-direction: row;
        padding: 10px;
        align-items: center;
        cursor: pointer;

        .appreciate_list_pics {
          display: inline-flex;
          height: 41px;
          width: 41px;
          border-radius: 50%;
          overflow: hidden;
          text-align: center;
          background-color: #f07575;
          justify-content: center;
          font-weight: 600;
          color: #fff;
          margin-right: 10px;
          line-height: 41px;

          img {
            max-width: 100%;
          }
        }

        .appreciate_profile-info {
          display: inline-flex;
          flex-direction: column;

          .appriciatelist_name {
            line-height: normal;
            font-weight: 500;
          }

          .appriciatelist_designation {
            line-height: normal;
            font-size: 12px;
            color: #928fa0;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

.auto_complete_dropdown {
  z-index: 10;

  .auto_complete__control {
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 0.36px;
    line-height: 19px;
    color: $primaryText;
    font-weight: 500;
    border-color: rgba(0, 0, 0, 0.1);
    //padding: 13px 12px;
    min-height: 46px;

    &.auto_complete__control--is-focused {
      border-color: $textShade1;
      box-shadow: none;
    }
  }

  * {
    z-index: 99999;
  }
}

.select_dropdown_text {
  border: 0;
  width: 200px;

  .select__control,
  .select__control--is-focused {
    border: 0 !important;
    box-shadow: none;
  }

  .select__single-value {
    font-size: 14px;
    font-weight: 500;
    color: $primaryColor;
    right: 0;
    cursor: pointer;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    padding: 0px;
    cursor: pointer;

    svg {
      path {
        fill: $primaryColor;
      }
    }
  }
}

.select_dropdown {
  z-index: 10;

  .select__control {
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 0.36px;
    line-height: 19px;
    color: $primaryText;
    font-weight: 500;
    border-color: $borderColor;
    min-height: 46px;
    background: transparent;

    &.select__control--is-focused {
      box-shadow: none;
      border-color: $borderColor;
    }
  }

  .select__menu-list {
    display: block;
    border: 1px solid $primaryColor;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 0;
  }

  .select__option {
    padding: 6px 16px;
    @include flexbox;
    font-size: 14px;
    font-weight: 500;

    &.select__option--is-focused {
      background: $bodyColor;
      color: $primaryText;
    }

    &.select__option--is-selected {
      background: $primaryColor;
      color: $whiteColor;
    }

    .dropdown_icon {
      margin-right: 12px;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  &.select_dropdown_sm {
    .select__control {
      min-height: 0;
    }
  }
}

/* ====================
 React Select Dropdown End
 ==================== */

/* ===================
 Priority Component 
 ===================== */
.priority_carryforward {
  width: 20px;
  @include flex(0, 0, 20px);
  text-align: left;

  a {
    margin-right: 10px;
  }

  .custom-tooltip {
    &.custom-tooltip-top {
      .box-info:hover + .info-attach-file {
        top: -111px;
      }
    }
  }
}

// @keyframes strike{
//   0%   { width : 0; }
//   100% { width: 100%; }
// }
// .strike {
//   position: relative;
// }
// .strike::after {
//   content: ' ';
//   position: absolute;
//   top: 50%;
//   left: 0;
//   width: 100%;
//   height: 1px;
//   background: black;
//   animation-name: strike;
//   animation-duration: 4s;
//   animation-timing-function: linear;
//   animation-iteration-count: 1;
//   animation-fill-mode: forwards;
// }

/* ====================
Create Objective
====================== */
// .textbox_wrapper{
//   background: $whiteColor;
//   border: 1px solid $textShade3;
//   border-radius: 4px;
//   &:hover{
//       background: $whiteColor;
//       border: 1px solid $textShade3;
//       border-radius: 4px;
//   }
//   &:focus{
//       background: $whiteColor;
//       border: 1px solid $primaryColor;
//       box-shadow: 0 2px 4px 0 $textShade1;
//       border-radius: 4px;
//   }
// }
/* ====================
  Page component start
 ==================== */
// Accordion start
.custom-accordion {
  .card {
    border: 0;
    box-shadow: none;
    font-size: 16px;
    color: $primaryText;
    font-weight: 500;
    letter-spacing: 0.36px;

    &:first-child {
      .card-header {
        &:first-child {
          border-top: 0px solid rgba(0, 0, 0, 0.1);
          padding-top: 0;
        }
      }
    }

    .card-header {
      border: 0;
      background: no-repeat;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 0 0 0;

      .btn {
        display: block;
        width: 100%;
        position: relative;
        padding-right: 60px;

        svg,
        svg * {
          @include transition(all, 0.4s, ease-out);
        }

        &.show {
          .accordion-icon {
            svg {
              transform: rotate(180deg);

              g {
                stroke: $primaryColor;
              }
            }
          }
        }
      }
    }
  }

  .accordion-heading {
    width: 100%;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);

    .accordion-heading-left {
      @include flexbox;
      @include align-items(flex-start);
      text-align: left;
      width: 75%;

      h3,
      h4 {
        margin-bottom: 0;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.45px;
        color: $primaryText;
        word-break: break-word;
      }

      h4 {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.36px;
        color: $textShade1;
      }
    }

    .accordion-heading-right {
      text-align: right;

      .btn-toolbar {
        @include justify-content(flex-end);
      }
    }
  }

  .accordion-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
  }
}

.custom-accordion-2 {
  .card {
    border: none;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;

    .card-header {
      background-color: #fff;
      border-radius: 0;
      padding: 15px 15px 15px 30px;
      margin-bottom: 0;
      border-top: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: url(assets/images/ic_expand.svg) no-repeat center;
        top: 17px;
        left: 0;
      }

      button {
        padding: 0;
        font-weight: 500;
        font-size: 15px;
        color: #74798c;
        letter-spacing: 0;
      }
    }

    .show {
      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: url(assets/images/ic_expand_dash.svg) no-repeat center;
        top: 17px;
        left: 0;
      }

      .card-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &:last-child {
      .card-header {
        border-bottom: none;
      }

      .show {
        .card-body {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: none;
        }
      }
    }
  }
}

// Accordion end
.pagetitle_separator {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.currrentCheckInTime {
  @include flexbox;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 25px 0;

  .label {
    color: $textShade1;
    margin: 0 5px 0 0;
  }

  .data {
    color: $headingColor2;
  }
}

.draftSaved {
  @include flexbox;
  @include justify-content(flex-end);
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 25px 0;

  .label {
    color: $textShade2;
    margin: 0 5px 0 0;
  }

  .data {
    color: $textShade1;
  }
}

/***my-dahboard-tab-content****/
.tab-content-dashboard {
  .tab-content {
    @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
    background: #fff;
    @include border-radius(0 8px 8px);
    z-index: 1;
    position: relative;

    .box-light-gradient:before {
      @include border-radius(5px);
    }
  }
}

/*******************************/
// Custom tabs start
.custom-tabs {
  .nav-tabs {
    border-bottom: 0;

    .nav-link {
      border: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.53px;
      line-height: 19px;
      color: $textShade1;
      background: rgba(184, 180, 204, 0.15);
      margin-right: 10px;
      padding: 17px 24px;
      position: relative;

      &.active {
        @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
        color: $primaryText;
        font-weight: 600;
        background: $whiteColor;

        &:after {
          //content: "";
          width: 100%;
          height: 2px;
          background: $whiteColor;
          @include position(absolute, auto, auto, 0, 0);
          z-index: 111;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tab-content {
    // @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.09), false);
    // background: #fff;
    // // border-bottom-left-radius: 8px;
    // // border-bottom-right-radius: 8px;
    // // border-top-right-radius: 8px;
    // @include border-radius(0 8px 8px);
    // min-height: 600px;
    // z-index: 1;
    // position: relative;

    .box-light-gradient:before {
      @include border-radius(5px);
    }
  }

  &.custom-tabs-full-width {
    .nav-tabs {
      @include justify-content(space-between);

      .nav-link {
        @include flex(1);
      }
    }
  }
}

// Custom tabs end
// Table Theme start
.table-theme-1 {
  .table-responsive {
    overflow-x: initial !important;
  }

  table {
    tbody {
      th {
        border-top: 0;
        border-bottom: 1px solid $borderColor;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.47px;
        font-weight: 600;
        padding: 10px;
      }

      tr {
        @include transition(all, 0.2s);

        &:hover {
          color: inherit;
          background-color: rgba(246, 245, 250, 0.5);
        }

        &.expandable {
          &:hover {
            color: inherit;
            background-color: transparent;
          }

          > td {
            padding: 0;
            border: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            tr:first-child td {
              border-top: 0;
            }
          }
        }

        &.no-hover {
          &:hover {
            background-color: transparent;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      td {
        border-bottom: 1px solid $borderColor;
        padding: 14px 10px;
        vertical-align: middle;
      }
    }
  }
}

// Table Theme end
// Action Dropdown start
.action-dropdown-wrapper {
  display: inline-block;

  .btn {
    padding: 0;
    margin: 0;
    background: none;

    &::after,
    &::before {
      display: none;
    }

    &:hover,
    &:focus,
    &:focus:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .action-dropdown {
    width: 36px;
    height: 14px;
    border: 1px solid $textShade2;
    background: #fbfaff;
    border-radius: 8px;
    text-align: center;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    @include transition(all, 0.4s, ease-out);

    .dots {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #d2d0e0;

      + .dots {
        margin-left: 3px;
      }
    }

    &:hover {
      border-color: $primaryColor;
      background: #fbfaff;
    }
  }

  .dropdown-menu {
    display: block;
    //top: 20px !important;
    //transform: translateY(20px) !important;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    border-color: $primaryColor;
    //padding: 13px 0;
    @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.1));
    //@include transition(all,0.4s,ease-in);
    z-index: 100;
    padding: 10px 0;

    &.show {
      //top: 10px !important;
      //transform: translateY(15px) !important;
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }

    .dropdown-item {
      cursor: pointer;
      padding: 6px 16px;
      @include flexbox;
      &:hover {
        color: $primaryColor;
        * {
          color: $primaryColor;
          fill: $primaryColor;
        }
      }
      .dropdown_icon {
        margin-right: 12px;
        &.one_on_one_icon {
          svg {
            * {
              fill: #322e46;
            }
          }
        }
      }
    }
  }

  &.show {
    .action-dropdown {
      border-color: $primaryColor;
    }
  }
}

.items_action_box {
  width: 100px;
  @include flex(0, 0, 100px);
  text-align: right;
  display: flex; //as per new change
  justify-content: flex-end; //as per new change
  .comment_svg_icon {
    margin-right: 10px;
    cursor: pointer;
  }
  a {
    margin-right: 10px;
  }
}

// Action Dropdown end

// Add comment and View comment box start
.comment_box_wrapper {
  @include align-content(flex-start);
  @include border-radius(4px);
  @include flexbox;
  width: 100%;

  &.comment_box_wrapper_padding {
    background: $textShade4;
    padding-left: 20px;
    padding-right: 10px;
    margin-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include calc("width", "100% - 30px");
  }

  .user_img {
    width: 55px;
    height: 55px;
    @include flex(0 0 55px);
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 55px;
      height: 55px;
      object-fit: contain;
    }
  }

  .comment_area_wrapper {
    width: 100%;
  }

  .btn {
    min-width: 144px;
  }

  .form_group_comment {
    position: relative;
    margin-bottom: 12px;

    + .btn {
      margin-top: 15px;
    }

    .comment_option {
      @include center(false, true);
      right: 6px;

      a {
        margin-right: 14px;
      }
    }

    &.form_group_comment_1options {
      .form-control {
        padding-right: 45px;
      }
    }

    &.form_group_comment_2options {
      .form-control {
        padding-right: 70px;
      }
    }

    &.form_group_comment_3options {
      .form-control {
        padding-right: 100px;
      }
    }
  }
}

.comment-popup-error {
  svg {
    * {
      fill: $themeColor1;
    }
  }
}

.popup-comment-icon {
  display: inline-block;
  cursor: pointer;
}

.fg-comment-wrapper {
  .textarea-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 34px);
    margin-right: 15px;
    // need to remove as per QA suggestion
    .manager_comments {
      // resize: none !important;
      max-height: 85px;
    }

    &:after {
      display: none;
    }
  }

  button {
    &.btn {
      background: transparent;
      min-width: auto;
      width: auto;
      padding: 0;
      line-height: 25px;

      &:after {
        display: none;
      }

      &:hover {
        background: transparent;
        box-shadow: none;

        svg {
          * {
            stroke: $primaryColor;
          }
        }
      }

      &:focus,
      &:active {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .manage_comment_view {
    .custom-checkbox {
      input[type="checkbox"],
      input[type="radio"] {
        & + label {
          font-size: 14px;
        }
      }
    }

    .manage_comment_visible_to {
      select {
        padding-top: 0;
        padding-bottom: 0;
        height: 34px;
        font-size: 14px;
      }
    }

    .manage_comment_help {
      font-size: 13px;
    }
  }
}

.manage_comment_view {
  @include flexbox;
  @include align-items(center);
  @include flex-wrap(wrap);
  margin: 12px 0 15px;

  .manage_comment_visible_to {
    width: 140px;
    margin: 0 10px 0 5px;

    select {
      color: $textShade1;
      padding-right: 20px !important;
    }
  }

  .manage_comment_help {
    font-size: 14px;
    color: $textShade1;
    font-weight: 500;
  }
}

.view_comment_box_wrapper {
  @include border-radius(4px);
  background: rgba($color: $textShade4, $alpha: 0.6);
  border: 1px solid $borderColor;
  margin-top: 22px;
  margin-bottom: 0; // margin-bottom: 0 instead of 10px

  .view_comment_box_overview {
    background: #f2f1fb;
    @include flexbox;
    // padding: 21px 15px 21px 24px; //adding new padding in following line in some circumstance
    padding: 21px 15px;
    border-bottom: 1px solid $borderColor;
    font-style: 14px;
    font-weight: 500;

    .view_comment_box_overview_icon {
      margin-right: 10px;

      svg {
        g {
          fill: $primaryColor;
        }
      }
    }

    span {
      font-size: 12px;
      color: $textShade1;
    }
  }

  .view_comment_box_row {
    padding: 15px;
    @include flexbox;
    position: relative;

    & ~ .view_comment_box_row {
      padding-top: 10px;
    }

    & ~ #commentbox {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .comment_box_wrapper {
      padding-bottom: 5px;
    }

    .user_pics,
    .comment_box_wrapper .user_img {
      width: 40px;
      @include flex(0 0 40px);
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      height: 40px;
      flex: 0 0 40px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }

    .user_comment_details {
      font-size: 16px;
      font-weight: normal;
      color: $primaryText;
      @include flex(2);

      .user_name {
        font-size: 16px;
        color: $primaryText;
        letter-spacing: 0.36px;
        font-weight: 600;
      }

      .comment_time {
        font-size: 12px;
        color: $textShade1;
        font-weight: 500;
        margin-left: 5px;
      }
    }

    .remove_user_comment {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;

      &:hover {
        svg {
          circle,
          polygon {
            stroke: $primaryColor;
          }
        }
      }
    }
  }
}

/*reflect only objectve checkins*/
.view_comment_box_overview {
  + .view_comment_box_row {
    &.border-top {
      border-top: 0 !important;
    }
  }
}

.view_comment_box_wrapper {
  .view_comment_box_row {
    &.border-top {
      padding: 15px 15px 15px 5px;
      margin: 0 20px;
    }

    &.pl65 {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
}

/*end*/

.private_comment_box_wrapper,
.one_on_1_comment_box {
  overflow: hidden;

  .view_comment_box_overview {
    background: $whiteColor;
    padding: 15px;
    align-items: center;

    .view_comment_box_overview_icon {
      position: relative;
      top: -1px;
      margin-right: 5px;
    }
  }
}

// Add comment and View comment box end
// Checkin status start
.user_checkin_status {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid $borderColor;
  @include border-radius(4px);
  display: inline-block;

  &.ready {
    color: #33c5ff;
    background: #f0fafd;
    border-color: #cff1ff;
  }

  &.pending {
    color: #fd8c61;
    background: #fcf6f4;
    border-color: #fce6dd;
  }

  &.reviewed {
    color: #00c4bc;
    background: #f5ffff;
    border-color: rgba(36, 218, 210, 0.23);
  }

  &.lapsed {
    background: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    color: #787878;
  }
}

// Checkin status end

// Search employee list start
.global_search_employee_list {
  margin-top: 12px;
  position: relative;
  z-index: 10;

  .input-group-text {
    background: #fff;
    border-right: 0;
    border-color: rgba(0, 0, 0, 0.1);
    font-size: 18px;
    font-weight: 600;
  }

  .input-group {
    overflow: hidden;
    position: relative;
  }

  .made_my_day_type {
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
  }

  button {
    width: 118px;
  }

  .auto_complete__option {
    padding: 0;
    cursor: pointer;

    + .auto_complete__option {
      border-top: 1px solid $borderColor;
    }

    &.auto_complete__option--is-focused {
      background: none;
    }
  }

  .employee_list_item {
    @include flexbox;
    padding: 10px 10px 10px 16px;

    .employee_pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include flex(0 0 46px);
      margin-right: 12px;
    }

    .employee_info {
      .employee_name {
        font-size: 16px;
        font-weight: 500;
        color: $primaryText;
      }

      .employee_deg {
        font-size: 13px;
        font-weight: 500;
        color: $textShade1;
      }
    }

    &:hover {
      .employee_info {
        .employee_name {
          color: $primaryColor;
        }
      }
    }
  }
}

// Search employee list end
// Page level Loader start
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999999;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  z-index: 9999;

  img {
    width: 75px; //75px instead of 150
  }
}

// Page level Loader end
// Circular Progressbar round end strock
.CircularProgressbar {
  .CircularProgressbar-trail {
    stroke-linecap: round !important;
  }

  .CircularProgressbar-path {
    stroke-linecap: round !important;
    stroke: #00e4df !important;
  }

  .CircularProgressbar-text {
    dominant-baseline: text-after-edge !important;
    font-size: 14px !important;
    color: #322e46 !important;
    font-weight: 600 !important;
    fill: #322e46 !important;
  }

  path {
    stroke-width: 4 !important;
  }
}

/* ====================
  Page component end
 ==================== */

/* ====================
 Help class start
 ==================== */

.mt40 {
  margin-top: 40px;
}

.mt30 {
  margin-top: 30px;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt4 {
  margin-top: 4px;
}

.mt3 {
  margin-top: 3px;
}

.mt2 {
  margin-top: 2px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml20 {
  margin-left: 20px;
}

.ml15 {
  margin-left: 15px;
}

.ml10 {
  margin-left: 10px;
}

.ml5 {
  margin-left: 5px;
}

.mr20 {
  margin-right: 20px;
}

.mr15 {
  margin-right: 15px;
}

.mr10 {
  margin-right: 10px;
}

.mr5 {
  margin-right: 5px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt20 {
  padding-top: 20px;
}

.pt15 {
  padding-top: 15px;
}

.pt10 {
  padding-top: 10px;
}

.pt5 {
  padding-top: 5px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl20 {
  padding-left: 20px;
}

.pl15 {
  padding-left: 15px;
}

.pl10 {
  padding-left: 10px;
}

.pl5 {
  padding-left: 5px;
}

.pl65 {
  padding-left: 65px !important;
}

.pr20 {
  padding-right: 20px;
}

.pr15 {
  padding-right: 15px;
}

.pr10 {
  padding-right: 10px;
}

.pr5 {
  padding-right: 5px;
}

.no_mar {
  margin: 0 !important;
}

.no_pad {
  padding: 0 !important;
}

.p_t_n {
  padding-top: 0 !important;
}

.p_b_n {
  padding-bottom: 0 !important;
}

.p_l_n {
  padding-left: 0 !important;
}

.p_r_n {
  padding-right: 0 !important;
}

.p30 {
  padding: 30px;
}

.no_float {
  float: none;
}

.d_b {
  display: block;
}

.inline_flex {
  display: inline-flex;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.breake_all {
  word-break: break-all;
}

.cursor_pointer,
:global(.cursor_pointer) {
  cursor: pointer;
}

.two_column {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(flex-start);
}

.clear {
  clear: both;
}

.truncate,
:global(.truncate) {
  @include truncate(100%);
}
.truncate-250 {
  @include truncate(250px);
}
.text_capitalize {
  text-transform: capitalize;
}

.xoverflow_hidden {
  overflow-x: hidden;
}

.bb0 {
  border-bottom: 0 !important;
}

.link {
  color: #7033ff;
  transition: all 0.4s ease-out;
  text-decoration: none;
  cursor: pointer;
  pointer-events: all;
}

/* ====================
 Help class end
 ==================== */
/*  =====================
Wiating for Submition
Checkins Lapsed
=======================  */
.notice_image_page {
  .user_info {
    @include flexbox;
    padding: 0 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include align-items(center);

    .user_img {
      width: 72px;
      height: 72px;
      margin: 0 15px 0 0;
      @include flex(0 0 72px);
      overflow: hidden;
      @include border-radius(50%);
      font-size: 26px;
      font-weight: 600;
      color: $whiteColor;
      line-height: 72px;
      text-align: center;
      img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        position: relative;
        top: -2px;
      }
    }

    .user_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
    }

    .user_designation {
      font-size: 16px;
      font-weight: 400;
      color: $primaryText;
    }
  }

  .user_details {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    width: 100%;
    padding-right: 30.33%;
  }

  .user_details_right {
    text-align: right;
  }

  .user_date_of_submission {
    font-size: 15px;
    font-weight: 500;
    color: $textShade1;
    margin-bottom: 5px;

    span {
      color: $primaryText;
    }
  }

  .user_checkin_status {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 10px;
    border: 1px solid $borderColor;
    @include border-radius(4px);
    display: inline-block;

    &.pending {
      color: #fd8c61;
      background: #fcf6f4;
      border-color: #fce6dd;
    }
  }

  .myPageView {
    .user_details {
      padding-right: 0;
    }

    .userBandWrapper,
    .userInfoText {
      display: none;
    }
  }
}

[contenteditable]:not(:focus):empty:before {
  content: attr(placeholder);
  display: block;
  color: #ccc;
}

/* ====================
Custom Modal Popup (Win & Callenges)
====================== */
.custom_modal_popup {
  .modal-lg {
    .custom_modal_container {
      &.custom_lg_modal_container {
        padding: 0;

        .modal-header,
        .modal-footer {
          padding: 20px 25px;
        }
      }
    }
  }

  .custom_modal_container {
    padding: 40px;

    .modal-body {
      padding: 0px;

      h1 {
        color: $primaryText;
        font-size: 18px;
        font-weight: 500;
      }

      P {
        padding: 20px 0px 10px 0px;
        margin: 0px;
      }
      ul {
        margin-bottom: 0;
      }
    }

    .custom_modal_instruction {
      word-wrap: break-word;
    }

    .custom_modal_button {
      margin-top: 30px;

      button {
        width: 117px;
        height: 40px;
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
}

.view_certificate_modal {
  .modal-dialog {
    width: 650px;
    max-width: calc(100% - 1rem);

    .custom_modal_container {
      padding: 0;
    }

    .custom_modal_button {
      margin-top: 0;
      padding: 20px 25px;
    }
  }
}

/* ====================
 Tooltip Style start
 ==================== */
.tooltip {
  .tooltip-inner {
    background-color: $whiteColor;
    font-size: 14px;
    font-weight: 500;
    color: $primaryText;
    border: 1px solid rgba(184, 180, 204, 1);
  }

  .arrow {
    &:before {
      border-top-color: $whiteColor;
      z-index: 2;
      top: -1px;
    }

    &:after {
      left: -1px;
      top: -1px;
      position: absolute;
      @include position(absolute, -1px, auto, auto, -1px);
      content: "";
      z-index: 1;
      border-color: transparent;
      border-style: solid;
      border-width: 0.5rem 0.5rem 0 !important;
      border-top-color: rgba(184, 180, 204, 0.5);
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &:before {
        top: 1px;
        border-bottom-color: $whiteColor;
      }

      &:after {
        @include position(absolute, -1px, auto, auto, -1px);
        content: "";
        z-index: 1;
        border-color: transparent;
        border-style: solid;
        border-width: 0 0.5rem 0.5rem !important;
        border-bottom-color: rgba(184, 180, 204, 0.5);
      }
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &:before {
        left: -1px;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-color: transparent;
        border-left-color: #fff;
      }

      &:after {
        @include position(absolute, -2px, 0, auto, auto);
        content: "";
        z-index: 1;
        border-color: transparent;
        border-style: solid;
        border-width: 0.5rem 0 0.5rem 0.5rem !important;
        border-left-color: rgba(184, 180, 204, 0.5);
      }
    }
  }

  &.bs-tooltip-right {
    .arrow {
      &:before {
        right: -1px;
        border-width: 0.4rem 0.4rem 0.4rem;
        border-color: transparent;
        border-right-color: #fff;
      }

      &:after {
        @include position(absolute, -2px, auto, auto, -9px);
        content: "";
        z-index: 1;
        border-color: transparent;
        border-style: solid;
        border-width: 0.5rem 0.5rem 0.5rem !important;
        border-right-color: rgba(184, 180, 204, 0.5);
      }
    }
  }
}

//start custom tooltip
.outer-table-wrapper {
  .custom-table {
    .table-responsive {
      table {
        .custom-tooltip {
          &.custom-tooltip-top {
            .box-info {
              margin: 0;

              + .info-attach-file {
                top: -130px;
              }

              &:hover {
                + .info-attach-file {
                  top: -115px;
                }
              }
            }
          }
        }
      }
    }
  }
  .tab_filter {
    .check_in_search_bar {
      display: flex;
      align-items: center;
      form {
        width: 100%;
      }
    }
  }
}

/*start tooltip for info file*/
.custom-tooltip {
  position: relative;

  .box-info {
    margin: 3px 0 0 5px;
    cursor: pointer;

    &:hover {
      + .info-attach-file {
        opacity: 1;
        visibility: visible;
        top: 35px;
      }
    }
  }

  &.custom-tooltip-top {
    .box-info {
      margin: 0;

      &:hover {
        + .info-attach-file {
          opacity: 1;
          visibility: visible;
          top: -85px;
        }
      }
    }

    .info-attach-file {
      min-width: 315px;
      @include box-shadow(0, 3px, 4px, rgba(0, 0, 0, 0.09), false);
      left: -45px;
      margin: auto;
      top: -100px;
      padding: 15px 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);

      &::before {
        top: auto;
        bottom: -6px;
        transform: rotate(135deg);
        box-shadow: none;
        height: 11px;
        width: 11px;
        left: 47px;
        border-top-color: rgba(0, 0, 0, 0.2);
        border-right-color: rgba(0, 0, 0, 0.2);
      }

      .checkin-cycle {
        font-weight: 600;
        color: #322e46;
        letter-spacing: 0.47px;
        margin-bottom: 10px;
        display: inline-block;
      }

      .tTitle {
        color: $textShade2;
        font-weight: 600;
        font-size: 14px;
        margin: 0 0 5px 0;
      }

      .tDateBox {
        @include flexbox;
        @include justify-content(flex-start);
        @include flex-direction(row);
        @include align-items(center);

        .tCalendar {
          width: 14px;
          height: 16px;
          @include flexbox;
          margin: 0 5px 0 0;
        }

        .tDate {
          @include flexbox;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: $primaryText;
        }
      }

      .tDelays {
        font-size: 12px;
        font-weight: 400;
        padding-left: 21px;

        .red {
          color: red;
        }
      }
    }
  }
}

.info-attach-file {
  padding: 10px 15px;
  border: 1px solid #eaeaea;
  min-width: 330px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 45px;
  left: -10px;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transition: 0.5s all;
  pointer-events: none;

  &:before {
    left: 20px;
    height: 7px;
    width: 7px;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    transform: rotate(-45deg);
    top: -4px;
    background-color: #fff;
    margin: auto;
    content: "";
    position: absolute;
    box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.15);
  }

  .attachment-title {
    color: #7033ff;
    font-weight: 600;
    font-size: 15px;
  }

  ul {
    margin: 5px 0 15px;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 15px;
      color: #322e46;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b4b0c8;
        bottom: 0;
      }
    }
  }

  .attachment-note {
    color: #322e46;
    font-weight: 600;
  }

  p {
    margin: 3px 0 0;
    font-size: 13px;
    color: #928fa0;
    font-weight: 400;
  }
}

// POPOver style
.Theme2Tooltip {
  min-width: 315px; //290px instead of 315px in sliderdot
  max-width: 600px;
  max-height: 300px !important;
  margin-left: 100px !important;
  margin-bottom: 19px !important;
  @include box-shadow(0, 3px, 4px, rgba(0, 0, 0, 0.09), false);

  .popover-body {
    padding: 15px 20px;

    .tTitle {
      color: $textShade2;
      font-weight: 600;
      font-size: 14px;
      margin: 0 0 5px 0;
    }

    .tDateBox {
      @include flexbox;
      @include justify-content(flex-start);
      @include flex-direction(row);
      @include align-items(center);

      .tCalendar {
        width: 14px;
        height: 16px;
        @include flexbox;
        margin: 0 5px 0 0;
      }

      .tDate {
        @include flexbox;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: $primaryText;
      }
    }

    .tDelays {
      font-size: 12px;
      font-weight: 400;
      padding-left: 21px;

      .red {
        color: red;
      }
    }
  }

  .tooltip-inner {
    max-width: 320px;
    width: 320px;
    max-height: 110px !important;
    overflow: hidden;
  }

  &.bs-popover-top {
    .arrow {
      height: 30px !important;

      &:before {
        // left:-8px;
        // bottom:-8px;
        @include position(absolute, auto, auto, -8px, -8px);
        border-width: 1rem 1rem 0;
      }

      &:after {
        @include position(absolute, auto, auto, -7px, -8px);
        border-width: 1rem 1rem 0;
      }
    }
  }
}

/* ====================
 Tooltip Style start
 ==================== */

.bs-tooltip-bottom {
  .arrow {
    top: 1px !important;
  }
}

/* Welcome Alert Starts */
.alert_welcome {
  background: $themeColor3;
  border-color: $themeColor2;
  padding: 25px 35px 25px 25px;

  .close {
    color: $primaryColor;
    opacity: 1;
  }

  .title {
    color: $primaryText;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    color: $primaryText;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  a {
    font-weight: 600;
  }
}

/* Welcome Alert Ends */

/* =====================
    Toast 
 ===================== */

.toastMain {
  border-radius: 8px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

.Toastify__toast--success {
  background-color: #fff !important;
}

.Toastify__toast--error {
  background-color: #fff !important;
}

.Toastify__toast--info {
  background-color: #fff !important;
}

.Toastify__toast--warning {
  background-color: #fff !important;
}

.Toastify__progress-bar--success {
  background-color: #eaf9da !important;
}

.Toastify__progress-bar--error {
  background-color: #fdecec !important;
}

.Toastify__close-button {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  opacity: 0.6 !important;
}

/* ================= 
Notifications
=================== */

.notification_wrapper {
  position: fixed;
  right: -400px;
  top: 0;
  width: 400px;
  height: 100%;
  transition: 0.5s all;
  box-sizing: border-box;
  z-index: 9999; // 11 instead of 3 -> aagain change 9999 instead of 11
  display: block;

  &.show_notification {
    right: 0;

    .notification_overlay {
      z-index: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  .notification_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: auto;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    right: 0;
    transition: 0.5s all;
    opacity: 0;
    visibility: hidden;
    z-index: -999;
  }

  .notification_slider {
    background: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    transition: right 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94), box-shadow 500ms ease;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 3;

    .header {
      height: 81px;
      background-color: $primaryText;
      padding: 0px 20px 0px 20px;

      .notification_close {
        justify-content: flex-end;
        display: flex;
        line-height: 81px;

        > div {
          cursor: pointer;
        }
      }

      .title {
        font-size: 18px;
        color: $whiteColor;
        font-weight: 500;
        line-height: 81px;
        text-align: left;

        span {
          background-color: $themeColor1;
          width: 25px;
          height: 25px;
          color: $whiteColor;
          font-size: 14px;
          font-weight: 500;
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          line-height: 25px;
        }
      }
    }

    .sub-header {
      background-image: linear-gradient(180deg, #f6f5fa 8%, rgba(246, 245, 250, 0) 98%);
      height: 48px;
      padding: 0px 20px 0px 20px;
      border-bottom: 1px solid $themeColor16;

      .title {
        font-size: 14px;
        font-weight: 500;
        color: $primaryText;
        text-align: left;
        line-height: 48px;
      }

      .mark_read {
        font-size: 14px;
        font-weight: 500;
        color: $primaryColor;
        text-align: right;
        line-height: 48px;
        cursor: pointer;
      }
    }

    .notification_list_wrapper {
      height: calc(100% - 129px);
      overflow-y: scroll;

      .notification_list {
        ul {
          li {
            height: auto;
            flex-wrap: wrap;
          }
        }
      }
    }

    .nonotification_wrapper {
      width: 100%;
      text-align: center;
      height: calc(100% - 81px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 18px;
        color: $primaryText;
        margin-top: 28px;
        font-weight: 600;
      }

      h4 {
        font-size: 18px;
        color: $textShade1;
        margin-top: 10px;
        font-weight: 500;
      }
    }
  }
}

.notification_list_wrapper {
  .notification_list {
    margin: 0px;

    // transform: translate3d(0, 0, 0);
    // transition: 0.75s all;
    .title {
      background-color: $themeColor17;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
      color: $themeColor18;
      text-align: left;
      padding: 0px 0px 0px 20px;
    }

    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;

      li {
        margin: 0px;
        padding: 0px;
        list-style: none;
        // transform: translate3d(0, 0, 0);
        transition: 0.75s all;
        overflow: hidden;
        height: 64px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &.fadeout {
          opacity: 0;
        }

        &.slideup {
          height: 0;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        &.notification {
          padding: 14px 0px 14px 25px;
          display: flex;
          cursor: pointer;

          &.private-notification {
            background: rgba(0, 196, 188, 0.1);
          }

          .user_wrapper {
            width: 36px;
            height: 36px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: $whiteColor;
            background-color: $primaryColor;
            border-radius: 50%;
            line-height: 36px;
            display: inline-flex;
            justify-content: center;
            overflow: hidden;

            img {
              max-width: 100%;
            }
          }

          .notification_message {
            font-size: 14px;
            font-weight: 500;
            color: $primaryText;
            margin-left: 10px;
            width: calc(100% - 46px);

            .name {
              font-weight: 600;
            }

            .published {
              color: $textShade1;
              font-size: 12px;
              font-weight: 400;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

#toggle {
  position: absolute;
  appearance: none;
  cursor: pointer;
  left: -100%;
  top: -100%;
}

#toggle + label {
  position: absolute;
  cursor: pointer;
  padding: 10px;
  width: 30px;
  border-radius: 3px;
  padding: 10px 0 0 0;
  color: #333;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  margin: 0px;
  transition: all 500ms ease;
}

#toggle + label:after {
  content: "\f0c9";
  font-family: FontAwesome;
  font-size: 32px;
}

// .container {
// transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
//   padding:5em 3em;
// }

#toggle:checked ~ .message {
  right: 0;
  box-shadow: 0 0 50px #666;
}

#toggle:checked + label:after {
  content: "\f00d";
  font-family: FontAwesome;
  font-size: 32px;
}

/* rating bar */
.rating_bar_wrapper {
  .form-check {
    padding: 0;
  }

  .form-check-input {
    visibility: hidden;
    padding: 0;

    &:checked {
      + label {
        background-color: #00c4bc;
        color: #fff;
      }
    }
  }

  label {
    width: 100%;
    height: 48px;
    margin: 0;
    line-height: 50px;
    float: left;
    font-size: 20px;
    color: #928fa0;
    letter-spacing: 0.45px;
    text-align: center;

    &:hover,
    &.active {
      color: #fff;
    }
  }
}

/*  Info */
.checkin-question-info {
  font-size: 14px;
  color: #b4b0c8;
  letter-spacing: 0;
  text-align: right;
  flex: auto;
  min-width: 150px;

  &.characters-limit-reached {
    color: #ff6c71;
  }
}

/*Welcome feelingbox global css for radio button*/
.ratingform {
  .rating {
    .form-check {
      padding-left: 0;
    }

    .form-check-input {
      -webkit-appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      height: 0;
      width: 0;
      opacity: 0;
    }
  }
}

/*end feelingbox global css for radio button*/

/*start custom button css*/
.custom-btn {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 14px;
  border: 1px solid rgba(144, 136, 185, 0.5);
  background: transparent !important;
  color: #322e46 !important;

  &::after {
    display: inline-block;
    margin-left: 7px;
    vertical-align: 1px;
    content: "";
    border: none;
    height: 6px;
    width: 10px;
    background: url(assets/images/ic_down_arrow_black.svg) no-repeat center;
  }

  &:focus {
    box-shadow: none !important;
  }
}

/*search*/
.close-button {
  display: none;
}

.search-character ~ .close-button {
  display: block;
}

.search-character ~ .form-postfix-icon {
  display: none;
}

.close-button {
  position: absolute !important;
  right: 10px; //10 instead of 20
  top: 0;
  background: none !important;
  height: 15px;
  width: 15px;
  bottom: 0;
  margin: auto;

  &:after {
    display: none !important;
  }

  .close-icon {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: url(assets/images/ic_search_reset.svg) no-repeat center;
  }
}

/*start custom file upload*/
.custom-file-upload {
  position: relative;
  cursor: pointer;

  + .box-info {
    margin: 3px 0 0 5px;
    cursor: pointer;
  }

  input[type="file"] {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 0;
  }

  span {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #928fa0;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    @include transition(all, 0.4s, ease-out);

    > div {
      margin-right: 5px;
    }

    svg > g > g {
      @include transition(all, 0.4s, ease-out);
    }
  }

  &:hover {
    span {
      color: #7033ff;
    }

    svg > g > g {
      fill: #7033ff;
    }
  }
}

.multiple_uploaded_file {
  position: relative;
  top: -10px;
  ul {
    padding: 0 15px 15px 65px;
    li {
      max-width: 237px;
      background-color: white;
      margin-right: 15px;
      width: auto;
    }
  }
}
.question_multiple_uploaded_file {
  position: relative;
  ul {
    li {
      max-width: 237px;
      background-color: white;
      margin-right: 15px;
      width: auto;
    }
  }
}

.file-listing {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  li {
    border: 1px solid #e5e5e5;
    cursor: pointer;
    margin: 10px 0 0 0;
    padding: 5px 40px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    // width: calc(33.33% - 10px);
    width: 48%;
    color: #322e46;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    /* &:nth-child(3n + 1){
        margin-left: 0;
        width: 33.33%;
      } */
  }

  .file-attachment-icon {
    position: absolute;
    left: 15px;
    top: 4px;
  }

  .delete-item {
    float: right;
    position: absolute;
    right: 15px;
    top: 3px;
    cursor: pointer;
  }
}

div#appreciateBox {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 500;
  word-break: break-word;
  min-height: 41px;
  @include transition(all, 0.4s, ease-out);

  &:hover {
    border: 1px solid #828282;
    box-shadow: 0 2px 4px 0 #e6e6e6;
  }

  &:focus {
    border: 1px solid #7134ff;
    box-shadow: 0 2px 4px 0 #e8deff;
  }
}

.auto_complete_wrapper {
  .form-group {
    .textarea-wrapper {
      &:after {
        display: none;
      }
    }
  }
}

/*start private comment wrapper*/

.lock-icon,
.private-lock-icon {
  position: absolute;
  right: 0;
  cursor: pointer;

  .lock-icon-privacy {
    // display: none;
    &:hover {
      * {
        fill: $primaryColor;
      }
    }
  }
}

.private-parent-wrapper {
  background: rgba(245, 245, 250, 0.61);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: block;
  margin-top: 10px;
  position: relative;

  .close_icon {
    cursor: pointer;
  }

  .private-comment-wrapper {
    border: 0px solid rgba(0, 0, 0, 0.1);

    &.manage_comment_view {
      margin: 0px;
    }
  }

  .border-bottom-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .private-msg-wrapper {
    background: rgba(245, 245, 250, 0.61);
    padding: 15px 20px;
    display: block;

    .textarea-wrapper {
      .form-control {
        min-height: 48px !important;
        resize: vertical;
        max-height: 150px;
      }
    }
  }

  .close_icon {
    position: absolute;
    right: 15px;
    top: 10px;

    svg {
      height: 10px;
      width: 10px;
    }
  }
}

.private-comment-wrapper {
  background: rgba(245, 245, 250, 0.61);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px 20px;
  display: block;

  .manager_comment_text {
    display: inline-block;
    vertical-align: middle;
    color: #322e46;
    font-weight: 500;

    .lock {
      display: inline-block;
    }

    svg {
      margin-right: 5px;
      position: relative;
      top: -1px;

      * {
        fill: $primaryColor;
      }
    }
  }

  .manage_comment_visible_to {
    width: auto; // auto instead-of 197px
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle;

    select {
      padding: 0 12px;
      height: 34px;
    }
  }
}

.custom-range-slider {
  .input-range__label--max,
  .input-range__label--min {
    display: none;
  }

  .input-range__label--value {
    position: absolute;
    top: -65px;
    padding: 10px 15px;
    border: 1px solid #eaeaea;
    background: #fff;
    border-radius: 3.36px;
    min-width: 130px;
    text-align: center;
    font-size: 14px;
    font-family: "Gilroy";
    font-weight: 500;
    left: -72px;
    box-shadow: 10px 0 34px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
    @include transition(all, 0.4s, ease-out);

    .input-range__label-container {
      left: 0;
      position: relative;
      color: #312d45;
    }
  }

  .input-range__track {
    height: 8px;
  }

  .input-range__track--active {
    background: linear-gradient(90deg, #bfe659 0%, #ff6454 100%) no-repeat center left !important;
    background-size: 707px 100% !important;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }

  .input-range__slider-container {
    .input-range__slider {
      background: #dadada;
      border: 6px solid #fff;
      height: 30px;
      margin-top: -14px;
      width: 30px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);

      &:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        border-left: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        top: -21px;
        transform: rotate(-45deg);
        background-color: #fff;
        z-index: 1;
        left: 4px;
      }

      &:active {
        transform: scale(1);
      }

      &[aria-valuenow="2"],
      &[aria-valuenow="3"] {
        margin-left: -15px;
      }

      &[aria-valuenow="4"],
      &[aria-valuenow="5"] {
        margin-left: -17px;
      }

      &[aria-valuenow="6"],
      &[aria-valuenow="7"] {
        margin-left: -19px;
      }

      &[aria-valuenow="8"],
      &[aria-valuenow="9"],
      &[aria-valuenow="10"] {
        margin-left: -22px;
      }
    }
  }

  .rating-number {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #928fa0;
    font-weight: 500;
  }
}

.nps-range-slider {
  .input-range__track--active {
    background-image: url("../src/assets/images/nps_progress_bar.svg") !important;
    background-size: 765px 765px !important;
  }
}

/*start slick slider myhistory and team*/
.nps-range-slider {
  .input-range__slider-container {
    .input-range__slider {
      &::after {
        display: none;
      }
    }
    .input-range__label {
      display: none;
    }
  }
  .input-range__slider-container {
    &[style*="left: 10%"] {
      .input-range__slider {
        left: -1px;
      }
    }
    &[style*="left: 70%"],
    &[style*="left: 80%"] {
      .input-range__slider {
        left: -2px;
      }
    }
    &[style*="left: 90%"] {
      .input-range__slider {
        left: -5px;
      }
    }
  }
  .input-range__track--active {
    background: #cdcdcd !important;
    &[style*="width: 0%"],
    &[style*="width: 10%"],
    &[style*="width: 20%"],
    &[style*="width: 30%"],
    &[style*="width: 40%"],
    &[style*="width: 50%"],
    &[style*="width: 60%"] {
      background: #ff6454 !important;
    }
    &[style*="width: 80%"],
    &[style*="width: 70%"] {
      background: #cdcdcd !important;
    }
    &[style*="width: 90%"],
    &[style*="width: 100%"] {
      background: #bfe659 !important;
    }
  }
  .rating-number {
    li {
      padding: 0 3px;
    }
  }
}
.box {
  &.slick-slider-box-wrapper {
    padding: 0;

    .slick-slide {
      padding: 0 5px;

      > div {
        > div {
          padding: 20px 15px 20px 30px;
        }
      }

      &:after {
        height: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
      }
    }

    .slick-slide {
      &:before {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        background: #7033ff;
        bottom: 0;
        left: 0;
        border-radius: 4px 4px 0 0;
        right: 0;
        margin: auto;
        @include transition(all, 0.4s, ease-out);
      }

      &.active-cycle {
        &:before {
          width: 80%;
        }
      }

      .active-cycle {
        &:before {
          content: "";
          position: absolute;
          width: 80%;
          height: 3px;
          background: #7033ff;
          bottom: 0;
          left: 0;
          border-radius: 4px 4px 0 0;
          right: 0;
          margin: auto;
          @include transition(all, 0.4s, ease-out);
        }
      }
    }
  }
}

.list-item-2info {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    &.no-1-on-1 {
      font-weight: 500;
      font-size: 14px;
      color: #bdbcc3;
      letter-spacing: 0.32px;
      text-align: center;
      padding: 25px 0;
    }

    h4 {
      font-size: 15px;
      color: #3e3266;
      line-height: 18px;
      letter-spacing: 0.34px;
    }

    span {
      font-size: 13px;
      color: $textShade1;
      line-height: 15px;
      margin-top: 7px;
      display: inline-block;
      width: 100%;
      font-weight: 500;

      .time {
        position: relative;
        display: inline-block;
        padding-left: 15px;
        margin: 0 0 0 8px;
        width: auto;

        &:before {
          content: "";
          background: #b5afca;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}

.list-of-objective {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 15px 15px 15px 40px;
    background: url(assets/images/ic_link.svg) no-repeat 15px 15px;
    font-weight: 500;
    color: $primaryText;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    .pre-plan-disabled {
      color: rgb(184, 180, 204);
    }

    &:last-child {
      border-bottom: none;
    }

    .close-icon {
      cursor: pointer;
      height: 10px;
      width: 10px;
      display: inline-block;
      @include transition(0.4s, all, ease-out);
      position: relative;
      top: 5px;

      img {
        width: auto;
        height: 100%;
        display: inherit;
      }

      &:hover {
        transform: rotate(90deg);
      }
    }
  }
}

.box {
  .available-objective {
    padding: 0;

    .box-light-gradient {
      padding: 12px 15px;
      border-top: 1px solid rgba(184, 180, 204, 0.3);
      border-bottom: 1px solid rgba(184, 180, 204, 0.3);

      &::before {
        height: 100%;
      }

      .box-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 16px;
        }

        .close-obj {
          cursor: pointer;
          color: $primaryColor;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .list-of-objective {
      padding: 10px 0;

      li {
        background-image: none;
        border: none;
        position: relative;
        padding: 10px 30px;

        &.no-objective {
          text-align: center;
          display: block;
          font-size: 16px;
          font-weight: 500;
          padding: 20px 15px;
          color: $textShade1;

          &:before {
            display: none;
          }
        }

        &:before {
          content: "";
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: #b5afca;
          position: absolute;
          top: 0;
          left: 15px;
          bottom: 0;
          margin: auto;
        }

        span {
          font-size: 15px;
          font-weight: 500;
          color: $primaryText;
          letter-spacing: 0;
          line-height: 18px;
          cursor: pointer;
          @include transition(0.4s, all, ease-out);

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.date_picker_time_wrapper {
  padding: 25px;

  > h5 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .calendar_wrapper {
    margin-right: 15px;

    .react-datepicker-wrapper {
      width: 100%;

      .form-control {
        background: $whiteColor url(assets/images/ic_calendar_2.svg) no-repeat 12px center;
        padding-left: 40px;
      }
    }
  }

  .time_wrapper {
    display: inline-block;

    .form-group {
      width: 106px;
      vertical-align: middle;

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;

          .form-control {
            width: 100%;
          }
        }
      }
    }

    > span {
      font-weight: 600;
      color: #928fa0;
      margin: 0 15px;
    }
  }

  .time_zone_wrapper {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
    .custom-default-dd-box {
      .select__control {
        min-height: 40px;
        .select__indicators {
          .select__indicator-separator {
            display: none;
          }
        }
      }
    }
  }

  .form-group {
    margin-bottom: 0;
    display: inline-block;
  }
}

.btn-export {
  span {
    font-size: 16px;
    font-weight: 500;
    color: $primaryColor;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.fill-svg-icon {
  svg {
    position: relative;
    top: -1px;

    * {
      fill: $primaryColor;
    }
  }

  span {
    padding-left: 5px;
  }
}

.fill-svg-icon-h {
  &:hover {
    * {
      fill: $primaryColor;
    }
  }
}

.gray-svg {
  margin: 0 2px;

  svg {
    * {
      stroke: #928fa0;
    }
  }

  &.d-inline-block {
    position: relative;
    top: -1px;
  }
}

.tags_wrapper {
  .select_placeholder {
    position: absolute;
    z-index: 1;
    left: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #aaa;
    top: 12px;
  }
  .react-tags {
    padding: 0 10px 10px;
    .customPlaceholder input {
      font-weight: 700!important;
    }

    &.limit-reached {
      border-radius: 4px;
      border: 1px solid #f07575;
    }

    .react-tags__selected {
      display: inline-block;

      .react-tags__selected-tag {
        background: rgba(246, 245, 250, 0.6);
        border: 1px solid #e0dfe2;
        height: 30px;
        border-radius: 3px;
        font-weight: 600;
        color: #322e46;
        padding: 0 30px 0 10px;
        margin: 10px 10px 0 0;
        position: relative;

        &:focus,
        &:hover {
          box-shadow: none;
          outline: none;
        }

        .react-tags__selected-tag-name {
          &::before {
            position: absolute;
            background: url(../src/assets/images/ic_search_reset.svg) no-repeat center;
            content: "";
            right: 8px;
            height: 10px;
            width: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }

    .react-tags__search {
      display: inline-block;
      position: relative;

      .customPlaceholder input {
        font-weight: 500 !important;
        font-size: 16px !important;
        // letter-spacing: 0.36px !important;
        color: #322e46 !important;
      }

      input {
        margin-top: 10px;
        border: none;
        min-width: 100px;
        padding-left: 0;
        padding-right: 0;
      }

      .react-tags__suggestions {
        position: absolute;
        left: 0;
        min-width: 250px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #fff;
        border-radius: 3px;
        min-height: 40px;
        max-height: 200px;
        top: 45px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            width: 100%;
            font-weight: 500;
            padding: 10px;
            background: #fff;
            color: #322e46;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            @include transition(0.2s all);

            &:hover {
              background: rgba(246, 245, 250, 0.5);
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.comment1on1-wrapper {
  .manager_comment_text {
    > div {
      display: inline-block;
    }
  }
}

.btn1on1-wrapper {
  margin-top: 15px;

  button {
    min-width: 120px;
    margin-right: 10px;
  }

  span {
    color: #928fa0;
    font-weight: 500;
    display: inline-block;
  }
}

.custom-obj-tooltip {
  display: inline-block;
  text-align: left;
  margin-left: 10px;

  .info-attach-file {
    left: auto;
    right: -10px;

    &::before {
      right: 15px;
      left: auto;
    }
  }
}

.cmp-in-comp-info {
  display: inline-block;
  margin-top: 10px;
  font-weight: 500;

  .form-check {
    display: inline-block;
    position: relative;
    top: 0;
    margin-left: 5px;
  }
}

/* ====================
Common CSS
======================= */
.a_name {
  a {
    color: $primaryText !important;
    font-weight: 600 !important;

    &:hover {
      color: $primaryColor !important;
    }
  }
}

.vd-obj-description {
  font-size: 16px;
  font-weight: 500;
  color: #37324b;
  margin-bottom: 0px;
  label {
    font-weight: 600;
  }
}

.duration_txt {
  color: $textShade1;
  font-size: 16px;
  font-weight: 600;
}

.no_checkin_activity {
  height: 215px !important;
  text-align: center !important;
  padding: 0px !important;
}

//subNavigation
.subNavigation {
  background: $whiteColor;
  @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.05), false);
  margin: 0 0 25px 0;
  height: 0px;
  // transition: height .8s linear .01s ;
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  position: relative;

  &.active {
    height: 44px;
    // visibility: visible;
    // opacity: 1;
  }
}

.submenu_transition {
  transition: height 0.8s linear 0.01s;
}

.home_remove_subnav {
  animation: sub_remove 1s forwards;
}

@keyframes sub_remove {
  from {
    height: 48px;
  }

  to {
    height: 0px;
  }
}

.start_end_cal_wrapper {
  .calendar_wrapper {
    background: #ffffff;
    border: 1px solid #b8b4cc;
    border-radius: 4px;
    display: inline-flex;
    width: 228px;
    height: 50px;
    margin-right: 20px;
    padding: 14px;

    span {
      margin-left: 30px;
    }

    .date_picker_wrapper {
      position: relative;

      .date-picker {
        width: 15px;
        height: 15px;
        //  background: $whiteColor url("assets/images/ic_calender_2.svg") no-repeat 5px center;
        border: 0px solid #fff;
        opacity: 0;
        z-index: 1;
        position: absolute;
        border: 1px solid #f00;
        top: -10px;
        left: 2px;
        cursor: pointer;
      }

      .calendar_picker {
        position: absolute;
        top: 0px;
      }
    }
  }

  .react-datepicker-popper {
    left: -40px !important;
  }
}

.tr_expanded {
  background-color: rgba(246, 245, 250, 0.5);
}

.status_color_red {
  width: 12px;
  height: 12px;
  background-color: $themeColor1;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.status_color_green {
  width: 12px;
  height: 12px;
  background-color: $themeGreen;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.status_color_yellow {
  width: 12px;
  height: 12px;
  background-color: $themeYellow;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.alert_welcome_university, .alert_engage_introduction {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  padding: 42px 30px;
  border-color: #fff;
  position: relative;
  margin-bottom: 30px;

  .welcome_wrapper {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);

    .welcome_text {
      width: 67%;
      max-width: 100%;

      .title {
        font-family: 600;
        font-size: 22px;
        color: #20253a;
        letter-spacing: 0.26px;
      }

      p {
        margin: 20px 0 0;
        color: #322e46;
        letter-spacing: 0.32px;
        line-height: 20px;
      }
    }

    button {
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 0;
      border: none;
      padding: 0;
      height: 20px;
      width: 20px;
      background: url(assets/images/ic_search_reset.svg) no-repeat center center;
    }

    span.welcome_img {
      width: 33%;
      text-align: right;
      padding-right: 35px;
    }
  }
}

.alert_engage_introduction {
  .welcome_wrapper .welcome_text {
    width: 60%;  
  }

  .welcome_img {
    padding-right: 0 !important;
  }
}

.react-datepicker__tab-loop {
  .react-datepicker {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: "Gilroy";

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      background-color: #814cff;
      border-radius: 50%;
    }

    .react-datepicker__day--selected {
      background-color: #814cff;
      border-radius: 50%;
      font-weight: 600;
    }
  }

  .react-datepicker__header {
    background-color: #f6f5fa;
    border-bottom-color: rgba(0, 0, 0, 0.1);

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-weight: 600;
      color: #322e46;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-weight: 500;
      color: #322e46;
    }

    .date-picker-dropdown {
      @include flexbox;
      @include justify-content(space-around);

      select {
        padding: 7px 10px;
        border-radius: 3px;
        min-width: 100px;
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .react-datepicker__navigation--previous {
    left: 20px;
    border-width: 0 2px 2px 0;
    border-style: none solid solid none;
    border-color: transparent #8c96ab #8c96ab transparent;
    height: 8px;
    width: 8px;
    transform: rotate(135deg);
    top: 14px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .react-datepicker__navigation--next {
    right: 20px;
    border-width: 0 2px 2px 0;
    border-style: none solid solid none;
    border-color: transparent #8c96ab #8c96ab transparent;
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
    top: 14px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.react-datepicker-popper {
  &[data-placement^="bottom"] {
    .react-datepicker__triangle {
      border-bottom-color: #f6f5fa !important;

      &::before {
        border-bottom-color: rgba(0, 0, 0, 0.15) !important;
      }
    }
  }

  &[data-placement^="top"] {
    .react-datepicker__triangle {
      &:before {
        border-top-color: rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
}

span#feedback {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  height: 40px;
  width: 60px;
}

.reviewer-select {
  .form-group {
    label {
      font-size: 16px;
    }

    .form-control {
      width: 200px;
      max-width: 100%;
      height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 16px;
    }
  }
}

.mng_obj_notification {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
  margin: 0px 0px 20px 0px;

  .notify_icon {
    position: relative;
    top: -2px;
  }

  span {
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }

  p {
    width: 96%;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
  }

  button {
    min-width: 127px;
    height: 40px;
    margin-right: 20px;
  }

  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 127px;
    height: 40px;
  }
}
.assess_prev_comment_wrapper {
  h5 {
    font-weight: 600;
    font-size: 16px;
    color: $primaryText;
    letter-spacing: 0.36px;
    padding: 15px 15px 0;
  }
  + .assess_prev_comment_wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    h5 {
      padding-bottom: 15px;
    }
  }
}

.disable-ele {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

.orderd-tooltip-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-position: inside;
  text-align: left;

  li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.graph-item {
  .export-btn {
    float: right;
    cursor: pointer;
    margin: 0 15px 10px 0;
    color: #7033ff;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  .graph-heading {
    color: #4a4a4a;
    padding: 15px 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }
  svg {
    pointer-events: visible !important;
  }

  h5 {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
  }

  .recharts-pie {
    .recharts-pie-sector {
      .recharts-sector {
        stroke: transparent;
      }
    }
  }

  .recharts-rectangle {
    &.recharts-tooltip-cursor {
      display: none;
    }
  }

  .inner-recharts-label {
    text-transform: uppercase;
    font-weight: 500;
    color: $primaryText;
  }

  .recharts-cartesian-axis-tick {
    line {
      visibility: hidden;
    }

    .recharts-cartesian-axis-tick-value {
      text-transform: uppercase;
      color: #000;
      opacity: 0.7;
      font-weight: 500;
      font-size: 12px;
    }
  }

  &.width50 {
    width: 450px;
    padding-right: 25px;
  }

  &.width70 {
    width: 650px;
  }

  &.width100 {
    width: 650px;
  }

  &:last-child {
    border-right: 0;
  }
}

// .recharts-default-tooltip {
//   box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
//   border-radius: 4px;
//   border-color: rgba(0,0,0,0.1) !important;
//   padding: 0 !important;
// }
// .recharts-default-tooltip p.recharts-tooltip-label {
//   font-size: 15px;
//   font-weight: 500;
//   border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
//   padding:7px 10px;
// }
// .recharts-tooltip-item-list{
//   display: block !important;
//   padding: 10px !important;
// }
// .recharts-tooltip-item{
//   color: #928FA0 !important;
//   padding: 0 !important;
//   margin-top: 5px;
//   font-size: 13px;
//   font-weight: 500;
//   &:first-child{
//     margin-top: 0;
//   }
//   .recharts-tooltip-item-name{
//     color: #37324B;
//     text-transform: capitalize;
//   }
//   .recharts-tooltip-item-value{
//     color: rgba(112, 51, 255, 0.9);
//   }
// }
.recharts-tooltip-wrapper {
  border-radius: 4px;
  background-color: $whiteColor;
  box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.2);

  .recharts-tooltip-custom-wrapper {
    padding: 5px 10px;
    text-align: center;

    .recharts-tooltip-label {
      margin-bottom: 0;
      font-weight: 600;
    }

    .recharts-tooltip-value {
      color: #4a4a4a;
      font-size: 13px;
    }
  }
}

/** start custom date range picker css*/
.date-range-apply-btn {
  min-width: 120px;
  padding: 5px;
  height: 40px;
}
.dashboard-filter-btn {
  min-width: 100px;
  padding: 5px;
  height: 35px;
}
.date_range_calendar_wrapper {
  .DateRangePicker {
    width: 300px;
    .DateInput_fang {
      top: 36px !important;
      .DateInput_fangShape {
        fill: $textShade4;
      }
    }
    .DateRangePicker_picker {
      top: 57px !important;
    }
    .CalendarMonth,
    .DayPicker_weekHeader {
      padding: 0 !important;
    }
    .CalendarMonthGrid__horizontal {
      .CalendarMonthGrid_month__hideForAnimation,
      .CalendarMonthGrid_month__horizontal {
        width: calc(300px + 18px);
        margin-left: -9px;
      }
    }
    .CalendarMonth_table {
      margin-top: 13px;
      margin-left: 19px;
      td {
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .DayPickerNavigation_button {
      top: 22px;
    }
    .CalendarMonth_caption {
      background-color: $textShade4;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .DayPicker_weekHeader_ul {
      padding: 0 10px;
    }
    .DayPicker_weekHeader_li {
      small {
        font-size: 0.8rem;
        font-weight: 500;
        color: $primaryText;
      }
    }
    .custom-month-year-picker {
      @include flexbox;
      @include justify-content(center);
      .custom-month-picker {
        margin-right: 10px;
      }
      select {
        padding: 6px 14px 5px 5px;
        border-radius: 3px;
        border-color: rgba(0, 0, 0, 0.15);
        font-size: 14px;
        min-width: 80px;
      }
    }
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border-color: rgba($color: #000000, $alpha: 0.1);
    padding: 10px 40px 10px 10px;
    @include flexbox;
    @include align-items(center);
    // background: url(../src/assets/images/ic_calendar_2.svg) no-repeat 96% 12px;
    background-size: 17px;
    .DateRangePickerInput_calendarIcon {
      position: absolute;
      right: 10px;
      top: 12px;
      background: url(../src/assets/images/ic_calendar_2.svg) no-repeat center;
      margin: 0;
      outline: none !important;
      svg {
        display: none;
      }
    }
    > * {
      &:last-child {
        .DateInput_input {
          text-align: right;
        }
        .DateInput_fang {
          left: 60px;
        }
      }
    }
  }
  .DateInput_input {
    padding: 0;
    font-size: 15px;
    color: $textShade1;
    font-weight: 500;
    border-bottom: 0 !important;
  }
  .DateRangePickerInput_arrow {
    .DateRangePickerInput_arrow_svg {
      fill: $textShade1;
      height: 19px;
      width: 19px;
    }
  }
}
.noObjective {
  justify-content: center;
  position: relative;
  display: flex;
  font-size: 14px;
  color: #bdbcc3;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 5px;
}
.no-sent-receive-wrapper {
  justify-content: center;
  position: relative;
  display: flex;
  font-size: 14px;
  color: #bdbcc3;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 5px;
  margin: 22px;
}

.exclude-objective {
  .custom-checkbox {
    label {
      font-weight: 600 !important;
      &[for*="Excluded"] {
        font-weight: 500 !important;
      }
    }
  }
}

.template-rating {
  &.card {
    @include border-radius(0);
    border: none !important;
    box-shadow: none !important;
    margin-top: 15px !important;
  }
}

.viewMoreText {
  max-width: 200px;
  overflow-x: hidden;
  word-wrap: break-word;
  height: 40px;
}
.a-tag {
  color: #7033ff !important;
  text-decoration: none;
  transition: all 0.4s ease-out;
  cursor: pointer;
}
.link-tag {
  text-decoration: none;
  transition: all 0.4s ease-out;
  cursor: pointer;
  &:hover {
    color: #7033ff;
  }
}
.blinked {
  a {
    color: #7033ff !important;
    animation: blink 1s linear infinite;
  }
}
.check_in_streak_box {
  padding: 34px 30px 33px 34px;
  display: flex;
  justify-content: space-between;
  .check_in_streak_container {
    display: inline-flex;
    margin: 0px 80px 0px 0px;
    &:last-child {
      margin: 0px 0px 0px 0px;
    }
    .check_in_streak_ic {
      width: 44px;
      height: 44px;
    }
    .checking_streak_number {
      font-size: 22px;
      color: #322e46;
      letter-spacing: 0;
      font-weight: 600;
      padding: 0;
      margin: 0px 0px 3px 10px;
      text-align: left;
      line-height: 22px;
    }
    .checking_streak_caption {
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0.39px;
      text-align: left;
      font-weight: 600;
      padding: 0;
      margin: 0px 0px 0px 10px;
      line-height: 14px;
    }
  }
}
.round-employee-list-wrapper {
  cursor: pointer;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  float: left;
  margin-left: -10px;
  transition: all 300ms ease-in-out;
  color: $primaryText;
  line-height: 50px;
  text-align: center;
  font-weight: 700;
  box-shadow: 0 1px 2px 0 rgba($color: #000000, $alpha: 0.2);
  .employee-avatar-item {
    height: 100%; //100% instead of 48px
    width: 100%; //100% instead of 48px
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    color: $whiteColor;
    img {
      width: auto;
      height: 100%;
      vertical-align: top;
    }
  }
}

.round-employee-list-wrapper:hover {
  z-index: 1;
  height: 50px;
  width: 50px;
  margin-right: 13px;
}

.employee-avatar-tooltip {
  position: absolute;
  top: 125%;
  left: 50%;
  width: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  text-align: center;
  padding: 0px 10px;
  opacity: 0;
  border-radius: 4px;
  pointer-events: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  transition: all 100ms ease-in-out;
  transition-delay: 50ms;
  font-size: 14px;
  white-space: nowrap;
  height: auto;
  line-height: 25px;
  border: 1px solid #eee;
  span {
    color: $textShade1;
  }
}

.employee-avatar-tooltip:before {
  content: "";
  position: absolute;
  top: -0.4rem;
  left: 50%;
  border-radius: 2px;
  margin-left: -0.5rem;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  z-index: -1;
  background: #fff;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.round-employee-list-wrapper:hover > .employee-avatar-tooltip {
  opacity: 1;
  transform: translate(-50%, 0);
}
.w-1200 {
  width: 1200px;
}
.w-60 {
  width: 60% !important;
}
.w-55 {
  width: 55% !important;
}
.w-45 {
  width: 45% !important;
}
.w-38 {
  width: 38% !important;
}
.w-15 {
  width: 15% !important;
}
.no-data {
  text-align: center !important;
  padding: 18px 13px !important;
  font-size: 14px;
  align-items: center;
  align-content: center;
  font-weight: 600;
  color: #928fa0;
  clear: both;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 115px;
  margin-bottom: 20px;
}
.switches {
  display: inline;
  :global(.custom-switch) {
    padding-left: 0;
    :global(.custom-control-input) {
      &:focus {
        ~ :global(.custom-control-label) {
          &:before {
            border-color: #e9e9eb;
            box-shadow: none;
            outline: none;
          }
        }
      }
      &:checked {
        ~ :global(.custom-control-label) {
          &:before {
            background-color: #00c4bc;
            border-color: #00c4bc;
          }
          &:after {
            right: 14px;
            box-shadow: 0 2px 2px 0 rgba(45, 45, 45, 0.2);
            border: none;
          }
        }
      }
    }
    :global(.custom-control-label) {
      font-weight: 600;
      color: #322e46;
      letter-spacing: 0.47px;
      line-height: 24px;
      padding-right: 55px;
      &:before {
        left: auto;
        right: 0;
        width: 40px;
        height: 24px;
        border-radius: 25px;
        background: #ffffff;
        border: 1px solid #e9e9eb;
        top: 0;
      }
      &:after {
        right: 17px;
        left: auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        top: 2px;
        background: #ffffff;
        border: 1px solid rgba(45, 45, 45, 0.2);
      }
    }
  }
}

.span-status {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  padding: 5px 10px;
  width: auto; //148px insteadof auto
  display: inline-block;
  min-width: 86px;
  text-align: center;
  &.drafted {
    background: #f5f5f5;
    border: 1px solid #d8d8d8;
    color: #787878;
  }
  &.inprogress {
    background: #f5ffff;
    border: 1px solid #24dad2;
    color: #00c4bc;
  }
  &.completed {
    background: #f0fafd;
    border: 1px solid #cff1ff;
    color: #33c5ff;
  }
  &.ended,
  &.pending,
  &.notattempted {
    color: #fd8c61;
    background: #fcf6f4;
    border: 1px solid #fce6dd;
  }
  &.submitted,
  &.published,
  &.attempted {
    background: #f9f7ff;
    border: 1px solid rgba(149, 106, 251, 0.23);
    color: #814cff;
  }
  &.lapsed {
    background: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    color: #787878;
  }
  &.saved {
    color: #05ada6;
    border: 1px solid rgba(36, 218, 210, 0.23);
    background: #f5ffff;
  }
  &.overdue {
    background: #f5f5f5;
    border: 1px solid #d8d8d8;
    color: #787878;
  }
}

.rich-text-box.quill {
  .ql-toolbar.ql-snow {
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px 4px 0 0;
  }
  .ql-container.ql-snow {
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 0 0 4px 4px;
    .ql-editor {
      height: 200px;
      &.ql-blank::before {
        font-size: 16px;
        letter-spacing: 0.36px;
        line-height: 19px;
        color: $textShade1;
        font-weight: 500;
      }
    }
  }
  &.quill-error {
    .ql-container.ql-snow {
      border-color: #f07575;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* ====================
 Base Responsive Start
 ==================== */
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .graph-item {
    &.width100 {
      width: 50%;
    }
  }

  .alert_engage_introduction {
    .welcome_img {
      width: auto !important;
      padding-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  span#feedback {
    display: none;
  }

  .contentArea {
    margin-top: 81px;
  }

  .manage_comment_view {
    display: inline-block;
    width: 100%;

    .manage_comment_visible_to,
    .custom-checkbox {
      display: inline-block;
      width: auto;
    }

    .manage_comment_help {
      display: inline;
    }
  }

  .subNavigation {
    display: none;

    + .container,
    + .myobjectives_wrapper {
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .form-group {
    .textarea-wrapper {
      .only-attached-icon,
      .private-lock-icon,
      .attached-pin-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .items_action_box {
    width: auto;
    @include flex(0, 0, auto);
    padding-left: 10px;
  }

  .tags_wrapper {
    .react-tags {
      position: relative;

      .react-tags__search {
        position: static;

        .react-tags__suggestions {
          right: 0;
          top: 58px;
        }
      }
    }
  }
  .graph-item {
    border-right: none;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    text-align: center;
    h5 {
      display: block;
    }
    > div {
      display: inline-block;
    }
    &.width50 {
      width: 100%;
      padding-right: 0;
      text-align: center;
      // margin-bottom: 25px;
      g {
        &.recharts-layer.recharts-pie {
          transform: translateX(40px);
        }
      }
    }

    &.width70 {
      width: 100%;
    }

    &.width100 {
      width: 100%;
    }
  }
  .check_in_streak_box {
    flex-wrap: wrap;
    .check_in_streak_container {
      display: inline-flex;
      margin: 0;
      width: 33.33%;
      flex-basis: auto;
      flex-grow: initial;
      &:nth-child(3n + 3) {
        ~ .check_in_streak_container {
          margin: 34px 0 0;
        }
      }
    }
  }

  .alert_engage_introduction {
    .welcome_img {
      width: auto !important;
      padding-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .info-attach-file {
    left: auto;
    right: -30px;

    &:before {
      left: auto;
      right: 36px;
    }
  }

  .priority_carryforward {
    .custom-tooltip {
      &.custom-tooltip-top {
        .info-attach-file {
          left: -265px;

          &:before {
            left: 265px;
          }
        }
      }
    }
  }

  .outer-table-wrapper {
    .custom-table {
      .table-responsive {
        table {
          .custom-tooltip {
            &.custom-tooltip-top {
              .box-info {
                + .info-attach-file {
                  left: auto;
                  right: -50px;

                  &:before {
                    left: auto;
                    right: 52px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .input-range__slider-container {
    &[style*="left: 0%"] {
      .input-range__label--value {
        left: -10px;
      }
    }

    &[style*="left: 11.1111%"] {
      .input-range__label--value {
        left: -42px;
      }
    }

    &[style*="left: 88.8889%"] {
      .input-range__label--value {
        left: -102px;
      }
    }

    &[style*="left: 100%"] {
      .input-range__label--value {
        left: -120px;
      }
    }
  }
  .graph-item {
    &.width50 {
      g {
        &.recharts-layer.recharts-pie {
          transform: translateX(30px);
        }
      }
    }
  }
}

// Custom tabs
@media only screen and (max-width: 767px) {
  .contentArea {
    margin-top: 61px;
  }

  .box {
    padding: 15px 0;

    .box-heading-wrapper {
      padding: 0 15px 20px;
    }

    .box-inner-pad {
      padding: 15px;
    }
  }

  .table-theme-1 {
    .table-responsive {
      overflow-x: scroll !important;
    }
  }

  .custom-tabs {
    .nav-tabs {
      @include flex-wrap(nowrap);
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      margin-top: 10px;

      .nav-link {
        min-width: -webkit-fill-available;
        min-width: fit-content;
        font-size: 14px;
        padding: 12px 14px;
      }
    }
  }

  .currrentCheckInTime {
    font-size: 16px;
    margin: 0 0 15px 0;
  }

  .box-heading-wrapper {
    .box-heading {
      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 14px;
      }
    }
  }

  .box {
    .box-inner-pad {
      .form-group {
        label {
          font-size: 16px;

          .form-text {
            font-size: 14px;
          }
        }
      }
    }
  }

  .draftSaved {
    @include justify-content(flex-start);
    margin: 0 0 15px 0;
  }

  .file-listing {
    li {
      width: 100%;
      margin-left: 0;

      /* &:nth-child(3n + 1){
        margin-left: 0;
        width: 100%;
      } */
      &:first-child {
        margin-top: 5px;
      }
    }
  }

  .info-attach-file {
    min-width: 300px;
    left: -113px;

    &:before {
      left: 122px;
    }
  }

  .attached-pin-icon {
    .custom-tooltip {
      .info-attach-file {
        left: -238px;

        &:before {
          left: 230px;
        }
      }
    }
  }

  .private-comment-wrapper {
    text-align: center;
    padding: 10px;

    .manage_comment_visible_to {
      margin: 10px 0;
    }

    .manager_comment_text,
    .manage_comment_help {
      display: block;
    }
  }

  .form-group {
    textarea {
      &.form-control {
        font-size: 14px;
      }
    }

    .textarea-wrapper {
      + .d-flex {
        flex-direction: column-reverse;
      }
    }

    input {
      &.form-control {
        font-size: 14px;
      }
    }
  }

  .box_inner_title {
    font-size: 16px;
  }

  .custom-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
      & + label {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .view_comment_box_wrapper {
    .view_comment_box_row {
      .user_comment_details {
        font-size: 14px;

        .user_name {
          font-size: 14px;
        }
      }
    }
  }

  .notification_wrapper {
    width: 340px;

    .notification_slider {
      .header {
        height: 58px;
        padding: 0px 15px 0px 15px;

        .title {
          font-size: 16px;
          line-height: 58px;
        }

        .notification_close {
          line-height: 58px;
        }
      }

      .sub-header {
        height: 48px;
        padding: 0px 15px 0px 15px;
      }

      .notification_list_wrapper {
        height: calc(100% - 107px);
      }
    }

    .notification_list {
      .title {
        padding: 0px 0px 0px 15px;
      }

      ul {
        li {
          padding: 14px 15px;

          &.notification {
            padding: 14px 15px;

            .notification_message {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .common_list_items {
    li {
      font-size: 14px;
    }
  }

  /*start dashboard*/
  .p30 {
    padding: 20px;
  }

  .card {
    .card_info {
      min-height: 64px;

      .card_title {
        .icon-svg {
          top: 3px;
          position: relative;
          margin-right: 10px;
        }
        .card_title_text {
          font-size: 18px;
        }

        &.flex-wrap {
          + .card_action {
            max-width: 42px;
            @include flex(0 0 42px);
          }
        }
      }

      .card_action {
        max-width: 42px;
        @include flex(0, 0, 42px);
      }
    }
    .card-footer {
      .card-ft-wrapper {
        flex-wrap: wrap;
        .card_action {
          margin-top: 10px;
        }
      }
    }
  }

  /*end dashboard*/

  .manage_comment_view {
    display: inline-block;
    width: calc(100% + 50px);
    // text-align: center;
    // margin-left: -50px;
    text-align: left;
    margin-left: 0px;

    .manage_comment_visible_to {
      margin: 5px 0;
      display: block;
      width: 200px;
    }

    .manage_comment_help {
      display: inline-block;
    }
  }

  .checkin-question-info {
    text-align: left;
  }

  .private-parent-wrapper {
    .private-msg-wrapper {
      padding: 10px;

      .textarea-wrapper {
        .form-control {
          min-height: 66px !important;
        }
      }
    }

    .private-comment-wrapper {
      &.manage_comment_view {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .custom-table {
    &.pt40 {
      padding-top: 0;
    }
  }

  .alert_welcome_university, .alert_engage_introduction {
    padding: 20px;

    .welcome_wrapper {
      flex-direction: column;

      .welcome_text {
        width: 100%;
      }

      span.welcome_img {
        width: 100%;
        padding-right: 0;
        text-align: center;
      }
    }
  }

  .alert_engage_introduction {
    .welcome_wrapper .welcome_img img {
      width: 100%;
    }
  }

  .custom-accordion-2 {
    .card {
      .card-body {
        padding: 0;
      }
    }
  }
  .noObjective,
  .no-sent-receive-wrapper {
    padding: 15px;
  }

  .graph-item {
    &.width50 {
      g {
        &.recharts-layer.recharts-pie {
          transform: translateX(15px);
        }
      }
    }
  }
  .check_in_streak_box {
    padding: 34px 20px 33px 20px;
    .check_in_streak_container {
      width: 50%;
      padding-right: 5px;
      word-break: break-word;
      &:nth-child(2n + 2) {
        ~ .check_in_streak_container {
          margin: 34px 0 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .only-attached-icon {
    .info-attach-file {
      min-width: 260px;
      left: -188px;
      &:before {
        left: 170px;
      }
    }
  }
  .outer-table-wrapper {
    .check_in_search_bar {
      .custom-tooltip {
        .info-attach-file {
          left: -180px !important;
          &::before {
            right: -74px;
          }
        }
      }
    }
  }
}

/* ====================
 Base Responsive End
 ==================== */

/* ==========================
 =============================
 Don't write any CSS after this responsive
 =============================
 ============================= */
