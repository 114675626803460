@import "theme";

.pms_filter_box_wrapper {
  .filter_info {
    padding: 25px 25px 0px;
    position: relative;
    display: block;

    .filter_box {
      display: inline-block;
      width: 270px;
      margin-bottom: 20px;
      padding-right: 20px;
      .dd_title {
        font-weight: 600;
        font-size: 16px;
        color: #322e46;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        display: block;
      }
      :global(.tags_wrapper) {
        position: relative;
        border: 0 solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 47px;
        transition: height 0.5s;
        :global(.multi-select) {
          :global(.dropdown-container) {
            border-color: rgba(0, 0, 0, 0.1);
            height: 47px;
            :global(.dropdown-heading) {
              height: 100%;
              :global(.dropdown-heading-value) {
                font-weight: 500;
                font-size: 15px;
              }
              :global(.dropdown-heading-dropdown-arrow) {
                padding-top: 0 !important;
              }
            }
          }
          :global(.select-panel) {
            :global(.select-item) {
              margin-bottom: 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              :global(span) {
                width: 90%;
                vertical-align: top;
                position: relative;
              }
              :global(.item-renderer) {
                input[type="checkbox"] {
                  opacity: 0;
                }
                :global(span) {
                  position: relative;
                  &::after {
                    content: "";
                    transform: scale(0);
                    transition: 0.2s linear all;
                    cursor: pointer;
                    left: -16px;
                    position: absolute;
                    top: 0;
                    background: #7033ff url(../../assets/images/checkbox-arrow.svg) no-repeat center;
                    width: 17px;
                    height: 17px;
                    opacity: 0;
                    border-radius: 4px;
                    background-size: 9px;
                  }
                  &::before {
                    border: 1px solid $textShade1;
                    content: "";
                    cursor: pointer;
                    display: inline-block;
                    height: 17px;
                    left: -16px;
                    position: absolute;
                    transition: 0.2s linear all;
                    width: 17px;
                    background-color: $whiteColor;
                    border-radius: 4px;
                  }
                }
              }
              &:global(.selected) {
                :global(.item-renderer) {
                  :global(span) {
                    &::after {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }
                }
              }
            }
            :global(li) {
              :global(label) {
                width: 100%;
                margin-bottom: 0;
                &:global(.selected),
                &:hover {
                  background-color: #fcfaff;
                }
              }
              &:last-child {
                :global(label) {
                  border-bottom: none;
                }
              }
            }
          }
        }
        :global(.select__control) {
          min-height: 46px;
          border-color: rgba(0, 0, 0, 0.1);
          :global(.select__placeholder) {
            color: #aaa;
            font-size: 15px;
            font-weight: 500;
          }
          :global(.select__indicators) {
            :global(.select__indicator-separator) {
              display: none;
            }
            :global(.select__dropdown-indicator) {
              color: #aaa;
            }
          }
        }
      }
      :global(.date_picker_time_wrapper) {
        padding: 0px !important;
        :global(.form-group) {
          width: 100% !important;
        }
      }
      :global(.multi-select) {
        :global(.dropdown-heading) {
          min-height: 44px;
        }
      }
    }
    @mixin heading {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .heading {
      @include heading;
      display: flex;
    }
    .otherFilter {
      .heading {
        @include heading;
        margin-top: 10px;
      }
    }
    .filter_btn_group {
      margin-top: 10px;
      :global(.btn) {
        min-width: 115px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .pms_filter_box_wrapper {
    .performance_deviation_btn {
      margin-top: 10px;
    }
  }
}
