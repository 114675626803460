@import '../../../theme';

.cycleListWrapper{
    display: block;
    .create_new_btn{
        padding: 10px 20px;
    }     
    .box_2{
        @include border-radius(8px 8px 0 0);  
        margin-bottom: 0;      
        padding-bottom: 0;
    }
    :global(.outer-table-wrapper){
        min-height: 300px;
    }
    .custom_table{
        position: relative;
        padding: 30px 20px 0;
        .tab_table_responsive{
            margin-bottom: 0;
            thead{
                th{
                    padding: 10px;
                    border:none;
                    width: 15%;
                    &:nth-child(2){
                        width: 70%;
                    }
                }
            }
            tbody{
                td{
                    padding: 20px 10px;
                    border-bottom: none;
                    border-top: 1px solid rgba(0,0,0,0.1);
                    a{
                        font-weight: 600;
                        font-size: 16px;
                        color: $themeColor11;
                        letter-spacing: 0.36px;
                        &:hover{
                            color: $primaryColor;
                        }
                    }
                    span{
                        font-size: 16px;
                        font-weight: 500;
                        color: $themeColor11;
                    }
                    .ended,
                    .inprogress,
                    .view{
                        font-size: 12px;
                        font-weight: 600;
                        padding: 4px 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        display: inline-block;
                        min-width: 86px;
                        text-align: center;
                    }
                    .inprogress{
                        color: #33C5FF;
                        background: #F0FAFD;
                        border-color: #CFF1FF;
                    }
                    .ended{
                        color: #FD8C61;
                        background: #FCF6F4;
                        border-color: #FCE6DD;
                    }
                    .view{
                        color: #33C5FF;
                        background: #F0FAFD;
                        border-color: #CFF1FF;
                    }
                    .edit_cycle{
                        cursor: pointer;
                        display: inline-block;
                    }
                }
            }
        }
    }
}