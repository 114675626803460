@import "../../../theme.scss";

.roll_based_skill_wrapper {
  // display: none;
  :global(.check_in_search_bar) {
    min-width: 320px;
  }
  .create_new_btn {
    padding: 10px 20px;
    min-width: 140px;
  }
  :global(.page-title) {
    padding-bottom: 5px;
    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
  li {
    .skill_name {
      width: 30%;
      > span {
        display: inline-block;
      }
      .skill_gap_tooltip {
        display: inline-block;
        position: relative;
        .skill_gap_info {
          position: absolute;
          left: -30px;
          background: #ffffff;
          border: 1px solid #eaeaea;
          box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
          padding: 5px 10px;
          width: 100px;
          border-radius: 4px;
          text-align: center;
          top: auto;
          bottom: 40px;
          transition: 0.5s all;
          opacity: 0;
          visibility: hidden;
          font-weight: 500;
          font-size: 14px;
          &::before {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-left: 1px solid #eaeaea;
            border-top: 1px solid #eaeaea;
            background: #fff;
            border-radius: 2px;
            right: 51px;
            transform: rotate(-136deg);
            bottom: -5px;
          }
        }
        .skill_gap_icon {
          height: 18px;
          width: 18px;
          border: 1px solid transparent;
          border-radius: 50%;
          display: inline-block;
          margin-left: 3px;
          position: relative;
          transition: 0.5s all;
          top: 5px;
          cursor: pointer;
          &::before {
            background: #ccc;
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;
          }
          &:global(.red) {
            &::before {
              background-color: #f07575;
            }
          }
          &:global(.green) {
            &::before {
              background-color: #00c4bc;
            }
          }
          &:hover {
            background: #ffffff;
            border-color: #dfdfdf;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
            + .skill_gap_info {
              opacity: 1;
              visibility: visible;
              bottom: 30px;
            }
          }
        }
      }
    }
    .endorsement {
      width: 12%;
    }
    .relevant_exp,
    .last_used {
      width: 15%;
    }
    .current,
    .th_info_wrapper {
      width: 14%;
    }
    .th_info_wrapper {
      > .th_info_tooltip {
        display: inline-block;
      }
    }
  }
  :global(.tags_wrapper) {
    position: relative;
    border: 0 solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: auto;
    transition: height 0.5s;
    :global(.multi-select) {
      :global(.dropdown-container) {
        border-color: rgba(0, 0, 0, 0.1);
        height: 47px;
        :global(.dropdown-heading) {
          height: 100%;
          :global(.dropdown-heading-value) {
            font-weight: 500;
            font-size: 15px;
          }
          :global(.dropdown-heading-dropdown-arrow) {
            padding-top: 0 !important;
          }
        }
      }
      :global(.select-panel) {
        :global(.select-item) {
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          :global(span) {
            width: 90%;
            vertical-align: top;
            position: relative;
          }
          :global(.item-renderer) {
            input[type="checkbox"] {
              opacity: 0;
            }
            :global(span) {
              position: relative;
              &::after {
                content: "";
                transform: scale(0);
                transition: 0.2s linear all;
                cursor: pointer;
                left: -16px;
                position: absolute;
                top: 0;
                background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                width: 17px;
                height: 17px;
                opacity: 0;
                border-radius: 4px;
                background-size: 9px;
              }
              &::before {
                border: 1px solid $textShade1;
                content: "";
                cursor: pointer;
                display: inline-block;
                height: 17px;
                left: -16px;
                position: absolute;
                transition: 0.2s linear all;
                width: 17px;
                background-color: $whiteColor;
                border-radius: 4px;
              }
            }
          }
          &:global(.selected) {
            :global(.item-renderer) {
              :global(span) {
                &::after {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
        :global(li) {
          :global(label) {
            width: 100%;
            margin-bottom: 0;
            &:global(.selected),
            &:hover {
              background-color: #fcfaff;
            }
          }
          &:last-child {
            :global(label) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
.role_based_skills_wrapper {
  padding-bottom: 10px;
  margin-bottom: 0;
  padding-top: 0;
  &:global(.active) {
    padding-top: 0;
    :global(.box-heading-wrapper) {
      display: none !important;
    }
    .filter_info_wrapper {
      display: block;
      z-index: 10;
    }
  }
  .panel_title {
    position: relative;
    @include flexbox;
    padding: 15px 0 10px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    > span {
      font-weight: 600;
      font-size: 14px;
      color: #322e46;
      letter-spacing: 0.47px;
      width: 20%;
      &:first-child {
        padding-left: 30px;
        width: 70%;
      }
      &:last-child {
        padding-right: 30px;
        width: 10%;
      }
    }
  }
  .filter_txt {
    font-weight: 600;
    font-size: 15px;
    color: $primaryColor;
    cursor: pointer;
  }
  .category_title {
    font-weight: 600;
    font-size: 14px;
    color: #322e46;
    letter-spacing: 0.32px;
    position: relative;
    width: 100%;
    background-color: #fafafc;
    padding: 15px;
    z-index: 1;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  li {
    @include flexbox;
    @include justify-content(space-between);
  }
  > :global(.box-inner-pad) {
    padding-top: 10px;
    padding-bottom: 0;
  }
}

.skill_require_set_wrapper {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  &:last-child {
    border-bottom: none;
  }
  .skill_required_txt {
    text-transform: uppercase;
    margin: 8px 0;
    display: inline-block;
  }
}

.listing_wrapper {
  position: relative;
  .item {
    display: inline-block;
    width: 100%;
    &:nth-child(n + 2) {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
    .item_info_top_wrapper {
      padding: 15px 0 15px 5px;
      &:global(.active) {
        background-color: rgba(246, 245, 250, 0.5);
      }
    }
  }
}
.item_topbar {
  @include flexbox;
  @include align-items(center);
  .member_info {
    width: 70%;
    @include flexbox;
    @include align-items(center);
    padding-left: 0;
    position: relative;
    .expand_collapse_wrapper {
      cursor: pointer;
      position: static;
      margin-right: 10px;
    }
    .user {
      display: flex;
      cursor: pointer;
      .user_img {
        height: 52px;
        width: 52px;
        overflow: hidden;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
        line-height: 52px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        img {
          max-width: 100%;
          position: relative;
          top: -1px;
        }
      }
      .user_info {
        @include flexbox;
        flex-direction: column;
        @include align-items(flex-start);
        .username {
          font-size: 16px;
          color: #322e46;
          font-weight: 600;
        }
        .department {
          font-size: 14px;
          color: #322e46;
          letter-spacing: 0.39px;
          font-weight: 400;
          margin-top: 2px;
        }
      }
    }
  }
  .skillgaps_text {
    width: 20%;
    font-weight: 600;
    font-size: 16px;
    color: #37324b;
    letter-spacing: 0.36px;
  }
  :global(.action-dropdown-wrapper) {
    width: 10%;
    :global(svg) {
      * {
        fill: #212529;
      }
    }
    :global(.dropdown-item) {
      &:hover {
        color: $primaryColor;
        :global(svg) {
          * {
            fill: $primaryColor;
          }
        }
      }
    }
  }
}
.member_details {
  @include flexbox;
  @include align-items(center);
  flex-wrap: wrap;
  margin-left: 92px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 2px;
  padding-top: 6px;
  .member_details_list {
    margin-right: 30px;
    span {
      font-weight: 500;
      color: #928fa0;
      letter-spacing: 0.39px;
    }
    h5 {
      font-weight: 500;
      color: #37324b;
      letter-spacing: 0.36px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.item_body_wrapper {
  padding: 15px;
}

.filter_info_wrapper {
  padding: 25px 25px 30px;
  position: relative;
  display: block;
  .delete_icon {
    position: absolute;
    top: 10px;
    right: 25px;
    cursor: pointer;
    img {
      width: 10px;
      display: inline-block;
      @include transition(0.4s);
    }
    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
  }
  .filter_top_bar {
    @include flexbox;
    flex-wrap: wrap;
    .filter_box {
      .dd_title {
        font-weight: 600;
        font-size: 16px;
        color: #322e46;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        display: block;
      }
      :global(.custom-default-dd-box) {
        :global(.select__control) {
          min-height: 46px;
          border-color: rgba(0, 0, 0, 0.1);
        }
        :global(.select__indicator-separator) {
          display: none;
        }
        :global(.select__single-value) {
          font-size: 16px;
        }
      }
    }
  }
  .filter_btn_group {
    margin-top: 10px;
    :global(.btn) {
      min-width: 115px;
    }
  }
}
.item_body_wrapper {
  :global(.inner-accordion) {
    margin-bottom: 10px;
    &:global(.active) {
      :global(.inner-accordion-title) {
        :global(.arrow-up-down) {
          &::before {
            opacity: 0;
            transform: rotate(180deg);
          }
          &::after {
            opacity: 1;
            transform: rotate(0);
          }
        }
      }
      .skill_gaps_table {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
        height: auto;
        overflow: visible;
        padding-top: 0;
        padding-bottom: 12px;
      }
    }
  }
}
.skill_gaps_table {
  height: 0;
  overflow: hidden;
  transition: 0.5s all;
  width: 100%;
  .skills_head {
    padding: 15px;
    list-style: none;
    position: relative;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    li {
      padding-right: 0;
      display: block;
      > * {
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
        display: inline-block;
      }
      .skill_name {
        padding-left: 0;
      }
    }
  }
  .skills_body {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      font-weight: 500;
      font-size: 15px;
      color: #74798c;
      display: block;
      position: relative;
      margin-bottom: 15px;
      &.skill_required_txt {
        &::before {
          display: none;
        }
      }
      &:before {
        background: #b5afca;
        content: "";
        position: absolute;
        left: 0;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        top: 13px;
      }
      > * {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #37324b;
        vertical-align: middle;
        &:first-child {
          padding-left: 15px;
        }
      }
      .required_skill {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;
  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }
  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }
  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
}
.endorse_tooltip {
  position: relative;
  display: inline-block;
  ~ .endorse_tooltip {
    margin-left: -7px;
  }
  .tool_tip_info {
    position: absolute;
    left: -25px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #37324b;
    bottom: 50px;
    padding: 5px;
    border-radius: 4px;
    @include transition(0.2s all);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    min-width: 120px;
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      right: 70px;
      height: 7px;
      width: 7px;
      border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      background-color: #fff;
      bottom: -4px;
      transform: rotate(45deg);
    }
    h6 {
      margin-bottom: 3px;
      font-weight: 600;
    }
    > span {
      display: block;
      text-align: center;
      font-size: 13px;
      margin-top: 2px;
      font-weight: 400;
    }
  }
  > img {
    cursor: pointer;
    &:hover {
      + .tool_tip_info {
        opacity: 1;
        bottom: 40px;
        visibility: visible;
      }
    }
  }
}
.addition_skill_wrapper {
  .skill_gaps_table {
    .skills_head {
      li {
        @include flexbox;
      }
    }
    .skills_body {
      li {
        @include flexbox;
        @include align-items(center);
      }
    }
  }
}
@media only screen and (min-width: 1025px) {
  .filter_box {
    width: 18.4%;
    margin: 0 2% 20px 0;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .filter_box {
    width: 24%;
    margin: 0 1.33% 20px 0;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .filter_box {
    width: 32%;
    margin: 0 2% 15px 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .member_details {
    padding-top: 0;
    margin-top: 0;
    .member_details_list {
      margin-top: 2px;
      padding-top: 6px;
    }
  }
  .table_responsive {
    overflow: auto;
    .table_width {
      width: 720px;
    }
  }
  .member_details {
    margin-left: 0;
    margin-top: 15px;
    .member_details_list {
      width: 48%;
      margin-right: 4%;
      margin-top: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .user {
    max-width: 90%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1024px) {
  .roll_based_skill_wrapper {
    width: 100%;
    :global(.check_in_search_bar) {
      margin-bottom: 0;
    }
  }
  .skill_require_set_wrapper {
    position: relative;
  }
  .skill_gap_table_wrapper {
    overflow-y: auto;
    .skill_gaps_table {
      width: 1000px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .roll_based_skill_wrapper {
    :global(.text-right) {
      margin-bottom: 20px;
      text-align: left !important;
    }
  }
  .role_based_skills_wrapper {
    :global(.box-heading-wrapper) {
      flex-wrap: wrap;
      :global(.check_in_search_bar) {
        margin: 0;
        min-width: 80%;
      }
    }
    :global(.custom-accordion-2) {
      :global(.card) {
        :global(.card-header) {
          :global(button) {
            .user_info {
              .username,
              .department {
                text-align: left;
              }
            }
          }
        }
      }
    }
    .panel_title {
      padding: 10px 0;
      > span {
        font-size: 12px;
        width: 17%;
        padding-right: 10px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          width: 13%;
        }
      }
    }
  }
  .item_topbar {
    flex-wrap: wrap;
    .skillgaps_text {
      width: 17%;
    }
    .member_info {
      flex-wrap: wrap;
      .expand_collapse_wrapper {
        margin-right: 5px;
      }
      .user_img {
        height: 40px;
        width: 40px;
        line-height: 42px;
        font-size: 15px;
        margin-right: 5px;
      }
      .user_info {
        width: calc(100% - 70px);
        .department {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
  .member_details {
    margin-left: 0;
    margin-top: 15px;
    .member_details_list {
      width: 48%;
      margin-right: 4%;
      margin-top: 15px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .filter_info_wrapper {
    padding: 35px 15px 15px;
    .filter_top_bar {
      .filter_box {
        width: 48%;
        margin: 0 4% 15px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .dd_title {
          font-size: 13px;
        }
      }
    }
    .filter_btn_group {
      margin-top: 5px;
    }
    .delete_icon {
      right: 15px;
    }
  }

  :global(.inner-accordion) {
    margin: 15px 0;
    &:last-child {
      margin-bottom: 15px;
    }
    &:global(.active) {
      .skill_gaps_table {
        .skills_body {
          li {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .item_body_wrapper {
    padding: 0;
  }
  .skill_require_set_wrapper {
    padding: 5px 10px 10px;
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .skill_gaps_table {
    .skills_body {
      li {
        width: 100%;
        margin-top: 0 !important;
        margin-bottom: 10px;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .table_responsive {
    overflow: auto;
    .table_width {
      width: 720px;
    }
  }
  .user {
    max-width: 90%;
    cursor: pointer;
  }
}
