@import "theme.scss";
.startButton {
  margin-top: 20px;
  width: 217px;
  height: 60px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  border-radius: 33px;
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:focus {
    outline: none;
  }
}
.attemptBox {
  background-color: rgba(243, 240, 255, 0.5);
  text-align: center;
  box-shadow: 0px 2px 4px 2px rgba(222, 215, 249, 0.51);
  border-radius: 8px;

  .middleLineAttemptBox {
    margin-left: 6.55%;
    margin-right: 6.55%;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.255555px;
    color: #322e46;
  }

  .titleAttemptBox {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.255555px;
    color: #322e46;
  }

  .lastLineAttemptBox {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.209091px;
    color: #322e46;
  }

}

.backButton {
  margin-left: 20px;
  width: 145.6px;
  height: 40px;
  background: linear-gradient(180deg, #a962ff -37.78%, #7033ff 100%);
  border-radius: 20px;
  border: 1px solid rgba(184, 180, 204, 0.5);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;

  &:focus {
    outline: none;
  }
}

.info_tooltip {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.21px;
}

.gradient_size {
  padding: 20px;
  @include flexbox;
  @include justify-content(flex-end);

  &::before {
    height: 100%;
  }
}

.QuestionTemplate {
    :global(.box) {
      box-shadow: none;
      :global(.box-heading-wrapper) {
        border-bottom: none;
      }
      :global(.box-inner-pad) {
        // margin-bottom: 22px;
      }
    }
    :global(.private-assessment-wrapper) {
      &:global(.box) {
        :global(.ratingBarBox) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 25px;
          &:last-child {
            padding-bottom: none;
            border-bottom: none;
          }
        }
      }
    }
  .QuestionTemplateForHR {
    :global(.private-assessment-wrapper) {
      :global(.radio-wrapper) {
        :global(.custom-checkbox) {
          input {
            &:disabled {
              + :global(label) {
                &:after {
                  background-image: none;
                }
              }
            }
            &:checked {
              + :global(label) {
                &:after {
                  background-color: #eeeeee;
                }
              }
            }
          }
        }
      }
    }
  }
}

.grade_collection {
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 15px;

  @mixin grade_card {
    width: 155px;
    height: 156px;
    margin-left: 20px;
    margin-bottom: 24px;
    background-color: #f6f5fa;
    text-align: center;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    flex-direction: column;
    padding-top: 30px;
  }

  .grade_wrongAnswer {
    @include grade_card;
    background-color: rgba(255, 144, 102, 0.15);
  }

  .grade_correctAnswers {
    @include grade_card;
    background-color: rgba(0, 199, 255, 0.1);
  }

  .grade_Result_Pass {
    @include grade_card;
    background-color: rgba(109, 212, 0, 0.1);
  }

  .grade_Result_Fail {
    @include grade_card;
    background-color: rgba(240, 117, 117, 0.081012);
  }

  .grade_Score {
    @include grade_card;
    background-color: rgba(184, 180, 204, 0.1);
  }

  .cardText {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .grade_info_card {
    .grade_collection {
      padding-bottom: 20px;
      justify-content: center;

      .grade_card {
        justify-content: center;
        width: 20%;
        height: 145px;
        margin: 15px;
        padding-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .startButton {
    margin-left: auto;
    margin-top: auto;
    width: 150 px;
    margin-bottom: 10px;
  }
  .grade_info_card {
    .grade_collection {
      padding-bottom: 20px;
      justify-content: center;

      .grade_card {
        justify-content: center;
        width: 50%;
        height: 145px;
        margin: 15px;
        padding-bottom: 20px;
      }
    }
  }
}