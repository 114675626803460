.two_table_wrapper {
  .main_heading {
    text-align: center;
    border-right: 2px solid #dee2e6;
    border-left: 2px solid #dee2e6;
  }
  .left_heading {
    text-align: left;
    border-right: 2px solid #dee2e6;
    border-left: 2px solid #dee2e6;
    .project_info_tooltip {
      display: inline-block;
      :global(.info-attach-file) {
        min-width: 280px;
        left: auto;
        right: -252px;
        &::before {
          left: auto;
        }
        :global(.attachment-title) {
          color: #b4b0c8;
        }
      }
    }
  }
  .border_right {
    border-right: 2px solid #dee2e6;
  }
  .border_left {
    border-left: 2px solid #dee2e6;
  }
  table tbody tr:last-child td {
    border-bottom: 2px solid #dee2e6 !important;
  }
}
