.banner_wrapper {
  margin-top: 27px;
  padding: 20px 30px;

  .content_wrapper {
    margin: 0;
    width: 78%;

    p {
      margin: 0;
      font-size: 16px;
      color: #322e46;
      letter-spacing: 0.32px;
      line-height: 20px;
    }
  }

  .right_image {
    width: 22%;
    text-align: right;

    img {
      width: 170px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .banner_wrapper {
    .content_wrapper {
      width: 70%;
    }

    .right_image {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner_wrapper {
    .content_wrapper {
      width: 100%;
    }
    .right_image {
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
  }
}