@import "../../theme.scss";

.user_info {
    @include flexbox;
    padding: 0 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include align-items(center);
  
    .user_img {
      background-color: #FF5479;
      width: 72px;
      height: 72px;
      margin: 0 15px 0 0;
      @include flex(0 0 72px);
      overflow: hidden;
      @include border-radius(50%);
      font-size: 26px;
      font-weight: 600;
      color: $whiteColor;
      line-height: 72px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        max-width: 100%;
        height: 100%;
      }
      a{
        color: $whiteColor;
        height: 100%;
        display: inline-flex;
        
        &:hover{
          color: $whiteColor;
        }
      }
    }
  
    .user_name {
      font-size: 18px;
      font-weight: 600;
      color: $themeColor11;
    }
  
    .user_designation {
      font-size: 16px;
      font-weight: 400;
      color: $primaryText;
    }
}

.user_details {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    width: 100%;
    padding-right: 30.33%;
}

.user_details_right {
    text-align: right;
    white-space: nowrap; //adding word nowrap as per discussion
}

.user_date_of_submission {
    font-size: 15px;
    font-weight: 500;
    color: $textShade1;
    margin-bottom: 5px;
    span {
      color: $primaryText;
    }
}

.user_checkin_status {
    font-size: 12px;
    font-weight: 600;
    padding: 4px 10px;
    border: 1px solid $borderColor;
    @include border-radius(4px);
    display: inline-block;
  
    &.pending {
      color: #FD8C61;
      background: #FCF6F4;
      border-color: #FCE6DD;
    }
}

:global(.myPageView){
    .user_details{
      padding-right: 0;
    }
    .userBandWrapper,
    .userInfoText{
      display: none;
    }
}

.waitingsubmitionwrapper{
    justify-content: center;
    align-items: center;
    padding: 93px 0;
    margin:0;
    .waitingsubmitioncont{
        text-align: center;
        width: 270px;
        max-width: 390px;
        padding: 10px 0 0;
        margin: 0px 0px 62px 0px;
        .waitingsubmitiontitle{
            font-size: 18px;
            font-weight: 600;
            color: $primaryText;
            margin: 15px 0 5px;
        }
        .waitingsubmitiontext{
            margin-bottom: 0;
            color: $textShade1;
            line-height: 14px;
            font-weight: 500;
            span{
                color:$primaryText;
            }
        }   
    }
    .lookhistorywrapper{
        text-align: center;
        margin: 0px auto;
        .historycont{
            position: relative;
            width: 568px;
            border: 1px solid $textShade5;
            margin: 0px auto;
            padding: 40px 35px 32px 35px;
            .historytitle{
                font-size: 16px;
                color: $primaryText;
                font-weight: 500;
                padding: 0px 15px;
                position: absolute;
                top: -12px;
                left: 0;
                margin: auto;
                width: 235px;
                right: 0;
                background-color: $whiteColor;
            }
            .historydata{
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    li{
                        display: inline-flex;
                        .historyicon{
                            margin-right: 10px;
                        }
                        p{
                            margin: 0;
                            padding: 0;
                            &.number{
                                font-size: 22px;
                                font-weight: 600;
                                color: $primaryText;
                                text-align: left;
                            }
                            &.caption{
                                font-size: 14px;
                                font-weight: 500;
                                color: $textShade1;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

    }
}
:global(.folder-wrapper-overlay-1) {
  height: 0;
  width: 55px;
  background-color: #FFF;
  content: '';
  position: absolute;
  left: 2px;
  right: 0;
  margin: auto;
  z-index: 10;
  top: 76px;
  animation: foldervisible 0.5s linear 1 forwards;
}

@keyframes foldervisible {
  0% {height: 60px;}
  100% {height: 0;}
}
/*start responsive css*/
@media (max-width:1024px){
    .user_details{
      padding-right: 0;
      width: calc(100% - (232px + 15px));
    }
    .userBandWrapper{
      width: calc(50% - ( 78px + 15px));
      position: static;
      padding-left: 15px;
    }
    :global(.myPageView){
      .user_details{ 
          width: 100%;
      }
    }
  }
@media (max-width:767px){
    .user_info {
      .user_img {
        width: 60px;
        height: 60px;
        @include flex(0 0 60px);
        line-height: 64px;
      }
      .user_name{
        font-size: 16px;
      }
      .user_designation{
        font-size: 14px;
      }
    }
    .user_details{
      width: 100%;
    }
    .userBandWrapper{
      padding-left: 15px;
    }
    .userInfoText{
      .row{
        .col-6{
          &:last-child{
            .userExpDetails{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    :global(.myPageView){
      .user_info{
        flex-direction: column;
        .user_img{
          margin: 0 0 15px;
        }
        .submission_ontime{
          margin-top: 10px;
        }
      }
      .user_details_right {
        text-align: center;
        margin-top: 10px;
      }
      .user_details{ 
          width: 100%;
          flex-direction: column;
          text-align: center;
      }
    }

    .waitingsubmitionwrapper{
      .waitingsubmitioncont {
        padding: 10px 20px;
      }
    }
}