@import "theme";

.map_capability_wrapper {
  display: block;
  .map_capability_questions {
    .no_question_list {
      padding: 0 25px;
      border-bottom: 0;
    }
    .filter_for_capability {
      margin-top: 20px;
      .filter_top_bar {
        @include flexbox;
        flex-wrap: wrap;
        .filter_box {
          .dd_title {
            font-weight: 600;
            font-size: 16px;
            color: #322e46;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
            display: block;
          }
          :global(.tags_wrapper) {
            position: relative;
            border: 0 solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            height: auto;
            transition: height 0.5s;
            :global(.multi-select) {
              :global(.dropdown-container) {
                border-color: rgba(0, 0, 0, 0.1);
                height: 47px;
                :global(.dropdown-heading) {
                  height: 100%;
                  :global(.dropdown-heading-value) {
                    font-weight: 500;
                    font-size: 15px;
                  }
                  :global(.dropdown-heading-dropdown-arrow) {
                    padding-top: 0 !important;
                  }
                }
                :global(.dropdown-content) {
                  z-index: 2;
                }
              }
              :global(.select-panel) {
                :global(.select-item) {
                  margin-bottom: 0;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                  :global(span) {
                    width: 90%;
                    vertical-align: top;
                    position: relative;
                  }
                  :global(.item-renderer) {
                    input[type="checkbox"] {
                      opacity: 0;
                    }
                    :global(span) {
                      position: relative;
                      &::after {
                        content: "";
                        transform: scale(0);
                        transition: 0.2s linear all;
                        cursor: pointer;
                        left: -16px;
                        position: absolute;
                        top: 0;
                        background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
                        width: 17px;
                        height: 17px;
                        opacity: 0;
                        border-radius: 4px;
                        background-size: 9px;
                      }
                      &::before {
                        border: 1px solid $textShade1;
                        content: "";
                        cursor: pointer;
                        display: inline-block;
                        height: 17px;
                        left: -16px;
                        position: absolute;
                        transition: 0.2s linear all;
                        width: 17px;
                        background-color: $whiteColor;
                        border-radius: 4px;
                      }
                    }
                  }
                  &:global(.selected) {
                    :global(.item-renderer) {
                      :global(span) {
                        &::after {
                          transform: scale(1);
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
                :global(li) {
                  :global(label) {
                    width: 100%;
                    margin-bottom: 0;
                    &:global(.selected),
                    &:hover {
                      background-color: #fcfaff;
                    }
                  }
                  &:last-child {
                    :global(label) {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
            :global(.select__control) {
              min-height: 46px;
              border-color: rgba(0, 0, 0, 0.1);
              :global(.select__placeholder) {
                color: #aaa;
                font-size: 15px;
                font-weight: 500;
              }
              :global(.select__indicators) {
                :global(.select__indicator-separator) {
                  display: none;
                }
                :global(.select__dropdown-indicator) {
                  color: #aaa;
                }
              }
            }
          }
          :global(.date_picker_time_wrapper) {
            padding: 0px !important;
            :global(.form-group) {
              width: 100% !important;
            }
          }
          :global(.multi-select) {
            :global(.dropdown-heading) {
              min-height: 44px;
            }
          }
        }
      }
    }
    .questionList {
      padding: 25px;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: flex-start;
      &:last-child {
        border-bottom: none;
      }
      .question_sr_no {
        font-weight: 600;
        font-size: 22px;
        color: #322e46;
        letter-spacing: 0.26px;
      }
      .que_ans_wrapper {
        margin: 5px 0 0 15px;
        width: 100%;
        :global(.form-group) {
          margin-bottom: 0;
          .form_option_list {
            list-style: none;
            padding-left: 0;
            margin: 15px 0 0;
            &:global(.limit-reached) {
              :global(.custom-checkbox) {
                :global(label) {
                  color: #f07575;
                }
              }
            }
            li {
              display: inline-block;
              margin-right: 25px;
              &:last-child {
                margin-right: 0;
              }
              &:global(.w-100) {
                margin-right: 0;
                margin-bottom: 5px;
              }
            }
            :global(.custom-checkbox) {
              margin-bottom: 0;
              cursor: auto;
              input {
                &:disabled {
                  + :global(label) {
                    &:after {
                      background-image: url(../../../assets/images/ic_radio_disabled_2.svg);
                      background-size: 100%;
                      -ms-background-size: 100%;
                      -moz-background-size: 100%;
                    }
                  }
                }
                &:checked {
                  + :global(label) {
                    &:after {
                      background-image: url(../../../assets/images/ic_radio_active_2.svg);
                    }
                  }
                }
              }
              :global(input[type="radio"] + label) {
                font-size: 14px;
                padding-left: 30px;
                line-height: 20px;
                cursor: pointer;
                &::before {
                  height: 20px !important;
                  width: 20px !important;
                }
                &:after {
                  height: 20px !important;
                  width: 20px !important;
                  // background-image: url(assets/images/ic_radio_active_2.svg);
                }
              }
            }
            li {
              &:last-child {
                :global(.custom-checkbox) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .map_capability_history_wrapper {
    margin-bottom: 7px;
    + :global(.pagination-col) {
      margin-top: 0;
    }

    > :global(.box-heading-wrapper) {
      :global(.box-heading) {
        width: 70%;
      }
    }
    .defined_roles_table {
      padding: 15px 0 0;
      position: relative;
      :global(.table) {
        margin-bottom: 0;
      }

      thead {
        padding: 0;
        list-style: none;

        th {
          font-size: 14px;
          font-weight: 600;
          padding: 12px;
          color: #37324b;
          border-top: 0;
          border-bottom-width: 1px;
          &.department_name {
            padding-left: 25px;
            width: 79%;
          }
          &.designation_name {
            width: 49%;
          }
          &.action {
            width: 7%;
            text-align: center;
          }
        }
      }

      tbody {
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        td {
          font-size: 15px;
          font-weight: 500;
          padding: 12px;
          color: #37324b;
          position: static;
          svg {
            cursor: pointer;
            * {
              fill: #322e46;
            }
          }
          &:first-child {
            padding-left: 25px;
          }
          &.action {
            text-align: center;
            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .filter_top_bar {
    .filter_box {
      width: 30%;
      margin: 0 3% 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .filter_top_bar {
    .filter_box {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .filter_top_bar {
    .filter_box {
      width: 46%;
      margin: 0 4% 15px 0;
    }
  }
}
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .filter_top_bar {
    .filter_box {
      width: 32%;
      margin: 0 1.33% 20px 0;
    }
  }
}
