@import "../../theme.scss";

.pagenotfound_wrapper{
    margin: 0px auto;
    display: flex;
    .pagenotfound_datawrapper{
        width: 100%;
        margin: 0px auto;
        .pagenotfound_data {
            margin: 130px  0px 0px 0px;
            .pagenotfound_img{
                margin: 0px auto;
                justify-content: center;
                display: flex;
                margin-bottom: 50px;
            }
            .pagenotfound_title{
                color: $primaryText;
                font-size: 36px;
                font-weight: 600;
                text-align: center;
                line-height: 36px;
                margin-bottom: 20px;
            }
            .pagenotfound_txt{
                color: $textShade1;
                font-size: 21px;
                font-weight: 600;
                text-align: center;
                line-height: 21px;
                margin: 0px 0px 40px 0px;
            }
            .pagenotfound_link{
                display: flex;
                justify-content: center;
                text-align: center;
                color: $primaryColor;
                font-size: 20px;
                font-weight: 500;
                line-height: 20px;
                
            }
        }
    }
}

@media only screen and (max-width:767px){
    .pagenotfound_wrapper{
        .pagenotfound_datawrapper{
            .pagenotfound_data {
                margin: 50px  0px 0px 0px;
                .pagenotfound_img{
                    margin-bottom: 20px;
                    height: 250px;
                    > div{
                        height: 100%;
                        > div {
                            height: 100%;
                            svg{
                                height: 100%;
                            }
                        }
                    }
                }
                .pagenotfound_title{
                    font-size: 26px;
                }
                .pagenotfound_txt{
                    font-size: 16px;
                    margin: 0px 0px 20px 0px;
                }
            }
        }
    }
}