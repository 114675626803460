.login_footer {
  position: fixed;
  bottom: 0;
  background: url(../../assets/images/login_footer_bg_apexon.svg) repeat-x 0px 0px;
  height: 135px;
  width: 100%;
  z-index: -1;
}

@media only screen and (max-width: 479px) {
  .login_footer {
    height: 70px;
    background-size: 706px;
  }
}
