@import "../../theme.scss";

.member_certificate_wrapper {
  // display: none;
  :global(.check_in_search_bar) {
    min-width: 400px;
  }

  .create_new_btn {
    padding: 10px 20px;
    min-width: 120px;
  }

  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }
}

.my_certificate_box {
  padding-top: 15px;
  padding-bottom: 0;

  :global(.box-heading-wrapper) {
    padding-bottom: 15px;

    :global(.box-info) {
      position: static;
    }
  }

  form {
    display: inline-block;
    width: 90%;
  }

  .filter_info_tooltip {
    text-align: left;
    margin-left: 10px;
    display: inline-block;

    :global(.info-attach-file) {
      min-width: 280px;
      left: auto;
      right: -50px;

      &::before {
        right: 54px;
        left: auto;
      }

      :global(.attachment-title) {
        color: #b4b0c8;
      }

      :global(ul) {
        margin: 10px 0;

        :global(li) {
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.member_certificate_table {
  position: relative;

  table {
    margin-bottom: 0;

    thead {
      th {
        border-bottom: 0;
        width: 12%;
        border-top: 0;

        .sorting_icon {
          display: inline-block;
          margin-left: 7px;
          cursor: pointer;
        }

        &:nth-child(3) {
          width: 26%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 12%;
        }

        &:nth-child(6) {
          width: 10%;
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 500;
          font-size: 15px;
          color: #322e46;

          .download {
            cursor: pointer;
            margin: 0 15px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;
  min-width: 75px;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
  &:global(.yellow) {
    color: #ffcb00;
    border-color: rgba($color: #ffcb00, $alpha: 0.25);
    background-color: #fffcf0;
  }

  &:global(.red) {
    color: #fd6161;
    border-color: rgba($color: #fce6dd, $alpha: 1);
    background-color: #fcf6f4;
  }
}

@media only screen and (max-width: 1024px) {
  .member_certificate_wrapper {
    width: 100%;
    :global(.check_in_search_bar) {
      margin-bottom: 0;
      min-width: 350px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .member_certificate_wrapper {
    :global(.text-right) {
      text-align: left !important;
      margin-top: 15px;
    }

    :global(.box-heading-wrapper) {
      flex-direction: column;
      @include align-items(flex-start);
    }

    :global(.check_in_search_bar) {
      min-width: 100%;

      form {
        width: 88%;
      }
    }

    .filter_info_tooltip {
      :global(.info-attach-file) {
        left: auto;
        right: -5px;

        &::before {
          left: auto;
          right: 9px;
        }
      }
    }
  }

  .member_certificate_table {
    overflow-x: auto;

    table {
      width: 767px;
    }
  }
}
