@import "theme";
.allobjectives_wrapper {
  .create_new_btn {
    padding: 10px 20px;
  }
  :global(.form-control-rounded){
    font-size: 14px;
  }
  .tab_title {
    h2 {
      color: $primaryText;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 5px;
    }
    h3 {
      color: $textShade1;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .search_bar {
    // display: inline-block;
    // text-align: right;
    width: 100%;
    align-items: center;
    // justify-content: flex-end;
    padding: 20px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .dw_obj_btn {
      display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    }
    .allobjective_searchbar {
      width: 320px;
      display: inline-flex;
      max-width: 100%;
      input[type="text"] {
        padding-right: 50px;
      }
    }
    .form-search-item {
      height: 40px;
    }
  }
  .filter_info_tooltip_pending{
    margin-left: 0px!important;
    margin-right: 10px;
  }
  .filter_info_tooltip {
    text-align: left;
    margin-left: 10px;
    margin-top: 5px;
    :global(.info-attach-file) {
      min-width: 280px;
      left: auto;
      right: -50px;
      &::before {
        right: 54px;
        left: auto;
      }
      :global(.attachment-title) {
        color: #b4b0c8;
      }
      :global(ul) {
        margin: 10px 0;
        :global(li) {
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .hide_button {
    visibility: hidden;
    transition: none !important;
  }
  .show_button {
    visibility: visible;
    transition: none !important;
  }
  .file_name_popup {
    padding-top: 5px;
  }
  .allobjectives_tabs {
    :global(.nav-tabs) {
      width: 500px;
      text-align: center;
      max-width: 100%;
    }
    :global(.tab-content) {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
      background: transparent;
      border-radius: 0;
      z-index: 1;
      position: relative;
      .tab_container_wrapper {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
        background: #fff;
        border-radius: 0 8px 8px;
        z-index: 1;
        position: relative;
        .tab_heading {
          padding: 20px 30px;
        }
        .allobjective_table {
          padding-bottom: 10px;
          .tab_table_responsive {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
            background: transparent;
            border-radius: 0;
            z-index: 1;
            position: relative;
            margin-bottom: 0;
            thead {
              tr {
                border-bottom: 1px solid #dee2e6;
                td {
                  font-size: 14px;
                  color: $primaryText;
                  font-weight: 600;
                  border-top: 0px solid #dee2e6;
                  &.name {
                    width: 600px;
                  }
                  &.key_result {
                    width: 200px;
                  }
                  &.duration {
                    width: 200px;
                  }
                  &.tags {
                    width: 100px;
                  }
                  &.action {
                    width: 150px;
                  }
                }
              }
            }
            tbody {
              border-top: none;
              &:last-child {
                tr {
                  border-bottom-width: 0;
                }
                :global(.tr_expanded) {
                  border-bottom-width: 1px;
                }
              }
              tr {
                border-bottom: 1px solid #dee2e6;
                td {
                  font-size: 14px;
                  font-weight: 400;
                  color: $primaryText;
                  border: none;
                  &:first-child {
                    color: $primaryText;
                    font-size: 15px;
                    font-weight: 600 !important;
                    padding-left: 40px;
                    span {
                      position: absolute;
                      left: 10px;
                      cursor: pointer;
                    }
                  }
                  &.close_expand {
                    height: 0px;
                    -moz-transition: height 1s ease;
                    -webkit-transition: height 1s ease;
                    -o-transition: height 1s ease;
                    transition: height 1s ease;
                    opacity: 0;
                    visibility: hidden;
                  }
                  &.open_expand {
                    height: 200px;
                    opacity: 1;
                    visibility: visible;
                  }
                  // ul{
                  //     list-style: none;
                  //     padding: 0;
                  //     margin: 5px 0;
                  //     li{
                  //         padding: 0px 0px 0px 15px;
                  //         margin: 0px 0px 15px 0px;
                  //         color: $themeColor11;
                  //         font-size: 14px;
                  //         font-weight: 500;
                  //         background: url(assets/images/ic_keyresult_bullet.svg) no-repeat 0px 6px;
                  //         &:last-child{
                  //             margin-bottom: 0;
                  //         }
                  //     }
                  // }
                  ul {
                    list-style: none;
                    padding: 0;
                    margin: 10px 0px 0px 0px;
                    display: inline-block;
                    width: 100%;
                    li {
                      float: left;
                      padding: 0px 0px 0px 15px;
                      margin: 0px 0px 0px 0px;
                      color: $themeColor11;
                      font-size: 14px;
                      font-weight: 500;

                      &:last-child {
                        margin-bottom: 0;
                      }
                      &:first-child {
                        background: url(../../../assets/images/ic_keyresult_bullet.svg) no-repeat 0px 6px;
                        width: 645px;
                      }
                      &.key_value {
                        width: 230px;
                        padding-left: 33px;
                      }
                    }
                  }
                  a {
                    font-weight: 500;
                    width: 100%;
                  }
                  :global(.dropdown_item),
                  :global(.dropdown-item) {
                    width: 100%;
                    cursor: pointer;
                    font-weight: 400;
                    &:hover {
                      color: $primaryColor;
                      * {
                        color: $primaryColor;
                        fill: $primaryColor;
                      }
                    }
                  }
                  &:global(.action-dropdown-wrapper) {
                    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
                    border-top: 0px solid rgba(0, 0, 0, 0.1);
                  }
                  &.a_assign {
                    a {
                      color: $primaryColor;
                      font-weight: 500;
                    }
                  }
                  // &.expand_duration{
                  //     ul{
                  //         list-style: none;
                  //         padding: 0;
                  //         margin: 5px 0;
                  //                 li{
                  //                     padding: 0px 0px 0px 15px;
                  //                     margin: 0px 0px 15px 0px;
                  //                     color: $themeColor11;
                  //                     font-size: 14px;
                  //                     font-weight: 500;
                  //                     background: none;
                  //                     list-style: none;
                  //                     &:last-child{
                  //                     margin-bottom: 0;
                  //                     }
                  //                 }
                  //     }
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
  .tab-content-dashboard .tab-content {
  }
}
.uploadcsv_popup_wrapper {
  .title_box {
    margin-bottom: 25px;
    h1 {
      font-size: 18px;
      font-weight: 600;
      color: $primaryText;
      padding: 0px;
      margin: 0px;
    }
    h2 {
      color: $textShade1;
      font-size: 18px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .error_box {
    border: 1px solid #fad1d1;
    border-radius: 4px;
    margin-bottom: 5px;
    .error_title {
      color: #e24c5e;
      font-size: 14px;
      border-bottom: 1px solid #fad1d1;
      padding: 5px 5px 5px 10px;
      height: 46px;
      line-height: 34px;
      font-weight: 600;
    }
    .error_point {
      padding: 15px;
      background-color: #fff7f7;
      border-radius: 4px;
      > b {
        margin-top: 15px;
        display: inline-block;
        width: 100%;
        &:first-child {
          margin-top: 0;
        }
      }
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        color: $primaryText;
      }
      li {
        list-style: none;
        color: $primaryText;
        font-size: 14px;
        margin: 5px 0px 0px 0px;
        padding: 0px 0px 0px 12px;
        position: relative;
        font-weight: 500;
        &::before {
          position: absolute;
          left: 0;
          width: 5px;
          height: 1px;
          background-color: $primaryText;
          content: "";
          left: 0;
          top: 8px;
        }
      }
    }
  }
  p {
    &.file_name_2 {
      font-size: 14px;
      font-weight: 400;
      color: $primaryText;
      margin: 0px 0px 15px 0px;
      width: 90%;
      display: inline-block;
      padding: 0px 0px 10px 0px !important;
    }
  }
  .fileicon_2 {
    display: inline-block;
    vertical-align: top;
    padding-right: 10px;
    + span {
      position: relative;
      top: 1px;
    }
  }
  .fileicon_3 {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 15px;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    > span {
      position: relative;
      top: 1px;
      margin-left: 10px;
    }
  }
}
.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  &.show {
    max-height: 99em;
    transition: max-height 0.5s ease-in-out;
  }
}

.block {
  margin-top: 10px;
  background: #f5f5f5;
  padding: 0;
  // height: 250px;

  &__content {
    border: 1px solid #ccc;
    padding: 1.5em;
    height: 100%;
  }
}

.tagsWrapper {
  white-space: nowrap; 
  width: 140px; 
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1024px) {
  .allobjective_table {
    :global(.custom-table.pt40) {
      overflow-x: auto;
      .tab_table_responsive {
        width: 900px;
      }
    }
  }
  .allobjectives_wrapper {
    .allobjectives_tabs {
      :global(.tab-content) {
        .tab_container_wrapper {
          .allobjective_table {
            .tab_table_responsive {
              tbody {
                tr {
                  td {
                    ul {
                      li {
                        &:first-child {
                          width: 490px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .allobjectives_wrapper {
    .search_bar {
      justify-content: flex-start;
      text-align: left;
      margin-top: 15px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .allobjectives_wrapper {
    .allobjectives_tabs {
      :global(.tab-content) {
        .tab_container_wrapper {
          .allobjective_table {
            .tab_table_responsive {
              tbody {
                tr {
                  td {
                    ul {
                      li {
                        &:first-child {
                          width: 490px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .filter_info_tooltip {
      margin-top: 15px;
      :global(.info-attach-file) {
        right: -5px !important;
        right: auto;
        &::before {
          right: 9px;
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .allobjectives_wrapper {
    // .tab_title {
    //   margin-bottom: 15px;
    // }
    .hide_button {
      display: none;
    }
    .allobjectives_tabs {
      :global(.nav-tabs) {
        width: 480px;
        text-align: center;
        max-width: 100%;
      }
      .allobjective_table {
        :global(.custom-table) {
          overflow-x: auto;
          .tab_table_responsive {
            width: 1000px;
          }
        }
      }
    }
    :global(.text-right.col-12.col-lg-8.col-md-9.col-sm-6) {
      text-align: left !important;
      margin-bottom: 15px;
      .create_new_btn {
        margin-bottom: 15px;
        &:global(.btn-primary) {
          margin-bottom: 0;
        }
      }
    }
    .search_bar {
      text-align: left;
      flex-wrap: wrap;
      margin-top: 0 !important;
      :global(.dw-obj-btn) {
        margin-top: 15px;
      }
    }
  }
}
