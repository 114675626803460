@import "theme";
.super_admin_wrapper {
  :global(div[id*="multi-select-dd-box-with-placeholder"]) {
    width: 250px;
    height: 48px;
  }
  .dd_title {
    font-weight: 600;
    font-size: 16px;
    color: #322e46;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    display: block;
  }
  :global(.tags_wrapper) {
    :global(.error-box) {
      :global(.dropdown-container) {
        border-color: #f07575 !important;
      }
    }
    :global(.dropdown-container) {
      border-color: rgba(0, 0, 0, 0.1);
      height: 47px;
      :global(.dropdown-heading) {
        height: 100%;
        :global(.dropdown-heading-value) {
          font-weight: 500;
          font-size: 15px;
        }
        :global(.dropdown-heading-dropdown-arrow) {
          padding-top: 0 !important;
        }
      }
    }
    :global(.select-panel) {
      :global(.select-item) {
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        :global(span) {
          width: 90%;
          vertical-align: top;
          font-weight: 500;
          font-size: 16px;
          position: relative;
        }
        :global(.item-renderer) {
          input[type="checkbox"] {
            opacity: 0;
          }
          :global(span) {
            position: relative;
            &::after {
              content: "";
              transform: scale(0);
              transition: 0.2s linear all;
              cursor: pointer;
              left: -16px;
              position: absolute;
              top: 0;
              background: #7033ff url(../../../assets/images/checkbox-arrow.svg) no-repeat center;
              width: 17px;
              height: 17px;
              opacity: 0;
              border-radius: 4px;
              background-size: 9px;
            }
            &::before {
              border: 1px solid $textShade1;
              content: "";
              cursor: pointer;
              display: inline-block;
              height: 17px;
              left: -16px;
              position: absolute;
              transition: 0.2s linear all;
              width: 17px;
              background-color: $whiteColor;
              border-radius: 4px;
            }
          }
        }
        &:global(.selected) {
          :global(.item-renderer) {
            :global(span) {
              &::after {
                transform: scale(1);
                opacity: 1;
              }
            }
          }
        }
      }
      :global(li) {
        :global(label) {
          width: 100%;
          margin-bottom: 0;
          &:global(.selected),
          &:hover {
            background-color: #fcfaff;
          }
        }
        &:last-child {
          :global(label) {
            border-bottom: none;
          }
        }
      }
    }
  }
  .save_box_left {
    :global(.btn) {
      min-width: 150px;
      font-size: 14px;

      :global(strong) {
        font-weight: 600;
      }
    }
  }
  .organization_history_wrapper {
    margin-bottom: 7px;
    + :global(.pagination-col) {
      margin-top: 0;
    }

    > :global(.box-heading-wrapper) {
      :global(.box-heading) {
        width: 70%;
      }
    }
    .defined_roles_table {
      padding: 15px 0 0;
      position: relative;
      :global(.table) {
        margin-bottom: 0;
      }

      thead {
        padding: 0;
        list-style: none;

        th {
          font-size: 14px;
          font-weight: 600;
          color: #37324b;
          border-top: 0;
          border-bottom-width: 1px;
          &.organization_name {
            padding-left: 25px;
            width: 12%;
          }
          &.admin {
            width: 10%;
          }
          &.modules {
            width: 30%;
          }
          &.action {
            width: 6%;
            text-align: center;
          }
        }
      }

      tbody {
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        td {
          font-size: 15px;
          font-weight: 500;
          color: #37324b;
          position: static;
          svg {
            cursor: pointer;
            * {
              fill: #322e46;
            }
          }
          &:first-child {
            padding-left: 25px;
          }
          &.action {
            text-align: center;
            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 975px) {
  .super_admin_wrapper {
    :global(.radio-wrapper) {
      flex-wrap: wrap;
      :global(.form-group) {
        padding-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .super_admin_wrapper {
    :global(.radio-wrapper) {
      display: block !important;
      :global(.form-group) {
        padding-bottom: 15px;
        margin-right: 0;
        :global(.tags_wrapper) {
          width: 100%;
        }
      }
    }
    .organization_history_wrapper {
      .defined_roles_table {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
  }
}
