@import "theme";

.role_based_skills_wrapper {
  padding-bottom: 0;
  padding-top: 15px;

  > :global(.box-heading-wrapper) {
    padding: 0 15px 15px;

    :global(.box-heading) {
      width: 70%;
    }
  }

  .create_new_btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  .roll_based_skill_box_info {
    top: 15px;

    > span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  li {
    .skill_name {
      width: 33%;

      > span {
        display: inline-block;
      }

      .skill_gap_tooltip {
        display: inline-block;
        position: relative;

        .skill_gap_info {
          position: absolute;
          left: -30px;
          background: #ffffff;
          border: 1px solid #eaeaea;
          box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.05);
          padding: 5px;
          width: 100px;
          border-radius: 4px;
          text-align: center;
          top: auto;
          bottom: 40px;
          transition: 0.5s all;
          opacity: 0;
          visibility: hidden;
          font-weight: 500;
          font-size: 14px;

          &::before {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-left: 1px solid #eaeaea;
            border-top: 1px solid #eaeaea;
            background: #fff;
            border-radius: 2px;
            right: 51px;
            transform: rotate(-136deg);
            bottom: -5px;
          }
        }

        .skill_gap_icon {
          height: 18px;
          width: 18px;
          border: 1px solid transparent;
          border-radius: 50%;
          display: inline-block;
          margin-left: 3px;
          position: relative;
          transition: 0.5s all;
          top: 5px;
          cursor: pointer;

          &::before {
            background: #ccc;
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;
          }

          &:global(.red) {
            &::before {
              background-color: #f07575;
            }
          }

          &:global(.green) {
            &::before {
              background-color: #00c4bc;
            }
          }

          &:hover {
            background: #ffffff;
            border-color: #dfdfdf;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

            + .skill_gap_info {
              opacity: 1;
              visibility: visible;
              bottom: 30px;
            }
          }
        }
      }
    }

    .endorsement {
      width: 14%;
    }

    .relevant_exp,
    .last_used {
      width: 13%;
    }

    .current,
    .th_info_wrapper {
      width: 13.5%;
    }

    .required_skill {
      width: 10%;
    }
  }

  > :global(.box-inner-pad) {
    padding: 0 15px 0;
    // padding-bottom: 15px;
  }

  :global(.custom-accordion-2) {
    :global(.card) {
      :global(.card-header) {
        background-color: transparent;
        padding-left: 0;
        @include justify-content(space-between);

        .skill_gap_count {
        }

        :global(button) {
          padding-left: 30px;
          position: relative;
          z-index: 1;
          width: 90%;
          text-align: left;
        }
      }
    }
  }
}

.rolled_based_skill_title {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

  .categorise_skill_title {
    display: inline-block;

    &:first-child {
      width: 86.5%;
    }
  }
}

.categorise_skill_title {
  font-weight: 600;
  font-size: 14px;
  color: #322e46;
  letter-spacing: 0.47px;
  padding: 15px;
}

.skill_require_set_wrapper {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .skill_required_txt {
    text-transform: uppercase;
    margin-bottom: 15px;
    display: inline-block;
  }
}

.skill_gaps_table {
  // overflow-x: auto;
  .skills_head {
    padding: 0;
    list-style: none;

    li {
      display: block;

      > * {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #37324b;
      }
    }
  }

  .skills_body {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      font-weight: 500;
      font-size: 15px;
      color: #74798c;
      display: block;
      position: relative;
      margin-bottom: 15px;

      &:before {
        background: #b5afca;
        content: "";
        position: absolute;
        left: 0;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        top: 13px;
      }

      > * {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #37324b;

        &:first-child {
          padding-left: 15px;
        }
      }

      .required_skill {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.req_comp_level {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  font-weight: 600;

  &:global(.green) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.purple) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.orange) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }

  &:global(.Intermediate) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }

  &:global(.Expert) {
    color: #814cff;
    border-color: rgba(149, 106, 251, 0.23);
    background-color: #f9f7ff;
  }

  &:global(.Beginner) {
    color: #fd8c61;
    border-color: #fce6dd;
    background-color: #fcf6f4;
  }
}

.th_info_wrapper {
  display: inline-block;
  position: relative;

  > * {
    display: inline-block;
  }
}

.th_info_tooltip {
  text-align: left;
  margin-left: 10px;

  :global(.box-info) {
    margin: 0;
  }

  :global(.info-attach-file) {
    min-width: 240px;
    left: auto;
    right: -50px;

    // display: none;
    &::before {
      right: 54px;
      left: auto;
    }

    :global(.attachment-title) {
      color: #b4b0c8;
    }

    :global(ul) {
      margin: 10px 0;

      :global(li) {
        line-height: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

.endorse_tooltip {
  position: relative;
  display: inline-block;

  ~ .endorse_tooltip {
    margin-left: -7px;
  }

  .tool_tip_info {
    position: absolute;
    left: -25px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #37324b;
    bottom: 50px;
    padding: 5px;
    border-radius: 4px;
    @include transition(0.2s all);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    min-width: 120px;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 70px;
      height: 7px;
      width: 7px;
      border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      background-color: #fff;
      bottom: -4px;
      transform: rotate(45deg);
    }

    h6 {
      margin-bottom: 3px;
      font-weight: 600;
    }

    > span {
      display: block;
      text-align: center;
      font-size: 13px;
      margin-top: 2px;
      font-weight: 400;
    }
  }

  > img {
    cursor: pointer;

    &:hover {
      + .tool_tip_info {
        opacity: 1;
        bottom: 40px;
        visibility: visible;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .skill_gaps_table {
    overflow-x: auto;
    overflow-y: visible;

    > * {
      width: 1000px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .rolled_based_skill_title {
    .categorise_skill_title {
      &:first-child {
        width: 84.3%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .role_based_skills_wrapper {
    > :global(.box-heading-wrapper) {
      flex-wrap: wrap;

      :global(.box-heading) {
        width: 100%;
      }
    }

    .create_new_btn {
      margin-top: 10px;
    }
  }

  .skill_gaps_table {
    margin-top: 20px;
  }
}

// @media only screen and (max-width:767px){
//   .role_based_skills_wrapper{
//     :global(.box-heading-wrapper){
//       :global(.box-heading) {
//         width: 88%;
//       }
//     }
//   }
//   .skill_gaps_table{
//     > ul{
//       width: 100%;
//     }
//     .skills_head{
//       display: none;
//     }
//     .skills_body{
//       li{
//         padding: 10px;
//         border: 1px solid rgba($color: #000000, $alpha: 0.1);
//         margin:15px 0 0;
//         border-radius: 4px;
//         box-shadow: 0 2px 7px 1px rgba(0,0,0,0.1);
//         background-color: rgba(246,245,250,0.7);
//         &:before{
//           display: none;
//         }
//         > *{
//           width: 100%;
//           padding-bottom: 15px;
//           &::before {
//             content: attr(data-title);
//             width: 150px;
//             display: inline-block;
//             color: #37324B;
//             font-weight: 500;
//             text-transform: none;
//           }
//           &:first-child{
//             padding-left: 0;
//             > span{
//               width: calc(100% - 150px);
//               display: inline-block;
//               vertical-align: top;
//             }
//           }
//           &:nth-child(2){
//             width: 100%;
//           }
//           &:last-child{
//             width: 100%;
//             padding-bottom: 0;
//           }
//         }
//         &:last-child{
//           margin-bottom:15px;
//         }
//       }
//     }
//   }
// }
