@import '../../../theme.scss';
.waitingsubmitionwrapper{
  justify-content: center;
  align-items: center;
  display: flex;
    .waitingsubmitiontitle{
        font-size: 14px;
        font-weight: 500;
        color: #BDBCC3;
        letter-spacing: 0.32px;
        display: flex;     
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.10);
        border-radius: 4px;
        padding: 20px;
        justify-content: center;
        margin: 20px;
        width: 100%;
    }  
}