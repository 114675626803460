@import '../../theme';

.rewards_box{
    padding: 20px 0px;
    .reward_heading{
        padding: 0px 20px 20px 20px;
        border-bottom: 1px solid rgba(0,0,0,0.10);
        h2{
        font-size: 22px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.26px;
        color: #322E46
          
        }
    }
    .reward_data_wrapper{
        padding: 0px 20px 10px 20px ;
        display: flex;
        flex-direction: column;
        :global(.select_dropdown_text){
            width: auto;
        }
        h3{
            font-size: 16px;
            color: $themeColor11;
            display: flex;
            justify-content:center;
            padding: 0px 0px 20px 0px;
        }
        .userimg_wrapper{
            width: 75px;
            height: 75px;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            left:  67px;;
            z-index: 1;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ymmd_stat{
            position: relative;
            width: 185px;
            margin: 0px auto;
            font-size: 16px;
            font-weight: 400;
            color: $textShade1;
            .ymmd_sent{
                position: relative;
                left: 0;
                text-align: center;
                width: 75px;
                display: inline-flex;
                flex-direction: column;
                margin-right: 18px;
                    .ymmd_sent_count{
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    line-height: 74px;
                    text-align: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: $primaryText;
                }
            }
            .ymmd_rece{
                position: relative;
                right: 0;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: $textShade1;
                width: 75px;
                display: inline-flex;
                flex-direction: column;
                margin-left: 17px;
                .ymmd_rece_count{
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    line-height: 75px;
                    text-align: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: $primaryText;
                }
            }
            .reward_value{
                font-size: 20px;
                font-weight: 700;
                color: $primaryText;
                padding: 10px 0px 5px 0px;
            }
            .caption{
                color: $textShade1;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
            }
        }
    }
}

@media only screen and(max-width:767px){
    .rewards_box{
        .reward_heading{
            h2{
                font-size: 18px;          
            }
        }
    }    
}