@import "theme";

.btn_notification {
  position: relative;
  margin: 9px 30px 0 0;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
  img {
    @include flexbox;
  }
}

/*start responsive*/
@media (max-width: 1024px) {
  :global(.ml-auto) {
    display: inline-block;
    vertical-align: middle;
    .btn_notification {
      display: inline-flex;
      margin: 5px 0 0 0;
      vertical-align: middle;
    }
    :global(.dropdown) {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  :global(.ml-auto) {
    .btn_notification {
      margin: 0;
    }
  }
}
