.badge {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
  min-width: 86px;
  text-align: center;
  &:global(.ended) {
    color: #fd8c61;
    background: #fcf6f4;
    border-color: #fce6dd;
  }
  &:global(.pending) {
    color: #fd8c61;
    background: #fcf6f4;
    border-color: #fce6dd;
  }
  &:global(.submit) {
    color: #814cff;
    background: #f9f7ff;
    border-color: rgba(149, 106, 251, 0.23);
  }
  &:global(.saved) {
    color: #05ada6;
    border-color: rgba(36, 218, 210, 0.23);
    background-color: #f5ffff;
  }
}
