@import "../../theme.scss";

.home_tabs {
  :global(.nav-tabs) {
    :global(.nav-link) {
      padding: 16px 12px;
      text-align: center;
    }
  }
  .pending_action_wrapper {
    padding: 30px;
    overflow-y: scroll;
    height: 340px;
  }
}

@media only screen and (max-width: 1023px) {
  .home_tabs {
    margin-bottom: 20px;
  }
}
