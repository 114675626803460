@import "theme";

.filter_top_bar {
  @include flexbox;
  flex-wrap: wrap;
  .filter_box {
    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322e46;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }
    :global(.tags_wrapper) {
      position: relative;
      border: 0 solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: auto;
      transition: height 0.5s;
      :global(.multi-select) {
        :global(.dropdown-container) {
          border-color: rgba(0, 0, 0, 0.1);
          height: 47px;
          :global(.dropdown-heading) {
            height: 100%;
            :global(.dropdown-heading-value) {
              font-weight: 500;
              font-size: 15px;
            }
            :global(.dropdown-heading-dropdown-arrow) {
              padding-top: 0 !important;
            }
          }
          :global(.dropdown-content) {
            z-index: 2;
          }
        }
        :global(.select-panel) {
          :global(.select-item) {
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            :global(span) {
              width: 90%;
              vertical-align: top;
              position: relative;
            }
            :global(.item-renderer) {
              input[type="checkbox"] {
                opacity: 0;
              }
              :global(span) {
                position: relative;
                &::after {
                  content: "";
                  transform: scale(0);
                  transition: 0.2s linear all;
                  cursor: pointer;
                  left: -16px;
                  position: absolute;
                  top: 0;
                  background: #7033ff url(../../assets/images/checkbox-arrow.svg) no-repeat center;
                  width: 17px;
                  height: 17px;
                  opacity: 0;
                  border-radius: 4px;
                  background-size: 9px;
                }
                &::before {
                  border: 1px solid $textShade1;
                  content: "";
                  cursor: pointer;
                  display: inline-block;
                  height: 17px;
                  left: -16px;
                  position: absolute;
                  transition: 0.2s linear all;
                  width: 17px;
                  background-color: $whiteColor;
                  border-radius: 4px;
                }
              }
            }
            &:global(.selected) {
              :global(.item-renderer) {
                :global(span) {
                  &::after {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
          :global(li) {
            :global(label) {
              width: 100%;
              margin-bottom: 0;
              &:global(.selected),
              &:hover {
                background-color: #fcfaff;
              }
            }
            &:last-child {
              :global(label) {
                border-bottom: none;
              }
            }
          }
        }
      }
      :global(.select__control) {
        min-height: 46px;
        border-color: rgba(0, 0, 0, 0.1);
        :global(.select__placeholder) {
          color: #aaa;
          font-size: 15px;
          font-weight: 500;
        }
        :global(.select__indicators) {
          :global(.select__indicator-separator) {
            display: none;
          }
          :global(.select__dropdown-indicator) {
            color: #aaa;
          }
        }
      }
    }
    :global(.date_picker_time_wrapper) {
      padding: 0px !important;
      :global(.form-group) {
        width: 100% !important;
      }
    }
    :global(.multi-select) {
      :global(.dropdown-heading) {
        min-height: 44px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .filter_top_bar {
    .filter_box {
      width: 30%;
      margin: 0 3% 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .filter_top_bar {
    .filter_box {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .filter_top_bar {
    .filter_box {
      width: 46%;
      margin: 0 4% 15px 0;
    }
  }
}
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .filter_top_bar {
    .filter_box {
      width: 32%;
      margin: 0 1.33% 20px 0;
    }
  }
}
