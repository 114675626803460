@import "theme";

.custom_tabs {
  nav {
    a {
      padding: 17px 24px;
    }
  }
}

:global(.my-team-check-in) {
  // border-radius: 8px 8px 0 0;
  @include border-radius(8px 8px 0 0);
}

.pendingReviewNote {
  @include flexbox;
  @include flex-direction(column);

  .line {
    @include flexbox;
    @include justify-content(flex-end);
    margin: 0 0 3px 0;

    .note {
      font-size: 14px;
      color: $themeColor11;
      margin-right: 10px;
    }

    .btn {
      font-size: 14px;
      font-weight: 600;
      color: $primaryColor;
    }
  }
}

@media only screen and (max-width: 767px) {
  :global(.my-team-check-in) {
    :global(.box-heading-wrapper) {
      padding: 0 15px 15px;
      :global(.box-heading) {
        justify-content: left !important;
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
  }
}
