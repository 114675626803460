@import "theme";

.skillrequired_wrapper {
  :global(.page-title) {
    padding-bottom: 5px;

    + :global(span) {
      font-weight: 500;
      font-size: 14px;
      color: #928fa0;
      letter-spacing: 0;
    }
  }

  .technical_role_changed_button {
    width: 185px;
    margin-top: 27px;
    margin-right: 15px;
    :global(.btn) {
      width: 100%;
    }
  }

  .select_tech_role {
    display: block;

    .dd_title {
      font-weight: 600;
      font-size: 16px;
      color: #322e46;
      letter-spacing: 0.3px;
      margin-bottom: 5px;
      display: block;
    }

    :global(.custom-default-dd-box) {
      :global(.select__control) {
        min-height: 50px;
        border-color: rgba(0, 0, 0, 0.1);
      }

      :global(.select__indicator-separator) {
        display: none;
      }

      :global(.select__single-value) {
        font-size: 16px;
      }
    }
  }

  .skill_criteria_info {
    display: block;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .skil_criteria_text {
      position: relative;
      font-weight: 600;
      font-size: 13px;
      color: #59585a;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      display: inline-block;
      width: 100%;
      margin: 10px 0 15px;
      padding-left: 15px;

      &::before {
        background: #b5afca;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }

  .box_light_gradient_inner {
    &::before {
      left: -25px;
      top: -25px;
      width: calc(100% + 50px);
    }
  }

  .skill_box {
    position: relative;

    h5 {
      font-weight: 600;
      color: #322e46;
      letter-spacing: 0.36px;
      text-transform: uppercase;
      margin-bottom: 20px;

      + .skill_criteria_info {
        .skil_criteria_text {
          margin-top: 0;
        }
      }
    }
  }

  .skill_listing {
    list-style: none;
    padding-left: 0;
    margin: 0 10px 0 0;

    :global(.custom-checkbox) {
      margin-bottom: 0;
      cursor: auto;

      input[type="checkbox"] {
        + :global(label) {
          font-size: 16px;
          padding: 8px 10px 8px 40px;
          font-weight: 600;
          color: #928fa0;
          cursor: pointer;
          width: 100%;

          &::before {
            height: 20px;
            width: 20px;
            left: 12px;
            top: 11px;
          }

          &:after {
            height: 20px;
            width: 20px;
            background-color: #fff;
            background-image: url(../../../assets/images/ic_radio_purple.svg);
            background-size: 100%;
            left: 12px;
            top: 11px;
          }
        }
      }

      input[type="checkbox"] {
        &:checked {
          + :global(label) {
            color: #322e46;

            + .skill_info_show {
              height: auto;
              padding-top: 12px;
              padding-bottom: 12px;
              overflow: visible;
              display: flex;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    li {
      display: inline-block;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 10px;

      .skill_requirement {
        font-weight: 600;
        font-size: 13px;
        color: #928fa0;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        position: absolute;
        right: 10px;
        top: 13px;
      }

      .skill_info_show {
        height: 0;
        transition: 0.5s all;
        overflow: hidden;
        padding-left: 12px;
        padding-right: 12px;

        .skill_exp_info {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          margin-right: 15px;

          &:global(.error) {
            border-color: #f38686;

            > * {
              border-right-color: #f38686;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          > * {
            border-right: 1px solid rgba(0, 0, 0, 0.1);

            &:last-child {
              border-right: none;
            }
          }

          > span {
            font-weight: 500;
            font-size: 15px;
            color: #322e46;
            letter-spacing: 0;
            padding: 9px 12px;
            background-color: rgba(246, 245, 250, 0.61);
          }

          :global(.custom-default-dd-box) {
            :global(.select__control) {
              min-height: 40px;
              min-width: 108px;
              border: none;
              border-radius: 0;
            }

            :global(.select__indicator-separator) {
              display: none;
            }

            :global(.select__single-value) {
              font-size: 15px;
              font-weight: 500;
            }
          }

          :global(.competensy-level-dd) {
            :global(.select__control) {
              min-width: 140px;
            }

            :global(.select__menu) {
              right: 0;
              width: 290px;

              :global(.select__option) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                  border-bottom: 0;
                }

                &:nth-child(2) {
                  position: relative;

                  &:after {
                    // content: '(Expected)';
                    position: absolute;
                    right: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #928fa0;
                    letter-spacing: 0.27px;
                  }
                }
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .saveBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }

  .save_box_left {
    :global(.btn) {
      min-width: 150px;
      font-size: 14px;

      :global(strong) {
        font-weight: 600;
      }
    }
  }

  .save_box_right {
    font-size: 14px;
    line-height: 16px;
    color: #b4b0c8;

    span {
      color: #928fa0;
      font-weight: 500;
    }
  }

  .save_box_center {
    margin: auto;
    font-size: 14px;
    line-height: 16px;
    color: #b4b0c8;

    span {
      color: #928fa0;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .skillrequired_wrapper {
    display: inline-block;
    width: 100%;
    margin-top: 30px;

    .skill_listing {
      margin-right: 0;

      .skill_info_show {
        .skill_exp_info {
          &:last-child {
            margin-top: 15px;
          }
        }
      }

      :global(.custom-checkbox) {
        input[type="checkbox"] {
          + :global(label) {
            &::before {
              height: 18px;
              width: 18px;
              left: 18px;
              top: 12px;
            }

            &:after {
              height: 18px;
              width: 18px;
              left: 18px;
              top: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .skillrequired_wrapper {
    display: inline-block;
    width: 100%;
    margin-top: 30px;

    .skill_listing {
      .skill_info_show {
        .skill_exp_info {
          &:nth-child(2) {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .skillrequired_wrapper {
    .skill_box {
      h5 {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }

    .box_light_gradient_inner {
      &::before {
        left: -15px;
        width: calc(100% + 30px);
        top: -15px;
      }
    }

    .skill_listing {
      li {
        .skill_requirement {
          top: 11px;
          font-size: 12px;
        }

        .skill_info_show {
          .skill_exp_info {
            margin-right: 0;
            width: 100%;

            > span {
              font-size: 13px;
              width: 100%;
              border-right: 0;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

              ~ div {
                width: 50%;
              }
            }

            :global(.custom-default-dd-box) {
              :global(.select__single-value) {
                font-size: 13px;
              }
            }

            &:last-child {
              > span {
                ~ div {
                  width: 100%;

                  :global(.competensy-level-dd) {
                    :global(.select__menu) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      :global(.custom-checkbox) {
        input[type="checkbox"] {
          + :global(label) {
            font-size: 14px;

            &::before {
              top: 10px;
            }

            &:after {
              top: 10px;
            }
          }
        }
      }
    }

    .saveBox {
      &:global(.box-flex-center) {
        display: block;
        text-align: center;
      }
    }

    .save_box_left {
      :global(.btn) {
        min-width: 140px;
      }
    }

    .save_box_right {
      margin-top: 15px;
    }
  }
}
