.grade_card_wrapper {
  position: relative;
  display: flex;
  padding: 25px 25px 10px;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .manager_rating_wrapper {
    width: 50%;
  }
  .overAll_wrapper {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 15px;
  }
  .card_heading {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .card_text {
    position: relative;
    color: #37324b;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    > {
      margin-bottom: 5px;
    }
    &::before {
      content: "";
      position: absolute;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: #b5afca;
      left: -19px;
      top: 7px;
    }
    span {
      font-weight: 600;
    }
  }
  :global(.manager_rating_wrapper) {
    font-weight: 600;
  }
}

@media only screen and (max-width: 467px) {
  .grade_card_wrapper {
    flex-direction: column;
    .manager_rating_wrapper {
      width: 100%;
    }
    .overAll_wrapper {
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 0;
      padding-top: 15px;
    }
  }
}
