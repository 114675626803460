@import "theme";

.employeeObjectives_box {
}

// Process indicate
.employeeObjectives_process_indicate {
  // @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  margin-top: 3px;
  margin-right: 5px;
  position: absolute;
  color: #000000 !important;
  left: 10px;
  .emp_objective_tooltip {
    top: -14px !important;
    left: 25px !important;
    min-width: 160px !important;
    &:before {
      border-right: 1px solid #eaeaea !important;
      border-top: 1px solid #eaeaea !important;
      left: -6px !important;
      top: -8px !important;
      transform: rotate(-135deg) !important;
    }
  }
  .employeeObjectives_step {
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    border: 1px solid $textShade3;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: relative;
    overflow: hidden;
    + .employeeObjectives_step {
      margin-left: 8px;
    }

    .step1,
    .step2,
    .step3 {
      width: 5px;
      height: 5px;
      @include border-radius(50%);
      background: $textShade1;
      @include transition(all, 0.2s, ease);
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }

    &:hover .step,
    .active {
      width: 16px;
      height: 16px;
    }

    .step1 {
      background: #6dd400;
    }

    .step2 {
      background: #ffcc40;
    }

    .step3 {
      background: #f07575;
    }
  }
}

.key_initiative_wrapper {
  display: inline-block;
  padding-left: 33px;
  // position: absolute;
  // left: 0;
  // bottom: 0;
  > span {
    display: inline-block;
    color: #928fa0;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #979797;
    line-height: 15px;
    font-size: 14px;
    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
    &.initiative_wrapper {
      cursor: pointer;
      @include transition(0.2s all);
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
.objective_error_box {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  left: 103px;
  width: 170px;
  text-align: center;
  top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &::after {
    content: "";
    background-color: #fff;
    height: 7px;
    width: 7px;
    position: absolute;
    left: -5px;
    top: 16px;
    margin: auto;
    transform: rotate(-225deg);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  span {
    color: #050505;
    font-weight: 500;
    font-size: 14px;
    background: url(../../assets/images/ic_info_pink.svg) no-repeat left center;
    padding: 3px 0 4px 25px;
  }
}

// Heading right
.employeeObjectives_process_complete {
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: $primaryText;
}
.employeeObjectives_end_days {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.36px;
  color: $textShade1;
}
// idp list
.emp_obj_end_days {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.36px;
  color: $textShade1;
  position: absolute;
  right: 0;
  bottom: -19px;
  width: 100px;
}
.employeeObjectives_info_list {
  padding-left: 15px;
  .employeeObjectives_info_block,
  :global(.employeeObjectives_info_block) {
    @include border-radius(4px);
    padding-left: 10px;
    padding-right: 0;
    @include transition(all, 0.4s, linear);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    &.open {
      padding: 10px 20px 20px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    :global(.custom-checkbox) {
      + :global(div) {
        :global(.custom-checkbox) {
          display: inline-block;
          margin: 0 0 0 5px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
      :global(.custom-checkbox) {
        margin-bottom: 5px;
      }
    }
  }
  .employeeObjectives_info_title {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    position: relative;
    padding-right: 150px;
    :global(.custom-checkbox) {
      margin-bottom: 0;
      label {
        word-break: break-word;
      }
    }
  }
  .weightage_detail {
    color: $textShade1;
    font-size: 13px;
    background: url(../../../src/assets/images/ic_keyresult_bullet.svg) no-repeat 0px 5px;
    margin-left: 40px;
    padding-left: 10px;
  }
  .employeeObjectives_info_details {
    padding-left: 20px;
    margin-left: 38px;
    background: url(../../assets/images/ic-child-arrow.svg) no-repeat top left;
    margin-bottom: 24px;
    .employeeObjectives_info_row {
      @include flexbox;
      @include align-content(flex-start);
      font-size: 14px;
      color: $primaryColor;
      img {
        margin-right: 8px;
      }
      a {
        text-decoration: underline;
        margin-bottom: 5px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.employeeObjectives_box {
  padding-bottom: 0;
  :global(.custom-accordion) {
    :global(.accordion-icon) {
      right: 0;
    }
    :global(.employeeObjectives_info_action) {
      width: 137px; //width:75px instead of min-width: 100px
      text-align: right;
      justify-content: flex-end;
      font-size: 16px;
      color: $themeColor11;
      font-weight: 600;
      position: absolute;
      right: 0;
      top: -7px;
      :global(.form-group) {
        margin-bottom: 0;
        width: 100%;
        position: relative;
        :global(.percentage) {
          position: absolute;
          right: -4px;
          top: 10px;
        }
        :global(.dollor-hash) {
          position: absolute;
          right: 2px;
          top: 10px;
        }
        :global(.info-attach-file) {
          min-width: 110px;
          @include box-shadow(0, 3px, 4px, rgba(0, 0, 0, 0.09), false);
          left: 47px;
          margin: auto;
          top: -55px;
          padding: 12px 5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          text-align: center;
          > div {
            display: inline-block;
            margin-right: 5px;
          }
          > h5 {
            > div {
              display: inline-block;
              margin-right: 5px;
            }
            > span {
              display: inline-block;
              vertical-align: middle;
            }
          }
          > span {
            display: inline-block;
            vertical-align: middle;
          }
          &:global(.dollarTooltip) {
            min-width: 250px;
            left: -110px;
            &::before {
              right: -140px;
            }
          }
          &::before {
            top: auto;
            bottom: -6px;
            transform: rotate(135deg);
            box-shadow: none;
            height: 11px;
            width: 11px;
            left: 67px;
            border-top-color: rgba(0, 0, 0, 0.2);
            border-right-color: rgba(0, 0, 0, 0.2);
          }
        }
        :global(.form-control) {
          padding: 10px 12px 10px 0;
          height: auto;
          background-color: transparent;
          text-align: right;
          @include transition(0.4s, all, ease-in-out);
          border-color: transparent;
          cursor: pointer;
          position: relative;
          z-index: 1;
          &:hover {
            + :global(.info-attach-file) {
              top: -45px;
              opacity: 1;
              visibility: visible;
            }
          }
          &:focus {
            padding: 10px 5px;
            cursor: auto;
            border-color: $primaryColor;
            background-color: #fff;
            + :global(.info-attach-file) {
              top: -55px;
              opacity: 0;
              visibility: hidden;
            }
          }
        }
        :global(.form-control.error) {
          border-color: #f10a0a;
        }
      }
    }
    :global(.card) {
      padding: 20px 25px 25px;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      :global(.card-header) {
        padding-top: 0;
        border-top: none;
        :global(.btn) {
          padding: 0 45px 0 0;
          margin-left: 85px;
          position: relative;
          width: 87%;
          border-radius: 0;
        }
        :global(.indicator_manager_view) {
          padding: 0 45px 0 0 !important;
          margin-left: 30px !important;
          position: relative !important;
          width: 96% !important;
        }
      }
      :global(.card-body) {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 25px;
        padding-left: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .obj_manager_comments {
    padding-top: 2px;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important ;
    > :global(.two_column) {
      padding-top: 18px;
    }
  }
}
.hide_title {
  opacity: 0;
}
.complete_objective_notify {
  background: rgba(246, 245, 250, 0.61);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px 24px;
  margin: 15px 0px 0;
  display: flex;
  span {
    margin: 0px 10px 0px 0px;
    display: inline-flex;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: $primaryText;
    margin: 0px 0px 0px 0px;
    padding-right: 20px;
  }

  .btn_cancel {
    background: #ffffff;
    border: 1px solid rgba(184, 180, 204, 0.5);
    border-radius: 21px;
    font-size: 14px;
    color: #7033ff;
    letter-spacing: 0;
    text-align: center;
    min-width: 127px;
    height: 40px;
  }
}

.system_objective_status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -2px;
  &.red {
    background-color: $themeColor1;
  }
  &.yellow {
    background-color: $mode3Dark;
  }
  &.green {
    background-color: $themeGreen;
  }
}
.view_all_objective_comment {
  display: inline-block;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  span {
    color: #7033ff;
    font-size: 14px;
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.5;
  cursor: default !important;
}

.time_frame_info {
  font-weight: 500;
  font-size: 14px;
  color: $textShade1;
  letter-spacing: 0.32px;
  margin: 10px 0 0;
  padding-left: 33px;
  span {
    font-size: 16px;
    color: #393f51;
    letter-spacing: 0.36px;
  }
}

//manager wise objective
.member_manager_wrapper {
  display: inline-block;
  width: 100%;
  .manager_list {
    display: inline-block;
    width: 100%;
    .topbar {
      @include flexbox;
      @include align-items(center);
      padding: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .collapse_img {
        cursor: pointer;
        margin-right: 15px;
        position: relative;
        top: -1px;
        :global(.colaps) {
          display: none;
        }
      }
      .manager_info {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        h4 {
          color: #928fa0;
          font-weight: 600;
          strong {
            color: #322e46;
            font-weight: 600;
          }
        }
        span {
          color: #322e46;
          font-size: 20px;
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .obj_manager_comments {
    :global(.view_comment_box_wrapper) {
      :global(.comment_area_wrapper) {
        position: relative;
        :global(.manage_comment_view) {
          // padding-right: 180px;
          // display: inline-block;
          // width: 100%;
          // margin-top: 0;
          padding-right: 0;
          display: inline-block;
          width: 70%;
          margin-top: 0;
          :global(.custom-checkbox),
          :global(.manage_comment_visible_to) {
            display: inline-block;
          }
        }
        :global(.checkin-question-info) {
          display: inline-block;
          width: 30%;
          position: static;
          vertical-align: top;
          padding-right: 36px;
          margin-top: 5px !important;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .obj_manager_comments {
    :global(.view_comment_box_wrapper) {
      :global(.comment_area_wrapper) {
        :global(.manage_comment_view) {
          margin-top: 0;
        }
      }
    }
  }
  .employeeObjectives_process_complete {
    font-size: 18px;
  }
  .employeeObjectives_end_days {
    font-size: 14px;
  }
  .employeeObjectives_box {
    :global(.custom-accordion) {
      :global(.card) {
        :global(.card-header) {
          :global(.btn) {
            width: calc(100% - 85px);
          }
        }
      }
      :global(.accordion-heading) {
        :global(.accordion-heading-left) {
          :global(h3) {
            font-size: 18px;
          }
          :global(h4) {
            font-size: 14px;
          }
        }
      }
      :global(.employeeObjectives_info_action) {
        :global(.form-group) {
          :global(.info-attach-file) {
            right: auto;
            // display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .member_manager_wrapper {
    .manager_list {
      :global(.comment_box_wrapper) {
        :global(.comment_area_wrapper) {
          :global(.custom-tooltip) {
            :global(.info-attach-file) {
              left: -236px !important;
              &::before {
                left: 225px !important;
              }
            }
          }
        }
      }
    }
  }
  .employeeObjectives_box {
    :global(.custom-accordion) {
      :global(.card) {
        :global(.card-header) {
          :global(.indicator_manager_view) {
            width: calc(100% - 30px) !important;
          }
        }
      }
    }
  }
  .employeeObjectives_process_complete {
    font-size: 14px;
    margin-right: 5px;
    line-height: normal;
  }
  .employeeObjectives_end_days {
    font-size: 12px;
    line-height: normal;
  }
  .employeeObjectives_process_indicate {
    margin-right: 10px;
    left: 0;
  }
  .employeeObjectives_info_list {
    .weightage_detail {
      margin-top: 6px;
    }
    :global(.custom-checkbox) {
      + :global(.employeeObjectives_info_action) {
        width: 100%;
        :global(.cmp-in-comp-info) {
          margin-top: 0;
          font-size: 13px;
          :global(.custom-checkbox) {
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
  .employeeObjectives_box {
    :global(.custom-accordion) {
      :global(.card) {
        padding: 10px 15px;
        :global(.card-header) {
          :global(.btn) {
            padding-right: 20px;
          }
        }
      }
      :global(.accordion-icon) {
        top: 0;
        margin-top: 0;
        width: 15px;
        // right: 20px;
        svg {
          width: 100%;
        }
      }
      :global(.accordion-heading) {
        align-items: normal;
        flex-direction: column;
        :global(.accordion-heading-left) {
          width: 100%;
          :global(h3) {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 2px;
          }
          :global(h4) {
            font-size: 13px;
            line-height: 16px;
          }
        }
        :global(.accordion-heading-right) {
          text-align: left;
          display: flex;
          align-items: center;
          padding-left: 0;
          margin-top: 5px;
          margin-bottom: 5px;
          .emp_obj_end_days {
            position: static;
            font-size: 14px;
          }
        }
      }
    }
  }
  .complete_objective_notify {
    padding: 15px;
    flex-wrap: wrap;
    p {
      padding-right: 0;
      display: inline-block;
      width: 89%;
      font-size: 14px;
    }
    .btn_cancel {
      margin: 15px 0 0 20px;
      line-height: normal;
      min-width: 100px;
    }
  }
  .obj_manager_comments {
    :global(.manage_comment_view) {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .employeeObjectives_info_list {
    .employeeObjectives_info_block,
    :global(.employeeObjectives_info_block) {
      padding-left: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  .employeeObjectives_info_list {
    .employeeObjectives_info_title {
      padding-right: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .employeeObjectives_box {
    :global(.custom-accordion) {
      :global(.card) {
        :global(.card-header) {
          + :global(div) {
            :global(.card-body) {
              padding: 10px 0 0;
              margin-top: 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      :global(.employeeObjectives_info_action) {
        position: static;
        padding-left: 38px;
        margin-top: 5px;
        text-align: left;
        justify-content: flex-end;
        :global(.form-group) {
          :global(.form-control) {
            text-align: left;
            padding: 0;
            + :global(.info-attach-file) {
              min-width: 140px;
              left: 0;
              top: auto;
              bottom: 40px;
              padding: 10px;
              &:before {
                right: auto;
                left: 10px;
              }
              h5 {
                font-size: 13px;
                text-align: left;
              }
            }
            &:hover {
              + :global(.info-attach-file) {
                top: auto;
                opacity: 1;
                visibility: visible;
                bottom: 30px;
              }
            }
            &:focus {
              padding: 10px;
              + :global(.info-attach-file) {
                bottom: 50px;
              }
            }
            // &:disabled{
            //   padding: 0;
            // }
          }
        }
      }
    }
  }
}
