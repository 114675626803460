.right_side_value_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.margin_cover {
    margin: -10px 0;
  }
  .text {
    font-weight: normal;
  }
  .manager_rating_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .rating_info {
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .right_side_value_wrapper {
    flex-direction: column;
    align-items: flex-start;
    .title_text {
      margin-bottom: 4px;
    }
    .manager_rating_wrapper {
      align-items: flex-start;
    }
  }
}
